/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'ko' | 'nl' | 'pt-BR' | 'ru' | 'zh-CN';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "AccountLimitReached": "Das Konto hat das maximale Dokumentenlimit erreicht.",
      "AttachedByLabel": "Angehängt von {{signer}}",
      "AttachmentDetailsLabel": "Anlagen",
      "AttachmentFieldLabel": "Anlage",
      "CheckFieldLabel": "Kontrollkästchen",
      "CheckGroupFieldLabel": "Kontrollkästchengruppe",
      "DateFieldLabel": "Datum",
      "InitialsFieldLabel": "Initialen",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Nach Unterzeichnung belasten",
        "chargedLabel": "{<paymentAmount>} belastet",
        "collectCardDetailsLabel": "Kartendetails sammeln",
        "defaultLabel": "Zahlung",
        "paymentFailedLabel": "Zahlung fehlgeschlagen"
      },
      "PaymentFieldLabel": "Feld 'Zahlung'",
      "SelectFieldLabel": "Dropdownmenü",
      "SignatureFieldLabel": "Signatur",
      "TextFieldLabel": "Text",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Ihr aktueller Plan begrenzt die Anzahl der Dokumente, die zur Signatur gesendet werden können, und Sie haben das Limit erreicht. Sie können Dokumente immer noch selbst unterschreiben.",
        "upgradeTrialBanner": "Sie haben {<documentsLeft>} in Ihrer Testversion noch übrig."
      },
      "accountLimitExhaustedMessage": "Das Dokument kann nicht zur Signatur gesendet werden, da Sie das Limit für das Konto erreicht haben. Bitte führen Sie ein Upgrade des Kontos durch.",
      "accountStatsFetchErrorMessage": "Kontoinformationen konnten nicht abgerufen werden",
      "add": "Hinzufügen",
      "addCheckbox": "Kontrollkästchen hinzufügen",
      "addFilesFromSharefile": "Elemente von ShareFile hinzufügen",
      "addMyself": "Mich selbst hinzufügen",
      "addOptionLabel": "Option hinzufügen",
      "addRecipient": "Weiteren Empfänger hinzufügen",
      "addSignature": "Signatur hinzufügen",
      "annotator": "Sie (jetzt)",
      "annotatorOptionTooltip": "Felder im Voraus ausfüllen",
      "apply": "Anwenden",
      "archiveLocationBlank": "Wählen Sie einen Ordner zum Speichern des signierten Dokuments aus",
      "assignedToLabel": "Zugewiesen zu:",
      "attachmentFieldLabel": "Anlage",
      "browseFiles": "Dateien durchsuchen",
      "bulkSend": "Massensendung",
      "cancel": "Abbrechen",
      "changesSaved": "Änderungen gespeichert",
      "checkbox": "Kontrollkästchen",
      "checkboxGroup": "Kontrollkästchengruppe",
      "checkboxes": "Kontrollkästchen",
      "clear": "Löschen",
      "clearBackground": "Clear background",
      "close": "Schließen",
      "completed": "Abgeschlossen",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} ausgewählte Felder",
      "confirm": "Bestätigen",
      "continue": "Weiter",
      "createDocument": {
        "almostDone": "Fast fertig. Klicken Sie nicht auf die Schaltfläche 'Aktualisieren' oder 'Zurück'"
      },
      "createDocumentErrorMessage": "Dokument konnte nicht erstellt werden",
      "creationDateLabel": "Gesendet am",
      "dashboard": {
        "adminView": "Administratoransicht",
        "adminViewTooltipText": "Alle Dokumente anzeigen, die von diesem Konto gesendet wurden",
        "autoDeleteText": "Automatische Löschung in {{remaining}} Tag(en)",
        "clearFilters": "Filter entfernen",
        "clearSearch": "Suche löschen",
        "continueAction": "Weiter",
        "deleteAction": "Löschen",
        "downloadPdf": "Download",
        "esignLeftNav": "Signaturen",
        "loadMore": "Mehr laden",
        "overdue": "1 Tag überfällig",
        "overdue_plural": "{{count}} Tage überfällig",
        "signatureDue": "Fällig am {{dueDate}}",
        "signatureRequests": "Signaturanforderungen",
        "signatureSettings": "Signatureinstellungen",
        "signaturesSubmitted": "Signaturen gesendet",
        "templates": "Vorlagen",
        "title": "Signaturanforderungen"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Keine Übereinstimmung mit Ihren Filtern gefunden.",
          "title": "Hier gibt es nichts"
        },
        "noRequestsCreated": {
          "description": "Benötigen Sie eine Signatur? Erstellen Sie eine Anforderung.",
          "title": "Sie haben noch keine Signaturanforderungen"
        },
        "noSearchResults": {
          "description": "Ihre Suche ergab keine Treffer.",
          "title": "Hier gibt es nichts"
        }
      },
      "date": "Datum",
      "dateExample": "Beispiel:",
      "dateExtendError": "Bitte wählen Sie ein zukünftiges Datum",
      "dateFormatLabel": "Format",
      "dateSignedLabel": "Am Datum der Signatur automatisch ausfüllen",
      "dateSignedTooltip": "Ein Empfänger kann das Feld nicht bearbeiten. Es wird an dem Tag, an dem das Dokument signiert wird, automatisch ausgefüllt.",
      "declinedByLabel": "Abgelehnt von {{name}}",
      "defaultBadgeLabel": "Standard",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Allen eine E-Mail mit der abgeschlossenen PDF-Datei senden",
          "attachCompletedPDFCheckboxTooltip": "Nach der letzten Signatur senden wir allen eine E-Mail mit der abgeschlossenen PDF-Datei als Anlage (es sei denn, sie ist größer als 10 MB).",
          "sectionHeader": "Anlagen"
        },
        "header": "Standardeinstellungen bearbeiten",
        "headerInfo": "Alle Änderungen, die Sie hier vornehmen, werden auf alle offenen Signaturanforderungen angewendet.",
        "hipaaConfirmationBodyFirst": "Alle Empfänger erhalten eine Kopie des signierten Dokuments als E-Mail-Anhang, wenn diese Einstellung angewendet wird. Als HIPAA-Kunde liegt es in Ihrer Verantwortung, zu bestätigen, dass die Empfänger die Verarbeitung ihrer Daten auf diese Weise zulassen.",
        "hipaaConfirmationBodySecond": "Möchten Sie diese Einstellung wirklich anwenden?",
        "hipaaConfirmationButton": "Einstellung anwenden",
        "hipaaConfirmationHeader": "Überlegungen zu HIPAA",
        "reminders": {
          "options": {
            "daily": "Täglich",
            "noAuto": "Nie",
            "weekly": "Wöchentlich"
          },
          "reminderFrequencyLabel": "Automatische Erinnerungen senden",
          "reminderFrequencyTooltip": "E-Mail-Erinnerungen an die Empfänger entweder wöchentlich (nach 3, 7, 14, 21 und 28 Tagen) oder täglich (nach 7 Tagen), bis sie unterschreiben.",
          "sectionHeader": "Erinnerungen"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Gezeichnete Signaturen zulassen",
          "allowTypedSignatureLabel": "Getippte Signaturen zulassen",
          "applyBlueInkSignatureLabel": "Signaturen mit blauer Tinte",
          "applyBlueInkSignatureTooltipAdmin": "Wenden Sie sich an den Administrator, wenn Sie diese Einstellung ändern müssen.",
          "applyBlueInkSignatureTooltipNonAdmin": "Blaue Tinte für alle Unterschriften verwenden, um signierte Originale von Fotokopien zu unterscheiden.",
          "sectionHeader": "Signaturtypen"
        },
        "signer": {
          "allowDownload": "Unterzeichner können das Originaldokument herunterladen, bevor sie es unterzeichnen",
          "sectionHeader": "Unterzeichnerberechtigungen"
        }
      },
      "defaultSettingsDescription": "Ändern Sie die Einstellungen für Erinnerungen, E-Mail-Anlagen und Signaturtypen.",
      "defaultSettingsEditLabel": "Standardeinstellungen bearbeiten",
      "defaultSettingsLabel": "Standardeinstellungen",
      "defaultSettingsUpdateFailed": "Fehler beim Aktualisieren der Standardeinstellungen",
      "defaultSettingsUpdateSuccess": "Standardeinstellungen aktualisiert",
      "defaultValueLabel": "Standardtext hinzufügen",
      "delete": "Löschen",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Hiermit wird die Signaturanforderung endgültig gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.",
        "deleteConfirmationMessageSecond": "Unterzeichner und Leser erhalten eine E-Mail mit dem ausgefüllten Dokument und dem Signaturzertifikat für ihre Unterlagen.",
        "deleteRequest": "Anforderung löschen",
        "deleteRequestSuccess": "Anforderung gelöscht"
      },
      "deleteDraftDocumentLabel": "Signaturanforderung löschen",
      "deleteDraftDocumentModalContent": "Möchten Sie die Signaturanforderung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
      "deleteDraftDocumentSuccess": "Signaturanforderung gelöscht",
      "deleteViewerWarningHeader": "Möchten Sie diesen Empfänger wirklich entfernen?",
      "deleteViewerWarningMessage": "Sie haben keinen Zugriff mehr auf diese Signaturanforderung.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Passcode bearbeiten",
        "kba": "Wissensbasierte Authentifizierung",
        "passcode": "Passcode",
        "title": "Sicherheitsoption angewendet",
        "updatePasscodeSuccess": "Der Passcode dieses Dokuments wurde aktualisiert."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "Sie können Anforderungen, die als Massensendung gesendet wurden, nicht überarbeiten",
          "signerSigned": "Sie können Anforderungen nicht mehr überarbeiten, sobald jemand unterschrieben hat",
          "userNotSender": "Sie können Anforderungen, die nicht von Ihnen gesendet wurden, nicht überarbeiten"
        }
      },
      "documentAlreadySentErrorDetails": "Gesendetes Dokument kann nicht zur Bearbeitung geöffnet werden. Starten Sie einen neuen Workflow.",
      "documentAlreadySentErrorTitle": "Dokument bereits gesendet",
      "documentForSignature": "Zu sendendes Dokument",
      "documentForceCompleted": "Abschluss der Anforderung erzwungen",
      "documentLockedMessage": "Dieser Empfänger ist aufgrund mehrerer fehlgeschlagener Authentifizierungsversuche vorübergehend gesperrt",
      "documentNameBlank": "Geben Sie einen Dokumentnamen ein",
      "documentNameInputDisabledTooltip": "Der Dokumentname kann nicht bearbeitet werden",
      "documentNameInputLabel": "Dokumentname",
      "documentStates": {
        "declined": "Abgelehnt",
        "draft": "Entwurf",
        "editing": "Zur Überarbeitung angehalten",
        "executed": "Abgeschlossen",
        "expired": "Abgelaufen",
        "pending": "In Bearbeitung",
        "voided": "Storniert"
      },
      "documentThumbnailAltText": "Miniaturansicht der ersten Seite",
      "documentTransactionCreateErrorMessage": "Dokumententransaktion konnte nicht erstellt werden",
      "documentTransactionGetErrorMessage": "Fehler beim Abrufen der Dokumenttransaktion",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Entsperren des Dokuments erfolgreich",
      "documentView": {
        "documentPageIndex": "Seite {<currentPage>} von {{totalPages}}",
        "pageChangeInputLabel": "Seite wechseln zu",
        "pageDown": "Nächste Seite",
        "pageUp": "Vorherige Seite"
      },
      "documentVoided": "Signaturanforderung storniert",
      "dontShowAgain": "Diese Meldung nicht mehr anzeigen",
      "downloadOptions": {
        "combinedPDF": "Zusammengefügte PDF",
        "completedPDF": "Fertige PDF",
        "download": "Download",
        "downloadErrorMessage": "Nicht unterstützter Downloadtyp: {{downloadType}}",
        "downloadOriginal": "Original-PDF",
        "signatureCertificate": "Signaturzertifikat"
      },
      "draftSaveErrorMessage": "Fehler beim Speichern der Signaturanforderung",
      "draftSaveSuccessMessage": "Signaturanforderung gespeichert",
      "draw": "Zeichnen",
      "dueDate": "Fälligkeitsdatum",
      "dueDateUpdated": "Fälligkeitsdatum aktualisiert",
      "edit": "Bearbeiten",
      "editDocumentModal": {
        "cancel": "Zurückgehen",
        "confirm": "Überarbeiten",
        "contentLine1": "Wenn Sie Empfänger oder Felder hinzufügen oder entfernen oder Ihre Sicherheitsoptionen ändern müssen, können Sie diese Anforderung überarbeiten.",
        "contentLine2": "Die Anforderung wird angehalten, während Sie Änderungen vornehmen. Dies bedeutet, dass jeder, der sich noch nicht angemeldet hat, vorübergehend keinen Zugriff mehr hat. Wir senden ihnen einen neuen Link, wenn Sie fertig sind.",
        "contentLine3": "Sobald Sie mit der Überarbeitung begonnen haben, können Sie das Anhalten der Anforderung nicht rückgängig machen, ohne ein überarbeitetes Dokument abzusenden.",
        "title": "Möchten Sie diese Anforderung wirklich überarbeiten?"
      },
      "editDueDate": "Fälligkeitsdatum bearbeiten",
      "editLeftMidwayModal": {
        "cancel": "Zurückgehen",
        "confirm": "Später fertigstellen",
        "contentLine1": "Unterzeichner können nicht auf diese Anforderung zugreifen, solange sie zur Überarbeitung angehalten ist. Wenn Sie fertig sind, gehen Sie zurück und wählen Sie ",
        "contentLine2": "Wenn Sie noch nicht bereit sind, können Sie später Ihre Überarbeitungen abschließen.",
        "title": "Überarbeiten Sie noch?"
      },
      "editRecipients": "Empfänger bearbeiten",
      "editSignatureRequest": "Überarbeiten",
      "editSigner": "Unterzeichner bearbeiten",
      "editViewer": "Leseberechtigte bearbeiten",
      "emptyFolder": "Dieser Ordner ist leer",
      "enforceStorageUsage": "Sie können erst wieder neue Signaturanforderungen erstellen, wenn Sie Speicherplatz freigeben",
      "expirationDateMessage": "Ablaufdatum: {{expirationDate}}",
      "expirationDaysLabel": "Tag(e)",
      "expirationInputDescription": "Das Dokument kann nach der angegebenen Anzahl von Tagen nicht mehr unterzeichnet werden:",
      "expirationInputLabel": "Ablauf",
      "extendDueDate": "Fälligkeitsdatum verlängern",
      "extendExpirationDate": "Verlängern",
      "favorites": "Favoriten",
      "field": "Feld",
      "fieldNameLabel": "Namensfeld",
      "fieldRequired": "Dieses Feld ist erforderlich.",
      "fields": "Felder",
      "fieldsAssignedWarningHeader": "Den Unterzeichnern zugewiesene Felder",
      "fieldsAssignedWarningMessage": "Alle dem/den Unterzeichner(n) zugewiesenen Felder werden entfernt.",
      "fieldsDescription": "Wählen Sie einen Feldtyp aus, der in Ihrem Dokument platziert werden soll",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Bearbeiten",
        "sectionLabel": "Wo möchten Sie das signierte Dokument speichern?"
      },
      "fileNotSupportedTooltip": "Das Dateiformat wird nicht unterstützt",
      "filesAndRecipients": "Dateien und Empfänger",
      "filterSelected": "1 Filter",
      "filterSelected_plural": "{{count}} Filter",
      "forceComplete": "Abschluss erzwingen",
      "forceCompleteTitle": "Abschluss von Anforderung erzwingen",
      "forceCompleteWarningFirst": "Dadurch werden alle Felder entfernt, die Unterzeichnern zugewiesen sind, die das Dokument noch nicht signiert haben, und das Dokument erhält den Endstatus 'unterzeichnet'. Dieser Vorgang kann nicht rückgängig gemacht werden.",
      "forceCompleteWarningSecond": "Es kann einen Moment dauern, bis der neue Status im Dashboard angezeigt wird.",
      "generateNewPasscodeButtonLabel": "Neuen Passcode generieren",
      "genericError": "Ein Fehler ist aufgetreten",
      "groupRule": "Gruppenregel",
      "hasAtleastOneSignerErrorMessage": "Fügen Sie mindestens einen Unterzeichner hinzu, um fortzufahren",
      "importFields": {
        "linkText": "Felder importieren",
        "modal": {
          "assignImportedFields": "Importierte Felder zuweisen",
          "body": {
            "buttonText": "Auswählen",
            "searchPlaceholder": "Nach Dokumentname, Dateiname, Absender oder Rolle suchen",
            "sentBy": "Gesendet von"
          },
          "componentsImported": "{{count}} Felder importiert",
          "fields": "Feld(er)",
          "importFieldsButtonText": "Felder importieren",
          "importFrom": "Importieren aus:",
          "mapAllRecipients": "Neuen Empfängern Felder zuweisen, um fortzufahren",
          "newRecipient": "Neuer Empfänger",
          "noDocuments": "Sie haben noch keine Dokumente",
          "originalRecipient": "Ursprünglicher Empfänger",
          "selectDifferentDocument": "Anderes Dokument auswählen",
          "selectRecipient": "Empfänger auswählen",
          "sentBy": "Gesendet von",
          "title": "Wählen Sie ein Dokument aus, in das Felder importiert werden sollen",
          "titleImportFields": "Felder importieren",
          "titleImportFieldsWithCount": "{{count}} Felder importieren"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Zurückgehen",
          "contentLine1": "{<documentName>} hat keine Felder, die importiert werden können. Sie können ein anderes Dokument auswählen oder neue Felder erstellen.",
          "title": "Dieses Dokument enthält keine Felder"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Zurückgehen",
          "confirmButtonText": "Felder trotzdem importieren",
          "contentLine1": "Das von Ihnen ausgewählte Dokument hat eine andere Seitenanzahl als das, an dem Sie gerade arbeiten.",
          "contentLine2": "Sie können weiterhin Felder importieren, wir betrachten jedoch nur die ersten {{pageCount}} Seiten des ausgewählten Dokuments. Alle Felder auf nachfolgenden Seiten werden nicht importiert.",
          "title": "Diese Dokumente sehen etwas anders aus"
        },
        "tooltipText": "Felder aus früher versendeten Dokumenten importieren"
      },
      "initials": "Initialen",
      "invalidEmail": "Ungültige E-Mail",
      "invalidExpirationDaysError": "Geben Sie eine Zahl zwischen 1 und 365 ein",
      "invalidExpirationDaysNotificationMessage": "Wählen Sie ein Ablaufdatum",
      "invalidRecipientErrorMessage": "Wir benötigen den Namen und die E-Mail-Adresse jedes Empfängers",
      "invalidSignatureRequest": "Die Signaturanforderung scheint ungültig zu sein. Bitte überprüfen Sie die URL",
      "invite": "Einladen",
      "inviteViewers": "Leseberechtigte einladen",
      "kbaDescription": "Fordern Sie die Empfänger auf, ihre Identität mit einem wissensbasierten Quiz zu verifizieren. Jeder Unterzeichner muss vor der Unterzeichnung des Dokuments die Verifizierung unabhängig von anderen durchführen.",
      "kbaInfoMessage": "Unterzeichner werden nach drei fehlgeschlagenen Versuchen vom Dokument ausgeschlossen.",
      "kbaLabel": "Wissensbasierte Authentifizierung (KBA)",
      "legacyTokenInterceptor": {
        "buttonText": "Anmelden",
        "message": "Sie werden direkt hierher zurückgebracht, damit Sie dort weitermachen können, wo Sie aufgehört haben.",
        "title": "Melden Sie sich erneut an, um auf Ihre Signaturanfragen zuzugreifen"
      },
      "messageLabel": "Nachricht an Empfänger",
      "mixedAlignment": "Gemischte Ausrichtung",
      "mixedDateFormat": "Gemischtes Format",
      "mixedFontSizes": "Gemischte Schriftgrößen",
      "moveAwaySelections": "Wenn Sie vom aktuellen Ordner weg navigieren, geht Ihre Auswahl verloren.",
      "multiLineLabel": "Mehrere Zeilen zulassen",
      "multipleRecipients": "Mehrere Empfänger",
      "nameGroup": "Gruppe benennen",
      "nextStep": "Nächster Schritt",
      "optional": "Optional",
      "optionalComment": "Optionaler Begleitkommentar",
      "optionsLabel": "Optionen",
      "packagerConfirmationModal": {
        "contentLine1": "Wir müssen diese Dokumente in einer Datei zusammenfassen, bevor wir sie für die Unterschrift vorbereiten können. Sie können nicht mehr zurückgehen und eines der Dokumente entfernen, sobald Sie mit dem nächsten Schritt fortfahren.",
        "contentLine2": "Sind Sie bereit, fortzufahren?",
        "mergeModalText": "Dies kann eine Zeitlang dauern. Wir bringen Sie zum nächsten Schritt, sobald Ihr Dokument fertig ist.",
        "progressBarText": "Zusammenführen...",
        "title": "Dokumente zusammenführen"
      },
      "passcodeCharacterWarning": "Der Passcode darf nur Ziffern enthalten.",
      "passcodeDescription": "Empfänger müssen einen {<fiveDigit>} Passcode eingeben, um auf die Dokumente zugreifen zu können.",
      "passcodeFiveDigit": "5-stelligen",
      "passcodeInfoMessage": "Sie müssen diesen Passcode für jeden Empfänger freigeben.",
      "passcodeLabel": "Passcode",
      "passcodeLengthError": "Wählen Sie einen Passcode oder deaktivieren Sie diese Sicherheitsoption",
      "passcodeLengthErrorEditMode": "Der Passcode muss 5-stellig sein",
      "pending": "Ausstehend",
      "personalFolders": "Persönliche Ordner",
      "placeFields": "Felder platzieren",
      "prepareDocument": {
        "components": {
          "attachment": "Anlage",
          "checkmarkAnnotation": "Häkchen hinzufügen",
          "editor": {
            "chargeAfterExecutedTooltip": "Verwenden Sie diese Option, wenn Sie die Karte belasten möchten, sobald alle Unterzeichner unterschrieben haben",
            "collectCreditCardTooltip": "Verwenden Sie diese Option, wenn Sie nur Karteninformationen erfassen möchten",
            "defaultHelpText": {
              "attachmentComponent": "E-Mail-Anlage hier hochladen",
              "paymentComponent": "Fügen Sie hier Ihre Kartendaten hinzu"
            },
            "fieldSubtypeLabel": "Felduntertyp",
            "fontSizeLabel": "Schriftgrad",
            "helpTextLabel": "Hilfetext hinzufügen",
            "paymentAmountLabel": "Zahlungsbetrag (USD)",
            "paymentAmountPlaceHolder": "Zahlungsbetrag eingeben"
          },
          "textAnnotation": "Text hinzufügen"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Jedes Dropdown-Menü muss mindestens 2 Optionen haben",
          "paymentAmountInvalidError": "Zahlungsbetrag eingeben, um fortzufahren"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Unterzeichner haben nichts zu unterschreiben.",
          "cancelButtonText": "Zurückgehen",
          "confirmButtonText": "Trotzdem fortfahren",
          "description": "Sie können trotzdem mit dem nächsten Schritt fortfahren, oder Sie können zurückgehen und Felder zum Ausfüllen hinzufügen.",
          "subtitle": "Diese Unterzeichner haben nichts zu unterschreiben:",
          "title": "Haben Sie irgendwelche Felder vergessen?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Dokument-ID",
          "dueDate": "Fälligkeitsdatum",
          "otherDetails": "Andere Details",
          "recipients": "Empfänger",
          "sender": "Absender",
          "sentOn": "Gesendet am",
          "signerSequencing": "Unterzeichnerreihenfolge angewendet",
          "signers": "Unterzeichner"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "Details anzeigen"
        },
        "downloadModal": {
          "combinedDescription": "Enthält Unterschriftenzerifikat und fertiges Dokument",
          "combinedTitle": "Zusammengefügte PDF",
          "completedDocumentDescription": "Unterzeichnetes Dokument mit ausgefüllten Feldern",
          "completedDocumentTitle": "Ausgefülltes Dokument",
          "done": "Fertig",
          "originalDescription": "PDF-Dokument ohne Unterzeichnerfelder",
          "originalTitle": "Original-PDF",
          "signatureCertificateDescription": "Sicherheitszertifikats von Sharefile",
          "signatureCertificateTitle": "Signaturzertifikat",
          "title": "Dokument herunterladen"
        },
        "pageHeader": {
          "completed": "Fertig",
          "download": "Download",
          "downloadOriginalDocument": "Originaldokument herunterladen",
          "inProgress": "In Bearbeitung"
        }
      },
      "previousStep": "Voriger Schritt",
      "processingState": "Verarbeitung...",
      "radio": "Optionsfeldgruppe",
      "readyState": "Bereit!",
      "reasonDeclineLabel": "Grund für die Ablehnung",
      "reasonVoidLabel": "Grund für Stornierung",
      "recipientDuplicate": "Diese Person ist bereits leseberechtigt",
      "recipientEmail": "E-Mail",
      "recipientListHeading": "Wer sind die Empfänger?",
      "recipientName": "Name",
      "recipientNoName": "Dieses Feld ist erforderlich.",
      "recipientRemindButtonLabel": "Erinnern",
      "recipientRole": "Rolle",
      "recipientRoleSigner": "Unterzeichner",
      "recipientRoleViewer": "Leseberechtigter",
      "recipientStatusSigned": "Signiert",
      "recipientUnlockLabel": "Entsperren",
      "recipientsLabel": "Empfänger",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Bald können Sie mit ShareFile eine CSV-Datei der Empfänger hochladen, sodass Sie eine Signatur von bis zu 300 Personen gleichzeitig anfordern können. Bis dahin können Sie dies noch in RightSignature tun.",
          "closeButton": "Abbrechen",
          "header": "Suchen Sie nach einer Massensendungsfunktion?",
          "redirectButton": "Zu RightSignature"
        },
        "packager": {
          "bodyLineOne": "Bald können Sie mit ShareFile mehrere Dokumente in einer Signaturanfrage senden. Bis dahin können Sie dies noch in RightSignature tun.",
          "closeButton": "Abbrechen",
          "header": "Müssen Sie mehrere Dokumente versenden?",
          "redirectButton": "Zu RightSignature"
        },
        "template": {
          "bodyLineOne": "Bald können Sie in ShareFile Signaturvorlagen erstellen und verwenden.",
          "bodyLineTwo": "Machen Sie sich bereit, indem Sie die Funktion in RightSignature ausprobieren.",
          "closeButton": "Jetzt nicht",
          "header": "Optimieren Sie Ihren Prozess mit Vorlagen",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Vorlagen ausprobieren"
        }
      },
      "referenceIdLabel": "Referenz-ID",
      "reminderSent": "Erinnerung gesendet",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Unterzeichner entfernen",
        "content": "Alle aus {<fileName>} importierten Unterzeichner werden entfernt.",
        "title": "Möchten Sie diese Unterzeichner wirklich entfernen?"
      },
      "removeCheckboxGroup": "Kontrollkästchengruppe entfernen",
      "removeFieldLabel": "Feld entfernen",
      "removeFileDisabledTooltip": "Das Dokument kann nicht entfernt werden",
      "removeFileFailed": "Die Datei konnte nicht entfernt werden. Bitte versuchen Sie es erneut.",
      "requestSignatureButton": {
        "label": "Signaturanforderung erstellen"
      },
      "requireGroup": "Gruppe erforderlich",
      "required": "Erforderlich",
      "requiredFieldLabel": "Feld erforderlich",
      "requiredFieldsLabel": "Pflichtfelder bestimmen",
      "reviewAndSend": "Überprüfen und senden",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Dieses Feld darf nicht leer sein"
          }
        }
      },
      "save": "Speichern",
      "saveCompletedDocument": "Fertiges Dokument speichern",
      "savedSignaturesLabel": "Wählen Sie die Signatur aus, die im Dokument angewendet werden soll",
      "search": "Suchen",
      "securityOptionsLabel": "Sicherheitsoptionen",
      "selectOneOrMoreLabel": "Mindestens 1 auswählen",
      "selectOnlyOneLabel": "Nur 1 auswählen",
      "selfSignCheckDisabledToolTip": "Die Selbstsignatur ist bei Auswahl einer Vorlage nicht verfügbar",
      "selfSignCheckLabel": "Ich bin der einzige Unterzeichner",
      "selfSignCheckToolTip": "Unterzeichnen Sie das Dokument selbst und geben Sie es dann frei. Es wird im ausgewählten Ordner gespeichert.",
      "selfSignSucessMessage": "Dokument gespeichert",
      "selfSignature": "Eigene Signatur",
      "sendForSignature": "Zur Signatur senden",
      "sendInBulk": {
        "allSigners": "Alle {<signersCount>} Unterzeichner",
        "bulkSigners": "Massenunterzeichner",
        "couldTakeAMoment": "Dies kann einen Moment dauern.",
        "disableTooltipTextForPLG": "Upgrade Ihres Abonnements durchführen, um Zugriff zu erhalten",
        "downloadCSVTemplateButton": "CSV-Vorlage herunterladen",
        "email": "E-Mail",
        "errors": {
          "correctTheFollowingError": "Korrigieren Sie die folgenden Fehler in Ihrer Datei und versuchen Sie es erneut.",
          "exceedsRecipientsLimit": "{<fileName>} überschreitet unser Limit von 300 Unterzeichnern.",
          "fileSizeExceeded": "Die Datei ist größer als das Limit von 5 MB.",
          "onlyOneFile": "Sie können Unterzeichner nur aus einer einzigen Datei importieren.",
          "supportedFileType": "Sie können Unterzeichner nur aus einer CSV-Datei importieren.",
          "unableToLoadSpreadsheet": "In Ihrer Datei fehlen einige wichtige Informationen."
        },
        "gatheringSignersFromFile": "Unterzeichner aus Ihrer Datei werden ausgelesen...",
        "goBack": "Zurück",
        "import": "Importieren",
        "importSigners": "Unterzeichner importieren",
        "importSignersModalText1": "Eine CSV-Vorlage steht bereit. Laden Sie sie herunter und füllen Sie die Namens- und E-Mail-Felder aus (erforderlich).",
        "importSignersModalText2": "CSV ist das einzige unterstützte Dateiformat. Die Anzahl der Unterzeichner darf 300 nicht überschreiten.",
        "name": "Name",
        "note": "Hinweis: ",
        "previewingTheSigners": "Vorschau des ersten {<firstSignersCount>} von {<signersCount>} Unterzeichnern",
        "selectDifferentFile": "Wählen Sie eine andere Datei aus",
        "title": "Als Massensendung versenden",
        "tooltipText": "Der Massenversand kann nur mit Vorlagen für einen einzelnen Unterzeichner verwendet werden",
        "uploadFileText": "CSV-Datei hierher ziehen oder"
      },
      "sendRevisedRequest": "Überarbeitete Anforderung senden",
      "sendSignatureRequest": "Signaturanforderung senden",
      "sendSigningLink": {
        "gotIt": "Verstanden",
        "sentLinkInfoBanner": "Wir haben Ihnen einen Link gesendet",
        "signingLinkInfoMessage1": "Überprüfen Sie Ihre {<email>}-E-Mail auf einen Link zum Signieren dieses Dokuments.",
        "signingLinkInfoMessage2": "Bald können Sie direkt in Sharefile Dokumente unterzeichnen."
      },
      "senderRoleChangeTooltip": "Der Absender kann kein Leseberechtigter sein",
      "sentByLabel": "Gesendet von",
      "setSigningOrder": "Signaturreihenfolge festlegen",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Abbrechen",
          "createFolderLabel": "Ordner erstellen",
          "disabledNodeUploadReason": "Uploadberechtigung zur Auswahl des Ordners erforderlich",
          "disabledSharedFoldersReason": "Freigegebene Ordner der obersten Ebene können nicht ausgewählt werden",
          "modalTitle": "Signierte Vereinbarung speichern unter...",
          "selectFolderButtonLabel": "Auswählen"
        }
      },
      "sharedFolders": "Freigegebene Ordner",
      "showAll": "Alle anzeigen",
      "signButtonLabel": "Signieren",
      "signYourself": "Selbst signieren",
      "signature": "Signatur",
      "signatureAnnotationMessage": "Klicken und ziehen, um die Signatur im Feld zu zeichnen",
      "signatureAnnotationTextMessage": "Geben Sie Ihre Signatur in das Feld unten ein",
      "signatureRequest": "Signaturanforderung",
      "signatureRequestErrorMessage": "Signaturanforderung fehlgeschlagen",
      "signatureRequestSuccessMessage": "Signaturanforderung gesendet",
      "signer": "Unterzeichner",
      "signerDescription": {
        "annotation": "Platzieren Sie Felder für sich selbst und füllen Sie diese im Voraus aus",
        "bulkSigners": "Sie platzieren Felder für alle Unterzeichner",
        "signers": "Sie platzieren Felder für jeden Unterzeichner"
      },
      "signerMessageInputLabel": "Hinweis für alle Unterzeichner (optional)",
      "signerMessageInputLabelTooltip": "Wir fügen diesen Hinweis in die E-Mail-Benachrichtigung ein, die an die Unterzeichner gesendet wird",
      "signerMessageInputPlaceholder": "Fügen Sie für alle Unterzeichner eine personalisierte Notiz hinzu",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Name und ID des Dokuments",
            "dueDateTitle": "Fälligkeitsdatum",
            "senderInfoTitle": "Absender",
            "senderMessageTitle": "Nachricht vom Absender"
          },
          "fields": {
            "addInitials": "Initialen hinzufügen",
            "attachment": {
              "buttonText": "Datei anfügen",
              "fileUploadingError": "Datei konnte nicht hochgeladen werden. Versuchen Sie es erneut",
              "modalButtonContinue": "Fertig",
              "modalDeleteFileCancelButton": "Abbrechen",
              "modalDeleteFileOkButton": "Löschen",
              "modalDeleteFileText": "Möchten Sie {<fileName>} wirklich löschen",
              "modalDeleteFileTitle": "Datei löschen",
              "modalTitle": "Datei hochladen",
              "modalUploadAnotherFileText": " und die neue Datei hochladen?",
              "uploadDifferentFileButton": "Eine andere Datei hochladen",
              "uploadHint": "Einzelne Datei",
              "uploadText": "Klicken oder ziehen Sie die Datei zum Hochladen in diesen Bereich",
              "uploadingStatus": "Hochladen"
            },
            "autofilled": "Automatisch ausgefüllt",
            "dropdown": {
              "clearSelection": "Auswahl aufheben",
              "selectOption": "Option auswählen"
            },
            "enterText": "Text eingeben",
            "format": "Format",
            "optional": "Optional",
            "paymentField": {
              "amount": "Betrag",
              "authorizePayment": "Zahlung autorisieren",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Ändern",
              "clear": "Löschen",
              "creditCardModalDetails": {
                "authorizeMessage": "Ich bin damit einverstanden, dass mir nach dem Absenden dieses Dokuments ein Betrag von {<paymentAmount>} in Rechnung gestellt wird",
                "cvcTooltipMessage": "Ein drei- oder vierstelliger Sicherheitscode, der auf der Rückseite (bzw. Vorderseite bei AMEX) Ihrer Kreditkarte aufgedruckt ist",
                "labels": {
                  "cardNumber": "Kartennummer",
                  "cvc": "CVC",
                  "expiryDate": "Ablaufdatum"
                },
                "modalTitle": "Kreditkartendaten",
                "placeholders": {
                  "cardNumber": "Kartennummer eingeben",
                  "cvc": "CVC eingeben"
                }
              },
              "creditDetails": "Kartendaten eingeben"
            },
            "required": "Erforderlich",
            "selectDate": "Datum auswählen",
            "signature": {
              "clearSignatureButtonText": "Signatur löschen",
              "editSignatureButtonText": "Signatur bearbeiten",
              "openSignatureModalText": "Hier unterschreiben",
              "prefilledSignatureText": "Zu Ihrer Bequemlichkeit haben wir Ihren Namen bereits eingetragen. Sie können ihn bei Bedarf ändern."
            }
          },
          "navigation": {
            "next": "Weiter",
            "nextField": "Nächstes Feld",
            "startSigning": "Signieren starten"
          },
          "progressBar": {
            "shortSuccessText": "Sie haben alle erforderlichen Felder ausgefüllt",
            "status": "{{fieldsFilled}}/{{totalFields}} Pflichtfelder",
            "submitAction": "Senden",
            "successText": "Sie haben alle erforderlichen Felder ausgefüllt. Sobald Sie mit der Überprüfung fertig sind, können Sie das Dokument '{<submitAction>}'."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Fortschritt verfolgen"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "Sie haben keine Felder zum Ausfüllen",
              "optionalCount": "{{count}} optionales Feld",
              "optionalCount_plural": "{{count}} optionale Felder",
              "optionalOnly": "Sie haben <optionalCountLabel> zum Ausfüllen",
              "pending": "Ausstehend",
              "requiredAndOptional": "Sie müssen {<requiredCountLabel>} und {<optionalCountLabel>} ausfüllen",
              "requiredCount": "{{count}} erforderliches Feld",
              "requiredCount_plural": "{{count}} erforderliche Felder",
              "requiredOnly": "Sie haben {<requiredCountLabel>} zum Ausfüllen"
            },
            "label": {
              "details": "Details",
              "fields": "Felder"
            }
          }
        },
        "declineDocument": {
          "header": "Dokument abgelehnt",
          "message": "Sie haben dieses Dokument abgelehnt. Wenn Sie es dennoch unterzeichnen möchten, bitten Sie den Absender, ein neues Dokument zu senden",
          "sender": "Absender"
        },
        "header": {
          "documentActions": {
            "actions": "Aktionen",
            "actionsAndDetails": "Details und Aktionen",
            "declineDocument": "Dokument ablehnen",
            "declineFailed": "Das Dokument konnte nicht abgelehnt werden. Versuchen Sie es erneut",
            "downloadOriginal": "Original-PDF herunterladen",
            "saveFailed": "Der Fortschritt konnte nicht gespeichert werden. Versuchen Sie es erneut",
            "saveProgress": "Fortschritt speichern",
            "saveSuccess": "Gespeicherter Fortschritt"
          },
          "moreActions": "Weitere Aktionen"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Authentifizierungsfehler!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Abbrechen",
            "decline": "Ablehnen",
            "reason": "Grund",
            "reasonHelp": "Dieser Grund wird dem Absender mitgeteilt",
            "reasonPlaceholder": "Warum lehnen Sie ab?",
            "title": "Dokument ablehnen",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} verbleibender Versuch",
            "attemptsAmountPlural": "{{attemptsAmount}} verbleibende Versuche",
            "contentMessage": "Geben Sie einen 5-stelligen Passcode vom Absender ein, um auf dieses Dokument zuzugreifen.",
            "inputLabel": "Passcode eingeben",
            "inputPlaceholder": "Geben Sie den 5-stelligen Passcode ein",
            "inputValidationMessage": "Der Passcode muss 5-stellig sein",
            "inputValueRequiedMessage": "Dieses Feld ist erforderlich",
            "invalidPasscodeMessage": "Passcode ungültig.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) hat Sie eingeladen, {<documentName>} zu unterschreiben",
            "unlockButton": "Entsperren",
            "welcomeMessage": "Willkommen!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "Wir haben eine Kopie des ausgefüllten Dokuments an {<email>} gesendet",
            "downloadButton": "Dokument herunterladen",
            "notAllSignedText": "Eine Kopie des unterzeichneten Dokuments wird an {<email>} gesendet, sobald alle Unterzeichner dieses Dokument unterzeichnet haben",
            "reviewButton": "Unterzeichnetes Dokument prüfen",
            "title": "Eingereichtes Dokument"
          },
          "termsAndConditions": {
            "continueButton": "Weiter",
            "esignConsent": "E-Signatur-Zustimmung",
            "footerEsignConsent": "Sie sind mit unseren {<link>} vertraut und stimmen diesen zu",
            "footerTerms": "Sie sind mit unseren {<linkTermsOfUse>} und {<linkPrivacyPolicy>} vertraut und stimmen diesen zu",
            "footerTitle": "Indem Sie fortfahren, bestätigen Sie Folgendes:",
            "greetings": "Hallo {{signerName}},",
            "privacyPolicy": "Datenschutzrichtlinie",
            "senderMessageTitle": "Nachricht vom Absender",
            "termsOfUse": "Nutzungsbedingungen",
            "text": "{{senderName}} ({{senderEmail}}) hat Sie eingeladen, {<documentName>} zu unterschreiben"
          }
        },
        "submitDocument": {
          "header": "Dokument einreichen",
          "submit": "Senden",
          "submitConfirm": "Ist das Dokument wirklich zum Einreichen bereit?",
          "submitWarning": "Nach dem Absenden können Sie keine Änderungen mehr vornehmen",
          "submitWithUnfilledOptionalFields": "Sie haben ein optionales Feld übersprungen. Ist das Dokument wirklich zum Einreichen bereit?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Füllen Sie alle erforderlichen Felder aus, um das Dokument einzureichen"
        }
      },
      "signerSequencingDisabledTooltip": "Die Signaturreihenfolge ist nur anwendbar, wenn es mehr als 1 Unterzeichner gibt",
      "signerSequencingNotApplicableTooltip": "Die Signaturreihenfolge gilt nicht für Leseberechtigte",
      "signers": "Unterzeichner",
      "signersLabel": "Unterzeichner",
      "singerSequencingAppliedText": "Unterzeichnerreihenfolge wurde angewendet",
      "sizeLimitFile": "Datei {{filename}} ",
      "sizeLimitMessage": "überschreitet unser 20-MB-Limit",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Vorlage entfernen",
              "content": "Die Liste der Massenunterzeichner geht verloren und Sie müssen ein neues Dokument hochladen, um fortzufahren.",
              "header": "Möchten Sie diese Vorlage wirklich entfernen?"
            },
            "modal": {
              "confirmButton": "Dokument entfernen",
              "content": "Durch das Entfernen eines Dokuments werden alle vordefinierten Empfänger und die ihnen zugewiesenen Felder gelöscht. Möchten Sie das Dokument wirklich entfernen?",
              "header": "Dokument entfernen"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Auswählen",
              "createdBy": "Erstellt von:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Für den Massenversand geeignet",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Fehler beim Laden der Vorlagen. Versuchen Sie es später erneut",
            "footer": {
              "buttonText": "Vorlage erstellen",
              "question": "Können Sie das Gesuchte nicht finden?"
            },
            "noDocuments": "Sie haben noch keine Vorlagen",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Hier gibt es nichts",
            "noSearchResultsDesc": "Ihre Suche ergab keine Treffer.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Rollen:",
            "title": "Vorlage auswählen"
          },
          "sourceName": "Vorlage verwenden"
        },
        "upload": {
          "basefileProcessingFailed": "Fehler beim Verarbeiten der Datei {{ filename }}. Bitte laden Sie die Datei erneut hoch",
          "failed": "Fehler beim Hochladen der Datei {{ filename }}",
          "numberLimitMessage": "Es können nicht mehr als 5 Dateien hochgeladen werden"
        }
      },
      "statusFilter": "Status",
      "switch": "Zur alten Benutzeroberfläche wechseln",
      "template": "Vorlage",
      "temporaryRedirectText": "Vorübergehend zu RightSignature wechseln",
      "text": "Text",
      "textAlignLabel": "Text ausrichten",
      "type": "Typ",
      "unableToFetchFileDetails": "Fehler beim Abrufen von Dateidetails",
      "unableToProceedNoDocumentError": "Der Vorgang kann nicht fortgesetzt werden. Bitte fügen Sie ein Dokument hinzu",
      "unableToProceedNoSignerError": "Der Vorgang kann nicht fortgesetzt werden. Bitte fügen Sie mindestens einen Unterzeichner hinzu",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Dieser Dokumenttyp wird nicht unterstützt. Unterstützte Dateitypen sind DOC, DOCX, TXT, PDF",
      "updateDocumentErrorMessage": "Das Dokument konnte nicht aktualisiert werden.",
      "uploadDocumentErrorMessage": "Laden Sie ein Dokument hoch, um mit dem nächsten Schritt fortzufahren",
      "uploadedFilesProcessingErrorMessage": "Eine oder mehrere hochgeladene Dateien werden noch verarbeitet",
      "useSaved": "Gespeicherte verwenden",
      "userFetchErrorMessage": "Benutzerinformationen konnten nicht abgerufen werden",
      "view": "Anzeigen",
      "viewer": "Leseberechtigter",
      "viewerUniqueEmailErrorMessage": "Die E-Mail-Adresse des Leseberechtigten muss eindeutig sein",
      "viewersLabel": "Leseberechtigte",
      "void": "Stornieren",
      "voidConfirmationTitle": "Bestätigung des Dokuments stornieren",
      "zoomIn": "Vergrößern",
      "zoomOut": "Verkleinern"
    }
  },
  {
    "key": "en",
    "content": {
      "AccountLimitReached": "The account has reached the maximum document limit.",
      "AttachedByLabel": "Attached by {{signer}}",
      "AttachmentDetailsLabel": "Attachments",
      "AttachmentFieldLabel": "Attachment",
      "CheckFieldLabel": "Checkbox",
      "CheckGroupFieldLabel": "Checkbox Group",
      "DateFieldLabel": "Date",
      "InitialsFieldLabel": "Initials",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Charge once signed",
        "chargedLabel": "Charged {<paymentAmount>}",
        "collectCardDetailsLabel": "Collect card details",
        "defaultLabel": "Payment",
        "paymentFailedLabel": "Payment failed"
      },
      "PaymentFieldLabel": "Payment Field",
      "SelectFieldLabel": "Dropdown",
      "SignatureFieldLabel": "Signature",
      "TextFieldLabel": "Text",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Your current plan limits how many documents can be sent for signature, and you’ve reached the limit. You can still sign documents yourself.",
        "upgradeTrialBanner": "You have {<documentsLeft>} left in your trial."
      },
      "accountLimitExhaustedMessage": "Cannot send the document for signature, account has exhausted the limit. Please upgrade the account.",
      "accountStatsFetchErrorMessage": "Failed to retrieve account information",
      "add": "Add",
      "addCheckbox": "Add checkbox",
      "addFilesFromSharefile": "Add items from ShareFile",
      "addMyself": "Add myself",
      "addOptionLabel": "Add option",
      "addRecipient": "Add another recipient",
      "addSignature": "Add Signature",
      "annotator": "You (Now)",
      "annotatorOptionTooltip": "Fill out fields in advance",
      "apply": "Apply",
      "archiveLocationBlank": "Select a folder to store the signed document",
      "assignedToLabel": "Assigned to",
      "attachmentFieldLabel": "Attachment",
      "browseFiles": "Browse files",
      "bulkSend": "Bulk send",
      "cancel": "Cancel",
      "changesSaved": "Changes saved",
      "checkbox": "Checkbox",
      "checkboxGroup": "Checkbox group",
      "checkboxes": "Checkboxes",
      "clear": "Clear",
      "clearBackground": "Clear background",
      "close": "Close",
      "completed": "Completed",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} selected fields",
      "confirm": "Confirm",
      "continue": "Continue",
      "createDocument": {
        "almostDone": "Almost done. Please don't click Refresh or Back button"
      },
      "createDocumentErrorMessage": "Create document failed",
      "creationDateLabel": "Sent on",
      "dashboard": {
        "adminView": "Admin View",
        "adminViewTooltipText": "View all documents sent from this account",
        "autoDeleteText": "Auto-deletes in {{remaining}} day(s)",
        "clearFilters": "Clear filters",
        "clearSearch": "Clear search",
        "continueAction": "Continue",
        "deleteAction": "Delete",
        "downloadPdf": "Download",
        "esignLeftNav": "Signatures",
        "loadMore": "Load more",
        "overdue": "1 day overdue",
        "overdue_plural": "{{count}} days overdue",
        "signatureDue": "Due {{dueDate}}",
        "signatureRequests": "Signature Requests",
        "signatureSettings": "Signature settings",
        "signaturesSubmitted": "Signatures submitted",
        "templates": "Templates",
        "title": "Signature requests"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "It doesn't look like anything matches your filters.",
          "title": "There's nothing here"
        },
        "noRequestsCreated": {
          "description": "Need to get a signature? Create a request.",
          "title": "You don't have any signature requests yet"
        },
        "noSearchResults": {
          "description": "It doesn't look like anything matches your search.",
          "title": "There's nothing here"
        }
      },
      "date": "Date",
      "dateExample": "Example:",
      "dateExtendError": "Please choose a future date",
      "dateFormatLabel": "Format",
      "dateSignedLabel": "Auto-fill date signed",
      "dateSignedTooltip": "A recipient will not be able to edit the field. It will be filled in automatically on the day the document is signed.",
      "declinedByLabel": "Declined by {{name}}",
      "defaultBadgeLabel": "Default",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Send everyone an email with the completed PDF",
          "attachCompletedPDFCheckboxTooltip": "After the last signature, we'll send everyone an email with the completed PDF as an attachment (unless it's larger than 10 MB).",
          "sectionHeader": "Attachments"
        },
        "header": "Edit default settings",
        "headerInfo": "Any changes you make here will be applied to all in progress signature requests.",
        "hipaaConfirmationBodyFirst": "All recipients will receive a copy of the signed document as an email attachment when this setting is applied. As a HIPAA customer, it's your responsibility to confirm that recipients allow their information to be handled this way.",
        "hipaaConfirmationBodySecond": "Are you sure you want to apply this setting?",
        "hipaaConfirmationButton": "Apply setting",
        "hipaaConfirmationHeader": "HIPAA considerations",
        "reminders": {
          "options": {
            "daily": "Daily",
            "noAuto": "Never",
            "weekly": "Weekly"
          },
          "reminderFrequencyLabel": "Send automatic reminders",
          "reminderFrequencyTooltip": "Email reminders to recipients either weekly (after 3, 7, 14, 21, and 28 days) or daily (after 7 days) until they sign.",
          "sectionHeader": "Reminders"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Allow drawn signatures",
          "allowTypedSignatureLabel": "Allow typed signatures",
          "applyBlueInkSignatureLabel": "Apply blue ink to signatures",
          "applyBlueInkSignatureTooltipAdmin": "Contact your admin if you need to change this setting.",
          "applyBlueInkSignatureTooltipNonAdmin": "Use blue ink for all signatures to help distinguish signed originals from photocopies.",
          "sectionHeader": "Signature types"
        },
        "signer": {
          "allowDownload": "Allow signers to download the original document before signing",
          "sectionHeader": "Signer permissions"
        }
      },
      "defaultSettingsDescription": "Change your settings for reminders, email attachments, and signature types.",
      "defaultSettingsEditLabel": "Edit default settings",
      "defaultSettingsLabel": "Default settings",
      "defaultSettingsUpdateFailed": "Failed to update default settings",
      "defaultSettingsUpdateSuccess": "Default settings updated",
      "defaultValueLabel": "Add default text",
      "delete": "Delete",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "This will permanently delete the signature request. Once this is done, there is no undo.",
        "deleteConfirmationMessageSecond": "Signers and Viewers will receive an email with the completed document and signature certificate for their records.",
        "deleteRequest": "Delete request",
        "deleteRequestSuccess": "Request deleted"
      },
      "deleteDraftDocumentLabel": "Delete signature request",
      "deleteDraftDocumentModalContent": "Are you sure you want to delete the signature request? This can't be undone.",
      "deleteDraftDocumentSuccess": "Signature request deleted",
      "deleteViewerWarningHeader": "Are you sure you want to remove this recipient?",
      "deleteViewerWarningMessage": "They’ll no longer have access to this signature request.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Edit Passcode",
        "kba": "Knowledge-based authentication",
        "passcode": "Passcode",
        "title": "Security option applied",
        "updatePasscodeSuccess": "The Passcode of this document has been updated."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "You can't revise requests that were sent with bulk send",
          "signerSigned": "You can't revise requests once someone has signed",
          "userNotSender": "You can't revise requests that you didn't send"
        }
      },
      "documentAlreadySentErrorDetails": "Cannot open sent document for edit, please start a fresh flow.",
      "documentAlreadySentErrorTitle": "Document already sent",
      "documentForSignature": "Document to send",
      "documentForceCompleted": "Request force completed",
      "documentLockedMessage": "This recipient is temporarily locked out because of multiple failed authentication attempts",
      "documentNameBlank": "Enter a document name",
      "documentNameInputDisabledTooltip": "Document Name can't be edited",
      "documentNameInputLabel": "Document name",
      "documentStates": {
        "declined": "Declined",
        "draft": "Draft",
        "editing": "Paused for revisions",
        "executed": "Completed",
        "expired": "Expired",
        "pending": "In progress",
        "voided": "Voided"
      },
      "documentThumbnailAltText": "First Page Thumbnail",
      "documentTransactionCreateErrorMessage": "Failed to create document transaction",
      "documentTransactionGetErrorMessage": "Failed to get document transaction",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Document unlock successful",
      "documentView": {
        "documentPageIndex": "Page {<currentPage>} of {{totalPages}}",
        "pageChangeInputLabel": "Change page to",
        "pageDown": "Next page",
        "pageUp": "Previous page"
      },
      "documentVoided": "Signature request voided",
      "dontShowAgain": "Don't show this message again",
      "downloadOptions": {
        "combinedPDF": "Combined PDF",
        "completedPDF": "Completed PDF",
        "download": "Download",
        "downloadErrorMessage": "Unsupported download type: {{downloadType}}",
        "downloadOriginal": "Original PDF",
        "signatureCertificate": "Signature Certificate"
      },
      "draftSaveErrorMessage": "Failed to save signature request",
      "draftSaveSuccessMessage": "Signature request saved",
      "draw": "Draw",
      "dueDate": "Due date",
      "dueDateUpdated": "Due date updated",
      "edit": "Edit",
      "editDocumentModal": {
        "cancel": "Go back",
        "confirm": "Revise",
        "contentLine1": "If you need to add or remove recipients or fields, or change your security options, you can revise this request.",
        "contentLine2": "We'll pause the request while you make revisions. This means that everyone who hasn't signed yet will temporarily lose access. We'll send them a new link when you're done.",
        "contentLine3": "Once you start revising, you won't be able to unpause the request without sending a revised document.",
        "title": "Are you sure you want to revise this request?"
      },
      "editDueDate": "Edit due date",
      "editLeftMidwayModal": {
        "cancel": "Go back",
        "confirm": "Finish later",
        "contentLine1": "Signers aren't able to access this request while it's paused for revisions. If you're finished, go back and choose ",
        "contentLine2": "If you're not ready, you can come back and finish your revisions later.",
        "title": "Are you still revising?"
      },
      "editRecipients": "Edit recipients",
      "editSignatureRequest": "Revise",
      "editSigner": "Edit signer",
      "editViewer": "Edit viewer",
      "emptyFolder": "This folder is empty",
      "enforceStorageUsage": "You cannot create new signature requests until you free up some space",
      "expirationDateMessage": "Expiration date: {{expirationDate}}",
      "expirationDaysLabel": "day(s)",
      "expirationInputDescription": "The document will no longer be available to sign after the specified number of days:",
      "expirationInputLabel": "Expiration",
      "extendDueDate": "Extend due date",
      "extendExpirationDate": "Extend",
      "favorites": "Favorites",
      "field": "field",
      "fieldNameLabel": "Name field",
      "fieldRequired": "This field is required",
      "fields": "Fields",
      "fieldsAssignedWarningHeader": "Fields assigned to signers",
      "fieldsAssignedWarningMessage": "All fields assigned to the signer(s) will be removed.",
      "fieldsDescription": "Choose a type of field to place in your document",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Edit",
        "sectionLabel": "Where would you like to store the Signed Document?"
      },
      "fileNotSupportedTooltip": "Unsupported file format",
      "filesAndRecipients": "Files and recipients",
      "filterSelected": "1 filter",
      "filterSelected_plural": "{{count}} filters",
      "forceComplete": "Force complete",
      "forceCompleteTitle": "Force complete request",
      "forceCompleteWarningFirst": "This will remove all the fields assigned to signers who have not yet signed and will make the document as executed (final state). Once this is done, there is no undo.",
      "forceCompleteWarningSecond": "It may take a brief moment to reflect the new status on dashboard.",
      "generateNewPasscodeButtonLabel": "Generate new passcode",
      "genericError": "An error occurred",
      "groupRule": "Group rule",
      "hasAtleastOneSignerErrorMessage": "Add at least one signer to proceed",
      "importFields": {
        "linkText": "Import fields",
        "modal": {
          "assignImportedFields": "Assign Imported Fields",
          "body": {
            "buttonText": "Select",
            "searchPlaceholder": "Search by document name, file name, sender or role",
            "sentBy": "Sent by"
          },
          "componentsImported": "{{count}} fields imported",
          "fields": "field(s)",
          "importFieldsButtonText": "Import fields",
          "importFrom": "Import from:",
          "mapAllRecipients": "Assign fields to new recipients to continue",
          "newRecipient": "New recipient",
          "noDocuments": "You don’t have any documents yet",
          "originalRecipient": "Original recipient",
          "selectDifferentDocument": "Select different document",
          "selectRecipient": "Select recipient",
          "sentBy": "Sent by",
          "title": "Select a document to import fields",
          "titleImportFields": "Import fields",
          "titleImportFieldsWithCount": "Import {{count}} fields"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Go back",
          "contentLine1": "{<documentName>} doesn't have any fields that we can import. You can choose a different document or create new fields.",
          "title": "This document doesn't have any fields"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Go back",
          "confirmButtonText": "Import fields anyway",
          "contentLine1": "The document you selected has a different number of pages than the one you're currently working on.",
          "contentLine2": "You can still import fields, but we'll only look at the first {{pageCount}} page(s) of the selected document. Any fields on subsequent pages won't be imported.",
          "title": "These documents look a little different"
        },
        "tooltipText": "Import fields from previously sent documents"
      },
      "initials": "Initials",
      "invalidEmail": "Invalid email",
      "invalidExpirationDaysError": "Enter a number between 1 and 365",
      "invalidExpirationDaysNotificationMessage": "Choose an expiration date",
      "invalidRecipientErrorMessage": "We need a name and email for each recipient",
      "invalidSignatureRequest": "Signature request seems invalid, please verify the Url",
      "invite": "Invite",
      "inviteViewers": "Invite viewers",
      "kbaDescription": "Require recipients to verify their identity with a knowledge-based quiz. Each signer will need to independently verify before signing the document.",
      "kbaInfoMessage": "Signers will be locked out of the document after 3 failed attempts.",
      "kbaLabel": "Knowledge-Based Authentication",
      "legacyTokenInterceptor": {
        "buttonText": "Sign in",
        "message": "We’ll bring you right back here so you can pick up where you left off.",
        "title": "Sign in again to access your signature requests"
      },
      "messageLabel": "Message to recipients",
      "mixedAlignment": "Mixed alignment",
      "mixedDateFormat": "Mixed format",
      "mixedFontSizes": "Mixed font sizes",
      "moveAwaySelections": "If you move away from your current folder, your selections will be lost.",
      "multiLineLabel": "Allow multiple lines",
      "multipleRecipients": "Multiple recipients",
      "nameGroup": "Name group",
      "nextStep": "Next step",
      "optional": "Optional",
      "optionalComment": "Optional accompanying comment",
      "optionsLabel": "Options",
      "packagerConfirmationModal": {
        "contentLine1": "We need to combine these documents into one file before we can prepare them for signature. You won't be able to go back and remove any once you proceed to the next step.",
        "contentLine2": "Are you ready to continue?",
        "mergeModalText": "This might take a minute. We’ll take you to the next step as soon as your document’s ready.",
        "progressBarText": "Merging...",
        "title": "Merge Documents"
      },
      "passcodeCharacterWarning": "The passcode can contain digits only.",
      "passcodeDescription": "Recipients must enter a {<fiveDigit>} passcode to access the documents.",
      "passcodeFiveDigit": "5-digit",
      "passcodeInfoMessage": "You’ll need to share this passcode with each recipient.",
      "passcodeLabel": "Passcode",
      "passcodeLengthError": "Choose a passcode or disable that security option",
      "passcodeLengthErrorEditMode": "The passcode must have 5 digits",
      "pending": "Pending",
      "personalFolders": "Personal Folders",
      "placeFields": "Place fields",
      "prepareDocument": {
        "components": {
          "attachment": "Attachment",
          "checkmarkAnnotation": "Add checkmark",
          "editor": {
            "chargeAfterExecutedTooltip": "Use this option if you want to charge their card once all signers have signed",
            "collectCreditCardTooltip": "Use this option if you just want to collect card information",
            "defaultHelpText": {
              "attachmentComponent": "Upload your attachment here",
              "paymentComponent": "Add your card details here"
            },
            "fieldSubtypeLabel": "Field subtype",
            "fontSizeLabel": "Font size",
            "helpTextLabel": "Add help text",
            "paymentAmountLabel": "Payment amount (USD)",
            "paymentAmountPlaceHolder": "Enter payment amount"
          },
          "textAnnotation": "Add text"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Each dropdown needs to have at least 2 options",
          "paymentAmountInvalidError": "Enter a payment amount to continue"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Signers don't have anything to sign.",
          "cancelButtonText": "Go back",
          "confirmButtonText": "Continue anyway",
          "description": "You can continue to the next step anyway, or you can go back and add fields for them to fill out.",
          "subtitle": "These signers don’t have anything to sign:",
          "title": "Did you forget any fields?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Document ID",
          "dueDate": "Due Date",
          "otherDetails": "Other details",
          "recipients": "Recipients",
          "sender": "Sender",
          "sentOn": "Sent on",
          "signerSequencing": "Signer sequencing applied",
          "signers": "Signers"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "View details"
        },
        "downloadModal": {
          "combinedDescription": "Includes signature cerificate and completed document",
          "combinedTitle": "Combined PDF",
          "completedDocumentDescription": "Signed document with completed fields",
          "completedDocumentTitle": "Completed document",
          "done": "Done",
          "originalDescription": "PDF document without any signer fields",
          "originalTitle": "Original PDF",
          "signatureCertificateDescription": "Certificate of trust from Sharefile",
          "signatureCertificateTitle": "Signature certificate",
          "title": "Download document"
        },
        "pageHeader": {
          "completed": "Completed",
          "download": "Download",
          "downloadOriginalDocument": "Download original document",
          "inProgress": "In Progress"
        }
      },
      "previousStep": "Previous step",
      "processingState": "Processing...",
      "radio": "Radio Group",
      "readyState": "Ready!",
      "reasonDeclineLabel": "Decline reason",
      "reasonVoidLabel": "Void reason",
      "recipientDuplicate": "This person is already a viewer",
      "recipientEmail": "Email",
      "recipientListHeading": "Who are the recipients?",
      "recipientName": "Name",
      "recipientNoName": "This field is required",
      "recipientRemindButtonLabel": "Remind",
      "recipientRole": "Role",
      "recipientRoleSigner": "Signer",
      "recipientRoleViewer": "Viewer",
      "recipientStatusSigned": "Signed",
      "recipientUnlockLabel": "Unlock",
      "recipientsLabel": "Recipients",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Soon ShareFile will let you upload a CSV of recipients so that you can request a signature from up to 300 people at once. Until then, you can still do this in RightSignature.",
          "closeButton": "Cancel",
          "header": "Looking for bulk send?",
          "redirectButton": "Go to RightSignature"
        },
        "packager": {
          "bodyLineOne": "Soon ShareFile will let you send multiple documents in one signature request. Until then, you can still do this in RightSignature.",
          "closeButton": "Cancel",
          "header": "Need to send multiple documents?",
          "redirectButton": "Go to RightSignature"
        },
        "template": {
          "bodyLineOne": "Soon you’ll be able to create and use signature templates in ShareFile.",
          "bodyLineTwo": "Get ready by trying them out in RightSignature.",
          "closeButton": "Not now",
          "header": "Streamline your process with templates",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Try templates"
        }
      },
      "referenceIdLabel": "Reference ID",
      "reminderSent": "Reminder sent",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Remove signers",
        "content": "All of the signers imported from {<fileName>} will be removed.",
        "title": "Are you sure you want to remove these signers?"
      },
      "removeCheckboxGroup": "Remove checkbox group",
      "removeFieldLabel": "Remove field",
      "removeFileDisabledTooltip": "Document can't be removed",
      "removeFileFailed": "Couldn't remove the file. Please try again.",
      "requestSignatureButton": {
        "label": "Create signature request"
      },
      "requireGroup": "Require group",
      "required": "Required",
      "requiredFieldLabel": "Require field",
      "requiredFieldsLabel": "Require fields",
      "reviewAndSend": "Review and send",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "This field can’t be empty"
          }
        }
      },
      "save": "Save",
      "saveCompletedDocument": "Save completed document",
      "savedSignaturesLabel": "Select the signature to apply in the document",
      "search": "Search",
      "securityOptionsLabel": "Security options",
      "selectOneOrMoreLabel": "Select one or more",
      "selectOnlyOneLabel": "Select only one",
      "selfSignCheckDisabledToolTip": "Self sign is not available on selecting a template",
      "selfSignCheckLabel": "I'm the only signer",
      "selfSignCheckToolTip": "Sign the document yourself, then share it. It will be saved in the selected folder.",
      "selfSignSucessMessage": "Document saved",
      "selfSignature": "Your signature",
      "sendForSignature": "Send for Signature",
      "sendInBulk": {
        "allSigners": "All {<signersCount>} signers",
        "bulkSigners": "Bulk signers",
        "couldTakeAMoment": "This could take a moment.",
        "disableTooltipTextForPLG": "Upgrade your plan to get access",
        "downloadCSVTemplateButton": "Download CSV template",
        "email": "Email",
        "errors": {
          "correctTheFollowingError": "Correct the following errors in your file and try again.",
          "exceedsRecipientsLimit": "{<fileName>} exceeds our 300 signer limit.",
          "fileSizeExceeded": "The file is bigger than our 5MB limit.",
          "onlyOneFile": "You can only import signers from a single file.",
          "supportedFileType": "You can only import signers from a .csv file.",
          "unableToLoadSpreadsheet": "Your file is missing some important information."
        },
        "gatheringSignersFromFile": "Gathering signers from your file...",
        "goBack": "Go Back",
        "import": "Import",
        "importSigners": "Import signers",
        "importSignersModalText1": "We’ve prepared a CSV template for you. Download it and fill in the name and e-mail fields (required).",
        "importSignersModalText2": "CSV is the only supported file format. The number of signers cannot exceed 300.",
        "name": "Name",
        "note": "Note: ",
        "previewingTheSigners": "Previewing the first {<firstSignersCount>} of {<signersCount>} signers",
        "selectDifferentFile": "Select different file",
        "title": "Send in bulk",
        "tooltipText": "Bulk send can only be used with a single signer templates",
        "uploadFileText": "Drag your CSV here or"
      },
      "sendRevisedRequest": "Send revised request",
      "sendSignatureRequest": "Send signature request",
      "sendSigningLink": {
        "gotIt": "Got it",
        "sentLinkInfoBanner": "We sent you a link",
        "signingLinkInfoMessage1": "Check your {<email>} email for a link to sign this document.",
        "signingLinkInfoMessage2": "Soon you'll be able to sign directly in Sharefile."
      },
      "senderRoleChangeTooltip": "The sender can't be a viewer",
      "sentByLabel": "Sent by",
      "setSigningOrder": "Set signing order",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Cancel",
          "createFolderLabel": "Create Folder",
          "disabledNodeUploadReason": "Upload permission is needed to select this folder",
          "disabledSharedFoldersReason": "Cannot select top level shared folders",
          "modalTitle": "Save the Signed Agreement to...",
          "selectFolderButtonLabel": "Select"
        }
      },
      "sharedFolders": "Shared Folders",
      "showAll": "Show All",
      "signButtonLabel": "Sign",
      "signYourself": "Sign Yourself",
      "signature": "Signature",
      "signatureAnnotationMessage": "Click and drag to draw your signature inside the box",
      "signatureAnnotationTextMessage": "Type your signature in the field below",
      "signatureRequest": "Signature request",
      "signatureRequestErrorMessage": "Signature request failed",
      "signatureRequestSuccessMessage": "Sent signature request",
      "signer": "Signer",
      "signerDescription": {
        "annotation": "Place fields for yourself and fill them out in advance",
        "bulkSigners": "You're placing fields for all signers",
        "signers": "You’ll place fields for each signer"
      },
      "signerMessageInputLabel": "Note to all signers (optional)",
      "signerMessageInputLabelTooltip": "We'll include this note in the email notification that goes out to signers",
      "signerMessageInputPlaceholder": "Add a personalized note to all signers",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Document name and ID",
            "dueDateTitle": "Due date",
            "senderInfoTitle": "Sender",
            "senderMessageTitle": "Message from sender"
          },
          "fields": {
            "addInitials": "Add Initials",
            "attachment": {
              "buttonText": "Attach file",
              "fileUploadingError": "Unable to upload file. Please try again",
              "modalButtonContinue": "Done",
              "modalDeleteFileCancelButton": "Cancel",
              "modalDeleteFileOkButton": "Delete",
              "modalDeleteFileText": "Are you sure you want to delete {<fileName>}",
              "modalDeleteFileTitle": "Delete file",
              "modalTitle": "Upload file",
              "modalUploadAnotherFileText": " and upload new file?",
              "uploadDifferentFileButton": "Upload different file",
              "uploadHint": "Single file",
              "uploadText": "Click or drag file to this area to upload",
              "uploadingStatus": "Uploading"
            },
            "autofilled": "Autofilled",
            "dropdown": {
              "clearSelection": "Clear selection",
              "selectOption": "Select option"
            },
            "enterText": "Enter Text",
            "format": "Format",
            "optional": "Optional",
            "paymentField": {
              "amount": "Amount",
              "authorizePayment": "Authorize payment",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Change",
              "clear": "Clear",
              "creditCardModalDetails": {
                "authorizeMessage": "I agree to be charged {<paymentAmount>} after submitting this document",
                "cvcTooltipMessage": "A three or four-digit security code printed on the back (or front for AMEX) of your credit card",
                "labels": {
                  "cardNumber": "Card Number",
                  "cvc": "CVC",
                  "expiryDate": "Expiry Date"
                },
                "modalTitle": "Edit card details",
                "placeholders": {
                  "cardNumber": "Enter card number",
                  "cvc": "Enter CVC"
                }
              },
              "creditDetails": "Enter card details"
            },
            "required": "Required",
            "selectDate": "Select date",
            "signature": {
              "clearSignatureButtonText": "Clear signature",
              "editSignatureButtonText": "Edit signature",
              "openSignatureModalText": "Sign here",
              "prefilledSignatureText": "For your convenience, we’ve pre-filled your name. You can change it if you need to."
            }
          },
          "navigation": {
            "next": "Next",
            "nextField": "Next field",
            "startSigning": "Start signing"
          },
          "progressBar": {
            "shortSuccessText": "You’ve completed all required fields",
            "status": "{{fieldsFilled}}/{{totalFields}} required fields",
            "submitAction": "Submit",
            "successText": "You’ve completed all required fields. Once you’re done reviewing, you can {<submitAction>} the document."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Track your progress"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "You have no fields to fill out",
              "optionalCount": "{{count}} optional field",
              "optionalCount_plural": "{{count}} optional fields",
              "optionalOnly": "You have {<optionalCountLabel>} to fill out",
              "pending": "Pending",
              "requiredAndOptional": "You have {<requiredCountLabel>} and {<optionalCountLabel>} to fill out",
              "requiredCount": "{{count}} required field",
              "requiredCount_plural": "{{count}} required fields",
              "requiredOnly": "You have {<requiredCountLabel>} to fill out"
            },
            "label": {
              "details": "Details",
              "fields": "Fields"
            }
          }
        },
        "declineDocument": {
          "header": "Declined document",
          "message": "You have declined this document. If you still wish to sign it, please ask sender to send a new document",
          "sender": "Sender"
        },
        "header": {
          "documentActions": {
            "actions": "Actions",
            "actionsAndDetails": "Details and actions",
            "declineDocument": "Decline document",
            "declineFailed": "We couldn't decline the document. Try again",
            "downloadOriginal": "Download original PDF",
            "saveFailed": "We couldn't save your progress. Try again",
            "saveProgress": "Save progress",
            "saveSuccess": "Saved progress"
          },
          "moreActions": "More actions"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Authentication Failed!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancel",
            "decline": "Decline",
            "reason": "Reason",
            "reasonHelp": "This reason will be shared with the sender",
            "reasonPlaceholder": "Why are you declining?",
            "title": "Decline document",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} attempt remaining",
            "attemptsAmountPlural": "{{attemptsAmount}} attempts remaining",
            "contentMessage": "Enter the 5-digit passcode provided by the sender to access this document.",
            "inputLabel": "Enter passcode",
            "inputPlaceholder": "Enter 5 digit passcode",
            "inputValidationMessage": "Passcode must be 5 digits",
            "inputValueRequiedMessage": "This field is required",
            "invalidPasscodeMessage": "Invalid passcode.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}",
            "unlockButton": "Unlock",
            "welcomeMessage": "Welcome!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "We sent a copy of the completed document to {<email>}",
            "downloadButton": "Download document",
            "notAllSignedText": "We will send a copy of the signed document to {<email>} once all signers have signed this document",
            "reviewButton": "Review signed document",
            "title": "Submitted document"
          },
          "termsAndConditions": {
            "continueButton": "Continue",
            "esignConsent": "E-Sign consent",
            "footerEsignConsent": "You're acquainted with and agree to our {<link>}",
            "footerTerms": "You're acquainted with and agree to our {<linkTermsOfUse>} and {<linkPrivacyPolicy>}",
            "footerTitle": "By continuing, you confirm that:",
            "greetings": "Hi {{signerName}},",
            "privacyPolicy": "Privacy Policy",
            "senderMessageTitle": "Message from sender",
            "termsOfUse": "Terms of Use",
            "text": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Submit document",
          "submit": "Submit",
          "submitConfirm": "Are you sure you're ready to submit this document?",
          "submitWarning": "You won't be able to make changes after submitting",
          "submitWithUnfilledOptionalFields": "You skipped an optional field. Are you sure you're ready to submit this document?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Complete all required fields to submit the document"
        }
      },
      "signerSequencingDisabledTooltip": "Signing order is applicable only when there is more than 1 signer",
      "signerSequencingNotApplicableTooltip": "Signing order doesn't apply to viewers",
      "signers": "Signers",
      "signersLabel": "Signers",
      "singerSequencingAppliedText": "Signer sequencing was applied",
      "sizeLimitFile": "File {{filename}} ",
      "sizeLimitMessage": "exceeds our 20MB limit",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Remove template",
              "content": "The bulk signers list will be lost and you'll need to upload a new document to proceed.",
              "header": "Are you sure you want to remove this template"
            },
            "modal": {
              "confirmButton": "Remove document",
              "content": "Removing a document deletes all predefined recipients and their assigned fields. Are you sure you want to remove the document?",
              "header": "Remove document"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Select",
              "createdBy": "Created by:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Eligible for bulk send",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Failed to load templates. Please try again later",
            "footer": {
              "buttonText": "Create a template",
              "question": "Can’t find what you're looking for?"
            },
            "noDocuments": "You don’t have any templates yet",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "There's nothing here",
            "noSearchResultsDesc": "It doesn't look like anything matches your search.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Roles:",
            "title": "Select a template"
          },
          "sourceName": "Use a Template"
        },
        "upload": {
          "basefileProcessingFailed": "Failed to process {{ filename }} file. Please upload the file again",
          "failed": "Failed to upload {{ filename }} file",
          "numberLimitMessage": "Can't upload more than 5 files"
        }
      },
      "statusFilter": "Status",
      "switch": "Switch to old experience",
      "template": "Template",
      "temporaryRedirectText": "Temporarily switch to RightSignature",
      "text": "Text",
      "textAlignLabel": "Align text",
      "type": "Type",
      "unableToFetchFileDetails": "Failed to fetch file details",
      "unableToProceedNoDocumentError": "Unable to proceed, please add a docment",
      "unableToProceedNoSignerError": "Unable to proceed, please add at least one signer",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "This document type is not supported. Supported file types are .doc, .docx, .txt, .pdf",
      "updateDocumentErrorMessage": "Failed to update the document.",
      "uploadDocumentErrorMessage": "Upload a document to move to the next step",
      "uploadedFilesProcessingErrorMessage": "One or more uploaded files are still processing",
      "useSaved": "Use saved",
      "userFetchErrorMessage": "Failed to retrieve user information",
      "view": "View",
      "viewer": "Viewer",
      "viewerUniqueEmailErrorMessage": "Viewer email must be unique",
      "viewersLabel": "Viewers",
      "void": "Void",
      "voidConfirmationTitle": "Void Document Confirmation",
      "zoomIn": "Zoom in",
      "zoomOut": "Zoom out"
    }
  },
  {
    "key": "es",
    "content": {
      "AccountLimitReached": "La cuenta ha alcanzado el límite máximo de documentos.",
      "AttachedByLabel": "Adjuntado por {{signer}}",
      "AttachmentDetailsLabel": "Datos adjuntos",
      "AttachmentFieldLabel": "Datos adjuntos",
      "CheckFieldLabel": "Casilla de verificación",
      "CheckGroupFieldLabel": "Grupo de casillas de verificación",
      "DateFieldLabel": "Fecha",
      "InitialsFieldLabel": "Iniciales",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Cobrar una vez firmado",
        "chargedLabel": "Cobrado: {<paymentAmount>}",
        "collectCardDetailsLabel": "Recopilar datos de la tarjeta",
        "defaultLabel": "Pago",
        "paymentFailedLabel": "Fallo en el pago"
      },
      "PaymentFieldLabel": "Campo de pago",
      "SelectFieldLabel": "Menú desplegable",
      "SignatureFieldLabel": "Firma",
      "TextFieldLabel": "Texto",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Su plan actual limita la cantidad de documentos que se pueden enviar para firmarse y ha alcanzado ese límite. Usted aún puede firmar documentos.",
        "upgradeTrialBanner": "Restantes de prueba: {<documentsLeft>}."
      },
      "accountLimitExhaustedMessage": "No se puede enviar el documento para su firma; la cuenta ha agotado el límite. Actualice la cuenta.",
      "accountStatsFetchErrorMessage": "No se pudo obtener la información de cuenta",
      "add": "Agregar",
      "addCheckbox": "Agregar casilla de verificación",
      "addFilesFromSharefile": "Agregar elementos desde ShareFile",
      "addMyself": "Agregarme",
      "addOptionLabel": "Agregar opción",
      "addRecipient": "Agregar otro destinatario",
      "addSignature": "Agregar firma",
      "annotator": "Usted (ahora)",
      "annotatorOptionTooltip": "Rellene los campos con antelación",
      "apply": "Aplicar",
      "archiveLocationBlank": "Seleccione una carpeta para almacenar el documento firmado",
      "assignedToLabel": "Asignado a",
      "attachmentFieldLabel": "Datos adjuntos",
      "browseFiles": "Examinar archivos",
      "bulkSend": "Enviar en bloque",
      "cancel": "Cancelar",
      "changesSaved": "Cambios guardados",
      "checkbox": "Casilla de verificación",
      "checkboxGroup": "Grupo de casillas de verificación",
      "checkboxes": "Casillas de verificación",
      "clear": "Borrar",
      "clearBackground": "Clear background",
      "close": "Cerrar",
      "completed": "Completado",
      "componentEditorHeaderLabel": "Campos seleccionados: {<selectedNumberOfFields>}",
      "confirm": "Confirmar",
      "continue": "Continuar",
      "createDocument": {
        "almostDone": "Casi listo. No haga clic en el botón Actualizar o Atrás."
      },
      "createDocumentErrorMessage": "Error al crear el documento",
      "creationDateLabel": "Enviado el",
      "dashboard": {
        "adminView": "Vista de administrador",
        "adminViewTooltipText": "Ver todos los documentos enviados desde esta cuenta",
        "autoDeleteText": "Eliminación automática en {{remaining}} día(s)",
        "clearFilters": "Borrar filtros",
        "clearSearch": "Borrar búsqueda",
        "continueAction": "Continuar",
        "deleteAction": "Eliminar",
        "downloadPdf": "Descargar",
        "esignLeftNav": "Firmas",
        "loadMore": "Cargar más",
        "overdue": "Vencido desde hace 1 día",
        "overdue_plural": "Vencido desde hace {{count}} días",
        "signatureDue": "Vencimiento: {{dueDate}}",
        "signatureRequests": "Solicitudes de firma",
        "signatureSettings": "Parámetros de firma",
        "signaturesSubmitted": "Firmas enviadas",
        "templates": "Plantillas",
        "title": "Solicitudes de firma"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "No parece haber ninguna coincidencia con sus filtros.",
          "title": "No hay nada aquí"
        },
        "noRequestsCreated": {
          "description": "¿Necesita obtener una firma? Cree una solicitud.",
          "title": "Aún no tiene ninguna solicitud de firma"
        },
        "noSearchResults": {
          "description": "La búsqueda no parece haber producido resultados.",
          "title": "No hay nada aquí"
        }
      },
      "date": "Fecha",
      "dateExample": "Ejemplo:",
      "dateExtendError": "Elija una fecha futura",
      "dateFormatLabel": "Formato",
      "dateSignedLabel": "Fecha de autorrellenado firmada",
      "dateSignedTooltip": "Un destinatario no podrá modificar el campo. Se rellenará automáticamente el día en que se firme el documento.",
      "declinedByLabel": "Rechazado por {{name}}",
      "defaultBadgeLabel": "Predeterminado",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Enviar a todos un correo electrónico con el PDF completado",
          "attachCompletedPDFCheckboxTooltip": "Tras la última firma, enviaremos a todos un correo electrónico con el PDF completado como archivo adjunto (a no ser que supere los 10 MB).",
          "sectionHeader": "Datos adjuntos"
        },
        "header": "Modificar parámetros predeterminados",
        "headerInfo": "Los cambios que realice aquí se aplicarán a todas las solicitudes de firma en curso.",
        "hipaaConfirmationBodyFirst": "Cuando se aplique este parámetro, todos los destinatarios recibirán una copia del documento firmado como archivo adjunto de correo electrónico. Como cliente de HIPAA, es su responsabilidad confirmar que los destinatarios permiten que su información se gestione de esta manera.",
        "hipaaConfirmationBodySecond": "¿Seguro que quiere aplicar este parámetro?",
        "hipaaConfirmationButton": "Aplicar parámetro",
        "hipaaConfirmationHeader": "Consideraciones sobre HIPAA",
        "reminders": {
          "options": {
            "daily": "Diariamente",
            "noAuto": "Nunca",
            "weekly": "Semanalmente"
          },
          "reminderFrequencyLabel": "Enviar recordatorios automáticos",
          "reminderFrequencyTooltip": "Envíe recordatorios por correo electrónico a los destinatarios semanalmente (después de 3, 7, 14, 21 y 28 días) o diariamente (después de 7 días) hasta que firmen.",
          "sectionHeader": "Recordatorios"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Permitir firmas manuscritas",
          "allowTypedSignatureLabel": "Permitir firmas mecanografiadas",
          "applyBlueInkSignatureLabel": "Aplicar tinta azul a las firmas",
          "applyBlueInkSignatureTooltipAdmin": "Contacte con su administrador si necesita cambiar este parámetro.",
          "applyBlueInkSignatureTooltipNonAdmin": "Utilice tinta azul para todas las firmas para ayudar a distinguir los originales firmados de las fotocopias.",
          "sectionHeader": "Tipos de firma"
        },
        "signer": {
          "allowDownload": "Permitir que los firmantes puedan descargar el documento original antes de firmarlo",
          "sectionHeader": "Permisos del firmante"
        }
      },
      "defaultSettingsDescription": "Cambie los parámetros de los recordatorios, archivos adjuntos de correo electrónico y tipos de firma.",
      "defaultSettingsEditLabel": "Modificar parámetros predeterminados",
      "defaultSettingsLabel": "Parámetros predeterminados",
      "defaultSettingsUpdateFailed": "Error al actualizar los parámetros predeterminados",
      "defaultSettingsUpdateSuccess": "Parámetros predeterminados actualizados",
      "defaultValueLabel": "Agregar texto predeterminado",
      "delete": "Eliminar",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Esto eliminará permanentemente la solicitud de firma. Una vez hecho esto, no se puede deshacer.",
        "deleteConfirmationMessageSecond": "Los firmantes y participantes recibirán un correo electrónico con el documento completo y el certificado de firma para sus registros.",
        "deleteRequest": "Eliminar solicitud",
        "deleteRequestSuccess": "Solicitud eliminada"
      },
      "deleteDraftDocumentLabel": "Eliminar solicitud de firma",
      "deleteDraftDocumentModalContent": "¿Seguro que quiere eliminar esta solicitud de firma? Esta operación no se puede deshacer.",
      "deleteDraftDocumentSuccess": "Solicitud de firma eliminada",
      "deleteViewerWarningHeader": "¿Seguro que quiere quitar este destinatario?",
      "deleteViewerWarningMessage": "Ya no tendrán acceso a esta solicitud de firma.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Modificar código de acceso",
        "kba": "Autenticación basada en conocimientos",
        "passcode": "Código de acceso",
        "title": "Opción de seguridad aplicada",
        "updatePasscodeSuccess": "El código de acceso de este documento se ha actualizado."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "No puede revisar las solicitudes enviadas con envío en bloque",
          "signerSigned": "No puede revisar las solicitudes una vez que alguien haya firmado",
          "userNotSender": "No puede revisar las solicitudes que no haya enviado"
        }
      },
      "documentAlreadySentErrorDetails": "No se puede abrir el documento enviado para modificarlo. Inicie un nuevo flujo.",
      "documentAlreadySentErrorTitle": "Documento ya enviado",
      "documentForSignature": "Documento que enviar",
      "documentForceCompleted": "Se ha forzado la finalización de la solicitud",
      "documentLockedMessage": "Este destinatario está bloqueado temporalmente debido a varios intentos fallidos de autenticación",
      "documentNameBlank": "Introduzca un nombre de documento",
      "documentNameInputDisabledTooltip": "El nombre del documento no se puede modificar",
      "documentNameInputLabel": "Nombre del documento",
      "documentStates": {
        "declined": "Rechazado",
        "draft": "Borrador",
        "editing": "En pausa para realizar revisiones",
        "executed": "Completado",
        "expired": "Caducado",
        "pending": "En curso",
        "voided": "Anulados"
      },
      "documentThumbnailAltText": "Miniatura de la primera página",
      "documentTransactionCreateErrorMessage": "Error al crear la transacción del documento",
      "documentTransactionGetErrorMessage": "Error al obtener la transacción del documento",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "El documento se ha desbloqueado correctamente",
      "documentView": {
        "documentPageIndex": "Página {<currentPage>} de {{totalPages}}",
        "pageChangeInputLabel": "Cambiar página a",
        "pageDown": "Página siguiente",
        "pageUp": "Página anterior"
      },
      "documentVoided": "Solicitud de firma anulada",
      "dontShowAgain": "No volver a mostrar este mensaje",
      "downloadOptions": {
        "combinedPDF": "PDF combinado",
        "completedPDF": "PDF completado",
        "download": "Descargar",
        "downloadErrorMessage": "Tipo de descarga no compatible: {{downloadType}}",
        "downloadOriginal": "PDF original",
        "signatureCertificate": "Certificado de firma"
      },
      "draftSaveErrorMessage": "Error al guardar la solicitud de firma",
      "draftSaveSuccessMessage": "Solicitud de firma guardada",
      "draw": "Dibujar",
      "dueDate": "Fecha de vencimiento",
      "dueDateUpdated": "Fecha de vencimiento actualizada",
      "edit": "Editar",
      "editDocumentModal": {
        "cancel": "Atrás",
        "confirm": "Revisar",
        "contentLine1": "Si necesita agregar o quitar destinatarios o campos, o si quiere cambiar las opciones de seguridad, puede revisar esta solicitud.",
        "contentLine2": "Pausaremos la solicitud mientras efectúa las revisiones. Esto significa que todas las personas que aún no hayan firmado perderán temporalmente el acceso. Cuando haya terminado, les enviaremos un nuevo enlace.",
        "contentLine3": "Una vez que haya empezado a realizar la revisión, no podrá reactivar la solicitud si no envía un documento revisado.",
        "title": "¿Seguro que quiere revisar esta solicitud?"
      },
      "editDueDate": "Modificar fecha de vencimiento",
      "editLeftMidwayModal": {
        "cancel": "Atrás",
        "confirm": "Terminar más tarde",
        "contentLine1": "Los firmantes no podrán acceder a esta solicitud mientras esté en pausa para las revisiones. Si ha terminado, vuelva atrás y elija ",
        "contentLine2": "Si aún no tiene todo listo, puede volver y terminar sus revisiones más tarde.",
        "title": "¿Sigue revisando?"
      },
      "editRecipients": "Modificar destinatarios",
      "editSignatureRequest": "Revisar",
      "editSigner": "Modificar firmante",
      "editViewer": "Modificar participante",
      "emptyFolder": "Esta carpeta está vacía",
      "enforceStorageUsage": "No podrá crear solicitudes de firma hasta que libere algo de espacio",
      "expirationDateMessage": "Fecha de caducidad: {{expirationDate}}",
      "expirationDaysLabel": "d",
      "expirationInputDescription": "El documento ya no estará disponible para firma una vez transcurrido el número de días especificado:",
      "expirationInputLabel": "Caducidad",
      "extendDueDate": "Prolongar fecha de vencimiento",
      "extendExpirationDate": "Prolongar",
      "favorites": "Favoritos",
      "field": "campo",
      "fieldNameLabel": "Campo de nombre",
      "fieldRequired": "Este campo es obligatorio",
      "fields": "Campos",
      "fieldsAssignedWarningHeader": "Campos asignados a los firmantes",
      "fieldsAssignedWarningMessage": "Se eliminarán todos los campos asignados a los firmantes.",
      "fieldsDescription": "Elija un tipo de campo para colocarlo en el documento",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Editar",
        "sectionLabel": "¿Dónde quiere guardar el documento firmado?"
      },
      "fileNotSupportedTooltip": "Formato de archivo no compatible",
      "filesAndRecipients": "Archivos y destinatarios",
      "filterSelected": "1 filtro",
      "filterSelected_plural": "{{count}} filtros",
      "forceComplete": "Forzar ejecución",
      "forceCompleteTitle": "Solicitud para forzar ejecución",
      "forceCompleteWarningFirst": "Esto eliminará todos los campos asignados a los firmantes que aún no han firmado y hará que el documento se ejecute (estado final). Una vez hecho esto, no se puede deshacer.",
      "forceCompleteWarningSecond": "El estado del documento puede tardar unos momentos en actualizarse en el panel.",
      "generateNewPasscodeButtonLabel": "Generar nuevo código de acceso",
      "genericError": "Ocurrió un error",
      "groupRule": "Regla del grupo",
      "hasAtleastOneSignerErrorMessage": "Agregue al menos un firmante para continuar",
      "importFields": {
        "linkText": "Importar campos",
        "modal": {
          "assignImportedFields": "Asignar campos importados",
          "body": {
            "buttonText": "Seleccionar",
            "searchPlaceholder": "Buscar por nombre de documento, nombre de archivo, remitente o rol",
            "sentBy": "Enviado por"
          },
          "componentsImported": "Campos importados: {{count}}",
          "fields": "campo(s)",
          "importFieldsButtonText": "Importar campos",
          "importFrom": "Importar desde:",
          "mapAllRecipients": "Asignar campos a nuevos destinatarios para continuar",
          "newRecipient": "Nuevo destinatario",
          "noDocuments": "Aún no tiene ningún documento",
          "originalRecipient": "Destinatario original",
          "selectDifferentDocument": "Seleccione otro documento",
          "selectRecipient": "Seleccionar destinatario",
          "sentBy": "Enviado por",
          "title": "Seleccione un documento para importar campos",
          "titleImportFields": "Importar campos",
          "titleImportFieldsWithCount": "Importar {{count}} campos"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Atrás",
          "contentLine1": "{<documentName>} no tiene ningún campo que podamos importar. Puede elegir un documento diferente o crear nuevos campos.",
          "title": "Este documento no tiene ningún campo"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Atrás",
          "confirmButtonText": "Importar campos de todos modos",
          "contentLine1": "El documento que seleccionó tiene un número de páginas diferente del documento en el que está trabajando actualmente.",
          "contentLine2": "Puede seguir importando campos, pero solo consideraremos la(s) primera(s) {{pageCount}} página(s) del documento seleccionado. No se importarán los campos de las páginas posteriores.",
          "title": "Estos documentos parecen un poco diferentes"
        },
        "tooltipText": "Importar campos de documentos enviados anteriormente"
      },
      "initials": "Iniciales",
      "invalidEmail": "Correo electrónico no válido",
      "invalidExpirationDaysError": "Escriba un número entre 1 y 365",
      "invalidExpirationDaysNotificationMessage": "Elija una fecha de caducidad",
      "invalidRecipientErrorMessage": "Necesitamos un nombre y un correo electrónico para cada destinatario",
      "invalidSignatureRequest": "La solicitud de firma no parece válida; compruebe la URL",
      "invite": "Invitar",
      "inviteViewers": "Invitar a participantes",
      "kbaDescription": "Pida a los destinatarios que verifiquen su identidad con un cuestionario de conocimientos. Cada firmante deberá verificarla de forma independiente antes de firmar el documento.",
      "kbaInfoMessage": "Después de 3 intentos fallidos, se bloqueará a los firmantes el acceso al documento.",
      "kbaLabel": "Autenticación basada en conocimientos",
      "legacyTokenInterceptor": {
        "buttonText": "Iniciar sesión",
        "message": "Le traeremos de regreso aquí para que pueda continuar donde lo dejó.",
        "title": "Inicie sesión nuevamente para acceder a sus solicitudes de firma"
      },
      "messageLabel": "Mensaje a los destinatarios",
      "mixedAlignment": "Alineación mixta",
      "mixedDateFormat": "Formato mixto",
      "mixedFontSizes": "Tamaños de fuente mixtos",
      "moveAwaySelections": "Si abandona la carpeta actual, se perderá lo que haya seleccionado.",
      "multiLineLabel": "Permitir varias líneas",
      "multipleRecipients": "Varios destinatarios",
      "nameGroup": "Grupo de nombres",
      "nextStep": "Siguiente paso",
      "optional": "Optativo",
      "optionalComment": "Comentario opcional adjunto",
      "optionsLabel": "Opciones",
      "packagerConfirmationModal": {
        "contentLine1": "Necesitamos combinar estos documentos en un solo archivo antes de poder prepararlos para la firma. Una vez que continúe con el siguiente paso, no podrá volver atrás ni eliminar ninguno.",
        "contentLine2": "¿Todo listo para continuar?",
        "mergeModalText": "Este proceso podría tardar un minuto. Le llevaremos al siguiente paso tan pronto como su documento esté listo.",
        "progressBarText": "Fusionando...",
        "title": "Fusionar documentos"
      },
      "passcodeCharacterWarning": "El código de acceso solo puede contener dígitos.",
      "passcodeDescription": "Los destinatarios deben introducir un código de acceso de {<fiveDigit>} para acceder a los documentos.",
      "passcodeFiveDigit": "5 dígitos",
      "passcodeInfoMessage": "Deberá compartir este código de acceso con cada destinatario.",
      "passcodeLabel": "Código de acceso",
      "passcodeLengthError": "Elija un código de acceso o inhabilite esa opción de seguridad",
      "passcodeLengthErrorEditMode": "El código de acceso debe tener 5 dígitos",
      "pending": "Pendiente",
      "personalFolders": "Carpetas personales",
      "placeFields": "Colocar campos",
      "prepareDocument": {
        "components": {
          "attachment": "Datos adjuntos",
          "checkmarkAnnotation": "Agregar marca de verificación",
          "editor": {
            "chargeAfterExecutedTooltip": "Use esta opción si quiere hacer el cargo en su tarjeta una vez que todos los firmantes hayan firmado",
            "collectCreditCardTooltip": "Use esta opción si solo quiere recopilar información de la tarjeta",
            "defaultHelpText": {
              "attachmentComponent": "Cargue sus datos adjuntos aquí",
              "paymentComponent": "Agregue aquí los datos de su tarjeta"
            },
            "fieldSubtypeLabel": "Subtipo de campo",
            "fontSizeLabel": "Tamaño de fuente",
            "helpTextLabel": "Agregar texto de ayuda",
            "paymentAmountLabel": "Importe del pago (USD)",
            "paymentAmountPlaceHolder": "Introduzca el importe del pago"
          },
          "textAnnotation": "Agregar texto"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Cada menú desplegable debe tener al menos 2 opciones",
          "paymentAmountInvalidError": "Introduzca un importe de pago para continuar"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Los firmantes no tienen nada que firmar.",
          "cancelButtonText": "Atrás",
          "confirmButtonText": "Continuar de todos modos",
          "description": "Puede continuar con el siguiente paso de todos modos, o puede volver atrás y agregar campos para que los rellenen.",
          "subtitle": "Estos firmantes no tienen nada que firmar:",
          "title": "¿Olvidó algún campo?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "ID del documento",
          "dueDate": "Fecha de vencimiento",
          "otherDetails": "Otros detalles",
          "recipients": "Destinatarios",
          "sender": "Remitente",
          "sentOn": "Enviado el",
          "signerSequencing": "Secuencia de firmantes aplicada",
          "signers": "Firmantes"
        },
        "documentHeader": {
          "status": "Estado:",
          "viewDetails": "Ver detalles"
        },
        "downloadModal": {
          "combinedDescription": "Incluye certificado de firma y documento cumplimentado",
          "combinedTitle": "PDF combinado",
          "completedDocumentDescription": "Documento firmado con campos rellenados",
          "completedDocumentTitle": "Documento cumplimentado",
          "done": "Listo",
          "originalDescription": "Documento PDF sin ningún campo de firmante",
          "originalTitle": "PDF original",
          "signatureCertificateDescription": "Certificado de confianza de Sharefile",
          "signatureCertificateTitle": "Certificado de firma",
          "title": "Descargar documento"
        },
        "pageHeader": {
          "completed": "Completados",
          "download": "Descargar",
          "downloadOriginalDocument": "Descargar documento original",
          "inProgress": "En curso"
        }
      },
      "previousStep": "Paso anterior",
      "processingState": "Procesando...",
      "radio": "Grupo de botones de opciones",
      "readyState": "Listo.",
      "reasonDeclineLabel": "Motivo del rechazo",
      "reasonVoidLabel": "Motivo de anulación",
      "recipientDuplicate": "Esta persona ya es un lector",
      "recipientEmail": "Correo electrónico",
      "recipientListHeading": "¿Quiénes son los destinatarios?",
      "recipientName": "Nombre",
      "recipientNoName": "Este campo es obligatorio",
      "recipientRemindButtonLabel": "Avisar",
      "recipientRole": "Rol",
      "recipientRoleSigner": "Firmante",
      "recipientRoleViewer": "Participante",
      "recipientStatusSigned": "Firmado",
      "recipientUnlockLabel": "Desbloquear",
      "recipientsLabel": "Destinatarios",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Pronto ShareFile le permitirá cargar un archivo CSV de destinatarios para que pueda solicitar una firma de hasta 300 personas a la vez. Hasta entonces, todavía puede hacer esto en RightSignature.",
          "closeButton": "Cancelar",
          "header": "¿Busca envío en bloque?",
          "redirectButton": "Ir a RightSignature"
        },
        "packager": {
          "bodyLineOne": "Pronto ShareFile le permitirá enviar varios documentos en una sola solicitud de firma. Hasta entonces, todavía puede hacer esto en RightSignature.",
          "closeButton": "Cancelar",
          "header": "¿Necesita enviar varios documentos?",
          "redirectButton": "Ir a RightSignature"
        },
        "template": {
          "bodyLineOne": "Pronto podrá crear y usar plantillas de firma en ShareFile.",
          "bodyLineTwo": "Pruebe en RightSignature para prepararse.",
          "closeButton": "Ahora no",
          "header": "Optimice el proceso con plantillas",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Probar plantillas"
        }
      },
      "referenceIdLabel": "ID de referencia",
      "reminderSent": "Aviso enviado",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Quitar firmantes",
        "content": "Se quitarán todos los firmantes importados de {<fileName>}.",
        "title": "¿Seguro que quiere quitar estos firmantes?"
      },
      "removeCheckboxGroup": "Quitar grupo de casillas de verificación",
      "removeFieldLabel": "Quitar campo",
      "removeFileDisabledTooltip": "El documento no se puede quitar",
      "removeFileFailed": "No se pudo quitar el archivo. Inténtelo de nuevo.",
      "requestSignatureButton": {
        "label": "Crear solicitud de firma"
      },
      "requireGroup": "Requerir grupo",
      "required": "Requerido",
      "requiredFieldLabel": "Se requiere campo",
      "requiredFieldsLabel": "Se requieren campos",
      "reviewAndSend": "Revisar y enviar",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Este campo no puede estar vacío"
          }
        }
      },
      "save": "Guardar",
      "saveCompletedDocument": "Guardar documento completado",
      "savedSignaturesLabel": "Seleccione la firma que quiere aplicar en el documento",
      "search": "Buscar",
      "securityOptionsLabel": "Opciones de seguridad",
      "selectOneOrMoreLabel": "Seleccione una o más",
      "selectOnlyOneLabel": "Seleccione solo una",
      "selfSignCheckDisabledToolTip": "La autofirma no está disponible al seleccionar una plantilla",
      "selfSignCheckLabel": "Soy el único firmante",
      "selfSignCheckToolTip": "Firme el documento y luego compártalo. Se guardará en la carpeta seleccionada.",
      "selfSignSucessMessage": "Documento guardado",
      "selfSignature": "Su firma",
      "sendForSignature": "Enviar para firma",
      "sendInBulk": {
        "allSigners": "Los {<signersCount>} firmantes",
        "bulkSigners": "Firmantes en bloque",
        "couldTakeAMoment": "Esto puede tardar unos instantes.",
        "disableTooltipTextForPLG": "Actualice su plan para obtener acceso",
        "downloadCSVTemplateButton": "Descargar plantilla CSV",
        "email": "Correo electrónico",
        "errors": {
          "correctTheFollowingError": "Corrija los siguientes errores de su archivo e inténtelo de nuevo.",
          "exceedsRecipientsLimit": "{<fileName>} supera nuestro límite de 300 firmantes.",
          "fileSizeExceeded": "El archivo supera nuestro límite de 5 MB.",
          "onlyOneFile": "Solo puede importar firmantes de un único archivo.",
          "supportedFileType": "Solo puede importar firmantes de un archivo CSV.",
          "unableToLoadSpreadsheet": "Al archivo le falta información importante."
        },
        "gatheringSignersFromFile": "Reuniendo firmantes de su archivo...",
        "goBack": "Atrás",
        "import": "Importar",
        "importSigners": "Importar firmantes",
        "importSignersModalText1": "Le hemos preparado una plantilla CSV. Descárguela y complete los campos de nombre y correo electrónico (obligatorios).",
        "importSignersModalText2": "CSV es el único formato de archivo compatible. El número de firmantes no puede superar los 300.",
        "name": "Nombre",
        "note": "Nota: ",
        "previewingTheSigners": "Vista previa de los {<firstSignersCount>} primeros de {<signersCount>} firmantes",
        "selectDifferentFile": "Seleccione un archivo diferente",
        "title": "Enviar en bloque",
        "tooltipText": "El envío en bloque solo se puede usar con plantillas de un solo firmante",
        "uploadFileText": "Arrastre el CSV hasta aquí o"
      },
      "sendRevisedRequest": "Enviar solicitud revisada",
      "sendSignatureRequest": "Enviar solicitud de firma",
      "sendSigningLink": {
        "gotIt": "De acuerdo",
        "sentLinkInfoBanner": "Le hemos enviado un enlace",
        "signingLinkInfoMessage1": "Compruebe su correo electrónico {<email>} para obtener un enlace para firmar este documento.",
        "signingLinkInfoMessage2": "Pronto podrá firmar directamente en Sharefile."
      },
      "senderRoleChangeTooltip": "El remitente no puede ser un participante",
      "sentByLabel": "Enviado por",
      "setSigningOrder": "Establecer orden de firmas",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Cancelar",
          "createFolderLabel": "Crear carpeta",
          "disabledNodeUploadReason": "Se necesita permiso de carga para seleccionar esta carpeta",
          "disabledSharedFoldersReason": "No se pueden seleccionar carpetas compartidas de nivel superior",
          "modalTitle": "Guardar el contrato firmado en...",
          "selectFolderButtonLabel": "Seleccionar"
        }
      },
      "sharedFolders": "Carpetas compartidas",
      "showAll": "Mostrar todo",
      "signButtonLabel": "Firmar",
      "signYourself": "Firmar usted mismo",
      "signature": "Firma",
      "signatureAnnotationMessage": "Haga clic y arrastre para dibujar su firma dentro del cuadro",
      "signatureAnnotationTextMessage": "Introduzca su firma en el campo que aparece a continuación",
      "signatureRequest": "Solicitud de firma",
      "signatureRequestErrorMessage": "Solicitud de firma fallida",
      "signatureRequestSuccessMessage": "Solicitud de firma enviada",
      "signer": "Firmante",
      "signerDescription": {
        "annotation": "Coloque campos para usted y complételos por adelantado",
        "bulkSigners": "Está colocando campos para todos los firmantes",
        "signers": "Deberá colocar campos para cada firmante"
      },
      "signerMessageInputLabel": "Nota para todos los firmantes (opcional)",
      "signerMessageInputLabelTooltip": "Incluiremos esta nota en la notificación por correo electrónico que se envía a los firmantes",
      "signerMessageInputPlaceholder": "Agregar una nota personalizada a todos los firmantes",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Nombre e ID del documento",
            "dueDateTitle": "Fecha de vencimiento",
            "senderInfoTitle": "Remitente",
            "senderMessageTitle": "Mensaje del remitente"
          },
          "fields": {
            "addInitials": "Agregar iniciales",
            "attachment": {
              "buttonText": "Adjuntar archivos",
              "fileUploadingError": "No se puede cargar el archivo. Inténtelo de nuevo.",
              "modalButtonContinue": "Listo",
              "modalDeleteFileCancelButton": "Cancelar",
              "modalDeleteFileOkButton": "Eliminar",
              "modalDeleteFileText": "¿Seguro que quiere eliminar {<fileName>}?",
              "modalDeleteFileTitle": "Eliminar archivo",
              "modalTitle": "Cargar archivo",
              "modalUploadAnotherFileText": " y cargar un nuevo archivo?",
              "uploadDifferentFileButton": "Cargar un archivo diferente",
              "uploadHint": "Un solo archivo",
              "uploadText": "Haga clic o arrastre el archivo a esta área para cargarlo",
              "uploadingStatus": "Cargando"
            },
            "autofilled": "Autocompletado",
            "dropdown": {
              "clearSelection": "Borrar selección",
              "selectOption": "Seleccionar opción"
            },
            "enterText": "Introducir texto",
            "format": "Format",
            "optional": "Optativo",
            "paymentField": {
              "amount": "Importe",
              "authorizePayment": "Autorizar pago",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Cambiar",
              "clear": "Borrar",
              "creditCardModalDetails": {
                "authorizeMessage": "Acepto que me cobren {<paymentAmount>} después de enviar este documento",
                "cvcTooltipMessage": "Un código de seguridad de tres o cuatro dígitos impreso en el reverso (o en el anverso en el caso de AMEX) de su tarjeta de crédito",
                "labels": {
                  "cardNumber": "Número de tarjeta",
                  "cvc": "CVC",
                  "expiryDate": "Fecha de caducidad"
                },
                "modalTitle": "Modificar datos de la tarjeta",
                "placeholders": {
                  "cardNumber": "Introducir número de tarjeta",
                  "cvc": "Introducir CVC"
                }
              },
              "creditDetails": "Introducir datos de la tarjeta"
            },
            "required": "Requerido",
            "selectDate": "Seleccionar fecha",
            "signature": {
              "clearSignatureButtonText": "Borrar firma",
              "editSignatureButtonText": "Modificar firma",
              "openSignatureModalText": "Firme aqui",
              "prefilledSignatureText": "Para su comodidad, hemos prerrellenado su nombre. Puede cambiarlo si es necesario."
            }
          },
          "navigation": {
            "next": "Siguiente",
            "nextField": "Siguiente campo",
            "startSigning": "Iniciar firma"
          },
          "progressBar": {
            "shortSuccessText": "Ha completado todos los campos obligatorios",
            "status": "{{fieldsFilled}}/{{totalFields}} campos obligatorios",
            "submitAction": "Enviar",
            "successText": "Ha completado todos los campos obligatorios. Una vez que haya terminado de revisar, puede {<submitAction>} el documento."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Haga seguimiento del progreso"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "No tiene ningún campo que rellenar",
              "optionalCount": "{{count}} campo opcional",
              "optionalCount_plural": "Campos opcionales: {{count}}",
              "optionalOnly": "Tiene {<optionalCountLabel>} que rellenar",
              "pending": "Pendientes",
              "requiredAndOptional": "Tiene {<requiredCountLabel>} y {<optionalCountLabel>} que rellenar",
              "requiredCount": "{{count}} campo obligatorio",
              "requiredCount_plural": "{{count}} campos obligatorios",
              "requiredOnly": "Tiene {<requiredCountLabel>} que rellenar"
            },
            "label": {
              "details": "Detalles",
              "fields": "Campos"
            }
          }
        },
        "declineDocument": {
          "header": "Documento rechazado",
          "message": "Ha rechazado este documento. Si aún quiere firmarlo, pida al remitente que envíe un documento nuevo",
          "sender": "Remitente"
        },
        "header": {
          "documentActions": {
            "actions": "Acciones",
            "actionsAndDetails": "Detalles y acciones",
            "declineDocument": "Rechazar documento",
            "declineFailed": "No pudimos rechazar el documento. Inténtelo de nuevo.",
            "downloadOriginal": "Descargar PDF Original",
            "saveFailed": "No pudimos guardar su progreso. Inténtelo de nuevo.",
            "saveProgress": "Guardar progreso",
            "saveSuccess": "Progreso guardado"
          },
          "moreActions": "Mas acciones"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Error de autenticación.",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancelar",
            "decline": "Rechazar",
            "reason": "Motivo",
            "reasonHelp": "Este motivo se compartirá con el remitente",
            "reasonPlaceholder": "¿Por qué rechaza?",
            "title": "Rechazar documento",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} intento restante",
            "attemptsAmountPlural": "{{attemptsAmount}} intentos restantes",
            "contentMessage": "Para acceder a este documento, introduzca un código de acceso de 5 dígitos proporcionado por el remitente.",
            "inputLabel": "Introducir código de acceso",
            "inputPlaceholder": "Introducir código de acceso de 5 dígitos",
            "inputValidationMessage": "El código de acceso debe tener 5 dígitos",
            "inputValueRequiedMessage": "Este campo es obligatorio",
            "invalidPasscodeMessage": "Código de acceso no válido.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) le ha invitado a firmar {<documentName>}",
            "unlockButton": "Desbloquear",
            "welcomeMessage": "Bienvenido"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "Enviamos una copia del documento completo a {<email>}",
            "downloadButton": "Descargar documento",
            "notAllSignedText": "Enviaremos una copia del documento firmado a {<email>} una vez que todos los firmantes hayan firmado este documento",
            "reviewButton": "Revisar documento firmado",
            "title": "Documento enviado"
          },
          "termsAndConditions": {
            "continueButton": "Continuar",
            "esignConsent": "Consentimiento E-Sign",
            "footerEsignConsent": "Conoce y acepta nuestro {<link>}",
            "footerTerms": "Conoce y acepta nuestros {<linkTermsOfUse>} y {<linkPrivacyPolicy>}",
            "footerTitle": "Al continuar, confirma que:",
            "greetings": "Hola, {{signerName}}:",
            "privacyPolicy": "Directiva de privacidad",
            "senderMessageTitle": "Mensaje del remitente",
            "termsOfUse": "Términos de uso",
            "text": "{{senderName}} ({{senderEmail}}) le ha invitado a firmar {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Enviar documento",
          "submit": "Enviar",
          "submitConfirm": "¿Seguro que está todo listo para enviar este documento?",
          "submitWarning": "No podrá hacer cambios después de enviar",
          "submitWithUnfilledOptionalFields": "Ha omitido un campo opcional. ¿Seguro que está todo listo para enviar este documento?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Complete todos los campos obligatorios para enviar el documento"
        }
      },
      "signerSequencingDisabledTooltip": "El orden de firma es aplicable solo cuando hay más de 1 firmante",
      "signerSequencingNotApplicableTooltip": "El orden de firma no se aplica a los participantes",
      "signers": "Firmantes",
      "signersLabel": "Firmantes",
      "singerSequencingAppliedText": "Se aplicó la secuencia de firmantes",
      "sizeLimitFile": "Archivo {{filename}} ",
      "sizeLimitMessage": "supera nuestro límite de 20 MB",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Quitar plantilla",
              "content": "La lista de firmantes en bloque se perderá, y deberá cargar un nuevo documento para continuar.",
              "header": "¿Seguro que quiere quitar esta plantilla?"
            },
            "modal": {
              "confirmButton": "Quitar documento",
              "content": "Al quitar un documento, se eliminan todos los destinatarios predefinidos y sus campos asignados. ¿Seguro que quiere quitar el documento?",
              "header": "Quitar documento"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Seleccionar",
              "createdBy": "Creación por:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Apto para envío en bloque",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Error al cargar plantillas. Inténtelo de nuevo más tarde",
            "footer": {
              "buttonText": "Crear una plantilla",
              "question": "¿No encuentra lo que busca?"
            },
            "noDocuments": "Aún no tiene ninguna plantilla",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "No hay nada aquí",
            "noSearchResultsDesc": "La búsqueda no parece haber producido resultados.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Roles:",
            "title": "Seleccionar una plantilla"
          },
          "sourceName": "Usar una plantilla"
        },
        "upload": {
          "basefileProcessingFailed": "Error al procesar el archivo {{ filename }}. Cargue el archivo nuevamente.",
          "failed": "Error al cargar el archivo {{ filename }}",
          "numberLimitMessage": "No se pueden cargar más de 5 archivos"
        }
      },
      "statusFilter": "Estado",
      "switch": "Cambiar a la antigua experiencia",
      "template": "Plantilla",
      "temporaryRedirectText": "Cambiar temporalmente a RightSignature",
      "text": "Texto",
      "textAlignLabel": "Alinear texto",
      "type": "Tipo",
      "unableToFetchFileDetails": "Error al obtener los detalles del archivo",
      "unableToProceedNoDocumentError": "No se puede continuar; agregue un documento",
      "unableToProceedNoSignerError": "No se puede continuar; agregue al menos un firmante",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "No se admite este tipo de documento. Los tipos de archivo admitidos son: .doc, .docx, .txt y .pdf",
      "updateDocumentErrorMessage": "Error al actualizar el documento.",
      "uploadDocumentErrorMessage": "Cargue un documento para pasar al siguiente paso",
      "uploadedFilesProcessingErrorMessage": "Uno o más archivos cargados aún se están procesando",
      "useSaved": "Usar guardado",
      "userFetchErrorMessage": "No se pudo obtener la información de usuario",
      "view": "Ver",
      "viewer": "Participante",
      "viewerUniqueEmailErrorMessage": "El correo electrónico del lector debe ser único",
      "viewersLabel": "Participantes",
      "void": "Nulo",
      "voidConfirmationTitle": "Confirmación de documento nulo",
      "zoomIn": "Zoom para acercar",
      "zoomOut": "Zoom para alejar"
    }
  },
  {
    "key": "fr",
    "content": {
      "AccountLimitReached": "Le compte a atteint la limite maximale de documents.",
      "AttachedByLabel": "Attaché par {{signer}}",
      "AttachmentDetailsLabel": "Pièces jointes",
      "AttachmentFieldLabel": "Pièce jointe",
      "CheckFieldLabel": "Case à cocher",
      "CheckGroupFieldLabel": "Groupe de cases à cocher",
      "DateFieldLabel": "Date",
      "InitialsFieldLabel": "Initiales",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Facturation après signature",
        "chargedLabel": "{<paymentAmount>} facturé(s)",
        "collectCardDetailsLabel": "Collecter les détails de la carte",
        "defaultLabel": "Paiement",
        "paymentFailedLabel": "Le paiement a échoué."
      },
      "PaymentFieldLabel": "Champ de paiement",
      "SelectFieldLabel": "Liste déroulante",
      "SignatureFieldLabel": "Signature",
      "TextFieldLabel": "Texte",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Votre plan actuel limite le nombre de documents pouvant être envoyés pour signature et vous avez atteint la limite. Vous pouvez toujours signer des documents vous-même.",
        "upgradeTrialBanner": "Il vous reste {<documentsLeft>} dans votre essai."
      },
      "accountLimitExhaustedMessage": "Impossible d'envoyer le document pour signature, car le compte a dépassé la limite. Veuillez mettre à niveau le compte.",
      "accountStatsFetchErrorMessage": "Impossible de récupérer les informations du compte",
      "add": "Ajouter",
      "addCheckbox": "Ajouter une case à cocher",
      "addFilesFromSharefile": "Ajouter des éléments depuis ShareFile",
      "addMyself": "M'ajouter",
      "addOptionLabel": "Ajouter une option",
      "addRecipient": "Ajouter un autre destinataire",
      "addSignature": "Ajouter une signature",
      "annotator": "Vous (maintenant)",
      "annotatorOptionTooltip": "Remplissez les champs à l'avance",
      "apply": "Appliquer",
      "archiveLocationBlank": "Sélectionnez un dossier pour stocker le document signé",
      "assignedToLabel": "Attribué à",
      "attachmentFieldLabel": "Pièce jointe",
      "browseFiles": "Parcourir les fichiers",
      "bulkSend": "Envoi en bloc",
      "cancel": "Annuler",
      "changesSaved": "Modifications enregistrées",
      "checkbox": "Case à cocher",
      "checkboxGroup": "Groupe de cases à cocher",
      "checkboxes": "Cases à cocher",
      "clear": "Effacer",
      "clearBackground": "Clear background",
      "close": "Fermer",
      "completed": "Terminé",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} champs sélectionnés",
      "confirm": "Confirmer",
      "continue": "Continuer",
      "createDocument": {
        "almostDone": "C'est presque terminé. Ne cliquez pas sur le bouton Actualiser ou Précédent"
      },
      "createDocumentErrorMessage": "La création du document a échoué",
      "creationDateLabel": "Envoyé le",
      "dashboard": {
        "adminView": "Vue administrateur",
        "adminViewTooltipText": "Affichez tous les documents envoyés depuis ce compte",
        "autoDeleteText": "Suppression automatique dans {{remaining}} jour(s)",
        "clearFilters": "Effacer les filtres",
        "clearSearch": "Effacer la recherche",
        "continueAction": "Continuer",
        "deleteAction": "Supprimer",
        "downloadPdf": "Télécharger",
        "esignLeftNav": "Signatures",
        "loadMore": "Charger plus",
        "overdue": "1 jour de retard",
        "overdue_plural": "{{count}} jours de retard",
        "signatureDue": "Échéance : {{dueDate}}",
        "signatureRequests": "Demandes de signature",
        "signatureSettings": "Paramètres de signature",
        "signaturesSubmitted": "Signatures envoyées",
        "templates": "Modèles",
        "title": "Demandes de signature"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Vos filtres n'ont renvoyé aucun résultat.",
          "title": "Aucun résultat"
        },
        "noRequestsCreated": {
          "description": "Besoin d'une signature ? Créez une demande.",
          "title": "Vous n'avez pas encore de demande de signature"
        },
        "noSearchResults": {
          "description": "Votre recherche n'a renvoyé aucun résultat.",
          "title": "Aucun résultat"
        }
      },
      "date": "Date",
      "dateExample": "Exemple :",
      "dateExtendError": "Choisissez une date ultérieure.",
      "dateFormatLabel": "Format",
      "dateSignedLabel": "Remplir automatiquement la date de signature",
      "dateSignedTooltip": "Le destinataire ne pourra pas modifier le champ. Il sera rempli automatiquement le jour de la signature du document.",
      "declinedByLabel": "Refusé par {{name}}",
      "defaultBadgeLabel": "Valeur par défaut",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Envoyer à tout le monde un e-mail avec le PDF complété",
          "attachCompletedPDFCheckboxTooltip": "Après la dernière signature, nous enverrons à chacun un e-mail avec le PDF complété en pièce jointe (sauf s'il dépasse 10 Mo).",
          "sectionHeader": "Pièces jointes"
        },
        "header": "Modifier les paramètres par défaut",
        "headerInfo": "Toutes les modifications que vous apportez ici seront appliquées à toutes les demandes de signature en cours.",
        "hipaaConfirmationBodyFirst": "Tous les destinataires recevront une copie du document signé sous forme de pièce jointe à un e-mail lorsque ce paramètre sera appliqué. En tant que client HIPAA, il est de votre responsabilité de confirmer que les destinataires autorisent le traitement de leurs informations de cette manière.",
        "hipaaConfirmationBodySecond": "Êtes-vous sûr de vouloir appliquer ce paramètre ?",
        "hipaaConfirmationButton": "Appliquer le paramètre",
        "hipaaConfirmationHeader": "Considérations HIPAA",
        "reminders": {
          "options": {
            "daily": "Tous les jours",
            "noAuto": "Jamais",
            "weekly": "Toutes les semaines"
          },
          "reminderFrequencyLabel": "Envoyer des rappels automatiques",
          "reminderFrequencyTooltip": "Envoyer des rappels par e-mail aux destinataires chaque semaine (après 3, 7, 14, 21 et 28 jours) ou tous les jours (après 7 jours) jusqu'à ce qu'ils signent.",
          "sectionHeader": "Rappels"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Autoriser les signatures dessinées",
          "allowTypedSignatureLabel": "Autoriser les signatures dactylographiées",
          "applyBlueInkSignatureLabel": "Appliquer de l’encre bleue aux signatures",
          "applyBlueInkSignatureTooltipAdmin": "Contactez votre administrateur si vous devez modifier ce paramètre.",
          "applyBlueInkSignatureTooltipNonAdmin": "Utiliser de l'encre bleue pour toutes les signatures afin de distinguer les originaux signés des photocopies.",
          "sectionHeader": "Types de signature"
        },
        "signer": {
          "allowDownload": "Autoriser les signataires à télécharger le document original avant de le signer",
          "sectionHeader": "Autorisations des signataires"
        }
      },
      "defaultSettingsDescription": "Modifier vos paramètres pour les rappels, les pièces jointes aux e-mails et les types de signature.",
      "defaultSettingsEditLabel": "Modifier les paramètres par défaut",
      "defaultSettingsLabel": "Paramètres par défaut",
      "defaultSettingsUpdateFailed": "Impossible de mettre à jour les paramètres par défaut",
      "defaultSettingsUpdateSuccess": "Paramètres par défaut mis à jour",
      "defaultValueLabel": "Ajouter texte par défaut",
      "delete": "Supprimer",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Cela supprimera définitivement la demande de signature. Cette action ne peut pas être annulée.",
        "deleteConfirmationMessageSecond": "Les signataires et les lecteurs recevront un e-mail contenant le document et le certificat de signature dûment remplis à des fins d'archivage.",
        "deleteRequest": "Supprimer la demande",
        "deleteRequestSuccess": "Demande supprimée"
      },
      "deleteDraftDocumentLabel": "Supprimer demande de signature",
      "deleteDraftDocumentModalContent": "Êtes-vous sûr de vouloir supprimer la demande de signature ? Cela ne peut pas être annulé.",
      "deleteDraftDocumentSuccess": "Demande de signature supprimée",
      "deleteViewerWarningHeader": "Êtes-vous sûr de vouloir supprimer ce destinataire ?",
      "deleteViewerWarningMessage": "Ils n'auront plus accès à cette demande de signature.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Modifier le code d'accès",
        "kba": "Authentification basée sur les connaissances",
        "passcode": "Code d'accès",
        "title": "Option de sécurité appliquée",
        "updatePasscodeSuccess": "Le code d'accès de ce document a été mis à jour."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "Vous ne pouvez pas réviser les demandes envoyées de manière groupée",
          "signerSigned": "Vous ne pouvez pas réviser les demandes dès lors qu'un destinataire les a signé",
          "userNotSender": "Vous ne pouvez pas réviser les demandes que vous n'avez pas envoyées"
        }
      },
      "documentAlreadySentErrorDetails": "Impossible d'ouvrir le document envoyé pour le modifier. Démarrez un nouveau flux.",
      "documentAlreadySentErrorTitle": "Document déjà envoyé",
      "documentForSignature": "Document à envoyer",
      "documentForceCompleted": "Finalisation de la demande forcée",
      "documentLockedMessage": "Ce destinataire est temporairement verrouillé en raison de plusieurs tentatives d'authentification infructueuses",
      "documentNameBlank": "Entrez un nom de document",
      "documentNameInputDisabledTooltip": "Le nom du document ne peut pas être modifié",
      "documentNameInputLabel": "Nom du document",
      "documentStates": {
        "declined": "Refusé",
        "draft": "Ébauche",
        "editing": "Suspendu pour révisions",
        "executed": "Terminé",
        "expired": "Expiré",
        "pending": "En cours",
        "voided": "Annulé"
      },
      "documentThumbnailAltText": "Miniature de la première page",
      "documentTransactionCreateErrorMessage": "Impossible de créer la transaction de document",
      "documentTransactionGetErrorMessage": "Impossible d'obtenir la transaction de document",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Déverrouillage du document réussi",
      "documentView": {
        "documentPageIndex": "Page {<currentPage>} sur {{totalPages}}",
        "pageChangeInputLabel": "Changer page sur",
        "pageDown": "Page suivante",
        "pageUp": "Page précédente"
      },
      "documentVoided": "Demande de signature annulée",
      "dontShowAgain": "Ne plus afficher ce message",
      "downloadOptions": {
        "combinedPDF": "PDF combiné",
        "completedPDF": "PDF complété",
        "download": "Télécharger",
        "downloadErrorMessage": "Type de téléchargement non pris en charge : {{downloadType}}",
        "downloadOriginal": "PDF original",
        "signatureCertificate": "Certificat de signature"
      },
      "draftSaveErrorMessage": "Impossible d'enregistrer la demande de signature",
      "draftSaveSuccessMessage": "Demande de signature enregistrée",
      "draw": "Dessiner",
      "dueDate": "Date d’échéance",
      "dueDateUpdated": "Date d'échéance mise à jour",
      "edit": "Modifier",
      "editDocumentModal": {
        "cancel": "Retour",
        "confirm": "Réviser",
        "contentLine1": "Si vous devez ajouter ou supprimer des destinataires ou des champs, ou modifier vos options de sécurité, vous pouvez réviser cette demande.",
        "contentLine2": "Nous suspendrons la demande pendant que vous apportez des modifications. Cela signifie que tous ceux qui n’ont pas encore signé perdront temporairement l’accès. Nous leur enverrons un nouveau lien lorsque vous aurez terminé.",
        "contentLine3": "Une fois que vous aurez commencé à réviser, vous ne pourrez plus réactiver la demande sans envoyer un document révisé.",
        "title": "Êtes-vous sûr de vouloir réviser cette demande ?"
      },
      "editDueDate": "Modifier la date d'échéance",
      "editLeftMidwayModal": {
        "cancel": "Retour",
        "confirm": "Terminer plus tard",
        "contentLine1": "Les signataires ne peuvent pas accéder à cette demande tant qu'elle est suspendue pour révision. Si vous avez terminé, revenez en arrière et choisissez ",
        "contentLine2": "Si vous n'êtes pas prêt, vous pouvez revenir et terminer vos révisions plus tard.",
        "title": "Êtes-vous toujours en train de réviser ?"
      },
      "editRecipients": "Modifier destinataires",
      "editSignatureRequest": "Réviser",
      "editSigner": "Modifier signataire",
      "editViewer": "Modifier observateur",
      "emptyFolder": "Ce dossier est vide",
      "enforceStorageUsage": "Impossible de créer de nouvelles demandes de signature tant que vous n'avez pas libéré de l'espace",
      "expirationDateMessage": "Date d'expiration : {{expirationDate}}",
      "expirationDaysLabel": "jour(s)",
      "expirationInputDescription": "Le document ne pourra plus être signé après le nombre de jours spécifié :",
      "expirationInputLabel": "Expiration",
      "extendDueDate": "Prolonger la date d'échéance",
      "extendExpirationDate": "Prolonger",
      "favorites": "Favoris",
      "field": "champ",
      "fieldNameLabel": "Champ de nom",
      "fieldRequired": "Ce champ est obligatoire",
      "fields": "Champs",
      "fieldsAssignedWarningHeader": "Champs attribués aux signataires",
      "fieldsAssignedWarningMessage": "Tous les champs attribués aux signataires seront supprimés.",
      "fieldsDescription": "Choisissez un type de champ à placer dans votre document",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Modifier",
        "sectionLabel": "Où souhaitez-vous stocker le document signé ?"
      },
      "fileNotSupportedTooltip": "Format de fichier non pris en charge",
      "filesAndRecipients": "Fichiers et destinataires",
      "filterSelected": "1 filtre",
      "filterSelected_plural": "{{count}} filtres",
      "forceComplete": "Forcer exécution",
      "forceCompleteTitle": "Forcer demande d'exécution",
      "forceCompleteWarningFirst": "Cela supprimera tous les champs assignés aux signataires qui n'ont pas encore signé et terminera l'exécution du document (état final). Cette action ne peut pas être annulée.",
      "forceCompleteWarningSecond": "Le nouvel état sera reflété sur le tableau de bord dans quelques instants.",
      "generateNewPasscodeButtonLabel": "Générer nouveau code d'accès",
      "genericError": "Une erreur s’est produite",
      "groupRule": "Règle du groupe",
      "hasAtleastOneSignerErrorMessage": "Ajoutez au moins un signataire pour continuer",
      "importFields": {
        "linkText": "Importer des champs",
        "modal": {
          "assignImportedFields": "Attribuer des champs importés",
          "body": {
            "buttonText": "Sélectionner",
            "searchPlaceholder": "Rechercher par nom de document, nom de fichier, expéditeur ou rôle",
            "sentBy": "Envoyé par"
          },
          "componentsImported": "{{count}} champs importés",
          "fields": "champ(s)",
          "importFieldsButtonText": "Importer des champs",
          "importFrom": "Importer à partir de :",
          "mapAllRecipients": "Attribuer des champs à de nouveaux destinataires pour continuer",
          "newRecipient": "Nouveau destinataire",
          "noDocuments": "Vous n'avez pas encore de documents",
          "originalRecipient": "Destinataire initial",
          "selectDifferentDocument": "Sélectionner un autre document",
          "selectRecipient": "Sélectionner un destinataire",
          "sentBy": "Envoyé par",
          "title": "Sélectionner un document pour importer des champs",
          "titleImportFields": "Importer des champs",
          "titleImportFieldsWithCount": "Importer {{count}} champs"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Retour",
          "contentLine1": "{<documentName>} ne contient aucun champ que nous pouvons importer. Vous pouvez choisir un autre document ou créer de nouveaux champs.",
          "title": "Ce document ne comporte aucun champ"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Retour",
          "confirmButtonText": "Importer les champs malgré tout",
          "contentLine1": "Le document que vous avez sélectionné comporte un nombre de pages différent de celui sur lequel vous travaillez actuellement.",
          "contentLine2": "Vous pouvez toujours importer des champs, mais nous examinerons uniquement la ou les premières {{pageCount}} page(s) du document sélectionné. Les champs des pages suivantes ne seront pas importés.",
          "title": "Ces documents sont un peu différents"
        },
        "tooltipText": "Importer des champs à partir de documents précédents"
      },
      "initials": "Initiales",
      "invalidEmail": "E-mail non valide",
      "invalidExpirationDaysError": "Entrez un nombre compris entre 1 et 365",
      "invalidExpirationDaysNotificationMessage": "Choisissez une date d'expiration",
      "invalidRecipientErrorMessage": "Nous avons besoin d'un nom et d'une adresse e-mail pour chaque destinataire",
      "invalidSignatureRequest": "La demande de signature ne semble pas valide. Vérifiez l'URL.",
      "invite": "Inviter",
      "inviteViewers": "Inviter des observateurs",
      "kbaDescription": "Demandez aux destinataires de vérifier leur identité à l'aide d'un questionnaire basé sur les connaissances. Chaque signataire devra procéder à une vérification indépendante avant de signer le document.",
      "kbaInfoMessage": "Les signataires seront verrouillés du document après 3 tentatives infructueuses.",
      "kbaLabel": "Authentification basée sur les connaissances",
      "legacyTokenInterceptor": {
        "buttonText": "Connexion",
        "message": "Nous vous ramènerons ici pour que vous puissiez reprendre là où vous vous étiez arrêté.",
        "title": "Reconnectez-vous pour accéder à vos demandes de signature"
      },
      "messageLabel": "Message aux destinataires",
      "mixedAlignment": "Alignement mixte",
      "mixedDateFormat": "Format mixte",
      "mixedFontSizes": "Tailles de police mixtes",
      "moveAwaySelections": "Si vous naviguez en dehors du dossier actuel, vos sélections seront perdues.",
      "multiLineLabel": "Autoriser plusieurs lignes",
      "multipleRecipients": "Destinataires multiples",
      "nameGroup": "Nom du groupe",
      "nextStep": "Étape suivante",
      "optional": "Facultatif",
      "optionalComment": "Commentaire d'accompagnement facultatif",
      "optionsLabel": "Options",
      "packagerConfirmationModal": {
        "contentLine1": "Nous devons regrouper ces documents dans un seul fichier avant de pouvoir les préparer à la signature. Vous ne pourrez plus revenir en arrière et en supprimer une fois que vous passerez à l'étape suivante.",
        "contentLine2": "Êtes-vous prêt à continuer ?",
        "mergeModalText": "Cela peut prendre une minute. Vous serez dirigé vers l'étape suivante dès que votre document sera prêt.",
        "progressBarText": "Fusion...",
        "title": "Fusionner documents"
      },
      "passcodeCharacterWarning": "Le code d'accès ne peut contenir que des chiffres.",
      "passcodeDescription": "Les destinataires doivent entrer un code d'accès à {<fiveDigit>} pour accéder aux documents.",
      "passcodeFiveDigit": "5 chiffres",
      "passcodeInfoMessage": "Vous devrez partager ce code d'accès avec chaque destinataire.",
      "passcodeLabel": "Code d'accès",
      "passcodeLengthError": "Choisissez un code d'accès ou désactivez cette option de sécurité",
      "passcodeLengthErrorEditMode": "Le code d'accès doit comporter 5 chiffres",
      "pending": "En attente",
      "personalFolders": "Dossiers personnels",
      "placeFields": "Placer les champs",
      "prepareDocument": {
        "components": {
          "attachment": "Pièce jointe",
          "checkmarkAnnotation": "Ajouter une coche",
          "editor": {
            "chargeAfterExecutedTooltip": "Utilisez cette option si vous souhaitez débiter la carte de tous les signataires une fois qu'ils ont signé",
            "collectCreditCardTooltip": "Utilisez cette option si vous souhaitez simplement collecter les informations de la carte.",
            "defaultHelpText": {
              "attachmentComponent": "Chargez votre pièce jointe ici",
              "paymentComponent": "Ajoutez les détails de votre carte ici"
            },
            "fieldSubtypeLabel": "Sous-type du champ",
            "fontSizeLabel": "Taille de police",
            "helpTextLabel": "Ajouter texte d'aide",
            "paymentAmountLabel": "Montant du paiement (USD)",
            "paymentAmountPlaceHolder": "Entrez le montant du paiement"
          },
          "textAnnotation": "Ajouter du texte"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Chaque liste déroulante doit comporter au moins 2 options",
          "paymentAmountInvalidError": "Pour continuer, saisissez un montant de paiement."
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Ces signataires n'ont rien à signer.",
          "cancelButtonText": "Retour",
          "confirmButtonText": "Continuer quand même",
          "description": "Vous pouvez passer à l’étape suivante, ou vous pouvez revenir en arrière et ajouter des champs à remplir.",
          "subtitle": "Ces signataires n'ont rien à signer :",
          "title": "Avez-vous oublié des champs ?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "ID du document",
          "dueDate": "Date d'échéance",
          "otherDetails": "Autres détails",
          "recipients": "Destinataires",
          "sender": "Expéditeur",
          "sentOn": "Envoyé le",
          "signerSequencing": "Séquençage des signataires appliqué",
          "signers": "Signataires"
        },
        "documentHeader": {
          "status": "État :",
          "viewDetails": "Afficher les détails"
        },
        "downloadModal": {
          "combinedDescription": "Inclut le certificat de signature et le document complété",
          "combinedTitle": "PDF combiné",
          "completedDocumentDescription": "Document signé avec champs complétés",
          "completedDocumentTitle": "Document complété",
          "done": "Terminé",
          "originalDescription": "Document PDF sans aucun champ de signataire",
          "originalTitle": "PDF original",
          "signatureCertificateDescription": "Certificat de confiance de Sharefile",
          "signatureCertificateTitle": "Certificat de signature",
          "title": "Télécharger document"
        },
        "pageHeader": {
          "completed": "Terminé",
          "download": "Télécharger",
          "downloadOriginalDocument": "Télécharger le document original",
          "inProgress": "En cours"
        }
      },
      "previousStep": "Étape précédente",
      "processingState": "Traitement...",
      "radio": "Groupe de cases d’option",
      "readyState": "Prêt",
      "reasonDeclineLabel": "Raison du refus",
      "reasonVoidLabel": "Raison d'annulation",
      "recipientDuplicate": "Cette personne est déjà un observateur",
      "recipientEmail": "E-mail",
      "recipientListHeading": "Qui sont les destinataires ?",
      "recipientName": "Nom",
      "recipientNoName": "Ce champ est obligatoire",
      "recipientRemindButtonLabel": "Rappel",
      "recipientRole": "Rôle",
      "recipientRoleSigner": "Signataire",
      "recipientRoleViewer": "Lecteur",
      "recipientStatusSigned": "Signé",
      "recipientUnlockLabel": "Déverrouiller",
      "recipientsLabel": "Destinataires",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "ShareFile vous permettra bientôt de charger un fichier CSV de destinataires afin que vous puissiez demander la signature d'un maximum de 300 personnes à la fois. En attendant, vous pouvez toujours le faire dans RightSignature.",
          "closeButton": "Annuler",
          "header": "Vous cherchez à envoyer en bloc ?",
          "redirectButton": "Aller à RightSignature"
        },
        "packager": {
          "bodyLineOne": "ShareFile vous permettra bientôt d'envoyer plusieurs documents en une seule demande de signature. En attendant, vous pouvez toujours le faire dans RightSignature.",
          "closeButton": "Annuler",
          "header": "Besoin d'envoyer plusieurs documents ?",
          "redirectButton": "Aller à RightSignature"
        },
        "template": {
          "bodyLineOne": "Vous pourrez bientôt créer et utiliser des modèles de signature dans ShareFile.",
          "bodyLineTwo": "Préparez-vous en les essayant dans RightSignature.",
          "closeButton": "Pas maintenant",
          "header": "Simplifier vos processus avec des modèles",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Essayer des modèles"
        }
      },
      "referenceIdLabel": "ID de référence",
      "reminderSent": "Rappel envoyé",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Supprimer des signataires",
        "content": "Tous les signataires importés à partir de {<fileName>} seront supprimés.",
        "title": "Êtes-vous sûr de vouloir supprimer ces signataires ?"
      },
      "removeCheckboxGroup": "Supprimer le groupe de cases à cocher",
      "removeFieldLabel": "Supprimer champ",
      "removeFileDisabledTooltip": "Le document ne peut pas être supprimé",
      "removeFileFailed": "Impossible de supprimer le fichier. Réessayez.",
      "requestSignatureButton": {
        "label": "Créer une demande de signature"
      },
      "requireGroup": "Exiger un groupe",
      "required": "Requis",
      "requiredFieldLabel": "Champ obligatoire",
      "requiredFieldsLabel": "Champs obligatoires",
      "reviewAndSend": "Vérifier et envoyer",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Ce champ ne peut pas être vide"
          }
        }
      },
      "save": "Enregistrer",
      "saveCompletedDocument": "Enregistrer le document terminé",
      "savedSignaturesLabel": "Sélectionner la signature à appliquer dans le document",
      "search": "Rechercher",
      "securityOptionsLabel": "Options de sécurité",
      "selectOneOrMoreLabel": "Sélectionner un ou plusieurs éléments",
      "selectOnlyOneLabel": "N'en sélectionnez qu'une",
      "selfSignCheckDisabledToolTip": "L'auto-signature n'est pas disponible lors de la sélection d'un modèle",
      "selfSignCheckLabel": "Je suis le seul signataire",
      "selfSignCheckToolTip": "Signez vous-même le document, puis partagez-le. Il sera enregistré dans le dossier sélectionné.",
      "selfSignSucessMessage": "Document enregistré",
      "selfSignature": "Votre signature",
      "sendForSignature": "Envoyer pour signature",
      "sendInBulk": {
        "allSigners": "L'intégralité des {<signersCount>} signataires",
        "bulkSigners": "Signataires groupés",
        "couldTakeAMoment": "Cela peut prendre un moment.",
        "disableTooltipTextForPLG": "Mettez à niveau votre abonnement pour avoir accès",
        "downloadCSVTemplateButton": "Télécharger le modèle CSV",
        "email": "E-mail",
        "errors": {
          "correctTheFollowingError": "Corrigez les erreurs suivantes dans votre fichier et réessayez.",
          "exceedsRecipientsLimit": "Le fichier {<fileName>} dépasse la limite de 300 signataires.",
          "fileSizeExceeded": "Le fichier dépasse la limite de 5 Mo.",
          "onlyOneFile": "Vous ne pouvez importer des signataires qu'à partir d'un seul fichier.",
          "supportedFileType": "Vous ne pouvez importer des signataires qu'à partir d'un fichier .csv.",
          "unableToLoadSpreadsheet": "Il manque des informations importantes dans votre fichier."
        },
        "gatheringSignersFromFile": "Collecte de signataires à partir de votre fichier...",
        "goBack": "Précédent",
        "import": "Importer",
        "importSigners": "Importer des signataires",
        "importSignersModalText1": "Nous vous avons préparé un modèle CSV. Téléchargez-le et remplissez les champs Nom et Adresse e-mail (obligatoires).",
        "importSignersModalText2": "Le format CSV est le seul format de fichier pris en charge. Le nombre de signataires ne peut excéder 300.",
        "name": "Nom",
        "note": "Remarque : ",
        "previewingTheSigners": "Aperçu des {<firstSignersCount>} premiers signataires sur {<signersCount>}",
        "selectDifferentFile": "Sélectionner autre fichier",
        "title": "Envoi groupé",
        "tooltipText": "L'envoi groupé ne peut être utilisé qu'avec des modèles à signataire unique.",
        "uploadFileText": "Faites glisser le fichier CSV ici ou"
      },
      "sendRevisedRequest": "Envoyer une demande révisée",
      "sendSignatureRequest": "Envoyer demande de signature",
      "sendSigningLink": {
        "gotIt": "OK",
        "sentLinkInfoBanner": "Nous vous avons envoyé un lien",
        "signingLinkInfoMessage1": "Consultez votre e-mail {<email>} pour accéder à un lien permettant de signer ce document.",
        "signingLinkInfoMessage2": "Vous pourrez bientôt signer directement dans ShareFile."
      },
      "senderRoleChangeTooltip": "L'expéditeur ne peut pas être un lecteur",
      "sentByLabel": "Envoyé par",
      "setSigningOrder": "Définir ordre de signature",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Annuler",
          "createFolderLabel": "Créer un dossier",
          "disabledNodeUploadReason": "L'autorisation de chargement est nécessaire pour sélectionner ce dossier",
          "disabledSharedFoldersReason": "Impossible de sélectionner des dossiers partagés de niveau supérieur",
          "modalTitle": "Enregistrer le contrat signé dans...",
          "selectFolderButtonLabel": "Sélectionner"
        }
      },
      "sharedFolders": "Dossiers partagés",
      "showAll": "Tout afficher",
      "signButtonLabel": "Signer",
      "signYourself": "Signer vous-même",
      "signature": "Signature",
      "signatureAnnotationMessage": "Cliquez et faites glisser pour dessiner votre signature dans la boîte",
      "signatureAnnotationTextMessage": "Tapez votre signature dans le champ ci-dessous",
      "signatureRequest": "Demande de signature",
      "signatureRequestErrorMessage": "La demande de signature a échoué",
      "signatureRequestSuccessMessage": "Demande de signature envoyée",
      "signer": "Signataire",
      "signerDescription": {
        "annotation": "Placez vous-même des champs et remplissez-les à l'avance.",
        "bulkSigners": "Vous devez placer des champs pour chaque signataire.",
        "signers": "Vous placerez des champs pour chaque signataire"
      },
      "signerMessageInputLabel": "Note à tous les signataires (facultatif)",
      "signerMessageInputLabelTooltip": "Nous inclurons cette note dans la notification par e-mail envoyée aux signataires",
      "signerMessageInputPlaceholder": "Ajouter une note personnalisée à tous les signataires",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Nom et ID du document",
            "dueDateTitle": "Date d’échéance",
            "senderInfoTitle": "Expéditeur",
            "senderMessageTitle": "Message de l'expéditeur"
          },
          "fields": {
            "addInitials": "Ajouter des initiales",
            "attachment": {
              "buttonText": "Joindre fichier",
              "fileUploadingError": "Impossible de télécharger le fichier. Réessayez",
              "modalButtonContinue": "Terminé",
              "modalDeleteFileCancelButton": "Annuler",
              "modalDeleteFileOkButton": "Supprimer",
              "modalDeleteFileText": "Êtes-vous sûr de vouloir supprimer {<fileName>}",
              "modalDeleteFileTitle": "Supprimer fichier",
              "modalTitle": "Charger fichier",
              "modalUploadAnotherFileText": " et charger un nouveau fichier ?",
              "uploadDifferentFileButton": "Charger autre fichier",
              "uploadHint": "Un seul fichier",
              "uploadText": "Cliquez ou faites glisser le fichier vers cette zone pour le charger",
              "uploadingStatus": "Chargement en cours"
            },
            "autofilled": "Rempli automatiquement",
            "dropdown": {
              "clearSelection": "Effacer la sélection",
              "selectOption": "Sélectionner une option"
            },
            "enterText": "Entrer du texte",
            "format": "Format",
            "optional": "Facultatif",
            "paymentField": {
              "amount": "Montant",
              "authorizePayment": "Autoriser le paiement",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Modifier",
              "clear": "Effacer",
              "creditCardModalDetails": {
                "authorizeMessage": "J'accepte de payer la somme de {<paymentAmount>} après avoir soumis ce document",
                "cvcTooltipMessage": "Code de sécurité à trois ou quatre chiffres imprimé au dos (ou au recto pour AMEX) de votre carte de crédit",
                "labels": {
                  "cardNumber": "Numéro de carte",
                  "cvc": "CVC",
                  "expiryDate": "Date d'expiration"
                },
                "modalTitle": "Modification des détails de la carte",
                "placeholders": {
                  "cardNumber": "Saisissez un numéro de carte",
                  "cvc": "Saisissez le code CVC"
                }
              },
              "creditDetails": "Saisir les détails de la carte"
            },
            "required": "Requis",
            "selectDate": "Sélectionner une date",
            "signature": {
              "clearSignatureButtonText": "Effacer la signature",
              "editSignatureButtonText": "Modifier la signature",
              "openSignatureModalText": "Signez ici",
              "prefilledSignatureText": "Pour vous faciliter la tâche, nous avons pré-rempli le champ avec votre nom. Vous pouvez le modifier si nécessaire."
            }
          },
          "navigation": {
            "next": "Suivant",
            "nextField": "Champ suivant",
            "startSigning": "Commencer à signer"
          },
          "progressBar": {
            "shortSuccessText": "Vous avez rempli tous les champs obligatoires",
            "status": "{{fieldsFilled}} champs obligatoires sur {{totalFields}}",
            "submitAction": "Soumettre",
            "successText": "Vous avez rempli tous les champs obligatoires. Une fois la vérification terminée, vous pouvez {<submitAction>} le document."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Suiviez votre progression"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "Vous n'avez aucun champ à remplir",
              "optionalCount": "{{count}} champ facultatif",
              "optionalCount_plural": "{{count}} champs facultatifs",
              "optionalOnly": "Vous avez {<optionalCountLabel>} à remplir",
              "pending": "En attente",
              "requiredAndOptional": "Vous avez {<requiredCountLabel>} et {<optionalCountLabel>} à remplir",
              "requiredCount": "{{count}} champ obligatoire",
              "requiredCount_plural": "{{count}} champ obligatoires",
              "requiredOnly": "Vous avez {<requiredCountLabel>} à remplir"
            },
            "label": {
              "details": "Détails",
              "fields": "Champs"
            }
          }
        },
        "declineDocument": {
          "header": "Document refusé",
          "message": "Vous avez refusé ce document. Si vous souhaitez toujours le signer, demandez à l'expéditeur d'envoyer un nouveau document",
          "sender": "Expéditeur"
        },
        "header": {
          "documentActions": {
            "actions": "Actions",
            "actionsAndDetails": "Détails et actions",
            "declineDocument": "Refuser document",
            "declineFailed": "Nous n'avons pas pu refuser le document. Réessayez",
            "downloadOriginal": "Télécharger le PDF d'origine",
            "saveFailed": "Nous n'avons pas pu enregistrer votre progression. Réessayez",
            "saveProgress": "Enregistrer la progression",
            "saveSuccess": "Progression enregistrée"
          },
          "moreActions": "Plus d'actions"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Échec de l'authentification",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Annuler",
            "decline": "Refuser",
            "reason": "Motif",
            "reasonHelp": "Ce motif sera communiqué à l'expéditeur",
            "reasonPlaceholder": "Quel est le motif de votre refus ?",
            "title": "Refuser document",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} tentative restante",
            "attemptsAmountPlural": "{{attemptsAmount}} tentatives restantes",
            "contentMessage": "Saisissez le code d'accès à 5 chiffres fourni par l'expéditeur pour accéder à ce document.",
            "inputLabel": "Entrer le code d'accès",
            "inputPlaceholder": "Entrez le code d'accès à 5 chiffres",
            "inputValidationMessage": "Le code d'accès doit comporter 5 chiffres",
            "inputValueRequiedMessage": "Ce champ est obligatoire",
            "invalidPasscodeMessage": "Code d'accès non valide.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) vous a invité à signer {<documentName>}",
            "unlockButton": "Déverrouiller",
            "welcomeMessage": "Bienvenue !"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "Nous avons envoyé une copie du document rempli à {<email>}",
            "downloadButton": "Télécharger document",
            "notAllSignedText": "Nous enverrons une copie du document à {<email>} une fois que tous les signataires l'auront signé",
            "reviewButton": "Vérifier le document signé",
            "title": "Document signé"
          },
          "termsAndConditions": {
            "continueButton": "Continuer",
            "esignConsent": "Consentement de signature électronique",
            "footerEsignConsent": "Vous avez pris connaissance de notre {<link>} et vous l'acceptez",
            "footerTerms": "Vous avez pris connaissance de notre {<linkTermsOfUse>} et de notre {<linkPrivacyPolicy>} et vous les acceptez",
            "footerTitle": "En continuant, vous confirmez que :",
            "greetings": "Bonjour {{signerName}},",
            "privacyPolicy": "Déclaration de confidentialité",
            "senderMessageTitle": "Message de l'expéditeur",
            "termsOfUse": "Conditions d'utilisation",
            "text": "{{senderName}} ({{senderEmail}}) vous a invité à signer {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Envoyer le document",
          "submit": "Soumettre",
          "submitConfirm": "Êtes-vous sûr d'être prêt à envoyer ce document ?",
          "submitWarning": "Après l'avoir envoyé, vous ne pourrez plus le modifier",
          "submitWithUnfilledOptionalFields": "Vous avez ignoré un champ facultatif. Êtes-vous sûr d'être prêt à envoyer ce document ?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Remplissez tous les champs obligatoires pour envoyer le document"
        }
      },
      "signerSequencingDisabledTooltip": "L'ordre de signature n'est applicable que lorsqu'il y a plus d'un signataire",
      "signerSequencingNotApplicableTooltip": "L'ordre de signature ne s'applique pas aux lecteurs",
      "signers": "Signataires",
      "signersLabel": "Signataires",
      "singerSequencingAppliedText": "La séquence de signature a été appliquée",
      "sizeLimitFile": "Le fichier {{filename}} ",
      "sizeLimitMessage": "dépasse notre limite de 20 Mo",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Supprimer le modèle",
              "content": "Vous allez perdre la liste des signataires groupés et devrez télécharger un nouveau document pour continuer.",
              "header": "Êtes-vous sûr de vouloir supprimer ce modèle ?"
            },
            "modal": {
              "confirmButton": "Supprimer le document",
              "content": "La suppression d'un document entraîne la suppression de tous les destinataires prédéfinis et des champs qui leur ont été assignés. Êtes-vous sûr de vouloir supprimer le document ?",
              "header": "Supprimer le document"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Sélectionner",
              "createdBy": "Créé par :",
              "searchPlaceholder": "Search"
            },
            "eligible": "Éligible à l'envoi groupé",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Impossible de charger les modèles. Réessayez ultérieurement",
            "footer": {
              "buttonText": "Créer un modèle",
              "question": "Vous ne trouvez pas ce que vous cherchez ?"
            },
            "noDocuments": "Vous ne disposez pas encore de modèle",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Aucun résultat",
            "noSearchResultsDesc": "Votre recherche n'a renvoyé aucun résultat.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Rôles :",
            "title": "Sélectionner un modèle"
          },
          "sourceName": "Utiliser un modèle"
        },
        "upload": {
          "basefileProcessingFailed": "Impossible de traiter le fichier {{ filename }}. Veuillez charger à nouveau le fichier",
          "failed": "Impossible de charger le fichier {{ filename }}",
          "numberLimitMessage": "Impossible de charger plus de 5 fichiers"
        }
      },
      "statusFilter": "État",
      "switch": "Passer à l'ancienne expérience",
      "template": "Modèle",
      "temporaryRedirectText": "Passer temporairement à RightSignature",
      "text": "Texte",
      "textAlignLabel": "Aligner le texte",
      "type": "Type",
      "unableToFetchFileDetails": "Impossible de récupérer les détails du fichier",
      "unableToProceedNoDocumentError": "Impossible de continuer. Ajoutez un document.",
      "unableToProceedNoSignerError": "Impossible de continuer. Ajoutez au moins un signataire.",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Ce type de document n'est pas pris en charge. Les types de fichiers pris en charge sont .doc, .docx, .txt, .pdf",
      "updateDocumentErrorMessage": "Impossible de mettre à jour le document.",
      "uploadDocumentErrorMessage": "Chargez un document pour passer à l'étape suivante",
      "uploadedFilesProcessingErrorMessage": "Un ou plusieurs fichiers chargés sont toujours en cours de traitement",
      "useSaved": "Utilisation enregistrée",
      "userFetchErrorMessage": "Impossible de récupérer les informations utilisateur",
      "view": "Afficher",
      "viewer": "Lecteur",
      "viewerUniqueEmailErrorMessage": "L'adresse e-mail de l'observateur doit être unique",
      "viewersLabel": "Lecteurs",
      "void": "Annuler",
      "voidConfirmationTitle": "Confirmation de l'annulation du document",
      "zoomIn": "Zoom avant",
      "zoomOut": "Zoom arrière"
    }
  },
  {
    "key": "it",
    "content": {
      "AccountLimitReached": "L'account ha raggiunto il limite massimo di documenti.",
      "AttachedByLabel": "Allegato da {{signer}}",
      "AttachmentDetailsLabel": "Allegati",
      "AttachmentFieldLabel": "Allegato",
      "CheckFieldLabel": "Casella di controllo",
      "CheckGroupFieldLabel": "Gruppo di caselle di controllo",
      "DateFieldLabel": "Data",
      "InitialsFieldLabel": "Iniziali",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Addebita una volta firmato",
        "chargedLabel": "Addebito {<paymentAmount>}",
        "collectCardDetailsLabel": "Raccogli i dati della carta",
        "defaultLabel": "Pagamento",
        "paymentFailedLabel": "Pagamento non andato a buon fine"
      },
      "PaymentFieldLabel": "Campo di pagamento",
      "SelectFieldLabel": "Elenco a discesa",
      "SignatureFieldLabel": "Firma",
      "TextFieldLabel": "Testo",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Il piano attuale limita il numero di documenti che possono essere inviati per la firma e hai raggiunto il limite. Puoi comunque firmare tu i documenti.",
        "upgradeTrialBanner": "Restano {<documentsLeft>} di prova."
      },
      "accountLimitExhaustedMessage": "Impossibile inviare il documento per la firma, l'account ha esaurito il limite. Aggiornare l'account.",
      "accountStatsFetchErrorMessage": "Impossibile recuperare le informazioni sull'account",
      "add": "Aggiungi",
      "addCheckbox": "Aggiungi casella di controllo",
      "addFilesFromSharefile": "Aggiungi elementi da ShareFile",
      "addMyself": "Aggiungi me stesso",
      "addOptionLabel": "Aggiungi opzione",
      "addRecipient": "Aggiungi un altro destinatario",
      "addSignature": "Aggiungi firma",
      "annotator": "Tu (ora)",
      "annotatorOptionTooltip": "Compila i campi in anticipo",
      "apply": "Applica",
      "archiveLocationBlank": "Selezionare una cartella in cui archiviare il documento firmato",
      "assignedToLabel": "Assegnato a",
      "attachmentFieldLabel": "Allegato",
      "browseFiles": "Sfoglia file",
      "bulkSend": "Invio in blocco",
      "cancel": "Annulla",
      "changesSaved": "Modifiche salvate",
      "checkbox": "Casella di controllo",
      "checkboxGroup": "Gruppo di caselle di controllo",
      "checkboxes": "Caselle di controllo",
      "clear": "Cancella",
      "clearBackground": "Clear background",
      "close": "Chiudi",
      "completed": "Completato",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} campi selezionati",
      "confirm": "Conferma",
      "continue": "Continua",
      "createDocument": {
        "almostDone": "Quasi fatto. Non fare clic sul pulsante Aggiorna o Indietro"
      },
      "createDocumentErrorMessage": "Creazione documento non riuscita",
      "creationDateLabel": "Inviato il",
      "dashboard": {
        "adminView": "Visualizzazione amministratore",
        "adminViewTooltipText": "Visualizzare tutti i documenti inviati da questo account",
        "autoDeleteText": "Eliminazione automatica fra {{remaining}} giorni",
        "clearFilters": "Cancella filtri",
        "clearSearch": "Cancella ricerca",
        "continueAction": "Continua",
        "deleteAction": "Elimina",
        "downloadPdf": "Scarica",
        "esignLeftNav": "Firme",
        "loadMore": "Carica altro",
        "overdue": "1 giorno di ritardo",
        "overdue_plural": "{{count}} giorni di ritardo",
        "signatureDue": "Scadenza {{dueDate}}",
        "signatureRequests": "Richieste di firma",
        "signatureSettings": "Impostazioni della firma",
        "signaturesSubmitted": "Firme inviate",
        "templates": "Modelli",
        "title": "Richieste di firma"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Non sembra che ci siano corrispondenze per i filtri impostati.",
          "title": "Qui non c'è niente"
        },
        "noRequestsCreated": {
          "description": "Si necessita di una firma? Creare una richiesta.",
          "title": "Non sono ancora presenti richieste di firma"
        },
        "noSearchResults": {
          "description": "Non sembra che ci siano corrispondenze per la ricerca.",
          "title": "Qui non c'è niente"
        }
      },
      "date": "Data",
      "dateExample": "Esempio:",
      "dateExtendError": "Scegliere una data futura",
      "dateFormatLabel": "Formato",
      "dateSignedLabel": "Compilazione automatica data di firma",
      "dateSignedTooltip": "Un destinatario non sarà in grado di modificare il campo. Sarà compilato automaticamente il giorno in cui il documento viene firmato.",
      "declinedByLabel": "Rifiutata da {{name}}",
      "defaultBadgeLabel": "Predefinito",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Invia a tutti un'e-mail con il PDF completato",
          "attachCompletedPDFCheckboxTooltip": "Dopo l'ultima firma, invieremo a tutti un'e-mail con il PDF completato in allegato (a meno che non sia più grande di 10 MB).",
          "sectionHeader": "Allegati"
        },
        "header": "Modifica le impostazioni predefinite",
        "headerInfo": "Qualsiasi modifica apportata qui verrà applicata a tutte le richieste di firma in corso.",
        "hipaaConfirmationBodyFirst": "Tutti i destinatari riceveranno una copia del documento firmato come allegato e-mail quando viene applicata questa impostazione. In qualità di cliente HIPAA, è tua responsabilità confermare che i destinatari consentano questa modalità di gestione delle loro informazioni.",
        "hipaaConfirmationBodySecond": "Applicare questa impostazione?",
        "hipaaConfirmationButton": "Applica impostazione",
        "hipaaConfirmationHeader": "Considerazioni HIPAA",
        "reminders": {
          "options": {
            "daily": "Ogni giorno",
            "noAuto": "Mai",
            "weekly": "Ogni settimana"
          },
          "reminderFrequencyLabel": "Invia promemoria automatici",
          "reminderFrequencyTooltip": "Invia promemoria via e-mail ai destinatari settimanalmente (dopo 3, 7, 14, 21 e 28 giorni) o giornalmente (dopo 7 giorni) fino alla firma.",
          "sectionHeader": "Promemoria"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Consenti firme disegnate",
          "allowTypedSignatureLabel": "Consenti firme digitate",
          "applyBlueInkSignatureLabel": "Applica inchiostro blu alle firme",
          "applyBlueInkSignatureTooltipAdmin": "Contattare l'amministratore se è necessario modificare questa impostazione.",
          "applyBlueInkSignatureTooltipNonAdmin": "Utilizzare l'inchiostro blu per tutte le firme per aiutare a distinguere gli originali firmati dalle fotocopie.",
          "sectionHeader": "Tipi di firma"
        },
        "signer": {
          "allowDownload": "Consenti ai firmatari di scaricare il documento originale prima di firmare",
          "sectionHeader": "Autorizzazioni dei firmatari"
        }
      },
      "defaultSettingsDescription": "Modifica le impostazioni per i promemoria, gli allegati e-mail e i tipi di firma.",
      "defaultSettingsEditLabel": "Modifica le impostazioni predefinite",
      "defaultSettingsLabel": "Impostazioni predefinite",
      "defaultSettingsUpdateFailed": "Impossibile aggiornare le impostazioni predefinite",
      "defaultSettingsUpdateSuccess": "Impostazioni predefinite aggiornate",
      "defaultValueLabel": "Aggiungere testo predefinito",
      "delete": "Elimina",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "In questo modo la richiesta di firma verrà eliminata definitivamente. Questa operazione è irreversibile.",
        "deleteConfirmationMessageSecond": "I firmatari e i partecipanti riceveranno un'e-mail con il documento completato e il certificato della firma, in modo da poterli conservare.",
        "deleteRequest": "Elimina richiesta",
        "deleteRequestSuccess": "Richiesta eliminata"
      },
      "deleteDraftDocumentLabel": "Elimina richiesta di firma",
      "deleteDraftDocumentModalContent": "Eliminare la richiesta di firma? Questa operazione non può essere annullata.",
      "deleteDraftDocumentSuccess": "Richiesta di firma eliminata",
      "deleteViewerWarningHeader": "Rimuovere questo destinatario?",
      "deleteViewerWarningMessage": "Non avrà più accesso a questa richiesta di firma.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Modifica passcode",
        "kba": "Autenticazione basata sulla conoscenza",
        "passcode": "Passcode",
        "title": "Opzione di sicurezza applicata",
        "updatePasscodeSuccess": "Il passcode di questo documento è stato aggiornato."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "Non è possibile modificare le richieste inviate con l'invio in blocco",
          "signerSigned": "Non è possibile modificare le richieste dopo che qualcuno ha firmato",
          "userNotSender": "Non è possibile modificare le richieste che non sono state inviate"
        }
      },
      "documentAlreadySentErrorDetails": "Impossibile aprire il documento inviato per la modifica, avviare un nuovo flusso.",
      "documentAlreadySentErrorTitle": "Documento già inviato",
      "documentForSignature": "Documento da inviare",
      "documentForceCompleted": "Completamento richiesta forzato",
      "documentLockedMessage": "Questo destinatario è temporaneamente bloccato a causa di più tentativi di autenticazione non riusciti",
      "documentNameBlank": "Immettere il nome di un documento",
      "documentNameInputDisabledTooltip": "Il nome del documento non può essere modificato",
      "documentNameInputLabel": "Nome del documento",
      "documentStates": {
        "declined": "Rifiutato",
        "draft": "Bozza",
        "editing": "In pausa per le revisioni",
        "executed": "Completato",
        "expired": "Scaduto",
        "pending": "In corso",
        "voided": "Annullato"
      },
      "documentThumbnailAltText": "Miniatura della prima pagina",
      "documentTransactionCreateErrorMessage": "Impossibile creare la transazione del documento",
      "documentTransactionGetErrorMessage": "Impossibile ottenere la transazione del documento",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Sblocco documenti riuscito",
      "documentView": {
        "documentPageIndex": "Pagina {<currentPage>} di {{totalPages}}",
        "pageChangeInputLabel": "Cambia pagina in",
        "pageDown": "Pagina successiva",
        "pageUp": "Pagina precedente"
      },
      "documentVoided": "Richiesta di firma annullata",
      "dontShowAgain": "Non mostrare questo messaggio in futuro",
      "downloadOptions": {
        "combinedPDF": "PDF combinato",
        "completedPDF": "PDF completato",
        "download": "Scarica",
        "downloadErrorMessage": "Tipo di download non supportato: {{downloadType}}",
        "downloadOriginal": "PDF originale",
        "signatureCertificate": "Certificato di firma"
      },
      "draftSaveErrorMessage": "Impossibile salvare la richiesta di firma",
      "draftSaveSuccessMessage": "Richiesta di firma salvata",
      "draw": "Disegna",
      "dueDate": "Data di scadenza",
      "dueDateUpdated": "Data di scadenza aggiornata",
      "edit": "Modifica",
      "editDocumentModal": {
        "cancel": "Indietro",
        "confirm": "Rivedi",
        "contentLine1": "Se è necessario aggiungere o rimuovere destinatari o campi o modificare le opzioni di sicurezza, è possibile rivedere questa richiesta.",
        "contentLine2": "Metteremo in pausa la richiesta durante le revisioni. Ciò significa che tutti coloro che non hanno ancora firmato perderanno temporaneamente l'accesso. Invieremo loro un nuovo collegamento quando avrai finito.",
        "contentLine3": "Una volta avviata la revisione, non sarà possibile annullare la pausa della richiesta senza inviare un documento revisionato.",
        "title": "Si è certi di voler rivedere questa richiesta?"
      },
      "editDueDate": "Modifica data di scadenza",
      "editLeftMidwayModal": {
        "cancel": "Indietro",
        "confirm": "Termina più tardi",
        "contentLine1": "I firmatari non sono in grado di accedere a questa richiesta mentre è in pausa per le revisioni. Se si è finito, tornare indietro e scegliere ",
        "contentLine2": "Se non si è pronti, è possibile tornare indietro e completare le revisioni in un secondo momento.",
        "title": "Stai ancora rivedendo?"
      },
      "editRecipients": "Modifica destinatari",
      "editSignatureRequest": "Rivedi",
      "editSigner": "Modifica firmatario",
      "editViewer": "Modifica partecipante",
      "emptyFolder": "La cartella è vuota",
      "enforceStorageUsage": "Non è possibile creare nuove richieste di firma finché non si libera spazio",
      "expirationDateMessage": "Data di scadenza: {{expirationDate}}",
      "expirationDaysLabel": "giorno/i",
      "expirationInputDescription": "Il documento non sarà più disponibile per la firma dopo il numero specificato di giorni:",
      "expirationInputLabel": "Scadenza",
      "extendDueDate": "Proroga la data di scadenza",
      "extendExpirationDate": "Estendi",
      "favorites": "Preferiti",
      "field": "campo",
      "fieldNameLabel": "Campo del nome",
      "fieldRequired": "Questo campo è obbligatorio",
      "fields": "Campi",
      "fieldsAssignedWarningHeader": "Campi assegnati ai firmatari",
      "fieldsAssignedWarningMessage": "Tutti i campi assegnati ai firmatari verranno rimossi.",
      "fieldsDescription": "Scegliere un tipo di campo da inserire nel documento",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Modifica",
        "sectionLabel": "Dove si desidera conservare il documento firmato?"
      },
      "fileNotSupportedTooltip": "Formato di file non supportato",
      "filesAndRecipients": "File e destinatari",
      "filterSelected": "1 filtro",
      "filterSelected_plural": "{{count}} filtri",
      "forceComplete": "Forza completamento",
      "forceCompleteTitle": "Forza richiesta di completamento",
      "forceCompleteWarningFirst": "In questo modo verranno rimossi tutti i campi assegnati ai firmatari che non hanno ancora firmato e il documento verrà reso esecutivo (stato finale). Non è possibile annullare questa operazione una volta completata.",
      "forceCompleteWarningSecond": "Potrebbe essere necessario attendere un momento prima che il nuovo stato venga visualizzato sulla dashboard.",
      "generateNewPasscodeButtonLabel": "Genera nuovo passcode",
      "genericError": "Si è verificato un errore",
      "groupRule": "Regola di gruppo",
      "hasAtleastOneSignerErrorMessage": "Aggiungere almeno un firmatario per procedere",
      "importFields": {
        "linkText": "Importa campi",
        "modal": {
          "assignImportedFields": "Assegna campi importati",
          "body": {
            "buttonText": "Seleziona",
            "searchPlaceholder": "Cerca per nome di documento, nome di file, mittente o ruolo",
            "sentBy": "Inviato da"
          },
          "componentsImported": "{{count}} campi importati",
          "fields": "campo/i",
          "importFieldsButtonText": "Importa campi",
          "importFrom": "Importa da:",
          "mapAllRecipients": "Assegnare campi ai nuovi destinatari per continuare",
          "newRecipient": "Nuovo destinatario",
          "noDocuments": "Non sono ancora presenti documenti",
          "originalRecipient": "Destinatario originale",
          "selectDifferentDocument": "Seleziona un documento diverso",
          "selectRecipient": "Selezionare il destinatario",
          "sentBy": "Inviato da",
          "title": "Seleziona un documento per importare i campi",
          "titleImportFields": "Importa campi",
          "titleImportFieldsWithCount": "Importa {{count}} campi"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Indietro",
          "contentLine1": "{<documentName>} non contiene campi che possiamo importare. È possibile scegliere un documento diverso o creare nuovi campi.",
          "title": "Questo documento non contiene campi"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Indietro",
          "confirmButtonText": "Importa comunque i campi",
          "contentLine1": "Il documento selezionato ha un numero di pagine diverso da quello su cui si sta lavorando.",
          "contentLine2": "Puoi comunque importare i campi, ma esamineremo solo le prime {{pageCount}} pagine del documento selezionato. Tutti i campi nelle pagine successive non verranno importati.",
          "title": "Questi documenti sembrano leggermente diversi"
        },
        "tooltipText": "Importa campi da documenti inviati in precedenza"
      },
      "initials": "Iniziali",
      "invalidEmail": "E-mail non valida",
      "invalidExpirationDaysError": "Inserire un numero compreso tra 1 e 365",
      "invalidExpirationDaysNotificationMessage": "Scegliere una data di scadenza",
      "invalidRecipientErrorMessage": "Sono necessari un nome e un indirizzo e-mail per ogni destinatario",
      "invalidSignatureRequest": "La richiesta di firma sembra non valida, verificare l'URL",
      "invite": "Invita",
      "inviteViewers": "Invita partecipanti",
      "kbaDescription": "Richiedere ai destinatari di verificare la propria identità con un quiz basato sulla conoscenza. Ogni firmatario dovrà eseguire la verifica autonomamente prima di firmare il documento.",
      "kbaInfoMessage": "I firmatari verranno esclusi dal documento dopo 3 tentativi falliti.",
      "kbaLabel": "Autenticazione basata sulla conoscenza",
      "legacyTokenInterceptor": {
        "buttonText": "Accedi",
        "message": "Ti riporteremo qui in modo che tu possa riprendere da dove eri rimasto.",
        "title": "Effettua nuovamente l'accesso per accedere alle tue richieste di firma"
      },
      "messageLabel": "Messaggio ai destinatari",
      "mixedAlignment": "Allineamento misto",
      "mixedDateFormat": "Formato misto",
      "mixedFontSizes": "Dimensioni dei caratteri miste",
      "moveAwaySelections": "Se si abbandona la cartella corrente, le selezioni andranno perse.",
      "multiLineLabel": "Consenti più righe",
      "multipleRecipients": "Più destinatari",
      "nameGroup": "Gruppo di nomi",
      "nextStep": "Passaggio successivo",
      "optional": "Facoltativo",
      "optionalComment": "Commento di accompagnamento facoltativo",
      "optionsLabel": "Opzioni",
      "packagerConfirmationModal": {
        "contentLine1": "Dobbiamo combinare questi documenti in un unico file prima di poterli preparare per la firma. Non sarà possibile tornare indietro e rimuoverne alcuno quando si procede al passaggio successivo.",
        "contentLine2": "Si è pronti a continuare?",
        "mergeModalText": "L'operazione potrebbe richiedere un minuto. Procederemo al passaggio successivo non appena il documento sarà pronto.",
        "progressBarText": "Unione in corso...",
        "title": "Unisci documenti"
      },
      "passcodeCharacterWarning": "Il passcode può contenere solo cifre.",
      "passcodeDescription": "I destinatari devono immettere un passcode di {<fiveDigit>} per accedere ai documenti.",
      "passcodeFiveDigit": "5 cifre",
      "passcodeInfoMessage": "Questo passcode dovrà essere condiviso con ciascun destinatario.",
      "passcodeLabel": "Passcode",
      "passcodeLengthError": "Scegliere un passcode o disabilitare l'opzione di sicurezza",
      "passcodeLengthErrorEditMode": "Il passcode deve avere 5 cifre",
      "pending": "In sospeso",
      "personalFolders": "Cartelle personali",
      "placeFields": "Posizionamento campi",
      "prepareDocument": {
        "components": {
          "attachment": "Allegato",
          "checkmarkAnnotation": "Aggiungi segno di spunta",
          "editor": {
            "chargeAfterExecutedTooltip": "Utilizzare questa opzione se si desidera addebitare l'importo sulla carta una volta che tutti i firmatari hanno firmato",
            "collectCreditCardTooltip": "Utilizzare questa opzione se si desidera raccogliere solo i dati della carta",
            "defaultHelpText": {
              "attachmentComponent": "Caricare qui l'allegato",
              "paymentComponent": "Aggiungi qui i dati della tua carta"
            },
            "fieldSubtypeLabel": "Sottotipo di campo",
            "fontSizeLabel": "Dimensione del carattere",
            "helpTextLabel": "Aggiungere testo della Guida",
            "paymentAmountLabel": "Importo del pagamento (USD)",
            "paymentAmountPlaceHolder": "Inserire l'importo del pagamento"
          },
          "textAnnotation": "Aggiungi testo"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Ogni menu a discesa deve avere almeno 2 opzioni",
          "paymentAmountInvalidError": "Inserire un importo di pagamento per continuare"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "I firmatari non hanno nulla da firmare.",
          "cancelButtonText": "Indietro",
          "confirmButtonText": "Continua comunque",
          "description": "È comunque possibile continuare con il passaggio successivo oppure tornare indietro e aggiungere campi da compilare.",
          "subtitle": "Questi firmatari non hanno nulla da firmare:",
          "title": "È stato dimenticato qualche campo?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "ID documento",
          "dueDate": "Data di scadenza",
          "otherDetails": "Altri dettagli",
          "recipients": "Destinatari",
          "sender": "Mittente",
          "sentOn": "Inviato il",
          "signerSequencing": "Sequenziamento dei firmatari applicato",
          "signers": "Firmatari"
        },
        "documentHeader": {
          "status": "Stato:",
          "viewDetails": "Visualizza dettagli"
        },
        "downloadModal": {
          "combinedDescription": "Include il certificato di firma e il documento completato",
          "combinedTitle": "PDF combinato",
          "completedDocumentDescription": "Documento firmato con campi compilati",
          "completedDocumentTitle": "Documento completato",
          "done": "Fatto",
          "originalDescription": "Documento PDF senza campi per il firmatario",
          "originalTitle": "PDF originale",
          "signatureCertificateDescription": "Certificato di attendibilità da Sharefile",
          "signatureCertificateTitle": "Certificato di firma",
          "title": "Scarica documento"
        },
        "pageHeader": {
          "completed": "Completato",
          "download": "Scarica",
          "downloadOriginalDocument": "Scarica documento originale",
          "inProgress": "In corso"
        }
      },
      "previousStep": "Passaggio precedente",
      "processingState": "Elaborazione...",
      "radio": "Gruppo di pulsanti di opzione",
      "readyState": "Pronto!",
      "reasonDeclineLabel": "Motivo del rifiuto",
      "reasonVoidLabel": "Motivo dell'annullamento",
      "recipientDuplicate": "Questa persona è già un partecipante",
      "recipientEmail": "E-mail",
      "recipientListHeading": "Chi sono i destinatari?",
      "recipientName": "Nome",
      "recipientNoName": "Questo campo è obbligatorio",
      "recipientRemindButtonLabel": "Invia promemoria",
      "recipientRole": "Ruolo",
      "recipientRoleSigner": "Firmatario",
      "recipientRoleViewer": "Visualizzatore",
      "recipientStatusSigned": "Firmato",
      "recipientUnlockLabel": "Sblocca",
      "recipientsLabel": "Destinatari",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Presto in ShareFile sarà possibile caricare un file CSV di destinatari in modo da poter richiedere la firma a fino a 300 persone allo stesso tempo. Fino ad allora, è ancora possibile farlo in RightSignature.",
          "closeButton": "Annulla",
          "header": "Occorre un invio in blocco?",
          "redirectButton": "Vai a RightSignature"
        },
        "packager": {
          "bodyLineOne": "Presto in ShareFile sarà possibile inviare più documenti in un'unica richiesta di firma. Fino ad allora, è ancora possibile farlo in RightSignature.",
          "closeButton": "Annulla",
          "header": "Occorre inviare più documenti?",
          "redirectButton": "Vai a RightSignature"
        },
        "template": {
          "bodyLineOne": "Presto sarà possibile creare e utilizzare modelli per la firma in ShareFile.",
          "bodyLineTwo": "Preparati provandoli in RightSignature.",
          "closeButton": "Non ora",
          "header": "Semplifica il processo con i modelli",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Prova i modelli"
        }
      },
      "referenceIdLabel": "ID di riferimento",
      "reminderSent": "Promemoria inviato",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Rimuovi firmatari",
        "content": "Tutti i firmatari importati da {<fileName>} verranno rimossi.",
        "title": "Rimuovere questi firmatari?"
      },
      "removeCheckboxGroup": "Rimuovi gruppo di caselle di controllo",
      "removeFieldLabel": "Rimuovi campo",
      "removeFileDisabledTooltip": "Il documento non può essere rimosso",
      "removeFileFailed": "Impossibile rimuovere il file. Riprovare.",
      "requestSignatureButton": {
        "label": "Crea richiesta di firma"
      },
      "requireGroup": "Richiedi gruppo",
      "required": "Obbligatorio",
      "requiredFieldLabel": "Campo obbligatorio",
      "requiredFieldsLabel": "Richiedi campi",
      "reviewAndSend": "Rivedi e invia",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Questo campo non può essere vuoto"
          }
        }
      },
      "save": "Salva",
      "saveCompletedDocument": "Salva documento completato",
      "savedSignaturesLabel": "Selezionare la firma da applicare nel documento",
      "search": "Cerca",
      "securityOptionsLabel": "Opzioni di sicurezza",
      "selectOneOrMoreLabel": "Selezionarne 1 o più",
      "selectOnlyOneLabel": "Selezionarne solo 1",
      "selfSignCheckDisabledToolTip": "L'autofirma non è disponibile selezionando un modello",
      "selfSignCheckLabel": "Sono l'unico firmatario",
      "selfSignCheckToolTip": "Firma tu stesso il documento, quindi condividilo. Verrà salvato nella cartella selezionata.",
      "selfSignSucessMessage": "Documento salvato",
      "selfSignature": "La tua firma",
      "sendForSignature": "Invia per la firma",
      "sendInBulk": {
        "allSigners": "Tutti i {<signersCount>} firmatari",
        "bulkSigners": "Firmatari in blocco",
        "couldTakeAMoment": "L'operazione può richiedere qualche istante.",
        "disableTooltipTextForPLG": "Aggiorna il tuo piano per ottenere l'accesso",
        "downloadCSVTemplateButton": "Scarica il modello CSV",
        "email": "E-mail",
        "errors": {
          "correctTheFollowingError": "Correggere i seguenti errori nel file e riprovare.",
          "exceedsRecipientsLimit": "{<fileName>} supera il limite di 300 firmatari.",
          "fileSizeExceeded": "Il file è più grande del nostro limite di 5 MB.",
          "onlyOneFile": "È possibile importare i firmatari solo da un singolo file.",
          "supportedFileType": "È possibile importare i firmatari solo da un file .csv.",
          "unableToLoadSpreadsheet": "Nel file mancano alcune informazioni importanti."
        },
        "gatheringSignersFromFile": "Raccolta dei firmatari dal file...",
        "goBack": "Indietro",
        "import": "Importa",
        "importSigners": "Importa firmatari",
        "importSignersModalText1": "Abbiamo preparato un modello CSV. Scaricarlo e compilare i campi del nome e dell'e-mail (obbligatori).",
        "importSignersModalText2": "CSV è l'unico formato di file supportato. Il numero di firmatari non può essere superiore a 300.",
        "name": "Nome",
        "note": "Nota: ",
        "previewingTheSigners": "Visualizzazione in anteprima dei primi {<firstSignersCount>} di {<signersCount>} firmatari",
        "selectDifferentFile": "Seleziona un file diverso",
        "title": "Invia in blocco",
        "tooltipText": "L'invio in blocco può essere utilizzato solo con modelli con un solo firmatario",
        "uploadFileText": "Trascinare il file CSV qui o"
      },
      "sendRevisedRequest": "Invia richiesta revisionata",
      "sendSignatureRequest": "Invia richiesta di firma",
      "sendSigningLink": {
        "gotIt": "OK",
        "sentLinkInfoBanner": "Ti abbiamo inviato un collegamento",
        "signingLinkInfoMessage1": "Controlla l'e-mail {<email>} per ottenere un link per firmare questo documento.",
        "signingLinkInfoMessage2": "Presto sarà possibile firmare direttamente in Sharefile."
      },
      "senderRoleChangeTooltip": "Il mittente non può essere un visualizzatore",
      "sentByLabel": "Inviato da",
      "setSigningOrder": "Imposta l'ordine di firma",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Annulla",
          "createFolderLabel": "Crea cartella",
          "disabledNodeUploadReason": "Per selezionare questa cartella è necessaria l'autorizzazione di caricamento",
          "disabledSharedFoldersReason": "Impossibile selezionare le cartelle condivise di primo livello",
          "modalTitle": "Salva il contratto firmato in...",
          "selectFolderButtonLabel": "Seleziona"
        }
      },
      "sharedFolders": "Cartelle condivise",
      "showAll": "Mostra tutto",
      "signButtonLabel": "Firma",
      "signYourself": "Firma tu stesso",
      "signature": "Firma",
      "signatureAnnotationMessage": "Fare clic e trascinare per disegnare la firma nella casella",
      "signatureAnnotationTextMessage": "Digita la tua firma nel campo sottostante",
      "signatureRequest": "Richiesta di firma",
      "signatureRequestErrorMessage": "Richiesta di firma non riuscita",
      "signatureRequestSuccessMessage": "Invia richiesta di firma",
      "signer": "Firmatario",
      "signerDescription": {
        "annotation": "Inserire i campi in autonomia e compilarli in anticipo",
        "bulkSigners": "Si stanno inserendo campi per tutti i firmatari",
        "signers": "Si inseriranno campi per ciascun firmatario"
      },
      "signerMessageInputLabel": "Nota per tutti i firmatari (opzionale)",
      "signerMessageInputLabelTooltip": "Includeremo questa nota nella notifica e-mail inviata ai firmatari",
      "signerMessageInputPlaceholder": "Aggiungi una nota personalizzata per tutti i firmatari",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "ID e nome del documento",
            "dueDateTitle": "Data di scadenza",
            "senderInfoTitle": "Mittente",
            "senderMessageTitle": "Messaggio dal mittente"
          },
          "fields": {
            "addInitials": "Aggiungi iniziali",
            "attachment": {
              "buttonText": "Allega file",
              "fileUploadingError": "Impossibile caricare il file. Riprovare",
              "modalButtonContinue": "Fatto",
              "modalDeleteFileCancelButton": "Annulla",
              "modalDeleteFileOkButton": "Elimina",
              "modalDeleteFileText": "Eliminare {<fileName>}",
              "modalDeleteFileTitle": "Elimina file",
              "modalTitle": "Carica file",
              "modalUploadAnotherFileText": " e caricare un nuovo file?",
              "uploadDifferentFileButton": "Carica un file diverso",
              "uploadHint": "File singolo",
              "uploadText": "Fare clic o trascinare il file in quest'area per caricarlo",
              "uploadingStatus": "Caricamento"
            },
            "autofilled": "Riempimento automatico",
            "dropdown": {
              "clearSelection": "Cancella selezione",
              "selectOption": "Seleziona opzione"
            },
            "enterText": "Inserire il testo",
            "format": "Format",
            "optional": "Facoltativo",
            "paymentField": {
              "amount": "Importo",
              "authorizePayment": "Autorizza il pagamento",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Modifica",
              "clear": "Cancella",
              "creditCardModalDetails": {
                "authorizeMessage": "Accetto che mi venga addebitato l'importo di {<paymentAmount>} dopo l'invio di questo documento",
                "cvcTooltipMessage": "Un codice di sicurezza di tre o quattro cifre stampato sul retro (o sul lato anteriore per AMEX) della tua carta di credito",
                "labels": {
                  "cardNumber": "Numero carta",
                  "cvc": "CVC",
                  "expiryDate": "Data di scadenza"
                },
                "modalTitle": "Modifica i dettagli della carta",
                "placeholders": {
                  "cardNumber": "Inserire il numero della carta",
                  "cvc": "Inserire il CVC"
                }
              },
              "creditDetails": "Inserire i dati della carta"
            },
            "required": "Obbligatorio",
            "selectDate": "Seleziona la data",
            "signature": {
              "clearSignatureButtonText": "Cancella firma",
              "editSignatureButtonText": "Modifica firma",
              "openSignatureModalText": "Firmare qui",
              "prefilledSignatureText": "Per tua comodità, il tuo nome è stato preinserito. Se necessario, è possibile modificarlo."
            }
          },
          "navigation": {
            "next": "Avanti",
            "nextField": "Campo successivo",
            "startSigning": "Inizia a firmare"
          },
          "progressBar": {
            "shortSuccessText": "Hai compilato tutti i campi obbligatori",
            "status": "{{fieldsFilled}}/{{totalFields}} campi obbligatori",
            "submitAction": "Invia",
            "successText": "Hai compilato tutti i campi obbligatori. Una volta terminata la revisione, puoi {<submitAction>} il documento."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Tieni traccia dell'avanzamento"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "Non ci sono campi da compilare",
              "optionalCount": "{{count}} campo facoltativo",
              "optionalCount_plural": "{{count}} campi facoltativi",
              "optionalOnly": "Devi compilare {<optionalCountLabel>}",
              "pending": "In sospeso",
              "requiredAndOptional": "Devi compilare {<requiredCountLabel>} e {<optionalCountLabel>}",
              "requiredCount": "{{count}} campo obbligatorio",
              "requiredCount_plural": "{{count}} campi obbligatori",
              "requiredOnly": "Devi compilare {<optionalCountLabel>}"
            },
            "label": {
              "details": "Dettagli",
              "fields": "Campi"
            }
          }
        },
        "declineDocument": {
          "header": "Documento rifiutato",
          "message": "Hai rifiutato questo documento. Se desideri comunque firmarlo, chiedi al mittente di inviare un nuovo documento",
          "sender": "Mittente"
        },
        "header": {
          "documentActions": {
            "actions": "Azioni",
            "actionsAndDetails": "Dettagli e azioni",
            "declineDocument": "Rifiuta documento",
            "declineFailed": "Non è stato possibile rifiutare il documento. Riprovare",
            "downloadOriginal": "Scarica PDF originale",
            "saveFailed": "Non è stato possibile salvare lo stato di avanzamento. Riprovare",
            "saveProgress": "Salva avanzamento",
            "saveSuccess": "Avanzamento salvato"
          },
          "moreActions": "Più azioni"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Errore di autenticazione!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Annulla",
            "decline": "Rifiuta",
            "reason": "Motivo",
            "reasonHelp": "Questo motivo verrà condiviso con il mittente",
            "reasonPlaceholder": "Perché stai rifiutando?",
            "title": "Rifiuta documento",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} tentativo rimanente",
            "attemptsAmountPlural": "{{attemptsAmount}} tentativi rimanenti",
            "contentMessage": "Inserire un passcode di 5 cifre fornito dal mittente per accedere a questo documento.",
            "inputLabel": "Inserisci il passcode",
            "inputPlaceholder": "Inserisci il passcode di 5 cifre",
            "inputValidationMessage": "Il passcode deve essere di 5 cifre",
            "inputValueRequiedMessage": "Questo campo è obbligatorio",
            "invalidPasscodeMessage": "Passcode non valido.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) ti ha invitato a firmare {<documentName>}",
            "unlockButton": "Sblocca",
            "welcomeMessage": "Benvenuti."
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "Abbiamo inviato una copia del documento compilato a {<email>}",
            "downloadButton": "Scarica documento",
            "notAllSignedText": "Invieremo una copia del documento firmato a {<email>} una volta che tutti i firmatari avranno firmato questo documento",
            "reviewButton": "Rivedere il documento firmato",
            "title": "Documento inviato"
          },
          "termsAndConditions": {
            "continueButton": "Continua",
            "esignConsent": "Consenso con firma elettronica",
            "footerEsignConsent": "Conosci e accetti il nostro {<link>}",
            "footerTerms": "Conosci e accetti i nostri {<linkTermsOfUse>} e la nostra {<linkPrivacyPolicy>}",
            "footerTitle": "Continuando confermi che:",
            "greetings": "Salve {{signerName}},",
            "privacyPolicy": "Informativa sulla privacy",
            "senderMessageTitle": "Messaggio dal mittente",
            "termsOfUse": "Condizioni di utilizzo",
            "text": "{{senderName}} ({{senderEmail}}) ti ha invitato a firmare {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Invia documento",
          "submit": "Invia",
          "submitConfirm": "Questo documento è pronto per essere inviato?",
          "submitWarning": "Non sarà possibile apportare modifiche dopo l'invio",
          "submitWithUnfilledOptionalFields": "È stato saltato un campo facoltativo. Questo documento è pronto per essere inviato?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Compilare tutti i campi obbligatori per inviare il documento"
        }
      },
      "signerSequencingDisabledTooltip": "L'ordine di firma è applicabile solo quando c'è più di 1 firmatario",
      "signerSequencingNotApplicableTooltip": "L'ordine di firma non si applica ai visualizzatori",
      "signers": "Firmatari",
      "signersLabel": "Firmatari",
      "singerSequencingAppliedText": "È stato applicato il sequenziamento del firmatario",
      "sizeLimitFile": "Il file {{filename}} ",
      "sizeLimitMessage": "supera il nostro limite di 20 MB",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Rimuovi modello",
              "content": "L'elenco dei firmatari in blocco andrà perso e sarà necessario caricare un nuovo documento per procedere.",
              "header": "Rimuovere questo modello?"
            },
            "modal": {
              "confirmButton": "Rimuovi documento",
              "content": "La rimozione di un documento comporta l'eliminazione di tutti i destinatari predefiniti e dei campi a loro assegnati. Rimuovere il documento?",
              "header": "Rimuovi documento"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Seleziona",
              "createdBy": "Creazione a cura di:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Idoneo per l'invio in blocco",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Impossibile caricare i modelli. Riprovare più tardi",
            "footer": {
              "buttonText": "Creare un modello",
              "question": "Non si riesce a trovare ciò che si sta cercando?"
            },
            "noDocuments": "Non sono ancora presenti modelli",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Qui non c'è niente",
            "noSearchResultsDesc": "Non sembra che ci siano corrispondenze per la ricerca.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Ruoli:",
            "title": "Seleziona un modello"
          },
          "sourceName": "Utilizza un modello"
        },
        "upload": {
          "basefileProcessingFailed": "Impossibile elaborare il file {{ filename }}. Caricarlo nuovamente",
          "failed": "Impossibile caricare il file {{ filename }}",
          "numberLimitMessage": "Non è possibile caricare più di 5 file"
        }
      },
      "statusFilter": "Stato",
      "switch": "Passare alla vecchia esperienza",
      "template": "Modello",
      "temporaryRedirectText": "Passare temporaneamente a RightSignature",
      "text": "Testo",
      "textAlignLabel": "Allinea il testo",
      "type": "Tipo",
      "unableToFetchFileDetails": "Impossibile recuperare i dettagli del file",
      "unableToProceedNoDocumentError": "Impossibile procedere; si prega di aggiungere un documento",
      "unableToProceedNoSignerError": "Impossibile procedere; si prega di aggiungere almeno un firmatario",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Questo tipo di documento non è supportato. I tipi di file supportati sono .doc, .docx, .txt .pdf",
      "updateDocumentErrorMessage": "Impossibile aggiornare il documento.",
      "uploadDocumentErrorMessage": "Caricare un documento per passare al passaggio successivo",
      "uploadedFilesProcessingErrorMessage": "Uno o più file caricati sono ancora in fase di elaborazione",
      "useSaved": "Usa salvato",
      "userFetchErrorMessage": "Impossibile recuperare le informazioni utente",
      "view": "Visualizza",
      "viewer": "Visualizzatore",
      "viewerUniqueEmailErrorMessage": "L'indirizzo e-mail del partecipante deve essere univoco",
      "viewersLabel": "Visualizzatori",
      "void": "Nullo",
      "voidConfirmationTitle": "Conferma dell'annullamento del documento",
      "zoomIn": "Ingrandisci",
      "zoomOut": "Riduci"
    }
  },
  {
    "key": "ja",
    "content": {
      "AccountLimitReached": "このアカウントはドキュメントの最大制限数に達しました。",
      "AttachedByLabel": "{{signer}} が添付",
      "AttachmentDetailsLabel": "添付ファイル",
      "AttachmentFieldLabel": "添付",
      "CheckFieldLabel": "チェックボックス",
      "CheckGroupFieldLabel": "チェックボックス グループ",
      "DateFieldLabel": "日付",
      "InitialsFieldLabel": "イニシャル",
      "PaymentField": {
        "chargeAfterExecutedLabel": "署名後に請求する",
        "chargedLabel": "請求済み金額 {<paymentAmount>}",
        "collectCardDetailsLabel": "カードの詳細を収集する",
        "defaultLabel": "支払い",
        "paymentFailedLabel": "支払いに失敗しました"
      },
      "PaymentFieldLabel": "支払いフィールド",
      "SelectFieldLabel": "ドロップダウン",
      "SignatureFieldLabel": "署名",
      "TextFieldLabel": "文字列",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "現在のプランでは署名のために送信できるドキュメントの数に制限があり、その制限に達しています。ただし、自分でドキュメントに署名することはできます。",
        "upgradeTrialBanner": "ご利用のトライアルでの残りは {<documentsLeft>} です。"
      },
      "accountLimitExhaustedMessage": "アカウントに許可された上限に達したため、署名用にドキュメントを送信できません。アカウントをアップグレードしてください。",
      "accountStatsFetchErrorMessage": "アカウント情報の取得に失敗しました",
      "add": "追加",
      "addCheckbox": "チェックボックスを追加する",
      "addFilesFromSharefile": "ShareFile からアイテムを追加",
      "addMyself": "自分を追加",
      "addOptionLabel": "オプションを追加",
      "addRecipient": "別の受信者を追加",
      "addSignature": "署名を追加する",
      "annotator": "あなた (現時点)",
      "annotatorOptionTooltip": "事前にフィールドに記入してください",
      "apply": "適用",
      "archiveLocationBlank": "署名されたドキュメントを保存するフォルダーを選択してください",
      "assignedToLabel": "割り当て先",
      "attachmentFieldLabel": "添付",
      "browseFiles": "ファイルの参照",
      "bulkSend": "一括送信",
      "cancel": "キャンセル",
      "changesSaved": "変更が保存されました",
      "checkbox": "チェックボックス",
      "checkboxGroup": "チェックボックス グループ",
      "checkboxes": "チェックボックス",
      "clear": "クリア",
      "clearBackground": "Clear background",
      "close": "閉じる",
      "completed": "完了",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} 個の選択されたフィールド",
      "confirm": "確認",
      "continue": "続行",
      "createDocument": {
        "almostDone": "もう少しで完了です。「更新」ボタンや「戻る」ボタンをクリックしないでください。"
      },
      "createDocumentErrorMessage": "ドキュメントの作成に失敗しました",
      "creationDateLabel": "送信日: ",
      "dashboard": {
        "adminView": "管理者表示",
        "adminViewTooltipText": "このアカウントから送信されたすべてのドキュメントを表示します",
        "autoDeleteText": "{{remaining}} 日後に自動削除されます",
        "clearFilters": "フィルターをクリアする",
        "clearSearch": "検索のクリア",
        "continueAction": "続行",
        "deleteAction": "削除",
        "downloadPdf": "ダウンロード",
        "esignLeftNav": "署名",
        "loadMore": "さらに読み込む",
        "overdue": "期限を 1 日超過",
        "signatureDue": "期限 {{dueDate}}",
        "signatureRequests": "署名リクエスト",
        "signatureSettings": "署名設定",
        "signaturesSubmitted": "署名が送信されました",
        "templates": "テンプレート",
        "title": "署名リクエスト"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "フィルターに一致する結果はないようです。",
          "title": "検索結果はありません"
        },
        "noRequestsCreated": {
          "description": "署名を取得する必要がある場合、リクエストを作成してください。",
          "title": "署名リクエストはまだ作成されていません"
        },
        "noSearchResults": {
          "description": "検索に一致する結果はないようです。",
          "title": "検索結果はありません"
        }
      },
      "date": "日付",
      "dateExample": "例:",
      "dateExtendError": "将来の日付を選択してください",
      "dateFormatLabel": "形式",
      "dateSignedLabel": "署名した日付を自動入力",
      "dateSignedTooltip": "受信者はフィールドを編集できません。ドキュメントに署名した日付が自動的に入力されます。",
      "declinedByLabel": "{{name}} が拒否しました",
      "defaultBadgeLabel": "デフォルト",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "完了した PDF をメールで全員に送信する",
          "attachCompletedPDFCheckboxTooltip": "最後の署名の後、完了した PDF を添付ファイルとしてメールで全員に送信します(10MB を超える場合を除く)。",
          "sectionHeader": "添付ファイル"
        },
        "header": "デフォルト設定を編集する",
        "headerInfo": "ここで加えた変更はすべて、処理中のすべての署名リクエストに適用されます。",
        "hipaaConfirmationBodyFirst": "この設定が適用されると、すべての受信者は署名されたドキュメントのコピーをメールの添付ファイルとして受け取ります。HIPAA のお客様はその責任の下で、受信者が自身の情報がこのように扱われることを許可していることを確認する必要があります。",
        "hipaaConfirmationBodySecond": "この設定を適用してもよろしいですか?",
        "hipaaConfirmationButton": "設定を適用する",
        "hipaaConfirmationHeader": "HIPAA に関する考慮事項",
        "reminders": {
          "options": {
            "daily": "毎日",
            "noAuto": "無期限",
            "weekly": "毎週"
          },
          "reminderFrequencyLabel": "自動リマインダーを送信する",
          "reminderFrequencyTooltip": "受信者が署名するまで、毎週 (3、7、14、21、および 28 日後) または毎日 (7 日後以降) に受信者にリマインダーをメールで送信します。",
          "sectionHeader": "リマインダー"
        },
        "signature": {
          "allowDrawnSignatureLabel": "手書きの署名を許可する",
          "allowTypedSignatureLabel": "印刷した署名を許可する",
          "applyBlueInkSignatureLabel": "署名に青いインクを適用する",
          "applyBlueInkSignatureTooltipAdmin": "この設定を変更する必要がある場合は、管理者に問い合わせてください。",
          "applyBlueInkSignatureTooltipNonAdmin": "署名されたオリジナルとコピーを区別しやすくするために、すべての署名に青色のインクを使用します。",
          "sectionHeader": "署名の種類"
        },
        "signer": {
          "allowDownload": "署名者が署名する前に元のドキュメントをダウンロードできるようにする",
          "sectionHeader": "署名者の権限"
        }
      },
      "defaultSettingsDescription": "リマインダー、メールの添付ファイル、署名の種類の設定を変更します。",
      "defaultSettingsEditLabel": "デフォルト設定を編集する",
      "defaultSettingsLabel": "デフォルト設定",
      "defaultSettingsUpdateFailed": "デフォルト設定の更新に失敗しました",
      "defaultSettingsUpdateSuccess": "デフォルト設定が更新されました",
      "defaultValueLabel": "デフォルトのテキストを追加する",
      "delete": "削除",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "署名リクエストが完全に削除されます。元に戻すことはできません。",
        "deleteConfirmationMessageSecond": "署名者と閲覧者は、参照用に完了したドキュメントと署名付き証明書をメールで受信します。",
        "deleteRequest": "リクエストを削除する",
        "deleteRequestSuccess": "リクエストが削除されました"
      },
      "deleteDraftDocumentLabel": "署名リクエストを削除する",
      "deleteDraftDocumentModalContent": "署名リクエストを削除してもよろしいですか? この操作は取り消すことができません。",
      "deleteDraftDocumentSuccess": "署名リクエストが削除されました",
      "deleteViewerWarningHeader": "この宛先を削除してもよろしいですか?",
      "deleteViewerWarningMessage": "この署名リクエストにアクセスできなくなります。",
      "detailsTabSecurityOptions": {
        "editPasscode": "パスコードを編集する",
        "kba": "ナレッジベース認証",
        "passcode": "パスコード",
        "title": "セキュリティ オプションが適用されました",
        "updatePasscodeSuccess": "このドキュメントのパスコードが更新されました。"
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "一括送信で送信されたリクエストを修正することはできません",
          "signerSigned": "誰かが署名したリクエストを修正することはできません",
          "userNotSender": "送信していないリクエストを修正することはできません"
        }
      },
      "documentAlreadySentErrorDetails": "送信済みのドキュメントを開いて編集できません。新しいフローを開始してください。",
      "documentAlreadySentErrorTitle": "ドキュメントは既に送信済みです",
      "documentForSignature": "送信するドキュメント",
      "documentForceCompleted": "リクエストが強制的に完了しました",
      "documentLockedMessage": "認証の試行が複数回失敗したため、この受信者は一時的にロックアウトされています",
      "documentNameBlank": "ドメイン名を入力してください",
      "documentNameInputDisabledTooltip": "ドキュメント名を編集することはできません",
      "documentNameInputLabel": "ドキュメント名",
      "documentStates": {
        "declined": "拒否",
        "draft": "下書き",
        "editing": "修正のため一時停止中",
        "executed": "完了",
        "expired": "期限切れ",
        "pending": "処理中",
        "voided": "取り消し済み"
      },
      "documentThumbnailAltText": "最初のページのサムネイル",
      "documentTransactionCreateErrorMessage": "ドキュメント トランザクションの作成に失敗しました",
      "documentTransactionGetErrorMessage": "ドキュメント トランザクションの取得に失敗しました",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "ドキュメントのロック解除に成功しました",
      "documentView": {
        "documentPageIndex": "ページ {<currentPage>}/{{totalPages}}",
        "pageChangeInputLabel": "移動先のページ",
        "pageDown": "次のページ",
        "pageUp": "前のページ"
      },
      "documentVoided": "署名リクエストが取り消されました",
      "dontShowAgain": "このメッセージを再度表示しない",
      "downloadOptions": {
        "combinedPDF": "統合 PDF",
        "completedPDF": "完了した PDF",
        "download": "ダウンロード",
        "downloadErrorMessage": "サポートされていないダウンロードの種類: {{downloadType}}",
        "downloadOriginal": "元の PDF",
        "signatureCertificate": "署名付き証明書"
      },
      "draftSaveErrorMessage": "署名リクエストを保存できませんでした",
      "draftSaveSuccessMessage": "署名リクエストが保存されました",
      "draw": "手書き入力",
      "dueDate": "期日",
      "dueDateUpdated": "期日が更新されました",
      "edit": "編集",
      "editDocumentModal": {
        "cancel": "戻る",
        "confirm": "修正",
        "contentLine1": "受信者やフィールドの追加または削除が必要な場合、またはセキュリティ オプションの変更が必要な場合は、このリクエストを修正できます。",
        "contentLine2": "修正を行っている間、リクエストは一時停止されます。これは、まだ署名していないユーザーは一時的にアクセスできなくなることを意味します。完了後、新しいリンクが送信されます。",
        "contentLine3": "修正を開始した場合、修正されたドキュメントを送信せずにリクエストの一時停止を解除することはできません。",
        "title": "このリクエストを修正してもよろしいですか?"
      },
      "editDueDate": "期日を編集する",
      "editLeftMidwayModal": {
        "cancel": "戻る",
        "confirm": "後で完了する",
        "contentLine1": "修正のために一時停止されている間、署名者はこのリクエストにアクセスできません。完了後に、戻って次を選択してください: ",
        "contentLine2": "準備ができていない場合は、後で戻って修正を完了することができます。",
        "title": "まだ修正中ですか?"
      },
      "editRecipients": "受信者の編集",
      "editSignatureRequest": "修正",
      "editSigner": "署名者を編集する",
      "editViewer": "閲覧者を編集する",
      "emptyFolder": "このフォルダーは空です",
      "enforceStorageUsage": "領域を解放するまで、新しい署名リクエストを作成できません",
      "expirationDateMessage": "有効期限: {{expirationDate}}",
      "expirationDaysLabel": "日",
      "expirationInputDescription": "指定された日数が経過すると、ドキュメントに署名できなくなります:",
      "expirationInputLabel": "有効期限",
      "extendDueDate": "期日を延長する",
      "extendExpirationDate": "延長する",
      "favorites": "お気に入り",
      "field": "フィールド",
      "fieldNameLabel": "名前フィールド",
      "fieldRequired": "このフィールドは必須です",
      "fields": "フィールド",
      "fieldsAssignedWarningHeader": "署名者に割り当てられたフィールド",
      "fieldsAssignedWarningMessage": "署名者に割り当てられたすべてのフィールドが削除されます。",
      "fieldsDescription": "ドキュメントに配置するフィールドの種類を選択する",
      "fileArchivalConfigurator": {
        "editButtonLabel": "編集",
        "sectionLabel": "署名済み文書をどこに保存しますか?"
      },
      "fileNotSupportedTooltip": "サポートされていないファイル形式です",
      "filesAndRecipients": "ファイルと受信者",
      "filterSelected": "1 個のフィルター",
      "forceComplete": "強制的に完了する",
      "forceCompleteTitle": "リクエストを強制的に完了する",
      "forceCompleteWarningFirst": "署名していない署名者に割り当てられたすべてのフィールドが削除され、ドキュメントが署名済み (最終状態) になります。これが完了すると、元に戻すことはできません。",
      "forceCompleteWarningSecond": "新しい状態がダッシュ​​ボードに反映されるまで、時間がかかることがあります。",
      "generateNewPasscodeButtonLabel": "新しいパスコードを生成する",
      "genericError": "エラーが発生しました",
      "groupRule": "グループ規則",
      "hasAtleastOneSignerErrorMessage": "続行するには少なくとも 1 人の署名者を追加する",
      "importFields": {
        "linkText": "フィールドをインポートする",
        "modal": {
          "assignImportedFields": "インポートされたフィールドを割り当てる",
          "body": {
            "buttonText": "選択",
            "searchPlaceholder": "ドキュメント名、ファイル名、送信者または役割で検索",
            "sentBy": "送信者: "
          },
          "componentsImported": "{{count}} 個のフィールドがインポートされました",
          "fields": "フィールド",
          "importFieldsButtonText": "フィールドをインポートする",
          "importFrom": "インポート元:",
          "mapAllRecipients": "続行するにはフィールドを新しい受信者に割り当てます",
          "newRecipient": "新しい受信者",
          "noDocuments": "まだドキュメントが作成されていません",
          "originalRecipient": "元の受信者",
          "selectDifferentDocument": "別のドキュメントを選択する",
          "selectRecipient": "受信者を選択",
          "sentBy": "送信者: ",
          "title": "フィールドをインポートするドキュメントを選択する",
          "titleImportFields": "フィールドをインポートする",
          "titleImportFieldsWithCount": "{{count}} 個のフィールドをインポートする"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "戻る",
          "contentLine1": "{<documentName>} にはインポートできるフィールドがありません。別のドキュメントを選択するか、新しいフィールドを作成することができます。",
          "title": "このドキュメントにはまだフィールドがありません"
        },
        "pageMismatchModal": {
          "cancelButtonText": "戻る",
          "confirmButtonText": "フィールドのインポートを続行する",
          "contentLine1": "選択したドキュメントのページ数が、現在作業中のドキュメントとは異なります。",
          "contentLine2": "フィールドをインポートすることもできますが、ここでは選択したドキュメントの最初の {{pageCount}} ページのみを確認します。その後のページのフィールドはインポートされません。",
          "title": "これらのドキュメントに異なる部分があります"
        },
        "tooltipText": "以前に送信したドキュメントからフィールドをインポートする"
      },
      "initials": "イニシャル",
      "invalidEmail": "無効なメール アドレス",
      "invalidExpirationDaysError": "1 ～ 365 の数字を入力してください",
      "invalidExpirationDaysNotificationMessage": "有効期限を選択する",
      "invalidRecipientErrorMessage": "各受信者の名前とメール アドレスを入力する必要があります",
      "invalidSignatureRequest": "署名リクエストが無効のようです。URL を確認してください",
      "invite": "招待",
      "inviteViewers": "閲覧者を招待する",
      "kbaDescription": "受信者は、ナレッジベースに登録された本人確認のための質問に回答する必要があります。署名者ごとに、ドキュメントに署名する前に確認を求められます。",
      "kbaInfoMessage": "3 回失敗すると、署名者はドキュメントからロックアウトされます。",
      "kbaLabel": "ナレッジベース認証",
      "legacyTokenInterceptor": {
        "buttonText": "サインイン",
        "message": "ここに戻りますので、中断したところから再開できます。",
        "title": "再度サインインして署名リクエストにアクセスします"
      },
      "messageLabel": "受信者へのメッセージ",
      "mixedAlignment": "配置の混在",
      "mixedDateFormat": "形式の混在",
      "mixedFontSizes": "フォント サイズの混在",
      "moveAwaySelections": "現在のフォルダーから移動すると、選択は無効になります。",
      "multiLineLabel": "複数行を許可する",
      "multipleRecipients": "複数の受信者",
      "nameGroup": "グループに名前を付ける",
      "nextStep": "次の手順",
      "optional": "オプション",
      "optionalComment": "追加のコメント (オプション)",
      "optionsLabel": "オプション",
      "packagerConfirmationModal": {
        "contentLine1": "署名用に準備する前に、これらのドキュメントを 1 つのファイルに統合する必要があります。次のステップに進むと、戻って削除することはできません。",
        "contentLine2": "続行する準備はできていますか?",
        "mergeModalText": "これには 1 分かかる場合があります。ドキュメントの準備が整い次第、次のステップに進みます。",
        "progressBarText": "マージ中...",
        "title": "ドキュメントをマージする"
      },
      "passcodeCharacterWarning": "パスコードには数字のみを含めることができます。",
      "passcodeDescription": "受信者がドキュメントにアクセスするには、{<fiveDigit>} のパスコードを入力する必要があります。",
      "passcodeFiveDigit": "5 桁",
      "passcodeInfoMessage": "このパスコードを各受信者と共有する必要があります。",
      "passcodeLabel": "パスコード",
      "passcodeLengthError": "パスコードを選択するか、そのセキュリティ オプションを無効にする",
      "passcodeLengthErrorEditMode": "パスコードは 5 桁で指定する必要があります",
      "pending": "保留",
      "personalFolders": "個人用フォルダー",
      "placeFields": "フィールドの配置",
      "prepareDocument": {
        "components": {
          "attachment": "添付",
          "checkmarkAnnotation": "チェックマークを追加する",
          "editor": {
            "chargeAfterExecutedTooltip": "すべての署名者が署名した後でカードに請求する場合は、このオプションを使用してください",
            "collectCreditCardTooltip": "カード情報の収集のみの場合は、このオプションを使用してください",
            "defaultHelpText": {
              "attachmentComponent": "ここに添付ファイルをアップロードしてください",
              "paymentComponent": "ここにカードの詳細を追加する"
            },
            "fieldSubtypeLabel": "フィールドのサブタイプ",
            "fontSizeLabel": "フォント サイズ",
            "helpTextLabel": "ヘルプ テキストを追加する",
            "paymentAmountLabel": "支払い額 (米ドル)",
            "paymentAmountPlaceHolder": "支払い額を入力する"
          },
          "textAnnotation": "テキストを追加する"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "各ドロップダウンには少なくとも 2 つのオプションが必要です",
          "paymentAmountInvalidError": "続行するには支払い額を入力してください"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "署名者には署名するものが何もありません。",
          "cancelButtonText": "戻る",
          "confirmButtonText": "このまま続行する",
          "description": "このまま続行して次の手順に進めますが、戻って記入用のフィールドを追加することもできます。",
          "subtitle": "これらの署名者には署名するものが何もありません。",
          "title": "フィールドを忘れていませんか?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "ドキュメント ID",
          "dueDate": "期限",
          "otherDetails": "その他の詳細",
          "recipients": "受信者",
          "sender": "送信者",
          "sentOn": "送信日: ",
          "signerSequencing": "適用された署名者のシーケンス",
          "signers": "署名者"
        },
        "documentHeader": {
          "status": "状態：",
          "viewDetails": "詳細を表示する"
        },
        "downloadModal": {
          "combinedDescription": "署名証明書と完成したドキュメントが含まれています",
          "combinedTitle": "統合 PDF",
          "completedDocumentDescription": "フィールドが入力済みの署名済みドキュメント",
          "completedDocumentTitle": "完成したドキュメント",
          "done": "完了",
          "originalDescription": "署名者フィールドのない PDF ドキュメント",
          "originalTitle": "元の PDF",
          "signatureCertificateDescription": "Sharefile からの信頼証明書",
          "signatureCertificateTitle": "署名付き証明書",
          "title": "ドキュメントをダウンロードする"
        },
        "pageHeader": {
          "completed": "完了",
          "download": "ダウンロード",
          "downloadOriginalDocument": "オリジナル ドキュメントをダウンロードする",
          "inProgress": "処理中"
        }
      },
      "previousStep": "前の手順",
      "processingState": "処理中...",
      "radio": "ラジオ ボタン グループ",
      "readyState": "準備完了",
      "reasonDeclineLabel": "拒否の理由",
      "reasonVoidLabel": "取り消しの理由",
      "recipientDuplicate": "この人物は既に閲覧者です",
      "recipientEmail": "メール",
      "recipientListHeading": "受信者は誰ですか?",
      "recipientName": "名前",
      "recipientNoName": "このフィールドは必須です",
      "recipientRemindButtonLabel": "通知",
      "recipientRole": "役割",
      "recipientRoleSigner": "署名者",
      "recipientRoleViewer": "閲覧者",
      "recipientStatusSigned": "署名済み",
      "recipientUnlockLabel": "ロック解除",
      "recipientsLabel": "受信者",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "まもなく、ShareFile で受信者の CSV をアップロードすることで、一度に最大 300 人に署名をリクエストできるようになります。それまでは、RightSignature でこの操作を行うことができます。",
          "closeButton": "キャンセル",
          "header": "一括送信が必要ですか?",
          "redirectButton": "RightSignature に移動"
        },
        "packager": {
          "bodyLineOne": "まもなく、ShareFile を使用して 1 回の署名リクエストで複数のドキュメントを送信できるようになります。それまでは、RightSignature でこの操作を行うことができます。",
          "closeButton": "キャンセル",
          "header": "複数のドキュメントを送信する必要がありますか?",
          "redirectButton": "RightSignature に移動"
        },
        "template": {
          "bodyLineOne": "まもなく、ShareFile で署名テンプレートを作成して使用できるようになります。",
          "bodyLineTwo": "RightSignature でこの機能を試してみてください。",
          "closeButton": "後で",
          "header": "テンプレートを使用してプロセスを合理化する",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "テンプレートを試す"
        }
      },
      "referenceIdLabel": "参照 ID",
      "reminderSent": "通知を送信しました",
      "removeBulkSignersConfirmationModal": {
        "confirm": "署名者を削除する",
        "content": "{<fileName>} からインポートされた署名者はすべて削除されます。",
        "title": "これらの署名者を削除してもよろしいですか?"
      },
      "removeCheckboxGroup": "チェックボックス グループを削除する",
      "removeFieldLabel": "フィールドを削除する",
      "removeFileDisabledTooltip": "ドキュメントは削除できません",
      "removeFileFailed": "ファイルを削除できませんでした。再試行してください。",
      "requestSignatureButton": {
        "label": "署名リクエストの作成"
      },
      "requireGroup": "グループは必須",
      "required": "必須",
      "requiredFieldLabel": "フィールドは必須",
      "requiredFieldsLabel": "フィールドは必須",
      "reviewAndSend": "確認して送信",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "このフィールドを空にすることはできません"
          }
        }
      },
      "save": "保存",
      "saveCompletedDocument": "完了したドキュメントを保存する",
      "savedSignaturesLabel": "ドキュメントに適用する署名を選択する",
      "search": "検索",
      "securityOptionsLabel": "セキュリティのオプション:",
      "selectOneOrMoreLabel": "1 つ以上選択",
      "selectOnlyOneLabel": "1 つのみ選択",
      "selfSignCheckDisabledToolTip": "テンプレートの選択で自己署名は使用できません",
      "selfSignCheckLabel": "署名者は自分だけです",
      "selfSignCheckToolTip": "ドキュメントに署名し、共有します。ドキュメントは選択したフォルダーに保存されます。",
      "selfSignSucessMessage": "ドキュメントが保存されました",
      "selfSignature": "あなたの署名",
      "sendForSignature": "署名用に送信",
      "sendInBulk": {
        "allSigners": "{<signersCount>} 人の全署名者",
        "bulkSigners": "一括送信の署名者",
        "couldTakeAMoment": "これには時間がかかることがあります。",
        "disableTooltipTextForPLG": "アクセスするにはプランをアップグレードしてください",
        "downloadCSVTemplateButton": "CSV テンプレートのダウンロード",
        "email": "メール",
        "errors": {
          "correctTheFollowingError": "ファイル内の次のエラーを修正して、再試行してください。",
          "exceedsRecipientsLimit": "{<fileName>} が署名者の上限 300 人を超えています。",
          "fileSizeExceeded": "ファイルが、5MB の上限を超えています。",
          "onlyOneFile": "署名者は 1 つのファイルからのみインポートできます。",
          "supportedFileType": "署名者は .csv ファイルからのみインポートできます。",
          "unableToLoadSpreadsheet": "ファイルでいくつかの重要な情報が見つかりません。"
        },
        "gatheringSignersFromFile": "ファイルから署名者を収集しています...",
        "goBack": "戻る",
        "import": "インポート",
        "importSigners": "署名者をインポートする",
        "importSignersModalText1": "CSV テンプレートを用意しました。ダウンロードして、名前とメールのフィールドに入力してください (必須)。",
        "importSignersModalText2": "サポートされているファイル形式は CSV のみです。署名者の数は 300 人以内にする必要があります。",
        "name": "名前",
        "note": "注: ",
        "previewingTheSigners": "署名者の最初の {<firstSignersCount>} 人 ({<signersCount>} 人中) をプレビューしています",
        "selectDifferentFile": "別のファイルを選択する",
        "title": "一括送信する",
        "tooltipText": "一括送信は単一の署名者のテンプレートでのみ使用できます",
        "uploadFileText": "ここに CSV ファイルをドラッグ。または"
      },
      "sendRevisedRequest": "修正されたリクエストを送信する",
      "sendSignatureRequest": "署名リクエストを送信する",
      "sendSigningLink": {
        "gotIt": "OK",
        "sentLinkInfoBanner": "リンクを送信しました",
        "signingLinkInfoMessage1": "このドキュメントに署名するためのリンクについては、{<email>} のメールを確認してください。",
        "signingLinkInfoMessage2": "まもなく、Sharefile に直接サインインできるようになります。"
      },
      "senderRoleChangeTooltip": "送信者は閲覧者になることはできません",
      "sentByLabel": "送信者: ",
      "setSigningOrder": "署名の順番を設定",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "キャンセル",
          "createFolderLabel": "フォルダーの作成",
          "disabledNodeUploadReason": "このフォルダーを選択するにはアップロード権限が必要です",
          "disabledSharedFoldersReason": "最上位の共有フォルダーを選択できません",
          "modalTitle": "署名済みの契約書を次の場所に保存します...",
          "selectFolderButtonLabel": "選択"
        }
      },
      "sharedFolders": "共有フォルダー",
      "showAll": "すべて表示",
      "signButtonLabel": "署名",
      "signYourself": "自己署名",
      "signature": "署名",
      "signatureAnnotationMessage": "クリックおよびドラッグしてボックスに署名を手書き入力",
      "signatureAnnotationTextMessage": "以下のフィールドに署名を入力してください",
      "signatureRequest": "署名リクエスト",
      "signatureRequestErrorMessage": "署名リクエストに失敗しました",
      "signatureRequestSuccessMessage": "署名リクエストを送信しました",
      "signer": "署名者",
      "signerDescription": {
        "annotation": "自分用のフィールドを配置し、事前に入力してください",
        "bulkSigners": "すべての署名者のフィールドを配置しています",
        "signers": "署名者ごとにフィールドを配置します"
      },
      "signerMessageInputLabel": "すべての署名者へのメモ (オプション)",
      "signerMessageInputLabelTooltip": "このメモは、署名者に送信されるメール通知に追加されます",
      "signerMessageInputPlaceholder": "すべての署名者に個人用メモを追加する",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "ドキュメント名と ID",
            "dueDateTitle": "期日",
            "senderInfoTitle": "送信者",
            "senderMessageTitle": "送信者からのメッセージ"
          },
          "fields": {
            "addInitials": "イニシャルを追加する",
            "attachment": {
              "buttonText": "ファイルを添付する",
              "fileUploadingError": "ファイルをアップロードできません。再試行してください。",
              "modalButtonContinue": "完了",
              "modalDeleteFileCancelButton": "キャンセル",
              "modalDeleteFileOkButton": "削除",
              "modalDeleteFileText": "{<fileName>} を削除してもよろしいですか?",
              "modalDeleteFileTitle": "ファイルの削除",
              "modalTitle": "ファイルをアップロードする",
              "modalUploadAnotherFileText": " そして新しいファイルをアップロードしますか?",
              "uploadDifferentFileButton": "別のファイルをアップロードする",
              "uploadHint": "1 つのファイル",
              "uploadText": "アップロードするには、クリックするか、この領域にファイルをドラッグします",
              "uploadingStatus": "アップロード中"
            },
            "autofilled": "自動入力済み",
            "dropdown": {
              "clearSelection": "選択を解除する",
              "selectOption": "オプションを選択する"
            },
            "enterText": "テキストを入力する",
            "format": "Format",
            "optional": "オプション",
            "paymentField": {
              "amount": "金額",
              "authorizePayment": "支払いの承認",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "変更",
              "clear": "クリア",
              "creditCardModalDetails": {
                "authorizeMessage": "このドキュメントを送信後、{<paymentAmount>} が請求されることに同意します",
                "cvcTooltipMessage": "クレジットカードの裏面 (AMEX の場合は表面) に印刷されている 3 桁または 4 桁のセキュリティ コード",
                "labels": {
                  "cardNumber": "カード番号",
                  "cvc": "CVC",
                  "expiryDate": "有効期限"
                },
                "modalTitle": "カードの詳細を編集する",
                "placeholders": {
                  "cardNumber": "カード番号を入力",
                  "cvc": "CVC を入力"
                }
              },
              "creditDetails": "カードの詳細を入力"
            },
            "required": "必須",
            "selectDate": "日付を選択",
            "signature": {
              "clearSignatureButtonText": "署名を消去する",
              "editSignatureButtonText": "署名を編集する",
              "openSignatureModalText": "ここに署名",
              "prefilledSignatureText": "名前は事前入力されています。必要であれば、変更できます。"
            }
          },
          "navigation": {
            "next": "次へ",
            "nextField": "次のフィールド",
            "startSigning": "署名を開始する"
          },
          "progressBar": {
            "shortSuccessText": "すべての必須フィールドに入力済みです",
            "status": "{{fieldsFilled}}/{{totalFields}} は必須フィールド",
            "submitAction": "送信",
            "successText": "すべての必須フィールドに入力済みです。確認が完了したら、ドキュメントに対して {<submitAction>} を実行できます。"
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "進捗状況を追跡する"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "記入が必要なフィールドはありません",
              "optionalCount": "{{count}} 個のオプションのフィールド",
              "optionalOnly": "{<optionalCountLabel>} に記入する必要があります",
              "pending": "保留",
              "requiredAndOptional": "{<requiredCountLabel>} と {<optionalCountLabel>} に記入する必要があります",
              "requiredCount": "{{count}} 個の必須フィールド",
              "requiredOnly": "{<requiredCountLabel>} に記入する必要があります"
            },
            "label": {
              "details": "詳細",
              "fields": "フィールド"
            }
          }
        },
        "declineDocument": {
          "header": "拒否したドキュメント",
          "message": "このドキュメントを拒否しました。署名が必要な場合は、送信者に新しいドキュメントを送信するよう依頼してください",
          "sender": "送信者"
        },
        "header": {
          "documentActions": {
            "actions": "操作",
            "actionsAndDetails": "詳細と操作",
            "declineDocument": "ドキュメントを拒否する",
            "declineFailed": "ドキュメントを拒否できませんでした。再試行してください",
            "downloadOriginal": "元の PDF をダウンロードする",
            "saveFailed": "進行状況を保存できませんでした。再試行してください",
            "saveProgress": "進行状況を保存する",
            "saveSuccess": "保存した進行状況"
          },
          "moreActions": "その他の操作"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "認証に失敗しました。",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "キャンセル",
            "decline": "拒否",
            "reason": "理由",
            "reasonHelp": "この理由は送信者と共有されます",
            "reasonPlaceholder": "拒否する理由はなんですか?",
            "title": "ドキュメントを拒否する",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "あと {{attemptsAmount}} 回試行できます",
            "attemptsAmountPlural": "あと {{attemptsAmount}} 回試行できます",
            "contentMessage": "このドキュメントにアクセスするには、送信者から提供された 5 桁のパスコードを入力してください。",
            "inputLabel": "パスコードを入力してください",
            "inputPlaceholder": "5 桁のパスコードを入力してください",
            "inputValidationMessage": "パスコードは 5 桁で指定する必要があります",
            "inputValueRequiedMessage": "このフィールドは必須です",
            "invalidPasscodeMessage": "無効なパスコードです。",
            "senderInformation": "{{senderName}} ({{senderEmail}}) によって、{<documentName>} に招待されました",
            "unlockButton": "ロック解除",
            "welcomeMessage": "ようこそ！"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "完成したドキュメントのコピーを {<email>} に送信しました",
            "downloadButton": "ドキュメントをダウンロードする",
            "notAllSignedText": "署名者全員がこのドキュメントに署名したら、署名済みのドキュメントのコピーを {<email>} に送信します",
            "reviewButton": "署名済みのドキュメントを確認する",
            "title": "送信済みのドキュメント"
          },
          "termsAndConditions": {
            "continueButton": "続行",
            "esignConsent": "電子署名による同意",
            "footerEsignConsent": "提示された {<link>} を理解し、これに同意します",
            "footerTerms": "提示された {<linkTermsOfUse>} および {<linkPrivacyPolicy>} を理解し、これに同意します",
            "footerTitle": "続行すると、次のことを確認したことになります:",
            "greetings": "{{signerName}} 様。",
            "privacyPolicy": "プライバシー ポリシー",
            "senderMessageTitle": "送信者からのメッセージ",
            "termsOfUse": "使用条件",
            "text": "{{senderName}} ({{senderEmail}}) によって、{<documentName>} に招待されました"
          }
        },
        "submitDocument": {
          "header": "ドキュメントを送信する",
          "submit": "送信",
          "submitConfirm": "このドキュメントを送信してもよろしいですか?",
          "submitWarning": "送信後は変更できません",
          "submitWithUnfilledOptionalFields": "オプションのフィールドをスキップしました。このドキュメントを送信してもよろしいですか?",
          "submitWithUnfilledRequiredFields": "必須フィールドをすべて入力してドキュメントを送信してください"
        }
      },
      "signerSequencingDisabledTooltip": "署名の順番は、署名者が複数いる場合にのみ適用されます",
      "signerSequencingNotApplicableTooltip": "署名の順番は閲覧者には適用されません",
      "signers": "署名者",
      "signersLabel": "署名者",
      "singerSequencingAppliedText": "署名者のシーケンスが適用されました",
      "sizeLimitFile": "ファイル {{filename}} ",
      "sizeLimitMessage": "20MB の制限を超えています",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "テンプレートを削除する",
              "content": "一括署名者のリストは失われるため、続行するには新しいドキュメントをアップロードする必要があります。",
              "header": "このテンプレートを削除してもよろしいですか?"
            },
            "modal": {
              "confirmButton": "ドキュメントを削除",
              "content": "ドキュメントを削除すると、事前定義された受信者とその受信者に割り当てられたフィールドがすべて削除されます。ドキュメントを削除してもよろしいですか?",
              "header": "ドキュメントを削除"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "選択",
              "createdBy": "作成者:",
              "searchPlaceholder": "Search"
            },
            "eligible": "一括送信の対象",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "テンプレートを読み込めません。後で再試行してください。",
            "footer": {
              "buttonText": "テンプレートを作成する",
              "question": "探しているものが見つからない場合。"
            },
            "noDocuments": "まだテンプレートが作成されていません。",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "検索結果はありません",
            "noSearchResultsDesc": "検索に一致する結果はないようです。",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "役割:",
            "title": "テンプレートを選択する"
          },
          "sourceName": "テンプレートを使用"
        },
        "upload": {
          "basefileProcessingFailed": "{{ filename }} ファイルの処理に失敗しました。ファイルを再度アップロードしてください",
          "failed": "{{ filename }} ファイルのアップロードに失敗しました",
          "numberLimitMessage": "5 個を超えるファイルをアップロードすることはできません"
        }
      },
      "statusFilter": "状態",
      "switch": "古いエクスペリエンスに切り替える",
      "template": "テンプレート",
      "temporaryRedirectText": "一時的に RightSignature に切り替える",
      "text": "文字列",
      "textAlignLabel": "テキストの位置を揃える",
      "type": "種類",
      "unableToFetchFileDetails": "ファイルの詳細の取得に失敗しました",
      "unableToProceedNoDocumentError": "続行できません。ドキュメントを追加してください",
      "unableToProceedNoSignerError": "続行できません。最低 1 人の署名者を追加してください",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "このドキュメント タイプはサポートされていません。サポートされているファイル タイプは、.doc、.docx、.txt、.pdf です。",
      "updateDocumentErrorMessage": "ドキュメントの更新に失敗しました。",
      "uploadDocumentErrorMessage": "ドキュメントをアップロードして次の手順に進む",
      "uploadedFilesProcessingErrorMessage": "アップロードされた 1 つ以上のファイルがまだ処理中です",
      "useSaved": "保存したものを使用する",
      "userFetchErrorMessage": "ユーザー情報の取得に失敗しました",
      "view": "ビュー",
      "viewer": "閲覧者",
      "viewerUniqueEmailErrorMessage": "閲覧者のメール アドレスは一意である必要があります",
      "viewersLabel": "閲覧者",
      "void": "取り消し",
      "voidConfirmationTitle": "取り消されたドキュメントの確認",
      "zoomIn": "拡大",
      "zoomOut": "縮小"
    }
  },
  {
    "key": "ko",
    "content": {
      "AccountLimitReached": "계정이 최대 문서 한도에 도달했습니다.",
      "AttachedByLabel": "{{signer}}님이 첨부함",
      "AttachmentDetailsLabel": "첨부 파일",
      "AttachmentFieldLabel": "첨부 파일",
      "CheckFieldLabel": "확인란",
      "CheckGroupFieldLabel": "확인란 그룹",
      "DateFieldLabel": "날짜",
      "InitialsFieldLabel": "이니셜",
      "PaymentField": {
        "chargeAfterExecutedLabel": "서명 후 청구",
        "chargedLabel": "{<paymentAmount>} 청구됨",
        "collectCardDetailsLabel": "카드 세부정보 수집",
        "defaultLabel": "결제",
        "paymentFailedLabel": "결제 실패"
      },
      "PaymentFieldLabel": "결제 필드",
      "SelectFieldLabel": "드롭다운",
      "SignatureFieldLabel": "서명",
      "TextFieldLabel": "텍스트",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "현재 요금제는 서명을 위해 보낼 수 있는 문서의 수를 제한하며, 한도에 도달했습니다. 여전히 문서에 직접 서명할 수는 있습니다.",
        "upgradeTrialBanner": "평가판에 {<documentsLeft>}개 남았습니다."
      },
      "accountLimitExhaustedMessage": "서명할 문서를 전송할 수 없습니다. 계정 한도가 소진되었습니다. 계정을 업그레이드하십시오.",
      "accountStatsFetchErrorMessage": "계정 정보를 검색하지 못함",
      "add": "추가",
      "addCheckbox": "확인란 추가",
      "addFilesFromSharefile": "ShareFile에서 항목 추가",
      "addMyself": "본인 추가",
      "addOptionLabel": "옵션 추가",
      "addRecipient": "다른 받는 사람 추가",
      "addSignature": "서명 추가",
      "annotator": "나(지금)",
      "annotatorOptionTooltip": "필드를 미리 작성하십시오",
      "apply": "적용",
      "archiveLocationBlank": "서명한 문서를 저장할 폴더 선택",
      "assignedToLabel": "할당 대상",
      "attachmentFieldLabel": "첨부 파일",
      "browseFiles": "파일 찾아보기",
      "bulkSend": "일괄 전송",
      "cancel": "취소",
      "changesSaved": "변경사항 저장됨",
      "checkbox": "확인란",
      "checkboxGroup": "확린란 그룹",
      "checkboxes": "확인란",
      "clear": "지우기",
      "clearBackground": "Clear background",
      "close": "닫기",
      "completed": "완료됨",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} selected fields",
      "confirm": "확인",
      "continue": "계속",
      "createDocument": {
        "almostDone": "Almost done. Please don't click Refresh or Back button"
      },
      "createDocumentErrorMessage": "문서를 생성하지 못함",
      "creationDateLabel": "보낸 날짜",
      "dashboard": {
        "adminView": "관리자 보기",
        "adminViewTooltipText": "이 계정에서 보낸 모든 문서 보기",
        "autoDeleteText": "Auto-deletes in {{remaining}} day(s)",
        "clearFilters": "필터 지우기",
        "clearSearch": "검색 지우기",
        "continueAction": "계속",
        "deleteAction": "삭제",
        "downloadPdf": "다운로드",
        "esignLeftNav": "서명",
        "loadMore": "추가 로드",
        "overdue": "1일 지연",
        "signatureDue": "기한 {{dueDate}}",
        "signatureRequests": "서명 요청",
        "signatureSettings": "Signature settings",
        "signaturesSubmitted": "서명 제출됨",
        "templates": "템플릿",
        "title": "서명 요청"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "필터와 일치하는 항목이 없는 것 같습니다.",
          "title": "여기에는 아무 것도 없습니다"
        },
        "noRequestsCreated": {
          "description": "서명을 받아야 한다면 요청을 생성하십시오.",
          "title": "아직 서명 요청이 없습니다"
        },
        "noSearchResults": {
          "description": "검색과 일치하는 항목이 없는 것 같습니다.",
          "title": "여기에는 아무 것도 없습니다"
        }
      },
      "date": "날짜",
      "dateExample": "예:",
      "dateExtendError": "미래의 날짜를 선택하십시오",
      "dateFormatLabel": "형식",
      "dateSignedLabel": "서명 날짜 자동 완성",
      "dateSignedTooltip": "받는 사람이 필드를 편집할 수 없습니다. 문서에 서명된 날짜가 자동으로 입력됩니다.",
      "declinedByLabel": "{{name}}에 의해 거부됨",
      "defaultBadgeLabel": "Default",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "완성된 PDF를 포함하여 모두에게 전자 메일 보내기",
          "attachCompletedPDFCheckboxTooltip": "마지막 서명 후 완성된 PDF를 첨부 파일로 포함해 모두에게 전자 메일을 보냅니다(10MB 보다 크지 않은 경우에 한함).",
          "sectionHeader": "첨부 파일"
        },
        "header": "기본 설정 편집",
        "headerInfo": "여기에서 적용하는 변경사항은 진행 중인 모든 서명 요청에도 적용됩니다.",
        "hipaaConfirmationBodyFirst": "이 설정이 적용되면 모든 받는 사람이 서명된 문서의 사본을 전자 메일 첨부 파일로 받게 됩니다. 받는 사람이 본인의 정보가 이런 식으로 처리되도록 허용하는지 확인하는 것은 HIPAA 고객의 책임입니다.",
        "hipaaConfirmationBodySecond": "이 설정을 적용하시겠습니까?",
        "hipaaConfirmationButton": "설정 적용",
        "hipaaConfirmationHeader": "HIPAA 고려 사항",
        "reminders": {
          "options": {
            "daily": "매일",
            "noAuto": "안 함",
            "weekly": "매주"
          },
          "reminderFrequencyLabel": "자동 미리 알림 보내기",
          "reminderFrequencyTooltip": "서명할 때까지 받는 사람에게 매주(3, 7, 14, 21, 29일 후) 또는 매일(7일 후) 미리 알림을 전자 메일로 보냅니다.",
          "sectionHeader": "미리 알림"
        },
        "signature": {
          "allowDrawnSignatureLabel": "그린 서명 허용",
          "allowTypedSignatureLabel": "입력한 서명 허용",
          "applyBlueInkSignatureLabel": "서명에 파란색 잉크 적용",
          "applyBlueInkSignatureTooltipAdmin": "이 설정을 변경해야 하는 경우 관리자에게 문의하십시오.",
          "applyBlueInkSignatureTooltipNonAdmin": "서명된 원본과 사본을 구별할 수 있도록 모든 서명에 파란색 잉크를 사용합니다.",
          "sectionHeader": "서명 유형"
        },
        "signer": {
          "allowDownload": "서명자가 서명 전에 원본 문서를 다운로드하도록 허용",
          "sectionHeader": "서명자 권한"
        }
      },
      "defaultSettingsDescription": "미리 알림, 전자 메일 첨부 파일, 서명 유형의 설정을 변경합니다.",
      "defaultSettingsEditLabel": "기본 설정 편집",
      "defaultSettingsLabel": "기본 설정",
      "defaultSettingsUpdateFailed": "기본 설정을 업데이트하지 못함",
      "defaultSettingsUpdateSuccess": "기본 설정 업데이트됨",
      "defaultValueLabel": "기본 텍스트 추가",
      "delete": "삭제",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "이렇게 하면 서명 요청이 영구적으로 삭제됩니다. 이 작업은 완료하면 실행 취소할 수 없습니다.",
        "deleteConfirmationMessageSecond": "서명자와 보기 권한자가 완성된 문서와 기록을 위한 서명 인증서가 포함된 전자 메일을 받습니다.",
        "deleteRequest": "요청 삭제",
        "deleteRequestSuccess": "요청 삭제됨"
      },
      "deleteDraftDocumentLabel": "서명 요청 삭제",
      "deleteDraftDocumentModalContent": "서명 요청을 삭제하시겠습니까? 이 작업은 실행 취소할 수 없습니다.",
      "deleteDraftDocumentSuccess": "서명 요청 삭제됨",
      "deleteViewerWarningHeader": "Are you sure you want to remove this recipient?",
      "deleteViewerWarningMessage": "They’ll no longer have access to this signature request.",
      "detailsTabSecurityOptions": {
        "editPasscode": "암호 수정",
        "kba": "아는 내용에 기반한 인증",
        "passcode": "암호",
        "title": "보안 옵션 적용됨",
        "updatePasscodeSuccess": "이 문서의 암호가 업데이트되었습니다."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "You can't revise requests that were sent with bulk send",
          "signerSigned": "You can't revise requests once someone has signed",
          "userNotSender": "You can't revise requests that you didn't send"
        }
      },
      "documentAlreadySentErrorDetails": "전송된 문서를 편집하기 위해 열 수 없습니다. 플로우를 새로 시작하십시오.",
      "documentAlreadySentErrorTitle": "문서가 이미 전송됨",
      "documentForSignature": "전송할 문서",
      "documentForceCompleted": "요청 강제 실행 완료됨",
      "documentLockedMessage": "이 받는 사람은 인증 시도에 여러 번 실패하여 일시적으로 잠겼습니다",
      "documentNameBlank": "문서 이름 입력",
      "documentNameInputDisabledTooltip": "문서 이름은 편집할 수 없습니다",
      "documentNameInputLabel": "문서 이름",
      "documentStates": {
        "declined": "거부됨",
        "draft": "초안",
        "editing": "수정을 위해 일시중지됨",
        "executed": "완료됨",
        "expired": "만료됨",
        "pending": "진행 중",
        "voided": "무효화됨"
      },
      "documentThumbnailAltText": "첫 번째 페이지 미리 보기",
      "documentTransactionCreateErrorMessage": "문서 거래를 생성하지 못함",
      "documentTransactionGetErrorMessage": "문서 거래를 가져오지 못함",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "문서 잠금 해제 성공",
      "documentView": {
        "documentPageIndex": "{<currentPage>}/{{totalPages}}페이지",
        "pageChangeInputLabel": "다음으로 페이지 변경",
        "pageDown": "다음 페이지",
        "pageUp": "이전 페이지"
      },
      "documentVoided": "서명 요청 무효화됨",
      "dontShowAgain": "이 메시지 다시 표시 안 함",
      "downloadOptions": {
        "combinedPDF": "결합된 PDF",
        "completedPDF": "완료된 PDF",
        "download": "다운로드",
        "downloadErrorMessage": "지원되지 않는 다운로드 유형: {{downloadType}}",
        "downloadOriginal": "원본 PDF",
        "signatureCertificate": "서명 인증서"
      },
      "draftSaveErrorMessage": "서명 요청을 저장하지 못함",
      "draftSaveSuccessMessage": "서명 요청 저장됨",
      "draw": "그리기",
      "dueDate": "기한",
      "dueDateUpdated": "기한 업데이트됨",
      "edit": "편집",
      "editDocumentModal": {
        "cancel": "뒤로 이동",
        "confirm": "수정",
        "contentLine1": "받는 사람 또는 필드를 추가하거나 제거해야 하는 경우 또는 보안 옵션을 변경해야 하는 경우 이 요청을 수정할 수 있습니다.",
        "contentLine2": "수정하는 동안 요청은 일시중지됩니다. 따라서 아직 서명하지 않은 모든 사람이 일시적으로 액세스할 수 없게 됩니다. 수정이 완료되면 새 링크를 보내 드립니다.",
        "contentLine3": "수정을 시작하면 수정된 문서를 보내지 않은 채 요청 일시중지를 해제할 수 없습니다.",
        "title": "이 요청을 수정하시겠습니까?"
      },
      "editDueDate": "기한 편집",
      "editLeftMidwayModal": {
        "cancel": "뒤로 이동",
        "confirm": "나중에 완료",
        "contentLine1": "개정을 위해 일시중지된 동안 서명자는 이 요청에 액세스할 수 없습니다. 완료하면 돌아가서 다음을 선택하십시오 ",
        "contentLine2": "준비가 되지 않았다면 돌아와서 나중에 수정을 완료해도 됩니다.",
        "title": "아직 수정 중이신가요?"
      },
      "editRecipients": "받는 사람 편집",
      "editSignatureRequest": "수정",
      "editSigner": "Edit signer",
      "editViewer": "Edit viewer",
      "emptyFolder": "이 폴더는 비어 있습니다.",
      "enforceStorageUsage": "일부 공간을 확보할 때까지 새로운 서명 요청을 생성할 수 없습니다",
      "expirationDateMessage": "만료 날짜: {{expirationDate}}",
      "expirationDaysLabel": "일",
      "expirationInputDescription": "지정된 일수가 지나면 문서에 더 이상 서명할 수 없음:",
      "expirationInputLabel": "만료",
      "extendDueDate": "기한 연장",
      "extendExpirationDate": "확장",
      "favorites": "즐겨찾기",
      "field": "필드",
      "fieldNameLabel": "이름 필드",
      "fieldRequired": "이 필드는 필수입니다",
      "fields": "필드",
      "fieldsAssignedWarningHeader": "필드가 서명자에게 할당됨",
      "fieldsAssignedWarningMessage": "서명자에게 할당된 모든 필드가 삭제됩니다.",
      "fieldsDescription": "문서에 배치할 필드 유형 선택",
      "fileArchivalConfigurator": {
        "editButtonLabel": "편집",
        "sectionLabel": "서명된 문서를 어디에 저장하시겠습니까?"
      },
      "fileNotSupportedTooltip": "지원되지 않는 파일 형식",
      "filesAndRecipients": "파일 및 받는 사람",
      "filterSelected": "필터 1개",
      "forceComplete": "강제 실행 완료",
      "forceCompleteTitle": "강제 실행 완료 요청",
      "forceCompleteWarningFirst": "이렇게 하면 아직 서명하지 않은 서명자에게 할당된 모든 필드가 삭제되고 문서를 실행된 상태(최종 상태)로 만듭니다. 이 작업은 완료하면 실행 취소할 수 없습니다.",
      "forceCompleteWarningSecond": "새로운 상태가 대시보드에 반영되기까지 잠시 시간이 걸릴 수 있습니다.",
      "generateNewPasscodeButtonLabel": "새 암호 생성",
      "genericError": "오류 발생",
      "groupRule": "그룹 규칙",
      "hasAtleastOneSignerErrorMessage": "계속 진행하려면 한 명 이상의 서명자 추가",
      "importFields": {
        "linkText": "Import fields",
        "modal": {
          "assignImportedFields": "Assign Imported Fields",
          "body": {
            "buttonText": "Select",
            "searchPlaceholder": "Search by document name, file name, sender or role",
            "sentBy": "Sent by"
          },
          "componentsImported": "{{count}} fields imported",
          "fields": "field(s)",
          "importFieldsButtonText": "Import fields",
          "importFrom": "Import from:",
          "mapAllRecipients": "Assign fields to new recipients to continue",
          "newRecipient": "New recipient",
          "noDocuments": "You don’t have any documents yet",
          "originalRecipient": "Original recipient",
          "selectDifferentDocument": "Select different document",
          "selectRecipient": "Select recipient",
          "sentBy": "Sent by",
          "title": "Select a document to import fields",
          "titleImportFields": "Import fields",
          "titleImportFieldsWithCount": "Import {{count}} fields"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Go back",
          "contentLine1": "{<documentName>} doesn't have any fields that we can import. You can choose a different document or create new fields.",
          "title": "This document doesn't have any fields"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Go back",
          "confirmButtonText": "Import fields anyway",
          "contentLine1": "The document you selected has a different number of pages than the one you're currently working on.",
          "contentLine2": "You can still import fields, but we'll only look at the first {{pageCount}} page(s) of the selected document. Any fields on subsequent pages won't be imported.",
          "title": "These documents look a little different"
        },
        "tooltipText": "Import fields from previously sent documents"
      },
      "initials": "이니셜",
      "invalidEmail": "잘못된 전자 메일",
      "invalidExpirationDaysError": "1에서 365 사이의 숫자 입력",
      "invalidExpirationDaysNotificationMessage": "만료일 선택",
      "invalidRecipientErrorMessage": "받는 사람마다 이름과 전자 메일을 입력해야 합니다",
      "invalidSignatureRequest": "서명 요청이 잘못된 것 같습니다. URL을 확인하십시오.",
      "invite": "Invite",
      "inviteViewers": "Invite viewers",
      "kbaDescription": "받는 사람이 아는 내용을 바탕으로 퀴즈에 응답해 신원을 확인해야 합니다. 각 서명자는 문서에 서명하기 전에 개별적으로 확인해야 합니다.",
      "kbaInfoMessage": "3회의 시도가 실패하면 문서가 잠겨 서명자가 액세스할 수 없습니다.",
      "kbaLabel": "아는 내용에 기반한 인증",
      "legacyTokenInterceptor": {
        "buttonText": "로그인",
        "message": "중단한 지점부터 다시 시작할 수 있도록 여기로 바로 이동합니다.",
        "title": "서명 요청에 액세스하려면 다시 로그인하십시오"
      },
      "messageLabel": "수신자에게 보내는 메시지",
      "mixedAlignment": "Mixed alignment",
      "mixedDateFormat": "Mixed format",
      "mixedFontSizes": "Mixed font sizes",
      "moveAwaySelections": "현재 폴더를 벗어나면 선택 사항이 손실됩니다.",
      "multiLineLabel": "여러 줄 허용",
      "multipleRecipients": "Multiple recipients",
      "nameGroup": "이름 그룹",
      "nextStep": "다음 단계",
      "optional": "선택 사항",
      "optionalComment": "첨부된 의견(선택 사항)",
      "optionsLabel": "옵션",
      "packagerConfirmationModal": {
        "contentLine1": "서명을 준비하기 전에 문서를 하나의 파일로 합쳐야 합니다. 다음 단계로 진행하면 돌아가서 삭제할 수 없습니다.",
        "contentLine2": "계속하시겠습니까?",
        "mergeModalText": "이 작업에는 몇 분 정도 걸릴 수 있습니다. 문서가 준비되는 대로 다음 단계로 안내해 드리겠습니다.",
        "progressBarText": "병합 중...",
        "title": "문서 병합"
      },
      "passcodeCharacterWarning": "암호에는 숫자만 포함될 수 있습니다.",
      "passcodeDescription": "문서에 액세스하려면 받는 사람은 {<fiveDigit>} 암호를 입력해야 합니다.",
      "passcodeFiveDigit": "5자리",
      "passcodeInfoMessage": "각 받는 사람과 이 암호를 공유해야 합니다.",
      "passcodeLabel": "암호",
      "passcodeLengthError": "암호 선택 또는 보안 옵션 비활성화",
      "passcodeLengthErrorEditMode": "암호는 5자리여야 합니다",
      "pending": "보류 중",
      "personalFolders": "개인 폴더",
      "placeFields": "필드 배치",
      "prepareDocument": {
        "components": {
          "attachment": "첨부 파일",
          "checkmarkAnnotation": "선택 표시 추가",
          "editor": {
            "chargeAfterExecutedTooltip": "모든 서명자가 서명한 후 카드에 청구하려면 이 옵션 사용",
            "collectCreditCardTooltip": "카드 정보만 수집하려면 이 옵션 사용",
            "defaultHelpText": {
              "attachmentComponent": "여기에 첨부 파일 업로드",
              "paymentComponent": "여기에 카드 세부정보 추가"
            },
            "fieldSubtypeLabel": "필드 하위 유형",
            "fontSizeLabel": "글꼴 크기",
            "helpTextLabel": "도움말 텍스트 추가",
            "paymentAmountLabel": "결제 금액(USD)",
            "paymentAmountPlaceHolder": "결제 금액 입력"
          },
          "textAnnotation": "텍스트 추가"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "드롭다운마다 최소 2가지 옵션이 있어야 합니다",
          "paymentAmountInvalidError": "계속하려면 결제 금액 입력"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Signers don't have anything to sign.",
          "cancelButtonText": "뒤로 이동",
          "confirmButtonText": "계속 진행",
          "description": "다음 단계로 계속 진행하거나 돌아와서 작성할 필드를 추가할 수도 있습니다.",
          "subtitle": "다음 서명자는 서명할 내용이 없습니다.",
          "title": "잊어버린 필드가 있습니까?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Document ID",
          "dueDate": "Due Date",
          "otherDetails": "Other details",
          "recipients": "Recipients",
          "sender": "Sender",
          "sentOn": "Sent on",
          "signerSequencing": "Signer sequencing applied",
          "signers": "Signers"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "View details"
        },
        "downloadModal": {
          "combinedDescription": "Includes signature cerificate and completed document",
          "combinedTitle": "Combined PDF",
          "completedDocumentDescription": "Signed document with completed fields",
          "completedDocumentTitle": "Completed document",
          "done": "Done",
          "originalDescription": "PDF document without any signer fields",
          "originalTitle": "Original PDF",
          "signatureCertificateDescription": "Certificate of trust from Sharefile",
          "signatureCertificateTitle": "Signature certificate",
          "title": "Download document"
        },
        "pageHeader": {
          "completed": "Completed",
          "download": "Download",
          "downloadOriginalDocument": "Download original document",
          "inProgress": "In Progress"
        }
      },
      "previousStep": "이전 단계",
      "processingState": "처리 중...",
      "radio": "라디오 그룹",
      "readyState": "준비!",
      "reasonDeclineLabel": "거부 이유",
      "reasonVoidLabel": "무효화 이유",
      "recipientDuplicate": "이 사람은 이미 보기 권한자입니다",
      "recipientEmail": "전자 메일",
      "recipientListHeading": "받는 사람은 누구입니까?",
      "recipientName": "이름",
      "recipientNoName": "이 필드는 필수입니다",
      "recipientRemindButtonLabel": "알림",
      "recipientRole": "역할",
      "recipientRoleSigner": "서명자",
      "recipientRoleViewer": "보기 권한자",
      "recipientStatusSigned": "서명됨",
      "recipientUnlockLabel": "잠금 해제",
      "recipientsLabel": "받는 사람",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "곧 ShareFile에서 받는 사람의 CSV를 업로드하여 한 번에 최대 300명의 서명을 요청할 수 있게 됩니다. 그때까지는 RightSignature에서 이 작업을 수행할 수 있습니다.",
          "closeButton": "취소",
          "header": "일괄 전송을 원하십니까?",
          "redirectButton": "RightSignature로 이동"
        },
        "packager": {
          "bodyLineOne": "곧 ShareFile에서 여러 문서를 하나의 서명 요청으로 보낼 수 있게 됩니다. 그때까지는 RightSignature에서 이 작업을 수행할 수 있습니다.",
          "closeButton": "취소",
          "header": "여러 문서를 보내야 합니까?",
          "redirectButton": "RightSignature로 이동"
        },
        "template": {
          "bodyLineOne": "곧 ShareFile에서 서명 템플릿을 만들고 사용할 수 있게 됩니다.",
          "bodyLineTwo": "RightSignature에서 사용해 보며 대비하십시오.",
          "closeButton": "나중에",
          "header": "템플릿으로 프로세스 간소화",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "템플릿 사용해 보기"
        }
      },
      "referenceIdLabel": "참조 ID",
      "reminderSent": "미리 알림 전송됨",
      "removeBulkSignersConfirmationModal": {
        "confirm": "서명자 제거",
        "content": "{<fileName>}에서 모든 서명자가 제거됩니다.",
        "title": "이 서명자를 제거하시겠습니까?"
      },
      "removeCheckboxGroup": "확인란 그룹 제거",
      "removeFieldLabel": "필드 제거",
      "removeFileDisabledTooltip": "문서를 제거할 수 없습니다",
      "removeFileFailed": "파일을 제거할 수 없습니다. 다시 시도하십시오.",
      "requestSignatureButton": {
        "label": "서명 요청 생성"
      },
      "requireGroup": "그룹 필요",
      "required": "필수",
      "requiredFieldLabel": "필드 필요",
      "requiredFieldsLabel": "Require fields",
      "reviewAndSend": "검토 및 보내기",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "이 필드는 비워둘 수 없습니다"
          }
        }
      },
      "save": "저장",
      "saveCompletedDocument": "완성된 문서 저장",
      "savedSignaturesLabel": "Select the signature to apply in the document",
      "search": "검색",
      "securityOptionsLabel": "보안 옵션",
      "selectOneOrMoreLabel": "하나 이상 선택",
      "selectOnlyOneLabel": "하나만 선택",
      "selfSignCheckDisabledToolTip": "템플릿 선택 시 셀프 서명을 사용할 수 없습니다",
      "selfSignCheckLabel": "유일한 서명자임",
      "selfSignCheckToolTip": "문서에 직접 서명한 다음 공유하십시오. 그러면 선택한 폴더에 저장됩니다.",
      "selfSignSucessMessage": "문서 저장됨",
      "selfSignature": "서명",
      "sendForSignature": "서명을 위해 보내기",
      "sendInBulk": {
        "allSigners": "총 서명자 {<signersCount>}명",
        "bulkSigners": "일괄 서명자",
        "couldTakeAMoment": "잠시 시간이 걸릴 수 있습니다.",
        "disableTooltipTextForPLG": "Upgrade your plan to get access",
        "downloadCSVTemplateButton": "CSV 템플릿 다운로드",
        "email": "전자 메일",
        "errors": {
          "correctTheFollowingError": "파일에서 다음 오류를 수정하고 다시 시도하십시오.",
          "exceedsRecipientsLimit": "{<fileName>} 파일은 서명자 제한인 300명을 초과합니다.",
          "fileSizeExceeded": "파일이 5MB 제한보다 큽니다.",
          "onlyOneFile": "단일 파일에서만 서명자를 가져올 수 있습니다.",
          "supportedFileType": ".csv 파일에서만 서명자를 가져올 수 있습니다.",
          "unableToLoadSpreadsheet": "파일에 중요한 일부 정보가 누락되어 있습니다."
        },
        "gatheringSignersFromFile": "파일에서 서명자를 수집하는 중...",
        "goBack": "뒤로 이동",
        "import": "가져오기",
        "importSigners": "서명자 가져오기",
        "importSignersModalText1": "CSV 템플릿이 준비되었습니다. 다운로드하여 이름과 전자 메일 필드(필수 항목)을 작성해 주십시오.",
        "importSignersModalText2": "CSV만 지원되는 파일 형식입니다. 서명자 수는 300명을 초과할 수 없습니다.",
        "name": "이름",
        "note": "참고:",
        "previewingTheSigners": "총 서명자 {<signersCount>} 중 첫 {<firstSignersCount>} 미리보기",
        "selectDifferentFile": "다른 파일 선택",
        "title": "일괄 전송",
        "tooltipText": "일괄 전송은 단일 서명자 템플릿에서만 사용할 수 있습니다.",
        "uploadFileText": "여기에 CSV 끌기 또는"
      },
      "sendRevisedRequest": "수정된 요청 보내기",
      "sendSignatureRequest": "서명 요청 보내기",
      "sendSigningLink": {
        "gotIt": "확인",
        "sentLinkInfoBanner": "링크를 보내드렸습니다",
        "signingLinkInfoMessage1": "{<email>} 전자 메일에서 이 문서에 서명할 수 있는 링크를 확인하십시오.",
        "signingLinkInfoMessage2": "곧 Sharefile에서 직접 서명할 수 있게 됩니다."
      },
      "senderRoleChangeTooltip": "발신자는 보기 권한자가 될 수 없음",
      "sentByLabel": "보낸 사람",
      "setSigningOrder": "서명 순서 설정",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "취소",
          "createFolderLabel": "폴더 만들기",
          "disabledNodeUploadReason": "Upload permission is needed to select this folder",
          "disabledSharedFoldersReason": "최상위 공유 폴더를 선택할 수 없음",
          "modalTitle": "서명된 계약서를 다음 위치에 저장...",
          "selectFolderButtonLabel": "선택"
        }
      },
      "sharedFolders": "공유 폴더",
      "showAll": "모두 표시",
      "signButtonLabel": "서명",
      "signYourself": "자기 서명",
      "signature": "서명",
      "signatureAnnotationMessage": "클릭한 후 드래그하여 상자 안에 서명하십시오",
      "signatureAnnotationTextMessage": "아래 필드에 서명 입력",
      "signatureRequest": "서명 요청",
      "signatureRequestErrorMessage": "서명 요청 실패함",
      "signatureRequestSuccessMessage": "보낸 서명 요청",
      "signer": "Signer",
      "signerDescription": {
        "annotation": "Place fields for yourself and fill them out in advance",
        "bulkSigners": "You're placing fields for all signers",
        "signers": "You’ll place fields for each signer"
      },
      "signerMessageInputLabel": "모든 서명자 참고 사항(선택사항)",
      "signerMessageInputLabelTooltip": "서명자에게 전송할 전자 메일 알림에 이 메모를 포함합니다",
      "signerMessageInputPlaceholder": "모든 서명자에게 개인화된 참고 사항 추가",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Document name and ID",
            "dueDateTitle": "Due date",
            "senderInfoTitle": "Sender",
            "senderMessageTitle": "Message from sender"
          },
          "fields": {
            "addInitials": "Add Initials",
            "attachment": {
              "buttonText": "Attach file",
              "fileUploadingError": "Unable to upload file. Please try again",
              "modalButtonContinue": "Done",
              "modalDeleteFileCancelButton": "Cancel",
              "modalDeleteFileOkButton": "Delete",
              "modalDeleteFileText": "Are you sure you want to delete {<fileName>}?",
              "modalDeleteFileTitle": "Delete file",
              "modalTitle": "Upload file",
              "modalUploadAnotherFileText": "and upload new file?",
              "uploadDifferentFileButton": "Upload different file",
              "uploadHint": "Single file",
              "uploadText": "Click or drag file to this area to upload",
              "uploadingStatus": "Uploading"
            },
            "autofilled": "Autofilled",
            "dropdown": {
              "clearSelection": "Clear selection",
              "selectOption": "Select option"
            },
            "enterText": "Enter Text",
            "format": "Format",
            "optional": "Optional",
            "paymentField": {
              "amount": "Amount",
              "authorizePayment": "Authorize payment",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Change",
              "clear": "Clear",
              "creditCardModalDetails": {
                "authorizeMessage": "I agree to be charged {<paymentAmount>} after submitting this document",
                "cvcTooltipMessage": "A three or four-digit security code printed on the back (or front for AMEX) of your credit card",
                "labels": {
                  "cardNumber": "Card Number",
                  "cvc": "CVC",
                  "expiryDate": "Expiry Date"
                },
                "modalTitle": "Edit card details",
                "placeholders": {
                  "cardNumber": "Enter card number",
                  "cvc": "Enter CVC"
                }
              },
              "creditDetails": "Enter card details"
            },
            "required": "Required",
            "selectDate": "Select date",
            "signature": {
              "clearSignatureButtonText": "Clear signature",
              "editSignatureButtonText": "Edit signature",
              "openSignatureModalText": "Sign here",
              "prefilledSignatureText": "For your convenience, we’ve pre-filled your name. You can change it if you need to."
            }
          },
          "navigation": {
            "next": "Next",
            "nextField": "Next field",
            "startSigning": "Start signing"
          },
          "progressBar": {
            "shortSuccessText": "You’ve completed all required fields",
            "status": "{{fieldsFilled}}/{{totalFields}} required fields",
            "submitAction": "Submit",
            "successText": "You’ve completed all required fields. Once you’re done reviewing, you can {<submitAction>} the document."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Track your progress"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "You have no fields to fill out",
              "optionalCount": "{{count}} optional field",
              "optionalOnly": "You have {<optionalCountLabel>} to fill out",
              "pending": "Pending",
              "requiredAndOptional": "You have {<requiredCountLabel>} and {<optionalCountLabel>} to fill out",
              "requiredCount": "{{count}} required field",
              "requiredOnly": "You have {<requiredCountLabel>} to fill out"
            },
            "label": {
              "details": "Details",
              "fields": "Fields"
            }
          }
        },
        "declineDocument": {
          "header": "Declined document",
          "message": "You have declined this document. If you still wish to sign it, please ask sender to send a new document",
          "sender": "Sender"
        },
        "header": {
          "documentActions": {
            "actions": "Actions",
            "actionsAndDetails": "Actions and details",
            "declineDocument": "Decline document",
            "declineFailed": "We couldn't decline the document. Try again",
            "downloadOriginal": "Download original PDF",
            "saveFailed": "We couldn't save your progress. Try again",
            "saveProgress": "Save progress",
            "saveSuccess": "Saved progress"
          },
          "moreActions": "More actions"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Authentication Failed!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancel",
            "decline": "Decline",
            "reason": "Reason",
            "reasonHelp": "This reason will be shared with the sender",
            "reasonPlaceholder": "Why are you declining?",
            "title": "Decline document",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} attempt remaining",
            "attemptsAmountPlural": "{{attemptsAmount}} attempts remaining",
            "contentMessage": "Enter the 5-digit passcode provided by the sender to access this document.",
            "inputLabel": "Enter passcode",
            "inputPlaceholder": "Enter 5 digit passcode",
            "inputValidationMessage": "Passcode must be 5 digits",
            "inputValueRequiedMessage": "This field is required",
            "invalidPasscodeMessage": "Invalid passcode.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}",
            "unlockButton": "Unlock",
            "welcomeMessage": "Welcome!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "We sent a copy of the completed document to {<email>}",
            "downloadButton": "Download document",
            "notAllSignedText": "We will send a copy of the signed document to {<email>} once all signers have signed this document",
            "reviewButton": "Review signed document",
            "title": "Submitted document"
          },
          "termsAndConditions": {
            "continueButton": "Continue",
            "esignConsent": "E-Sign consent",
            "footerEsignConsent": "You're acquainted with and agree to our {<link>}",
            "footerTerms": "You're acquainted with and agree to our {<linkTermsOfUse>} and {<linkPrivacyPolicy>}",
            "footerTitle": "By continuing, you confirm that:",
            "greetings": "Hi {{signerName}},",
            "privacyPolicy": "Privacy Policy",
            "senderMessageTitle": "Message from sender",
            "termsOfUse": "Terms of Use",
            "text": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Submit document",
          "submit": "Submit",
          "submitConfirm": "Are you sure you're ready to submit this document?",
          "submitWarning": "You won't be able to make changes after submitting",
          "submitWithUnfilledOptionalFields": "You skipped an optional field. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Complete all required fields to submit the document"
        }
      },
      "signerSequencingDisabledTooltip": "서명 순서는 서명자가 1명 이상인 경우에만 적용됩니다",
      "signerSequencingNotApplicableTooltip": "보기 권한자에게는 서명 순서가 적용되지 않습니다",
      "signers": "서명자",
      "signersLabel": "서명자",
      "singerSequencingAppliedText": "서명자 시퀀싱이 적용됨",
      "sizeLimitFile": "파일 {{filename}} ",
      "sizeLimitMessage": "20MB 한도를 초과함",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Remove template",
              "content": "The bulk signers list will be lost and you'll need to upload a new document to proceed.",
              "header": "Are you sure you want to remove this template"
            },
            "modal": {
              "confirmButton": "문서 제거",
              "content": "문서를 제거하면 사전 정의된 받는 사람과 할당된 필드가 모두 삭제됩니다. 문서를 제거하시겠습니까?",
              "header": "문서 제거"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "선택",
              "createdBy": "만든 사람:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Eligible for bulk send",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "템플릿을 로드하지 못했습니다. 나중에 다시 시도하십시오",
            "footer": {
              "buttonText": "템플릿 만들기",
              "question": "원하는 항목을 찾을 수 없습니까?"
            },
            "noDocuments": "아직 템플릿이 없습니다",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "여기에는 아무 것도 없습니다",
            "noSearchResultsDesc": "검색과 일치하는 항목이 없는 것 같습니다.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Roles:",
            "title": "템플릿 선택"
          },
          "sourceName": "템플릿 사용"
        },
        "upload": {
          "basefileProcessingFailed": "{{ filename }} 파일을 처리하지 못했습니다. 파일을 다시 업로드하십시오",
          "failed": "{{ filename }} 파일을 업로드하지 못했습니다",
          "numberLimitMessage": "파일을 5개 이상 업로드할 수 없습니다"
        }
      },
      "statusFilter": "상태",
      "switch": "Switch to old experience",
      "template": "템플릿",
      "temporaryRedirectText": "Temporarily switch to RightSignature",
      "text": "텍스트",
      "textAlignLabel": "텍스트 정렬",
      "type": "유형",
      "unableToFetchFileDetails": "파일 세부정보를 가져오지 못함",
      "unableToProceedNoDocumentError": "계속할 수 없습니다. 문서를 추가하십시오.",
      "unableToProceedNoSignerError": "계속할 수 없습니다. 서명자를 한 명 이상 추가하십시오.",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "이 문서 유형은 지원되지 않습니다. 지원되는 파일 유형은 .doc, .docx, .txt, .pdf입니다.",
      "updateDocumentErrorMessage": "문서를 업데이트하지 못했습니다.",
      "uploadDocumentErrorMessage": "다음 단계로 이동하려면 문서 업로드",
      "uploadedFilesProcessingErrorMessage": "업로드된 파일 하나 이상이 아직 처리 중입니다",
      "useSaved": "Use saved",
      "userFetchErrorMessage": "사용자 정보를 검색하지 못함",
      "view": "보기",
      "viewer": "Viewer",
      "viewerUniqueEmailErrorMessage": "보기 권한자 전자 메일은 고유해야 합니다",
      "viewersLabel": "보기 권한자",
      "void": "무효화",
      "voidConfirmationTitle": "문서 확인 무효화",
      "zoomIn": "확대",
      "zoomOut": "축소"
    }
  },
  {
    "key": "nl",
    "content": {
      "AccountLimitReached": "Het account heeft de maximale documentlimiet bereikt.",
      "AttachedByLabel": "Bijgevoegd door {{signer}}",
      "AttachmentDetailsLabel": "Bijlagen",
      "AttachmentFieldLabel": "Bijlage",
      "CheckFieldLabel": "Selectievakje",
      "CheckGroupFieldLabel": "Groep selectievakjes",
      "DateFieldLabel": "Datum",
      "InitialsFieldLabel": "Initialen",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Afschrijven na ondertekening",
        "chargedLabel": "{<paymentAmount>} afgeschreven",
        "collectCardDetailsLabel": "Kaartgegevens verzamelen",
        "defaultLabel": "Betaling",
        "paymentFailedLabel": "Betaling mislukt"
      },
      "PaymentFieldLabel": "Betalingsveld",
      "SelectFieldLabel": "Keuzelijst",
      "SignatureFieldLabel": "Handtekening",
      "TextFieldLabel": "Tekst",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Uw huidige abonnement beperkt het aantal documenten dat ter ondertekening kan worden verzonden en u hebt de limiet bereikt. U kunt documenten nog steeds zelf ondertekenen.",
        "upgradeTrialBanner": "U hebt nog {<documentsLeft>} in uw proefperiode."
      },
      "accountLimitExhaustedMessage": "Kan het document niet ter ondertekening verzenden, omdat het account de limiet heeft bereikt. Gelieve het account te upgraden.",
      "accountStatsFetchErrorMessage": "Kan accountgegevens niet ophalen",
      "add": "Toevoegen",
      "addCheckbox": "Selectievakje toevoegen",
      "addFilesFromSharefile": "Items toevoegen uit ShareFile",
      "addMyself": "Mezelf toevoegen",
      "addOptionLabel": "Optie toevoegen",
      "addRecipient": "Andere ontvanger toevoegen",
      "addSignature": "Handtekening toevoegen",
      "annotator": "U (nu)",
      "annotatorOptionTooltip": "Velden van tevoren invullen",
      "apply": "Toepassen",
      "archiveLocationBlank": "Selecteer een map om het ondertekende document op te slaan",
      "assignedToLabel": "Toegewezen aan",
      "attachmentFieldLabel": "Bijlage",
      "browseFiles": "Door bestanden bladeren",
      "bulkSend": "Bulkverzending",
      "cancel": "Annuleren",
      "changesSaved": "Wijzigingen opgeslagen",
      "checkbox": "Selectievakje",
      "checkboxGroup": "Groep selectievakjes",
      "checkboxes": "Selectievakjes",
      "clear": "Wissen",
      "clearBackground": "Clear background",
      "close": "Sluiten",
      "completed": "Voltooid",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} geselecteerde velden",
      "confirm": "Bevestigen",
      "continue": "Doorgaan",
      "createDocument": {
        "almostDone": "Bijna klaar. Klik niet op de knop Vernieuwen of Terug"
      },
      "createDocumentErrorMessage": "Document kan niet worden gemaakt",
      "creationDateLabel": "Verzonden op",
      "dashboard": {
        "adminView": "Beheerweergave",
        "adminViewTooltipText": "Alle documenten weergeven die vanaf dit account zijn verzonden",
        "autoDeleteText": "Automatische verwijderingen over {{remaining}} dag(en)",
        "clearFilters": "Filters wissen",
        "clearSearch": "Zoekopdracht wissen",
        "continueAction": "Doorgaan",
        "deleteAction": "Verwijderen",
        "downloadPdf": "Downloaden",
        "esignLeftNav": "Handtekeningen",
        "loadMore": "Meer laden",
        "overdue": "1 dag te laat",
        "overdue_plural": "{{count}} dagen te laat",
        "signatureDue": "Vereist {{dueDate}}",
        "signatureRequests": "Handtekeningaanvragen",
        "signatureSettings": "Instellingen voor handtekeningen",
        "signaturesSubmitted": "Handtekeningen verzonden",
        "templates": "Sjablonen",
        "title": "Handtekeningaanvragen"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Er is niets dat overeenkomt met uw filters.",
          "title": "Er is hier niets"
        },
        "noRequestsCreated": {
          "description": "Hebt u een handtekening nodig? Maak een aanvraag.",
          "title": "U hebt nog geen handtekeningaanvragen"
        },
        "noSearchResults": {
          "description": "Er is niets dat overeenkomt met uw zoekopdracht.",
          "title": "Er is hier niets"
        }
      },
      "date": "Datum",
      "dateExample": "Voorbeeld:",
      "dateExtendError": "Kies een datum in de toekomst",
      "dateFormatLabel": "Notatie",
      "dateSignedLabel": "Datum van automatisch invullen ondertekend",
      "dateSignedTooltip": "Een ontvanger kan het veld niet bewerken. Het wordt automatisch ingevuld op de dag dat het document wordt ondertekend.",
      "declinedByLabel": "Afgewezen door {{name}}",
      "defaultBadgeLabel": "Standaard",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Iedereen een e-mail met de ingevulde PDF sturen",
          "attachCompletedPDFCheckboxTooltip": "Na de laatste handtekening sturen we iedereen een e-mail met de ingevulde PDF als bijlage (tenzij deze groter is dan 10 MB).",
          "sectionHeader": "Bijlagen"
        },
        "header": "Standaardinstellingen bewerken",
        "headerInfo": "Alle wijzigingen die u hier aanbrengt, worden toegepast op alle lopende handtekeningaanvragen.",
        "hipaaConfirmationBodyFirst": "Alle ontvangers ontvangen een kopie van het ondertekende document als e-mailbijlage wanneer deze instelling wordt toegepast. Als HIPAA-klant is het uw verantwoordelijkheid om te bevestigen dat ontvangers toestaan dat hun gegevens op deze manier worden verwerkt.",
        "hipaaConfirmationBodySecond": "Weet u zeker dat u deze instelling wilt toepassen?",
        "hipaaConfirmationButton": "Instelling toepassen",
        "hipaaConfirmationHeader": "HIPAA-overwegingen",
        "reminders": {
          "options": {
            "daily": "Dagelijks",
            "noAuto": "Nooit",
            "weekly": "Wekelijks"
          },
          "reminderFrequencyLabel": "Automatische herinneringen verzenden",
          "reminderFrequencyTooltip": "Wekelijks e-mailherinneringen aan ontvangers sturen (na 3, 7, 14, 21 en 28 dagen) of dagelijks (na 7 dagen) totdat ze ondertekenen.",
          "sectionHeader": "Herinneringen"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Getekende handtekeningen toestaan",
          "allowTypedSignatureLabel": "Getypte handtekeningen toestaan",
          "applyBlueInkSignatureLabel": "Handtekeningen met blauwe inkt",
          "applyBlueInkSignatureTooltipAdmin": "Neem contact op met de beheerder als deze instelling moet worden gewijzigd.",
          "applyBlueInkSignatureTooltipNonAdmin": "Gebruik blauwe inkt voor alle handtekeningen om ondertekende originelen van fotokopieën te onderscheiden.",
          "sectionHeader": "Typen handtekeningen"
        },
        "signer": {
          "allowDownload": "Toestaan dat ondertekenaars het originele document downloaden voordat ze ondertekenen",
          "sectionHeader": "Machtigingen voor ondertekenaars"
        }
      },
      "defaultSettingsDescription": "Wijzig uw instellingen voor herinneringen, e-mailbijlagen en handtekeningtypen.",
      "defaultSettingsEditLabel": "Standaardinstellingen bewerken",
      "defaultSettingsLabel": "Standaardinstellingen",
      "defaultSettingsUpdateFailed": "Kan de standaardinstellingen niet bijwerken",
      "defaultSettingsUpdateSuccess": "Standaardinstellingen bijgewerkt",
      "defaultValueLabel": "Standaardtekst toevoegen",
      "delete": "Verwijderen",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Hiermee wordt de handtekeningaanvraag permanent verwijderd. Nadat dit is gebeurd, kan het niet ongedaan worden gemaakt.",
        "deleteConfirmationMessageSecond": "Ondertekenaars en waarnemers ontvangen een e-mail met het ingevulde document en een handtekeningcertificaat voor hun administratie.",
        "deleteRequest": "Aanvraag verwijderen",
        "deleteRequestSuccess": "Aanvraag verwijderd"
      },
      "deleteDraftDocumentLabel": "Handtekeningaanvraag verwijderen",
      "deleteDraftDocumentModalContent": "Weet u zeker dat u de handtekeningaanvraag wilt verwijderen? Dit kan niet ongedaan worden gemaakt.",
      "deleteDraftDocumentSuccess": "Handtekeningaanvraag verwijderd",
      "deleteViewerWarningHeader": "Weet u zeker dat u deze ontvanger wilt verwijderen?",
      "deleteViewerWarningMessage": "Ze hebben geen toegang meer tot dit ondertekeningsverzoek.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Wachtwoordcode bewerken",
        "kba": "Op kennis gebaseerde verificatie",
        "passcode": "Wachtwoordcode",
        "title": "Beveiligingsoptie toegepast",
        "updatePasscodeSuccess": "De wachtwoordcode van dit document is bijgewerkt."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "U kunt aanvragen die met bulkverzending zijn verzonden niet herzien",
          "signerSigned": "U kunt aanvragen niet meer herzien nadat iemand heeft ondertekend",
          "userNotSender": "U kunt aanvragen die u niet heeft verzonden niet herzien"
        }
      },
      "documentAlreadySentErrorDetails": "Kan het verzonden document niet openen om te bewerken. Start een nieuwe flow.",
      "documentAlreadySentErrorTitle": "Document is al verzonden",
      "documentForSignature": "Te verzenden document",
      "documentForceCompleted": "Aanvraag afdwingen voltooid",
      "documentLockedMessage": "Deze ontvanger is tijdelijk uitgesloten vanwege meerdere mislukte verificatiepogingen",
      "documentNameBlank": "Voer een documentnaam in",
      "documentNameInputDisabledTooltip": "De documentnaam kan niet worden bewerkt",
      "documentNameInputLabel": "Documentnaam",
      "documentStates": {
        "declined": "Afgewezen",
        "draft": "Concept",
        "editing": "Onderbroken voor revisie",
        "executed": "Voltooid",
        "expired": "Verlopen",
        "pending": "In uitvoering",
        "voided": "Nietig verklaard"
      },
      "documentThumbnailAltText": "Miniatuur van eerste pagina",
      "documentTransactionCreateErrorMessage": "Kan documenttransactie niet maken",
      "documentTransactionGetErrorMessage": "Kan documenttransactie niet ophalen",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Documentontgrendeling gelukt",
      "documentView": {
        "documentPageIndex": "Pagina {<currentPage>} van {{totalPages}}",
        "pageChangeInputLabel": "Pagina wijzigen in",
        "pageDown": "Volgende pagina",
        "pageUp": "Vorige pagina"
      },
      "documentVoided": "Handtekeningaanvraag ongeldig gemaakt",
      "dontShowAgain": "Dit bericht niet meer weergeven",
      "downloadOptions": {
        "combinedPDF": "Gecombineerde PDF",
        "completedPDF": "Voltooide PDF",
        "download": "Downloaden",
        "downloadErrorMessage": "Niet-ondersteund downloadtype: {{downloadType}}",
        "downloadOriginal": "Originele PDF",
        "signatureCertificate": "Handtekeningcertificaat"
      },
      "draftSaveErrorMessage": "Kan handtekeningaanvraag niet opslaan",
      "draftSaveSuccessMessage": "Handtekeningaanvraag opgeslagen",
      "draw": "Tekenen",
      "dueDate": "Vervaldatum",
      "dueDateUpdated": "Vervaldatum bijgewerkt",
      "edit": "Bewerken",
      "editDocumentModal": {
        "cancel": "Terug",
        "confirm": "Herzien",
        "contentLine1": "Als u ontvangers of velden wilt toevoegen of verwijderen, of als u uw beveiligingsopties wilt wijzigen, kunt u deze aanvraag herzien.",
        "contentLine2": "We onderbreken de aanvraag terwijl u wijzigingen aanbrengt. Dit betekent dat iedereen die nog niet ondertekend heeft tijdelijk geen toegang meer heeft. We sturen deze gebruikers een nieuwe koppeling als u klaar bent.",
        "contentLine3": "Zodra u begint met reviseren, kunt u de aanvraag niet hervatten zonder een herzien document te verzenden.",
        "title": "Weet u zeker dat u deze aanvraag wilt herzien?"
      },
      "editDueDate": "Vervaldatum bewerken",
      "editLeftMidwayModal": {
        "cancel": "Terug",
        "confirm": "Later voltooien",
        "contentLine1": "Ondertekenaars hebben geen toegang tot deze aanvraag terwijl deze onderbroken is voor revisies. Als u klaar bent, gaat u terug en kiest u ",
        "contentLine2": "Als u nog niet klaar bent, kunt u terugkomen om uw revisie later af te ronden.",
        "title": "Bent u nog aan het herzien?"
      },
      "editRecipients": "Ontvangers bewerken",
      "editSignatureRequest": "Herzien",
      "editSigner": "Ondertekenaar bewerken",
      "editViewer": "Waarnemer bewerken",
      "emptyFolder": "Deze map is leeg",
      "enforceStorageUsage": "U kunt pas nieuwe handtekeningaanvragen maken als u ruimte hebt vrijgemaakt",
      "expirationDateMessage": "Vervaldatum: {{expirationDate}}",
      "expirationDaysLabel": "dag(en)",
      "expirationInputDescription": "Het document kan na het aangegeven aantal dagen niet meer worden ondertekend:",
      "expirationInputLabel": "Verloop",
      "extendDueDate": "Vervaldatum verlengen",
      "extendExpirationDate": "Uitbreiden",
      "favorites": "Favorieten",
      "field": "veld",
      "fieldNameLabel": "Naamveld",
      "fieldRequired": "Dit veld is verplicht",
      "fields": "Velden",
      "fieldsAssignedWarningHeader": "Velden die zijn toegewezen aan ondertekenaars",
      "fieldsAssignedWarningMessage": "Alle velden die aan de ondertekenaar(s) zijn toegewezen, worden verwijderd.",
      "fieldsDescription": "Kies een type veld om in uw document te plaatsen",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Bewerken",
        "sectionLabel": "Waar wilt u het ondertekende document opslaan?"
      },
      "fileNotSupportedTooltip": "Niet ondersteunde bestandsindeling",
      "filesAndRecipients": "Bestanden en ontvangers",
      "filterSelected": "1 filter",
      "filterSelected_plural": "{{count}} filters",
      "forceComplete": "Voltooien afdwingen",
      "forceCompleteTitle": "Voltooiingsaanvraag afdwingen",
      "forceCompleteWarningFirst": "Hiermee worden alle velden verwijderd die zijn toegewezen aan ondertekenaars die nog niet hebben ondertekend en krijgt het document de eindstatus 'ondertekend'. Deze actie kan deze niet ongedaan worden gemaakt.",
      "forceCompleteWarningSecond": "Het kan even duren voordat de nieuwe status op het dashboard wordt weergegeven.",
      "generateNewPasscodeButtonLabel": "Nieuwe wachtwoordcode genereren",
      "genericError": "Er is een fout opgetreden",
      "groupRule": "Groepsregel",
      "hasAtleastOneSignerErrorMessage": "Voeg minstens één ondertekenaar toe om door te gaan",
      "importFields": {
        "linkText": "Velden importeren",
        "modal": {
          "assignImportedFields": "Geïmporteerde velden toewijzen",
          "body": {
            "buttonText": "Selecteren",
            "searchPlaceholder": "Zoeken op documentnaam, bestandsnaam, afzender of rol",
            "sentBy": "Verzonden door"
          },
          "componentsImported": "{{count}} velden geïmporteerd",
          "fields": "veld(en)",
          "importFieldsButtonText": "Velden importeren",
          "importFrom": "Importeren uit:",
          "mapAllRecipients": "Wijs velden toe aan nieuwe ontvangers om door te gaan",
          "newRecipient": "Nieuwe ontvanger",
          "noDocuments": "U hebt nog geen documenten",
          "originalRecipient": "Oorspronkelijke ontvanger",
          "selectDifferentDocument": "Ander document selecteren",
          "selectRecipient": "Ontvanger selecteren",
          "sentBy": "Verzonden door",
          "title": "Selecteer een document om velden te importeren",
          "titleImportFields": "Velden importeren",
          "titleImportFieldsWithCount": "{{count}} velden importeren"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Terug",
          "contentLine1": "{<documentName>} heeft geen velden die we kunnen importeren. U kunt een ander document kiezen of nieuwe velden aanmaken.",
          "title": "Dit document bevat geen velden"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Terug",
          "confirmButtonText": "Velden toch importeren",
          "contentLine1": "Het document dat u hebt geselecteerd, heeft een ander aantal pagina's dan het document waaraan u momenteel werkt.",
          "contentLine2": "U kunt nog steeds velden importeren, maar we kijken alleen naar de eerste {{pageCount}} pagina('s) van het geselecteerde document. Velden op volgende pagina's worden niet geïmporteerd.",
          "title": "Deze documenten zien er iets anders uit"
        },
        "tooltipText": "Velden uit eerder verzonden documenten importeren"
      },
      "initials": "Initialen",
      "invalidEmail": "Ongeldig e-mailadres",
      "invalidExpirationDaysError": "Voer een getal in tussen 1 en 365",
      "invalidExpirationDaysNotificationMessage": "Kies een vervaldatum",
      "invalidRecipientErrorMessage": "We hebben een naam en e-mailadres nodig voor elke ontvanger",
      "invalidSignatureRequest": "Handtekeningaanvraag lijkt ongeldig. Controleer de URL",
      "invite": "Uitnodigen",
      "inviteViewers": "Waarnemers uitnodigen",
      "kbaDescription": "Vereis dat ontvangers hun identiteit verifiëren via een op kennis gebaseerde quiz. Elke ondertekenaar moet zich onafhankelijk verifiëren voordat het document wordt ondertekend.",
      "kbaInfoMessage": "Ondertekenaars krijgen na 3 mislukte pogingen geen toegang meer tot het document.",
      "kbaLabel": "Op kennis gebaseerde verificatie",
      "legacyTokenInterceptor": {
        "buttonText": "Aanmelden",
        "message": "U wordt zo meteen naar hier teruggeleid, zodat u verder kunt gaan waar u was gebleven.",
        "title": "Meld u opnieuw aan om toegang te krijgen tot uw handtekeningaanvragen"
      },
      "messageLabel": "Bericht aan ontvangers",
      "mixedAlignment": "Gemengde uitlijning",
      "mixedDateFormat": "Gemengd formaat",
      "mixedFontSizes": "Gemengde lettergroottes",
      "moveAwaySelections": "Als u de huidige map verlaat, gaat uw selectie verloren.",
      "multiLineLabel": "Meerdere regels toestaan",
      "multipleRecipients": "Meerdere ontvangers",
      "nameGroup": "Groepsnaam",
      "nextStep": "Volgende stap",
      "optional": "Optioneel",
      "optionalComment": "Optionele begeleidende opmerking",
      "optionsLabel": "Opties",
      "packagerConfirmationModal": {
        "contentLine1": "We moeten deze documenten in één bestand samenvoegen voordat we ze kunnen voorbereiden voor ondertekening. Zodra u doorgaat naar de volgende stap, kunt u niet meer teruggaan om documenten te verwijderen.",
        "contentLine2": "Bent u klaar om door te gaan?",
        "mergeModalText": "Dit kan even duren. Zodra uw document gereed is, gaat u verder naar de volgende stap.",
        "progressBarText": "Samenvoegen...",
        "title": "Documenten samenvoegen"
      },
      "passcodeCharacterWarning": "De wachtwoordcode mag alleen cijfers bevatten.",
      "passcodeDescription": "Ontvangers moeten een wachtwoordcode van {<fiveDigit>} invoeren om toegang te krijgen tot de documenten.",
      "passcodeFiveDigit": "5-cijferig",
      "passcodeInfoMessage": "U moet deze wachtwoordcode met elke ontvanger delen.",
      "passcodeLabel": "Wachtwoordcode",
      "passcodeLengthError": "Kies een wachtwoordcode of schakel die beveiligingsoptie uit",
      "passcodeLengthErrorEditMode": "De wachtwoordcode moet uit 5 cijfers bestaan",
      "pending": "In behandeling",
      "personalFolders": "Persoonlijke mappen",
      "placeFields": "Velden plaatsen",
      "prepareDocument": {
        "components": {
          "attachment": "Bijlage",
          "checkmarkAnnotation": "Vinkje toevoegen",
          "editor": {
            "chargeAfterExecutedTooltip": "Gebruik deze optie als u het bedrag van de kaart wilt afschrijven zodra alle ondertekenaars hebben getekend",
            "collectCreditCardTooltip": "Kies deze optie als u alleen creditcardgegevens wilt verzamelen",
            "defaultHelpText": {
              "attachmentComponent": "Upload hier uw bijlage",
              "paymentComponent": "Voeg hier uw kaartgegevens toe"
            },
            "fieldSubtypeLabel": "Veldsubtype",
            "fontSizeLabel": "Tekengrootte",
            "helpTextLabel": "Helptekst toevoegen",
            "paymentAmountLabel": "Betalingsbedrag (USD)",
            "paymentAmountPlaceHolder": "Betalingsbedrag invoeren"
          },
          "textAnnotation": "Tekst toevoegen"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Elke keuzelijst moet minstens 2 opties hebben",
          "paymentAmountInvalidError": "Voer een betalingsbedrag in om door te gaan"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Ondertekenaars hebben niets te ondertekenen.",
          "cancelButtonText": "Terug",
          "confirmButtonText": "Toch doorgaan",
          "description": "U kunt toch doorgaan naar de volgende stap, of teruggaan en velden toevoegen die gebruikers moeten invullen.",
          "subtitle": "Deze ondertekenaars hebben niets te ondertekenen:",
          "title": "Bent u velden vergeten?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Document-id",
          "dueDate": "Vervaldatum",
          "otherDetails": "Overige gegevens",
          "recipients": "Ontvangers",
          "sender": "Afzender",
          "sentOn": "Verzonden op",
          "signerSequencing": "Volgordebepaling van ondertekenaars toegepast",
          "signers": "Ondertekenaars"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "Details weergeven"
        },
        "downloadModal": {
          "combinedDescription": "Omvat handtekeningcertificaat en ingevuld document",
          "combinedTitle": "Gecombineerde PDF",
          "completedDocumentDescription": "Ondertekend document met ingevulde velden",
          "completedDocumentTitle": "Ingevuld document",
          "done": "Klaar",
          "originalDescription": "PDF-document zonder velden voor ondertekenaars",
          "originalTitle": "Originele PDF",
          "signatureCertificateDescription": "Certificaat van vertrouwen van Sharefile",
          "signatureCertificateTitle": "Handtekeningcertificaat",
          "title": "Document downloaden"
        },
        "pageHeader": {
          "completed": "Voltooid",
          "download": "Downloaden",
          "downloadOriginalDocument": "Origineel document downloaden",
          "inProgress": "In uitvoering"
        }
      },
      "previousStep": "Vorige stap",
      "processingState": "Bezig met verwerken...",
      "radio": "Groep keuzerondjes",
      "readyState": "Klaar!",
      "reasonDeclineLabel": "Reden voor afwijzing",
      "reasonVoidLabel": "Reden voor ongeldig maken",
      "recipientDuplicate": "Deze persoon is al een waarnemer",
      "recipientEmail": "E-mail",
      "recipientListHeading": "Wie zijn de ontvangers?",
      "recipientName": "Naam",
      "recipientNoName": "Dit veld is verplicht",
      "recipientRemindButtonLabel": "Herinneren",
      "recipientRole": "Rol",
      "recipientRoleSigner": "Ondertekenaar",
      "recipientRoleViewer": "Waarnemer",
      "recipientStatusSigned": "Ondertekend",
      "recipientUnlockLabel": "Ontgrendelen",
      "recipientsLabel": "Ontvangers",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Binnenkort kunt u met ShareFile een CSV-bestand van ontvangers uploaden, zodat u in één keer een handtekening van maximaal 300 personen kunt aanvragen. Tot die tijd kunt u dit nog steeds doen in RightSignature.",
          "closeButton": "Annuleren",
          "header": "Op zoek naar bulkverzending?",
          "redirectButton": "Ga naar RightSignature"
        },
        "packager": {
          "bodyLineOne": "Binnenkort kunt u met ShareFile meerdere documenten in één handtekeningaanvraag verzenden. Tot die tijd kunt u dit nog steeds doen in RightSignature.",
          "closeButton": "Annuleren",
          "header": "Wilt u meerdere documenten verzenden?",
          "redirectButton": "Ga naar RightSignature"
        },
        "template": {
          "bodyLineOne": "Binnenkort kunt u handtekeningsjablonen maken en gebruiken in ShareFile.",
          "bodyLineTwo": "Bereid u alvast voor door ze in RightSignature uit te proberen.",
          "closeButton": "Niet nu",
          "header": "Stroomlijn uw proces met sjablonen",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Sjablonen proberen"
        }
      },
      "referenceIdLabel": "Referentie-id",
      "reminderSent": "Herinnering verzonden",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Ondertekenaars verwijderen",
        "content": "Alle ondertekenaars die uit {<fileName>} zijn geïmporteerd, worden verwijderd.",
        "title": "Weet u zeker dat u deze ondertekenaars wilt verwijderen?"
      },
      "removeCheckboxGroup": "Groep selectievakjes verwijderen",
      "removeFieldLabel": "Veld verwijderen",
      "removeFileDisabledTooltip": "Het document kan niet worden verwijderd",
      "removeFileFailed": "Kan het bestand niet verwijderen. Probeer het opnieuw.",
      "requestSignatureButton": {
        "label": "Handtekeningaanvraag maken"
      },
      "requireGroup": "Groep is vereist",
      "required": "Verplicht",
      "requiredFieldLabel": "Verplicht veld",
      "requiredFieldsLabel": "Verplichte velden",
      "reviewAndSend": "Controleren en verzenden",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Dit veld mag niet leeg zijn"
          }
        }
      },
      "save": "Opslaan",
      "saveCompletedDocument": "Voltooide document opslaan",
      "savedSignaturesLabel": "Selecteer de handtekening die u in het document wilt toepassen",
      "search": "Zoeken",
      "securityOptionsLabel": "Beveiligingsopties",
      "selectOneOrMoreLabel": "Minstens 1 selecteren",
      "selectOnlyOneLabel": "Selecteer er slechts één",
      "selfSignCheckDisabledToolTip": "Zelfondertekening is niet beschikbaar bij het selecteren van een sjabloon",
      "selfSignCheckLabel": "Ik ben de enige ondertekenaar",
      "selfSignCheckToolTip": "Onderteken het document zelf en deel het vervolgens. Het wordt opgeslagen in de geselecteerde map.",
      "selfSignSucessMessage": "Document opgeslagen",
      "selfSignature": "Uw handtekening",
      "sendForSignature": "Verzenden voor ondertekening",
      "sendInBulk": {
        "allSigners": "Alle {<signersCount>} ondertekenaars",
        "bulkSigners": "Ondertekenaars in bulk",
        "couldTakeAMoment": "Dit kan even duren.",
        "disableTooltipTextForPLG": "Upgrade uw abonnement om toegang te krijgen",
        "downloadCSVTemplateButton": "CSV-sjabloon downloaden",
        "email": "E-mail",
        "errors": {
          "correctTheFollowingError": "Corrigeer de volgende fouten in uw bestand en probeer het opnieuw.",
          "exceedsRecipientsLimit": "{<fileName>} overschrijdt de limiet van 300 ondertekenaars.",
          "fileSizeExceeded": "Het bestand is groter dan de limiet van 5 Mb.",
          "onlyOneFile": "U kunt ondertekenaars slechts uit één bestand importeren.",
          "supportedFileType": "U kunt ondertekenaars alleen uit een CSV-bestand importeren.",
          "unableToLoadSpreadsheet": "Er ontbreekt belangrijke informatie in uw bestand."
        },
        "gatheringSignersFromFile": "Ondertekenaars uit uw bestand verzamelen...",
        "goBack": "Terug",
        "import": "Importeren",
        "importSigners": "Ondertekenaars importeren",
        "importSignersModalText1": "We hebben een CSV-sjabloon voor u gemaakt. Download de sjabloon en vul de naam- en e-mailadresvelden in (verplicht).",
        "importSignersModalText2": "CSV is de enige ondersteunde bestandsindeling. Het aantal ondertekenaars mag niet groter zijn dan 300.",
        "name": "Naam",
        "note": "Opmerking: ",
        "previewingTheSigners": "Voorbeeld van de eerste {<firstSignersCount>} van {<signersCount>} ondertekenaars bekijken",
        "selectDifferentFile": "Ander bestand selecteren",
        "title": "Bulkverzending",
        "tooltipText": "Bulkverzending kan alleen worden gebruikt met sjablonen voor één ondertekenaar",
        "uploadFileText": "Sleep uw CSV-bestand hierheen of"
      },
      "sendRevisedRequest": "Herziene aanvraag verzenden",
      "sendSignatureRequest": "Handtekeningaanvraag verzenden",
      "sendSigningLink": {
        "gotIt": "OK",
        "sentLinkInfoBanner": "We hebben u een koppeling gestuurd",
        "signingLinkInfoMessage1": "Controleer uw e-mail {<email>} voor een koppeling om dit document te ondertekenen.",
        "signingLinkInfoMessage2": "Binnenkort kunt u rechtstreeks ondertekenen in ShareFile."
      },
      "senderRoleChangeTooltip": "De afzender kan geen waarnemer zijn",
      "sentByLabel": "Verzonden door",
      "setSigningOrder": "Ondertekeningsvolgorde instellen",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Annuleren",
          "createFolderLabel": "Map maken",
          "disabledNodeUploadReason": "Uploadmachtiging is vereist om deze map te selecteren",
          "disabledSharedFoldersReason": "Kan gedeelde mappen op het hoogste niveau niet selecteren",
          "modalTitle": "De ondertekende overeenkomst opslaan in...",
          "selectFolderButtonLabel": "Selecteren"
        }
      },
      "sharedFolders": "Gedeelde mappen",
      "showAll": "Alles weergeven",
      "signButtonLabel": "Ondertekenen",
      "signYourself": "Zelf ondertekenen",
      "signature": "Handtekening",
      "signatureAnnotationMessage": "Klik en sleep om uw handtekening in het vak te zetten",
      "signatureAnnotationTextMessage": "Typ uw handtekening in het onderstaande veld",
      "signatureRequest": "Handtekeningaanvraag",
      "signatureRequestErrorMessage": "Handtekeningaanvraag mislukt",
      "signatureRequestSuccessMessage": "Handtekeningaanvraag verzonden",
      "signer": "Ondertekenaar",
      "signerDescription": {
        "annotation": "Plaats velden voor uzelf en vul ze vooraf in",
        "bulkSigners": "U plaatst velden voor alle ondertekenaars",
        "signers": "U plaatst velden voor elke ondertekenaar"
      },
      "signerMessageInputLabel": "Opmerking voor alle ondertekenaars (optioneel)",
      "signerMessageInputLabelTooltip": "We nemen deze opmerking op in de e-mailmelding die naar ondertekenaars gaat",
      "signerMessageInputPlaceholder": "Een gepersonaliseerde opmerking aan alle ondertekenaars toevoegen",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Documentnaam en ID",
            "dueDateTitle": "Vervaldatum",
            "senderInfoTitle": "Afzender",
            "senderMessageTitle": "Bericht van afzender"
          },
          "fields": {
            "addInitials": "Initialen toevoegen",
            "attachment": {
              "buttonText": "Bestand bijvoegen",
              "fileUploadingError": "Kan bestand niet uploaden. Probeer het opnieuw",
              "modalButtonContinue": "Klaar",
              "modalDeleteFileCancelButton": "Annuleren",
              "modalDeleteFileOkButton": "Verwijderen",
              "modalDeleteFileText": "Weet u zeker dat u {<fileName>} wilt verwijderen",
              "modalDeleteFileTitle": "Bestand verwijderen",
              "modalTitle": "Bestand uploaden",
              "modalUploadAnotherFileText": " en een nieuw bestand uploaden?",
              "uploadDifferentFileButton": "Ander bestand uploaden",
              "uploadHint": "Enkel bestand",
              "uploadText": "Klik of sleep het bestand naar dit gebied om het te uploaden",
              "uploadingStatus": "Bezig met uploaden"
            },
            "autofilled": "Automatisch ingevuld",
            "dropdown": {
              "clearSelection": "Selectie wissen",
              "selectOption": "Optie selecteren"
            },
            "enterText": "Tekst invoeren",
            "format": "Format",
            "optional": "Optioneel",
            "paymentField": {
              "amount": "Bedrag",
              "authorizePayment": "Betaling autoriseren",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Wijzigen",
              "clear": "Wissen",
              "creditCardModalDetails": {
                "authorizeMessage": "Ik ga ermee akkoord dat er {<paymentAmount>} in rekening wordt gebracht nadat ik dit document heb ingediend",
                "cvcTooltipMessage": "Een drie- of viercijferige beveiligingscode die op de achterkant (of voorkant voor AMEX) van uw creditcard is afgedrukt",
                "labels": {
                  "cardNumber": "Kaartnummer",
                  "cvc": "CVC",
                  "expiryDate": "Vervaldatum"
                },
                "modalTitle": "Kaartgegevens bewerken",
                "placeholders": {
                  "cardNumber": "Kaartnummer invoeren",
                  "cvc": "CVC invoeren"
                }
              },
              "creditDetails": "Kaartgegevens invoeren"
            },
            "required": "Verplicht",
            "selectDate": "Datum selecteren",
            "signature": {
              "clearSignatureButtonText": "Handtekening wissen",
              "editSignatureButtonText": "Handtekening bewerken",
              "openSignatureModalText": "Hier ondertekenen",
              "prefilledSignatureText": "Voor uw gemak hebben we uw naam vooraf ingevuld. U kunt deze wijzigen als dat nodig is."
            }
          },
          "navigation": {
            "next": "Volgende",
            "nextField": "Volgend veld",
            "startSigning": "Begin met ondertekenen"
          },
          "progressBar": {
            "shortSuccessText": "U hebt alle verplichte velden ingevuld",
            "status": "{{fieldsFilled}}/{{totalFields}} verplichte velden",
            "submitAction": "Verzenden",
            "successText": "U hebt alle verplichte velden ingevuld. Als u klaar bent met controleren, kunt u het document {<submitAction>}."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Volg uw voortgang"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "U hoeft geen velden in te vullen",
              "optionalCount": "{{count}} optioneel veld",
              "optionalCount_plural": "{{count}} optionele velden",
              "optionalOnly": "Je moet {<optionalCountLabel>} invullen",
              "pending": "In behandeling",
              "requiredAndOptional": "Je moet {<requiredCountLabel>} en {<optionalCountLabel>} invullen",
              "requiredCount": "{{count}} verplicht veld",
              "requiredCount_plural": "{{count}} verplichte velden",
              "requiredOnly": "Je moet {<requiredCountLabel>} invullen"
            },
            "label": {
              "details": "Details",
              "fields": "Velden"
            }
          }
        },
        "declineDocument": {
          "header": "Geweigerd document",
          "message": "U hebt dit document geweigerd. Als u het nog steeds wilt ondertekenen, vraag de afzender dan om een nieuw document te verzenden",
          "sender": "Afzender"
        },
        "header": {
          "documentActions": {
            "actions": "Acties",
            "actionsAndDetails": "Details en acties",
            "declineDocument": "Document afwijzen",
            "declineFailed": "We konden het document niet weigeren. Probeer het opnieuw",
            "downloadOriginal": "Originele PDF downloaden",
            "saveFailed": "We kondnen uw voortgang niet opslaan. Probeer het opnieuw",
            "saveProgress": "Voortgang opslaan",
            "saveSuccess": "Voortgang opgeslagen"
          },
          "moreActions": "Meer acties"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Verificatie mislukt.",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Annuleren",
            "decline": "Weigeren",
            "reason": "Reden",
            "reasonHelp": "Deze reden wordt doorgegeven aan de afzender",
            "reasonPlaceholder": "Waarom weigert u?",
            "title": "Document afwijzen",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "Nog {{attemptsAmount}} poging",
            "attemptsAmountPlural": "Nog {{attemptsAmount}} pogingen",
            "contentMessage": "Voer een 5-cijferige wachtwoordcode in die door de afzender is verstrekt om toegang te krijgen tot dit document.",
            "inputLabel": "Wachtwoordcode invoeren",
            "inputPlaceholder": "Voer een 5-cijferige wachtwoordcode in",
            "inputValidationMessage": "De wachtwoordcode moet uit 5 cijfers bestaan",
            "inputValueRequiedMessage": "Dit veld is verplicht",
            "invalidPasscodeMessage": "Ongeldige wachtwoordcode.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) heeft u uitgenodigd om {<documentName>}",
            "unlockButton": "Ontgrendelen",
            "welcomeMessage": "Welkom!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "We hebben een kopie van het ingevulde document naar {<email>} gestuurd",
            "downloadButton": "Document downloaden",
            "notAllSignedText": "Wij sturen een kopie van het ondertekende document naar {<email>} zodra alle ondertekenaars dit document hebben ondertekend",
            "reviewButton": "Ondertekend document controleren",
            "title": "Ingediend document"
          },
          "termsAndConditions": {
            "continueButton": "Doorgaan",
            "esignConsent": "Toestemming voor elektronisch ondertekenen",
            "footerEsignConsent": "Je bent op de hoogte van en gaat akkoord met onze {<link>}",
            "footerTerms": "Je bent op de hoogte van en gaat akkoord met onze {<linkTermsOfUse>} en {<linkPrivacyPolicy>}",
            "footerTitle": "Door verder te gaan, bevestigt u dat:",
            "greetings": "Hallo {{signerName}},",
            "privacyPolicy": "Privacybeleid",
            "senderMessageTitle": "Bericht van afzender",
            "termsOfUse": "Gebruiksrechtovereenkomst",
            "text": "{{senderName}} ({{senderEmail}}) heeft u uitgenodigd om {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Document indienen",
          "submit": "Verzenden",
          "submitConfirm": "Weet u zeker dat u klaar bent om dit document in te dienen?",
          "submitWarning": "Na het indienen kunt u geen wijzigingen meer aanbrengen",
          "submitWithUnfilledOptionalFields": "U hebt een optioneel veld overgeslagen. Weet u zeker dat u klaar bent om dit document in te dienen?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Vul alle verplichte velden in om het document in te dienen"
        }
      },
      "signerSequencingDisabledTooltip": "Ondertekeningsvolgorde is alleen van toepassing als er meer dan 1 ondertekenaar is",
      "signerSequencingNotApplicableTooltip": "Ondertekeningsvolgorde is niet van toepassing op waarnemers",
      "signers": "Ondertekenaars",
      "signersLabel": "Ondertekenaars",
      "singerSequencingAppliedText": "Het sequentiëren van ondertekenaars is toegepast",
      "sizeLimitFile": "Bestand {{filename}} ",
      "sizeLimitMessage": "overschrijdt onze limiet van 20 MB",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Sjabloon verwijderen",
              "content": "De lijst met ondertekenaars in bulk gaat verloren en u moet een nieuw document uploaden om verder te gaan.",
              "header": "Weet u zeker dat u deze sjabloon wilt verwijderen"
            },
            "modal": {
              "confirmButton": "Document verwijderen",
              "content": "Als u een document verwijdert, worden alle vooraf gedefinieerde ontvangers en hun toegewezen velden verwijderd. Weet u zeker dat u het document wilt verwijderen?",
              "header": "Document verwijderen"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Selecteren",
              "createdBy": "Gemaakt door:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Komt in aanmerking voor bulkverzending",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Kan sjablonen niet laden. Probeer het later opnieuw",
            "footer": {
              "buttonText": "Sjabloon maken",
              "question": "Kunt u niet vinden wat u zoekt?"
            },
            "noDocuments": "U hebt nog geen sjablonen",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Er is hier niets",
            "noSearchResultsDesc": "Er is niets dat overeenkomt met uw zoekopdracht.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Rollen:",
            "title": "Selecteer een sjabloon"
          },
          "sourceName": "Een sjabloon gebruiken"
        },
        "upload": {
          "basefileProcessingFailed": "Kan het bestand {{ filename }} niet verwerken. Upload het bestand opnieuw",
          "failed": "Kan het bestand {{ filename }} niet uploaden",
          "numberLimitMessage": "U kunt niet meer dan 5 bestanden uploaden"
        }
      },
      "statusFilter": "Status",
      "switch": "Overschakelen naar oude ervaring",
      "template": "Sjabloon",
      "temporaryRedirectText": "Tijdelijk overschakelen naar RightSignature",
      "text": "Tekst",
      "textAlignLabel": "Tekst uitlijnen",
      "type": "Type",
      "unableToFetchFileDetails": "Kan bestandsgegevens niet ophalen",
      "unableToProceedNoDocumentError": "Kan niet doorgaan. Voeg een document toe",
      "unableToProceedNoSignerError": "Kan niet doorgaan. Voeg minstens één ondertekenaar toe",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Dit documenttype wordt niet ondersteund. Ondersteunde bestandstypen zijn .doc, .docx, .txt en .pdf",
      "updateDocumentErrorMessage": "Kan het document niet bijwerken.",
      "uploadDocumentErrorMessage": "Upload een document om naar de volgende stap te gaan",
      "uploadedFilesProcessingErrorMessage": "Een of meer geüploade bestanden worden nog verwerkt",
      "useSaved": "Opgeslagen gebruiken",
      "userFetchErrorMessage": "Kan gebruikersgegevens niet ophalen",
      "view": "Weergeven",
      "viewer": "Waarnemer",
      "viewerUniqueEmailErrorMessage": "Het e-mailadres van de waarnemer moet uniek zijn",
      "viewersLabel": "Waarnemers",
      "void": "Nietig verklaren",
      "voidConfirmationTitle": "Bevestiging ongeldig document",
      "zoomIn": "Inzoomen",
      "zoomOut": "Uitzoomen"
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "AccountLimitReached": "A conta atingiu o limite máximo de documentos.",
      "AttachedByLabel": "Anexado por {{signer}}",
      "AttachmentDetailsLabel": "Anexos",
      "AttachmentFieldLabel": "Anexo",
      "CheckFieldLabel": "Caixa de seleção",
      "CheckGroupFieldLabel": "Grupo de caixas de seleção",
      "DateFieldLabel": "Data",
      "InitialsFieldLabel": "Iniciais",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Cobrança depois de assinado",
        "chargedLabel": "Cobrado {<paymentAmount>}",
        "collectCardDetailsLabel": "Obter detalhes do cartão",
        "defaultLabel": "Pagamento",
        "paymentFailedLabel": "Pagamento não realizado"
      },
      "PaymentFieldLabel": "Campo de pagamento",
      "SelectFieldLabel": "Menu suspenso",
      "SignatureFieldLabel": "Assinatura",
      "TextFieldLabel": "Texto",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "Seu plano atual limita a quantidade de documentos que podem ser enviados para assinatura. Você ainda pode assinar documentos.",
        "upgradeTrialBanner": "Você tem {<documentsLeft>} restantes na sua avaliação."
      },
      "accountLimitExhaustedMessage": "Não é possível enviar o documento para assinatura, o limite da conta foi esgotado. Faça upgrade da conta.",
      "accountStatsFetchErrorMessage": "Falha ao obter as informações da conta",
      "add": "Adicionar",
      "addCheckbox": "Adicionar caixa de seleção",
      "addFilesFromSharefile": "Adicionar itens do ShareFile",
      "addMyself": "Adicionar-me",
      "addOptionLabel": "Adicionar opção",
      "addRecipient": "Adicionar outro destinatário",
      "addSignature": "Adicionar assinatura",
      "annotator": "Você (agora)",
      "annotatorOptionTooltip": "Preencha os campos com antecedência",
      "apply": "Aplicar",
      "archiveLocationBlank": "Selecione uma pasta para armazenar o documento assinado",
      "assignedToLabel": "Atribuído a",
      "attachmentFieldLabel": "Anexo",
      "browseFiles": "Navegar arquivos",
      "bulkSend": "Envio em massa",
      "cancel": "Cancelar",
      "changesSaved": "Alterações salvas",
      "checkbox": "Caixa de seleção",
      "checkboxGroup": "Grupo de caixas de seleção",
      "checkboxes": "Caixas de seleção",
      "clear": "Limpar",
      "clearBackground": "Clear background",
      "close": "Fechar",
      "completed": "Concluído",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} campos selecionados",
      "confirm": "Confirmar",
      "continue": "Continuar",
      "createDocument": {
        "almostDone": "Quase pronto. Não clique no botão Atualizar ou Voltar"
      },
      "createDocumentErrorMessage": "Falha ao criar documento",
      "creationDateLabel": "Enviado em",
      "dashboard": {
        "adminView": "Modo de exibição de administador",
        "adminViewTooltipText": "Ver todos os documentos enviados desta conta",
        "autoDeleteText": "Exclusão automática em {{remaining}} dia(s)",
        "clearFilters": "Limpar filtros",
        "clearSearch": "Limpar pesquisa",
        "continueAction": "Continuar",
        "deleteAction": "Excluir",
        "downloadPdf": "Baixar",
        "esignLeftNav": "Assinaturas",
        "loadMore": "Carregar mais",
        "overdue": "1 dia de atraso",
        "overdue_plural": "{{count}} dias de atraso",
        "signatureDue": "Vencimento em {{dueDate}}",
        "signatureRequests": "Solicitação de assinatura",
        "signatureSettings": "Configurações de assinatura",
        "signaturesSubmitted": "Assinaturas enviadas",
        "templates": "Modelos",
        "title": "Solicitações de assinatura"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Parece que nada corresponde aos seus filtros.",
          "title": "Não há nada aqui"
        },
        "noRequestsCreated": {
          "description": "Precisa obter uma assinatura? Crie uma solicitação.",
          "title": "Você ainda não tem nenhuma solicitação de assinatura"
        },
        "noSearchResults": {
          "description": "Parece que nada corresponde à sua pesquisa.",
          "title": "Não há nada aqui"
        }
      },
      "date": "Data",
      "dateExample": "Exemplo:",
      "dateExtendError": "Escolha uma data futura",
      "dateFormatLabel": "Formato",
      "dateSignedLabel": "Data de preenchimento automático assinada",
      "dateSignedTooltip": "Um destinatário não pode editar o campo. Esse campo será preenchido automaticamente no dia da assinatura do documento.",
      "declinedByLabel": "Recusado por {{name}}",
      "defaultBadgeLabel": "Padrão",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Enviar a todos um email com o PDF completo",
          "attachCompletedPDFCheckboxTooltip": "Após a última assinatura, enviaremos a todos um email com o PDF preenchido como anexo (a menos que seja maior que 10 MB).",
          "sectionHeader": "Anexos"
        },
        "header": "Editar configurações padrão",
        "headerInfo": "As alterações feitas aqui serão aplicadas a todas as solicitações de assinatura em andamento.",
        "hipaaConfirmationBodyFirst": "Todos os destinatários receberão uma cópia do documento assinado como anexo de e-ail quando essa configuração for aplicada. Como cliente da HIPAA, é sua responsabilidade confirmar se os destinatários permitem que suas informações sejam tratadas dessa forma.",
        "hipaaConfirmationBodySecond": "Tem certeza de que deseja aplicar esta configuração?",
        "hipaaConfirmationButton": "Aplicar configuração",
        "hipaaConfirmationHeader": "Considerações sobre a HIPAA",
        "reminders": {
          "options": {
            "daily": "Diário",
            "noAuto": "Nunca",
            "weekly": "Semanal"
          },
          "reminderFrequencyLabel": "Enviar lembretes automáticos",
          "reminderFrequencyTooltip": "Enviar lembretes por email aos destinatários semanalmente (após 3, 7, 14, 21 e 28 dias) ou diariamente (após 7 dias) até que assinem.",
          "sectionHeader": "Lembretes"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Permitir assinaturas manuscritas",
          "allowTypedSignatureLabel": "Permitir assinaturas digitadas",
          "applyBlueInkSignatureLabel": "Aplicar tinta azul nas assinaturas",
          "applyBlueInkSignatureTooltipAdmin": "Entre em contato com o seu administrador se precisar alterar essa configuração.",
          "applyBlueInkSignatureTooltipNonAdmin": "Usar tinta azul em todas as assinaturas para ajudar a distinguir originais assinados de fotocópias.",
          "sectionHeader": "Tipos de assinatura"
        },
        "signer": {
          "allowDownload": "Permitir que os signatários baixem o documento original antes de assinar",
          "sectionHeader": "Permissões do signatário"
        }
      },
      "defaultSettingsDescription": "Altere suas configurações de lembretes, anexos de email e tipos de assinatura.",
      "defaultSettingsEditLabel": "Editar configurações padrão",
      "defaultSettingsLabel": "Configurações padrão",
      "defaultSettingsUpdateFailed": "Falha ao atualizar as configurações padrão",
      "defaultSettingsUpdateSuccess": "Configurações padrão atualizadas",
      "defaultValueLabel": "Adicionar texto padrão",
      "delete": "Excluir",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Isso excluirá permanentemente a solicitação de assinatura. Uma vez eito isso, não é possível desfazer.",
        "deleteConfirmationMessageSecond": "Os signatários e observadores receberão um email com o documento preenchido e o certificado de assinatura para arquivamento.",
        "deleteRequest": "Excluir solicitação",
        "deleteRequestSuccess": "Solicitação excluída"
      },
      "deleteDraftDocumentLabel": "Excluir solicitação de assinatura",
      "deleteDraftDocumentModalContent": "Tem certeza de que deseja excluir a solicitação de assinatura? Isso não pode ser desfeito.",
      "deleteDraftDocumentSuccess": "Solicitação de assinatura excluída",
      "deleteViewerWarningHeader": "Tem certeza de que deseja remover este destinatário?",
      "deleteViewerWarningMessage": "Eles não terão mais acesso a esta solicitação de assinatura.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Editar código secreto",
        "kba": "Autenticação baseada em conhecimento",
        "passcode": "Código secreto",
        "title": "Opção de segurança aplicada",
        "updatePasscodeSuccess": "O código secreto deste documento foi atualizado."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "Você não pode revisar solicitações enviadas com o envio em massa",
          "signerSigned": "Você não pode revisar solicitações depois que alguém tiver assinado",
          "userNotSender": "Você não pode revisar solicitações que não enviou"
        }
      },
      "documentAlreadySentErrorDetails": "Não é possível abrir o documento enviado para edição. Inicie um novo fluxo.",
      "documentAlreadySentErrorTitle": "Documento já enviado",
      "documentForSignature": "Documento para enviar",
      "documentForceCompleted": "Solicitação de força concluída",
      "documentLockedMessage": "Esse destinatário está temporariamente bloqueado devido a várias tentativas de autenticação malsucedidas",
      "documentNameBlank": "Insira um nome de documento",
      "documentNameInputDisabledTooltip": "O nome do documento não pode ser editado",
      "documentNameInputLabel": "Nome do documento",
      "documentStates": {
        "declined": "Recusado",
        "draft": "Rascunho",
        "editing": "Pausado para revisões",
        "executed": "Concluído",
        "expired": "Expirado",
        "pending": "Em andamento ",
        "voided": "Anulado"
      },
      "documentThumbnailAltText": "Miniatura da primeira página",
      "documentTransactionCreateErrorMessage": "Falha ao criar a transação do documento",
      "documentTransactionGetErrorMessage": "Falha ao obter a transação do documento",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Desbloqueio de documentos bem-sucedido",
      "documentView": {
        "documentPageIndex": "Página {<currentPage>} de {{totalPages}}",
        "pageChangeInputLabel": "Alterar página para",
        "pageDown": "Próxima página",
        "pageUp": "Página anterior"
      },
      "documentVoided": "Solicitação de assinatura anulada",
      "dontShowAgain": "Não mostrar esta mensagem novamente",
      "downloadOptions": {
        "combinedPDF": "PDF combinado",
        "completedPDF": "PDF pronto",
        "download": "Baixar",
        "downloadErrorMessage": "Tipo de download não suportado: {{downloadType}}",
        "downloadOriginal": "PDF original",
        "signatureCertificate": "Certificado de assinatura"
      },
      "draftSaveErrorMessage": "Falha ao salvar a solicitação de assinatura",
      "draftSaveSuccessMessage": "Solicitação de assinatura salva",
      "draw": "Desenhar",
      "dueDate": "Data de vencimento",
      "dueDateUpdated": "Data de vencimento atualizada",
      "edit": "Editar",
      "editDocumentModal": {
        "cancel": "Voltar",
        "confirm": "Revisar",
        "contentLine1": "Se precisar adicionar ou remover destinatários ou campos, ou alterar suas opções de segurança, você pode revisar essa solicitação.",
        "contentLine2": "Faremos uma pausa na solicitação enquanto você fizer revisões. Isso significa que todos que ainda não assinaram perderão temporariamente o acesso. Enviaremos um novo link quando você terminar.",
        "contentLine3": "Depois de começar a revisar, você não poderá retomar a solicitação sem enviar um documento revisado.",
        "title": "Tem certeza de que deseja revisar essa solicitação?"
      },
      "editDueDate": "Editar a data de vencimento",
      "editLeftMidwayModal": {
        "cancel": "Voltar",
        "confirm": "Terminar mais tarde",
        "contentLine1": "Os signatários não conseguem acessar essa solicitação enquanto ela está pausada para revisões. Se você terminar, volte e escolha ",
        "contentLine2": "Se não tiver acabado, você poderá voltar e terminar suas revisões mais tarde.",
        "title": "Você ainda está revisando?"
      },
      "editRecipients": "Editar destinatários",
      "editSignatureRequest": "Revisar",
      "editSigner": "Editar signatário",
      "editViewer": "Editar observador",
      "emptyFolder": "Esta pasta está vazia",
      "enforceStorageUsage": "Você não pode criar novas solicitações de assinatura até liberar espaço",
      "expirationDateMessage": "Data de vencimento: {{expirationDate}}",
      "expirationDaysLabel": "dia(s)",
      "expirationInputDescription": "O documento não estará mais disponível para assinatura após o número de dias especificado:",
      "expirationInputLabel": "Expiração",
      "extendDueDate": "Estender a data de vencimento",
      "extendExpirationDate": "Estender",
      "favorites": "Favoritos",
      "field": "campo",
      "fieldNameLabel": "Campo de nome",
      "fieldRequired": "Este campo é obrigatório",
      "fields": "Campos",
      "fieldsAssignedWarningHeader": "Campos atribuídos aos signatários",
      "fieldsAssignedWarningMessage": "Todos os campos atribuídos ao(s) signatário(s) serão removidos.",
      "fieldsDescription": "Escolha um tipo de campo para colocar em seu documento",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Editar",
        "sectionLabel": "Onde você gostaria de armazenar o documento assinado?"
      },
      "fileNotSupportedTooltip": "Formato de arquivo sem suporte",
      "filesAndRecipients": "Arquivos e destinatários",
      "filterSelected": "1 filtro",
      "filterSelected_plural": "{{count}} filtros",
      "forceComplete": "Forçar execução",
      "forceCompleteTitle": "Forçar solicitação de execução",
      "forceCompleteWarningFirst": "Isso removerá todos os campos atribuídos aos signatários que ainda não assinaram e fará com que o documento seja executado (estado final). Depois que isso for feito, não é possível desfazer.",
      "forceCompleteWarningSecond": "Pode demorar alguns momentos para que o painel mostre o novo status.",
      "generateNewPasscodeButtonLabel": "Gerar nova senha",
      "genericError": "Ocorreu um erro",
      "groupRule": "Regra de grupo",
      "hasAtleastOneSignerErrorMessage": "Adicione pelo menos um signatário para continuar",
      "importFields": {
        "linkText": "Importar campos",
        "modal": {
          "assignImportedFields": "Atribuir campos importados",
          "body": {
            "buttonText": "Selecionar",
            "searchPlaceholder": "Pesquisar por nome do documento, nome do arquivo, remetente ou função",
            "sentBy": "Enviado por"
          },
          "componentsImported": "{{count}} campos importados",
          "fields": "campo(s)",
          "importFieldsButtonText": "Importar campos",
          "importFrom": "Importar de:",
          "mapAllRecipients": "Atribuir campos a novos destinatários para continuar",
          "newRecipient": "Novo destinatário",
          "noDocuments": "Você não tem nenhum documento ainda",
          "originalRecipient": "Destinatário original",
          "selectDifferentDocument": "Selecionar documento diferente",
          "selectRecipient": "Selecionar destinatário",
          "sentBy": "Enviado por",
          "title": "Selecione um documento para importar campos",
          "titleImportFields": "Importar campos",
          "titleImportFieldsWithCount": "Importar {{count}} campos"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Voltar",
          "contentLine1": "{<documentName>} não tem nenhum campo que possamos importar. Você pode escolher um documento diferente ou criar novos campos.",
          "title": "Este documento não tem campos"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Voltar",
          "confirmButtonText": "Importar campos mesmo assim",
          "contentLine1": "O documento que você selecionou tem um número de páginas diferente daquele em que você está trabalhando atualmente.",
          "contentLine2": "Você ainda pode importar campos, mas veremos apenas a(s) primeira(s) {{pageCount}} página(s) do documento selecionado. Quaisquer campos nas páginas subsequentes não serão importados.",
          "title": "Esses documentos parecem um pouco diferentes"
        },
        "tooltipText": "Importar campos de documentos enviados anteriormente"
      },
      "initials": "Iniciais",
      "invalidEmail": "Email inválido",
      "invalidExpirationDaysError": "Insira um número entre 1 e 365",
      "invalidExpirationDaysNotificationMessage": "Escolha uma data de vencimento",
      "invalidRecipientErrorMessage": "Precisamos de um nome e email para cada destinatário",
      "invalidSignatureRequest": "A solicitação de assinatura parece inválida, verifique o URL",
      "invite": "Convidar",
      "inviteViewers": "Convidar observadores",
      "kbaDescription": "Exija que os destinatários verifiquem sua identidade com um questionário baseado em conhecimento. Cada signatário precisará verificar de forma independente antes de assinar o documento.",
      "kbaInfoMessage": "Os signatários serão impedidos de acessar o documento após 3 tentativas fracassadas.",
      "kbaLabel": "Autenticação baseada em conhecimento",
      "legacyTokenInterceptor": {
        "buttonText": "Fazer logon",
        "message": "Nós o traremos de volta aqui para que você possa continuar de onde parou.",
        "title": "Faça login novamente para acessar suas solicitações de assinatura"
      },
      "messageLabel": "Mensagem para os destinatários",
      "mixedAlignment": "Alinhamento misto",
      "mixedDateFormat": "Formato misto",
      "mixedFontSizes": "Tamanhos de fonte mistos",
      "moveAwaySelections": "Se você sair da pasta atual, suas seleções serão perdidas.",
      "multiLineLabel": "Permitir várias linhas",
      "multipleRecipients": "Vários destinatários",
      "nameGroup": "Nome do grupo",
      "nextStep": "Próxima etapa",
      "optional": "Opcional",
      "optionalComment": "Comentário de acompanhamento opcional",
      "optionsLabel": "Opções",
      "packagerConfirmationModal": {
        "contentLine1": "Precisamos combinar esses documentos em um arquivo antes de prepará-los para assinatura. Depois de prosseguir para a próxima etapa, não será possível voltar e remover nenhum deles.",
        "contentLine2": "Deseja continuar?",
        "mergeModalText": "Isso pode levar um minuto. Vamos levá-lo para a próxima etapa assim que seu documento estiver pronto.",
        "progressBarText": "Mesclando...",
        "title": "Mesclar documentos"
      },
      "passcodeCharacterWarning": "O código secreto pode conter apenas dígitos.",
      "passcodeDescription": "Os destinatários devem inserir um código secreto {<fiveDigit>} para ter acesso aos documentos.",
      "passcodeFiveDigit": "5 dígitos",
      "passcodeInfoMessage": "Você precisará compartilhar este código secreto com cada destinatário.",
      "passcodeLabel": "Código secreto",
      "passcodeLengthError": "Escolha um código secreto ou desative essa opção de segurança",
      "passcodeLengthErrorEditMode": "O código secreto deve ter 5 dígitos",
      "pending": "Pendente",
      "personalFolders": "Pastas pessoais",
      "placeFields": "Posicionar campos",
      "prepareDocument": {
        "components": {
          "attachment": "Anexo",
          "checkmarkAnnotation": "Adicionar marca de seleção",
          "editor": {
            "chargeAfterExecutedTooltip": "Use esta opção se quiser cobrar o cartão dos signatários depois que todos assinarem",
            "collectCreditCardTooltip": "Use essa opção se quiser apenas obter as informações do cartão",
            "defaultHelpText": {
              "attachmentComponent": "Faça o upload do seu anexo aqui",
              "paymentComponent": "Adicione os dados do seu cartão aqui"
            },
            "fieldSubtypeLabel": "Subtipo de campo",
            "fontSizeLabel": "Tamanho da fonte",
            "helpTextLabel": "Adicionar texto de ajuda",
            "paymentAmountLabel": "Valor do pagamento (USD)",
            "paymentAmountPlaceHolder": "Insira o valor do pagamento"
          },
          "textAnnotation": "Adicionar texto"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "Cada lista suspensa precisa ter pelo menos duas opções",
          "paymentAmountInvalidError": "Insira um valor de pagamento para continuar"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Os signatários não têm nada para assinar.",
          "cancelButtonText": "Voltar",
          "confirmButtonText": "Continuar mesmo assim",
          "description": "Você pode continuar para a próxima etapa mesmo assim, ou voltar e adicionar campos para sejam preenchidos.",
          "subtitle": "Esses signatários não têm nada para assinar:",
          "title": "Esqueceu algum campo?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "ID do documento",
          "dueDate": "Data de vencimento",
          "otherDetails": "Outros detalhes",
          "recipients": "Destinatários",
          "sender": "Remetente",
          "sentOn": "Enviado em",
          "signerSequencing": "Sequenciamento do signatário aplicado",
          "signers": "Signatários"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "Exibir detalhes"
        },
        "downloadModal": {
          "combinedDescription": "Inclui certificado de assinatura e documento preenchido",
          "combinedTitle": "PDF combinado",
          "completedDocumentDescription": "Documento assinado com campos preenchidos",
          "completedDocumentTitle": "Documento preenchido",
          "done": "Concluído",
          "originalDescription": "Documento PDF sem campos de assinatura",
          "originalTitle": "PDF original",
          "signatureCertificateDescription": "Certificado de confiança do Sharefile",
          "signatureCertificateTitle": "Certificado de assinatura",
          "title": "Baixar documento"
        },
        "pageHeader": {
          "completed": "Concluído",
          "download": "Download",
          "downloadOriginalDocument": "Baixar documento original",
          "inProgress": "Em andamento"
        }
      },
      "previousStep": "Passo anterior",
      "processingState": "Processando...",
      "radio": "Grupo de rádio",
      "readyState": "Pronto!",
      "reasonDeclineLabel": "Motivo da recusa",
      "reasonVoidLabel": "Razão nula",
      "recipientDuplicate": "Esta pessoa já é um observador",
      "recipientEmail": "Email",
      "recipientListHeading": "Quem são os destinatários?",
      "recipientName": "Nome",
      "recipientNoName": "Este campo é obrigatório",
      "recipientRemindButtonLabel": "Lembrete",
      "recipientRole": "Função",
      "recipientRoleSigner": "Signatário",
      "recipientRoleViewer": "Observador",
      "recipientStatusSigned": "Assinado",
      "recipientUnlockLabel": "Desbloquear",
      "recipientsLabel": "Destinatários",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "Em breve, o ShareFile permitirá que você carregue um CSV de destinatários para que possa solicitar uma assinatura de até 300 pessoas de uma só vez. Até lá, isso ainda é possível no RightSignature.",
          "closeButton": "Cancelar",
          "header": "Procurando envio em massa?",
          "redirectButton": "Ir para o RightSignature"
        },
        "packager": {
          "bodyLineOne": "Em breve, o ShareFile permitirá que você envie vários documentos em uma solicitação de assinatura. Até lá, isso ainda será possível no RightSignature.",
          "closeButton": "Cancelar",
          "header": "Precisa enviar vários documentos?",
          "redirectButton": "Ir para o RightSignature"
        },
        "template": {
          "bodyLineOne": "Em breve, você poderá criar e usar modelos de assinatura no ShareFile.",
          "bodyLineTwo": "Prepare-se experimentando-os no RightSignature.",
          "closeButton": "Não agora",
          "header": "Simplifique seu processo com modelos",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Experimentar os modelos"
        }
      },
      "referenceIdLabel": "ID de referência",
      "reminderSent": "Lembrete enviado",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Remover signatários",
        "content": "Todos os signatários importados de {<fileName>} serão removidos.",
        "title": "Tem certeza de que deseja remover estes signatários?"
      },
      "removeCheckboxGroup": "Remover grupo de caixas de seleção",
      "removeFieldLabel": "Remover campo",
      "removeFileDisabledTooltip": "O documento não pode ser removido",
      "removeFileFailed": "Não foi possível remover o arquivo. Tente novamente.",
      "requestSignatureButton": {
        "label": "Criar solicitação de assinatura"
      },
      "requireGroup": "Exigir grupo",
      "required": "Obrigatório",
      "requiredFieldLabel": "Campo obrigatório",
      "requiredFieldsLabel": "Exigir campos",
      "reviewAndSend": "Revisar e enviar",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Este campo não pode estar vazio"
          }
        }
      },
      "save": "Salvar",
      "saveCompletedDocument": "Salvar documento concluído",
      "savedSignaturesLabel": "Selecione a assinatura a ser aplicada no documento",
      "search": "Pesquisar",
      "securityOptionsLabel": "Opções de segurança",
      "selectOneOrMoreLabel": "Selecione uma ou mais",
      "selectOnlyOneLabel": "Selecione apenas uma",
      "selfSignCheckDisabledToolTip": "A autoassinatura não está disponível na seleção de um modelo",
      "selfSignCheckLabel": "Sou o único signatário",
      "selfSignCheckToolTip": "Assine você mesmo o documento e compartilhe-o. Ele será salvo na pasta selecionada.",
      "selfSignSucessMessage": "Documento salvo",
      "selfSignature": "Sua assinatura",
      "sendForSignature": "Enviar para assinatura",
      "sendInBulk": {
        "allSigners": "Todos os {<signersCount>} singatários",
        "bulkSigners": "Signatários em massa",
        "couldTakeAMoment": "Isso pode demorar um pouco.",
        "disableTooltipTextForPLG": "Atualize seu plano para obter acesso",
        "downloadCSVTemplateButton": "Baixe o modelo de CSV",
        "email": "Email",
        "errors": {
          "correctTheFollowingError": "Corrija os seguintes erros em seu arquivo e tente novamente.",
          "exceedsRecipientsLimit": "{<fileName>} excede nosso limite de 300 signatários.",
          "fileSizeExceeded": "O arquivo é maior que o nosso limite de 5MB.",
          "onlyOneFile": "Você só pode importar signatários de um único arquivo.",
          "supportedFileType": "Você só pode importar signatários de um arquivo .csv.",
          "unableToLoadSpreadsheet": "Faltam algumas informações importantes em seu arquivo."
        },
        "gatheringSignersFromFile": "Obtendo signatários do seu arquivo...",
        "goBack": "Voltar",
        "import": "Importar",
        "importSigners": "Importar signatários",
        "importSignersModalText1": "Preparamos um modelo de CSV para você. Faça o download e preencha os campos de nome e e-mail (obrigatório).",
        "importSignersModalText2": "CSV é o único formato de arquivo compatível. O número de signatários não pode exceder 300.",
        "name": "Nome",
        "note": "Nota: ",
        "previewingTheSigners": "Pré-visualização dos primeiros {<firstSignersCount>} de {<signersCount>} signatários",
        "selectDifferentFile": "Selecionar outro arquivo",
        "title": "Enviar em massa",
        "tooltipText": "O envio em massa só pode ser usado com modelos de um único signatário",
        "uploadFileText": "Arraste seu CSV para cá ou"
      },
      "sendRevisedRequest": "Enviar solicitação revisada",
      "sendSignatureRequest": "Enviar solicitação de assinatura",
      "sendSigningLink": {
        "gotIt": "OK",
        "sentLinkInfoBanner": "Enviamos um link para você",
        "signingLinkInfoMessage1": "Verifique seu e-mail {<email>} para obter um link para assinar este documento.",
        "signingLinkInfoMessage2": "Em breve, você poderá assinar diretamente no Sharefile."
      },
      "senderRoleChangeTooltip": "O remetente não pode ser um observador",
      "sentByLabel": "Enviado por",
      "setSigningOrder": "Definir ordem de assinatura",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Cancelar",
          "createFolderLabel": "Criar pasta",
          "disabledNodeUploadReason": "É necessária permissão de upload para selecionar esta pasta",
          "disabledSharedFoldersReason": "Não é possível selecionar pastas compartilhadas de nível superior",
          "modalTitle": "Salvar o contrato assinado em...",
          "selectFolderButtonLabel": "Selecionar"
        }
      },
      "sharedFolders": "Pastas compartilhadas",
      "showAll": "Mostrar tudo",
      "signButtonLabel": "Assinar",
      "signYourself": "Assinar você mesmo",
      "signature": "Assinatura",
      "signatureAnnotationMessage": "Clique e arraste para traçar sua assinatura dentro da caixa",
      "signatureAnnotationTextMessage": "Digite sua assinatura no campo abaixo",
      "signatureRequest": "Solicitação de assinatura",
      "signatureRequestErrorMessage": "Falha na solicitação de assinatura",
      "signatureRequestSuccessMessage": "Solicitação de assinatura enviada",
      "signer": "Signatário",
      "signerDescription": {
        "annotation": "Crie campos para si mesmo e preencha-os com antecedência",
        "bulkSigners": "Você está criando campos para todos os signatários",
        "signers": "Você deve criar campos para cada signatário"
      },
      "signerMessageInputLabel": "Nota para todos os signatários (opcional)",
      "signerMessageInputLabelTooltip": "Incluiremos essa nota na notificação por email que é enviada aos signatários",
      "signerMessageInputPlaceholder": "Adicionar uma nota personalizada a todos os signatários",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Nome e ID do documento",
            "dueDateTitle": "Data de vencimento",
            "senderInfoTitle": "Remetente",
            "senderMessageTitle": "Mensagem do remetente"
          },
          "fields": {
            "addInitials": "Adicionar iniciais",
            "attachment": {
              "buttonText": "Anexar arquivo",
              "fileUploadingError": "Não é possível carregar o arquivo. Tente novamente",
              "modalButtonContinue": "Concluído",
              "modalDeleteFileCancelButton": "Cancelar",
              "modalDeleteFileOkButton": "Excluir",
              "modalDeleteFileText": "Tem certeza de que deseja excluir {<fileName>}",
              "modalDeleteFileTitle": "Excluir arquivo",
              "modalTitle": "Carregar arquivo",
              "modalUploadAnotherFileText": " e carregar um novo arquivo?",
              "uploadDifferentFileButton": "Carregar arquivo diferente",
              "uploadHint": "Único arquivo",
              "uploadText": "Clique ou arraste o arquivo para esta área para carregá-lo",
              "uploadingStatus": "Carregando"
            },
            "autofilled": "Preenchido automaticamente",
            "dropdown": {
              "clearSelection": "Limpar seleção",
              "selectOption": "Selecionar opção"
            },
            "enterText": "Insira o texto",
            "format": "Format",
            "optional": "Opcional",
            "paymentField": {
              "amount": "Quantia",
              "authorizePayment": "Autorizar pagamento",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Alterar",
              "clear": "Limpar",
              "creditCardModalDetails": {
                "authorizeMessage": "Concordo em ser cobrado {<paymentAmount>} após enviar este documento",
                "cvcTooltipMessage": "Um código de segurança de três ou quatro dígitos impresso na parte traseira (ou frontal para AMEX) do seu cartão de crédito",
                "labels": {
                  "cardNumber": "Número do cartão",
                  "cvc": "CVC",
                  "expiryDate": "Data de vencimento"
                },
                "modalTitle": "Editar detalhes do cartão",
                "placeholders": {
                  "cardNumber": "Insira o número do cartão",
                  "cvc": "Insira o CVC"
                }
              },
              "creditDetails": "Insira os detalhes do cartão"
            },
            "required": "Obrigatório",
            "selectDate": "Selecionar data",
            "signature": {
              "clearSignatureButtonText": "Limpar assinatura",
              "editSignatureButtonText": "Editar assinatura",
              "openSignatureModalText": "Assine aqui",
              "prefilledSignatureText": "Para sua conveniência, preenchemos previamente o seu nome. Você poderá alterá-lo se precisar."
            }
          },
          "navigation": {
            "next": "Avançar",
            "nextField": "Próximo campo",
            "startSigning": "Começar a assinar"
          },
          "progressBar": {
            "shortSuccessText": "Você preencheu todos os campos obrigatórios",
            "status": "{{fieldsFilled}}/{{totalFields}} campos obrigatórios",
            "submitAction": "Enviar",
            "successText": "Você preencheu todos os campos obrigatórios. Quando terminar de revisar, você pode {<submitAction>} o documento."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Acompanhe seu progresso"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "Você não tem campos para preencher",
              "optionalCount": "{{count}} campo opcional",
              "optionalCount_plural": "{{count}} campos opcionais",
              "optionalOnly": "Você tem {<optionalCountLabel>} para preencher",
              "pending": "Pendente",
              "requiredAndOptional": "Você tem {<requiredCountLabel>} e {<optionalCountLabel>} para preencher",
              "requiredCount": "{{count}} campo obrigatório",
              "requiredCount_plural": "{{count}} campos obrigatórios",
              "requiredOnly": "Você tem {<requiredCountLabel>} para preencher"
            },
            "label": {
              "details": "Detalhes",
              "fields": "Campos"
            }
          }
        },
        "declineDocument": {
          "header": "Documento recusado",
          "message": "Você recusou este documento. Se você ainda deseja assiná-lo, peça ao remetente para enviar um novo documento",
          "sender": "Remetente"
        },
        "header": {
          "documentActions": {
            "actions": "Ações",
            "actionsAndDetails": "Detalhes e ações",
            "declineDocument": "Recusar documento",
            "declineFailed": "Não foi possível recusar o documento. Tente novamente",
            "downloadOriginal": "Baixar PDF original",
            "saveFailed": "Não foi possível salvar seu progresso. Tente novamente",
            "saveProgress": "Salvar progresso",
            "saveSuccess": "Progresso salvo"
          },
          "moreActions": "Mais ações"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Falha na autenticação!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancelar",
            "decline": "Recusar",
            "reason": "Motivo",
            "reasonHelp": "Este motivo será compartilhado com o remetente",
            "reasonPlaceholder": "Por que você está recusando?",
            "title": "Recusar documento",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} tentativa restante",
            "attemptsAmountPlural": "{{attemptsAmount}} tentativas restantes",
            "contentMessage": "Insira um código secreto de 5 dígitos fornecido pelo remetente para acessar este documento.",
            "inputLabel": "Insira o código secreto",
            "inputPlaceholder": "Insira o código secreto de 5 dígitos",
            "inputValidationMessage": "O código secreto deve ter 5 dígitos",
            "inputValueRequiedMessage": "Este campo é obrigatório",
            "invalidPasscodeMessage": "Código secreto inválido.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) convidou você para assinar {<documentName>}",
            "unlockButton": "Desbloquear",
            "welcomeMessage": "Bem-vindo!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "Enviamos uma cópia do documento preenchido para {<email>}",
            "downloadButton": "Baixar documento",
            "notAllSignedText": "Enviaremos uma cópia do documento assinado para {<email>} assim que todos os signatários tiverem assinado esse documento",
            "reviewButton": "Revisar documento assinado",
            "title": "Documento enviado"
          },
          "termsAndConditions": {
            "continueButton": "Continuar",
            "esignConsent": "Consentimento de assinatura eletrônica",
            "footerEsignConsent": "Você está familiarizado e concorda com nosso {<link>}",
            "footerTerms": "Você está familiarizado e concorda com nossos {<linkTermsOfUse>} e {<linkPrivacyPolicy>}",
            "footerTitle": "Ao continuar, você confirma que:",
            "greetings": "Olá, {{signerName}},",
            "privacyPolicy": "Política de privacidade",
            "senderMessageTitle": "Mensagem do remetente",
            "termsOfUse": "Termos de Uso",
            "text": "{{senderName}} ({{senderEmail}}) convidou você para assinar {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Enviar documento",
          "submit": "Enviar",
          "submitConfirm": "Tem certeza de que está pronto para enviar este documento?",
          "submitWarning": "Você não poderá fazer alterações após o envio",
          "submitWithUnfilledOptionalFields": "Você pulou um campo opcional. Tem certeza de que está pronto para enviar este documento?",
          "submitWithUnfilledOptionalFields_plural": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Preencha todos os campos obrigatórios para enviar o documento"
        }
      },
      "signerSequencingDisabledTooltip": "A ordem de assinatura é aplicável somente quando há mais de um signatário",
      "signerSequencingNotApplicableTooltip": "A ordem de assinatura não se aplica aos observadores",
      "signers": "Signatários",
      "signersLabel": "Signatários",
      "singerSequencingAppliedText": "O sequenciamento do signatário foi aplicado",
      "sizeLimitFile": "Arquivo {{filename}} ",
      "sizeLimitMessage": "excede nosso limite de 20 MB",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Remover modelo",
              "content": "A lista de signatários em massa será perdida e você precisará carregar um novo documento para continuar.",
              "header": "Tem certeza de que deseja remover este modelo"
            },
            "modal": {
              "confirmButton": "Remover documento",
              "content": "A remoção de um documento exclui todos os destinatários predefinidos e seus campos atribuídos. Tem certeza de que deseja remover o documento?",
              "header": "Remover documento"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Selecionar",
              "createdBy": "Criado por:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Qualificado para envio em massa",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Falha ao carregar modelos. Tente novamente mais tarde",
            "footer": {
              "buttonText": "Criar um modelo",
              "question": "Não consegue encontrar o que procura?"
            },
            "noDocuments": "Você ainda não tem nenhum modelo",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Não há nada aqui",
            "noSearchResultsDesc": "Parece que nada corresponde à sua pesquisa.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Funções:",
            "title": "Selecione um modelo"
          },
          "sourceName": "Usar um modelo"
        },
        "upload": {
          "basefileProcessingFailed": "Falha ao processar {{ filename }} arquivo. Carregue o arquivo novamente",
          "failed": "Falha ao carregar o arquivo {{ filename }}",
          "numberLimitMessage": "Não é possível fazer upload de mais de 5 arquivos"
        }
      },
      "statusFilter": "Status",
      "switch": "Mudar para a experiência antiga",
      "template": "Modelo",
      "temporaryRedirectText": "Mudar temporariamente para RightSignature",
      "text": "Texto",
      "textAlignLabel": "Alinhar texto",
      "type": "Tipo",
      "unableToFetchFileDetails": "Não foi possível obter detalhes do arquivo",
      "unableToProceedNoDocumentError": "Não é possível continuar, adicione um documento",
      "unableToProceedNoSignerError": "Não é possível continuar, adicione pelo menos um signatário",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Esse tipo de documento não tem suporte. Os tipos de arquivo com suporte são: .doc, .docx, .txt e .pdf",
      "updateDocumentErrorMessage": "Falha ao atualizar o documento.",
      "uploadDocumentErrorMessage": "Faça upload de um documento para passar para a próxima etapa",
      "uploadedFilesProcessingErrorMessage": "Um ou mais arquivos enviados ainda estão sendo processados",
      "useSaved": "Usar salvo",
      "userFetchErrorMessage": "Falha ao obter as informações de usuário",
      "view": "Visualizar",
      "viewer": "Observador",
      "viewerUniqueEmailErrorMessage": "O email do observador deve ser exclusivo",
      "viewersLabel": "Observadores",
      "void": "Nulo",
      "voidConfirmationTitle": "Confirmação de cancelamento do documento",
      "zoomIn": "Mais zoom",
      "zoomOut": "Menos zoom"
    }
  },
  {
    "key": "ru",
    "content": {
      "AccountLimitReached": "Количество документов в учетной записи превышает допустимый предел.",
      "AttachedByLabel": "Вложено пользователем {{signer}}",
      "AttachmentDetailsLabel": "Вложения",
      "AttachmentFieldLabel": "Вложение",
      "CheckFieldLabel": "Флажок",
      "CheckGroupFieldLabel": "Группа флажков",
      "DateFieldLabel": "Дата",
      "InitialsFieldLabel": "Инициалы",
      "PaymentField": {
        "chargeAfterExecutedLabel": "Оплата после подписания",
        "chargedLabel": "Списано {<paymentAmount>}",
        "collectCardDetailsLabel": "Сбор данных карты",
        "defaultLabel": "Оплата",
        "paymentFailedLabel": "Платеж не прошел"
      },
      "PaymentFieldLabel": "Поле платежа",
      "SelectFieldLabel": "Выпадающее меню",
      "SignatureFieldLabel": "Подпись",
      "TextFieldLabel": "Текст",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "В текущем плане ограничено количество документов, которые можно отправить на подпись, и этот предел был достигнут. Но вы все равно можете подписать документы самостоятельно.",
        "upgradeTrialBanner": "В вашей пробной версии осталось {<documentsLeft>}."
      },
      "accountLimitExhaustedMessage": "Не удается отправить документ на подпись, учетная запись исчерпала лимит. Пожалуйста, обновите учетную запись.",
      "accountStatsFetchErrorMessage": "Не удалось получить сведения об учетной записи",
      "add": "Добавить",
      "addCheckbox": "Добавить флажок",
      "addFilesFromSharefile": "Добавить элементы из ShareFile",
      "addMyself": "Добавить себя",
      "addOptionLabel": "Добавить параметр",
      "addRecipient": "Добавить еще одного получателя",
      "addSignature": "Добавить подпись",
      "annotator": "Вы (сейчас)",
      "annotatorOptionTooltip": "Заполните поля заранее",
      "apply": "Применить",
      "archiveLocationBlank": "Выберите папку для хранения подписанного документа",
      "assignedToLabel": "Назначено",
      "attachmentFieldLabel": "Вложение",
      "browseFiles": "Обзор файлов",
      "bulkSend": "Массовая отправка",
      "cancel": "Отмена",
      "changesSaved": "Изменения сохранены",
      "checkbox": "Флажок",
      "checkboxGroup": "Группа флажков",
      "checkboxes": "Флажки",
      "clear": "Очистить",
      "clearBackground": "Clear background",
      "close": "Закрыть",
      "completed": "Завершено",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} selected fields",
      "confirm": "Подтвердить",
      "continue": "Продолжить",
      "createDocument": {
        "almostDone": "Almost done. Please don't click Refresh or Back button"
      },
      "createDocumentErrorMessage": "Не удалось создать документ",
      "creationDateLabel": "Дата отправления",
      "dashboard": {
        "adminView": "Представление администратора",
        "adminViewTooltipText": "Просмотр всех документов, отправленных из этой учетной записи",
        "autoDeleteText": "Auto-deletes in {{remaining}} day(s)",
        "clearFilters": "Очистить фильтры",
        "clearSearch": "Очистить поиск",
        "continueAction": "Продолжить",
        "deleteAction": "Удалить",
        "downloadPdf": "Загрузить",
        "esignLeftNav": "Подписи",
        "loadMore": "Загрузить еще",
        "overdue_0": "{{count}} days overdue",
        "overdue_1": "{{count}} days overdue",
        "overdue_2": "{{count}} days overdue",
        "signatureDue": "Срок: {{dueDate}}",
        "signatureRequests": "Запросы на подпись",
        "signatureSettings": "Signature settings",
        "signaturesSubmitted": "Представленные подписи",
        "templates": "Шаблоны",
        "title": "Запросы на подпись"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "Похоже, поиск по вашим фильтрам не дал никаких результатов.",
          "title": "Здесь ничего нет"
        },
        "noRequestsCreated": {
          "description": "Нужно получить подпись? Создайте заявку.",
          "title": "У вас пока нет запросов на подпись"
        },
        "noSearchResults": {
          "description": "Похоже, ваш поиск не дал никаких результатов.",
          "title": "Здесь ничего нет"
        }
      },
      "date": "Дата",
      "dateExample": "Пример:",
      "dateExtendError": "Выберите будущую дату",
      "dateFormatLabel": "Формат",
      "dateSignedLabel": "Автоматическое заполнение даты подписания",
      "dateSignedTooltip": "Получатель не сможет редактировать поле. Оно будет заполнено автоматически в день подписания документа.",
      "declinedByLabel": "Отклонено пользователем {{name}}",
      "defaultBadgeLabel": "Default",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "Отправка электронного письма с заполненным PDF-файлом всем сторонам",
          "attachCompletedPDFCheckboxTooltip": "После последней подписи мы отправим всем электронное письмо с заполненным PDF-файлом в виде вложения (если он не превышает 10 МБ).",
          "sectionHeader": "Вложения"
        },
        "header": "Изменение настроек по умолчанию",
        "headerInfo": "Любые внесенные здесь изменения будут применены ко всем текущим запросам на подпись.",
        "hipaaConfirmationBodyFirst": "При применении этого параметра все получатели получат копию подписанного документа в виде вложения электронной почты. Как клиент HIPAA, вы несете ответственность за подтверждение того, что получатели разрешают обработку своей информации таким образом.",
        "hipaaConfirmationBodySecond": "Действительно применить этот параметр?",
        "hipaaConfirmationButton": "Применить параметр",
        "hipaaConfirmationHeader": "Рекомендации по HIPAA",
        "reminders": {
          "options": {
            "daily": "Ежедневно",
            "noAuto": "Никогда",
            "weekly": "Еженедельно"
          },
          "reminderFrequencyLabel": "Отправка автоматических напоминаний",
          "reminderFrequencyTooltip": "Отправляйте напоминания получателям по электронной почте еженедельно (через 3, 7, 14, 21 и 28 дней) или ежедневно (через 7 дней), пока они не подпишутся.",
          "sectionHeader": "Напоминания"
        },
        "signature": {
          "allowDrawnSignatureLabel": "Разрешить рукописные подписи",
          "allowTypedSignatureLabel": "Разрешить печатные подписи",
          "applyBlueInkSignatureLabel": "Применять синие чернила к подписям",
          "applyBlueInkSignatureTooltipAdmin": "Если вам нужно изменить этот параметр, свяжитесь с администратором.",
          "applyBlueInkSignatureTooltipNonAdmin": "Используйте синие чернила для всех подписей, чтобы отличить подписанные оригиналы от фотокопий.",
          "sectionHeader": "Типы подписей"
        },
        "signer": {
          "allowDownload": "Разрешить подписывающим сторонам загружать исходный документ перед подписанием",
          "sectionHeader": "Разрешения подписывающей стороны"
        }
      },
      "defaultSettingsDescription": "Изменяйте настройки напоминаний, вложений электронной почты и типов подписей.",
      "defaultSettingsEditLabel": "Изменение настроек по умолчанию",
      "defaultSettingsLabel": "Настройки по умолчанию",
      "defaultSettingsUpdateFailed": "Не удалось обновить настройки по умолчанию",
      "defaultSettingsUpdateSuccess": "Настройки по умолчанию обновлены",
      "defaultValueLabel": "Добавить текст по умолчанию",
      "delete": "Удалить",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "Это приведет к безвозвратному удалению запроса на подпись. После этого действие нельзя будет отменить.",
        "deleteConfirmationMessageSecond": "Подписывающие и просматривающие лица получат электронное сообщение с заполненным документом и сертификатом подписи для своих записей.",
        "deleteRequest": "Удалить запрос",
        "deleteRequestSuccess": "Запрос удален"
      },
      "deleteDraftDocumentLabel": "Удалить запрос на подпись",
      "deleteDraftDocumentModalContent": "Действительно удалить запрос на подпись? Это действие нельзя отменить.",
      "deleteDraftDocumentSuccess": "Запрос на подпись удален",
      "deleteViewerWarningHeader": "Are you sure you want to remove this recipient?",
      "deleteViewerWarningMessage": "They’ll no longer have access to this signature request.",
      "detailsTabSecurityOptions": {
        "editPasscode": "Изменить секретный код",
        "kba": "Проверка подлинности на основе знаний",
        "passcode": "Секретный код",
        "title": "Применен параметр безопасности",
        "updatePasscodeSuccess": "Секретный код этого документа был обновлен."
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "You can't revise requests that were sent with bulk send",
          "signerSigned": "You can't revise requests once someone has signed",
          "userNotSender": "You can't revise requests that you didn't send"
        }
      },
      "documentAlreadySentErrorDetails": "Не удается открыть отправленный документ для редактирования, запустите новую цепочку.",
      "documentAlreadySentErrorTitle": "Документ уже отправлен",
      "documentForSignature": "Документ для отправки",
      "documentForceCompleted": "Запрос принудительно выполнен",
      "documentLockedMessage": "Этот получатель временно заблокирован из-за нескольких неудачных попыток проверки подлинности",
      "documentNameBlank": "Введите название документа",
      "documentNameInputDisabledTooltip": "Имя документа нельзя редактировать",
      "documentNameInputLabel": "Название документа",
      "documentStates": {
        "declined": "Отклонено",
        "draft": "Черновики",
        "editing": "Приостановлен для внесения изменений",
        "executed": "Завершено",
        "expired": "Срок действия истек",
        "pending": "Выполняется",
        "voided": "Аннулировано"
      },
      "documentThumbnailAltText": "Эскиз первой страницы",
      "documentTransactionCreateErrorMessage": "Не удалось создать транзакцию для документа",
      "documentTransactionGetErrorMessage": "Не удалось получить транзакцию с документом",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "Разблокировка документа выполнена успешно",
      "documentView": {
        "documentPageIndex": "Страница {<currentPage>} из {{totalPages}}",
        "pageChangeInputLabel": "Изменить страницу на",
        "pageDown": "Следущая страница",
        "pageUp": "Предыдущая страница"
      },
      "documentVoided": "Запрос на подпись аннулирован",
      "dontShowAgain": "Больше не показывать",
      "downloadOptions": {
        "combinedPDF": "Объединенный PDF-файл",
        "completedPDF": "Готовый PDF-файл",
        "download": "Загрузить",
        "downloadErrorMessage": "Неподдерживаемый тип загрузки: {{downloadType}}",
        "downloadOriginal": "Оригинальный PDF-файл",
        "signatureCertificate": "Сертификат подписи"
      },
      "draftSaveErrorMessage": "Не удалось сохранить запрос на подпись",
      "draftSaveSuccessMessage": "Запрос на подпись сохранен",
      "draw": "Нарисовать",
      "dueDate": "Срок выполнения",
      "dueDateUpdated": "Срок выполнения обновлен",
      "edit": "Изменить",
      "editDocumentModal": {
        "cancel": "Назад",
        "confirm": "Внести изменения",
        "contentLine1": "Если вам нужно добавить или удалить получателей или поля, а также изменить параметры безопасности, вы можете изменить этот запрос.",
        "contentLine2": "Мы приостановим запрос, пока вы вносите изменения. Это означает, что все, кто еще не подписался, временно потеряют доступ. Мы отправим им новую ссылку, когда вы закончите.",
        "contentLine3": "После того как вы начнете вносить изменения, вы не сможете возобновить выполнение запроса без отправки исправленного документа.",
        "title": "Действительно изменить этот запрос?"
      },
      "editDueDate": "Изменить срок выполнения",
      "editLeftMidwayModal": {
        "cancel": "Назад",
        "confirm": "Закончить позже",
        "contentLine1": "Подписывающие стороны не могут получить доступ к этому запросу, пока он приостановлен для внесения изменений. Если вы закончили, вернитесь назад и выберите ",
        "contentLine2": "Если вы не готовы, вы можете вернуться и закончить внесение изменений позже.",
        "title": "Вы все еще вносите изменения?"
      },
      "editRecipients": "Изменить получателей",
      "editSignatureRequest": "Внести изменения",
      "editSigner": "Edit signer",
      "editViewer": "Edit viewer",
      "emptyFolder": "Эта папка пуста",
      "enforceStorageUsage": "Невозможно будет создавать новые запросы на подпись, пока не освободится место",
      "expirationDateMessage": "Дата окончания срока действия: {{expirationDate}}",
      "expirationDaysLabel": "дн.",
      "expirationInputDescription": "Документ больше не будет доступен для подписания по истечении указанного количества дней:",
      "expirationInputLabel": "Окончание срока действия",
      "extendDueDate": "Продлить срок выполнения",
      "extendExpirationDate": "Продлить",
      "favorites": "Избранное",
      "field": "поле",
      "fieldNameLabel": "Поле имени",
      "fieldRequired": "Это обязательное поле",
      "fields": "Поля",
      "fieldsAssignedWarningHeader": "Поля, назначенные подписывающим сторонам",
      "fieldsAssignedWarningMessage": "Все поля, назначенные подписывающим сторонам, будут удалены.",
      "fieldsDescription": "Выберите тип поля для размещения в документе",
      "fileArchivalConfigurator": {
        "editButtonLabel": "Изменить",
        "sectionLabel": "Где следует хранить подписанный документ?"
      },
      "fileNotSupportedTooltip": "Неподдерживаемый формат файла",
      "filesAndRecipients": "Файлы и получатели",
      "filterSelected_0": "{{count}} filters",
      "filterSelected_1": "{{count}} filters",
      "filterSelected_2": "{{count}} filters",
      "forceComplete": "Принудительно завершить",
      "forceCompleteTitle": "Принудительно завершить запрос",
      "forceCompleteWarningFirst": "При этом все поля, назначенные подписывающим лицам, которые еще не подписали документ, будут удалены, и документ будет помечен как выполненный (окончательное состояние). Как только это будет сделано, действие нельзя будет отменить.",
      "forceCompleteWarningSecond": "Отображение нового статуса на панели мониторинга может занять некоторое время.",
      "generateNewPasscodeButtonLabel": "Сгенерировать новый секретный код",
      "genericError": "Произошла ошибка",
      "groupRule": "Групповое правило",
      "hasAtleastOneSignerErrorMessage": "Чтобы продолжить, добавьте хотя бы одну подписывающую сторону",
      "importFields": {
        "linkText": "Import fields",
        "modal": {
          "assignImportedFields": "Assign Imported Fields",
          "body": {
            "buttonText": "Select",
            "searchPlaceholder": "Search by document name, file name, sender or role",
            "sentBy": "Sent by"
          },
          "componentsImported": "{{count}} fields imported",
          "fields": "field(s)",
          "importFieldsButtonText": "Import fields",
          "importFrom": "Import from:",
          "mapAllRecipients": "Assign fields to new recipients to continue",
          "newRecipient": "New recipient",
          "noDocuments": "You don’t have any documents yet",
          "originalRecipient": "Original recipient",
          "selectDifferentDocument": "Select different document",
          "selectRecipient": "Select recipient",
          "sentBy": "Sent by",
          "title": "Select a document to import fields",
          "titleImportFields": "Import fields",
          "titleImportFieldsWithCount": "Import {{count}} fields"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Go back",
          "contentLine1": "{<documentName>} doesn't have any fields that we can import. You can choose a different document or create new fields.",
          "title": "This document doesn't have any fields"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Go back",
          "confirmButtonText": "Import fields anyway",
          "contentLine1": "The document you selected has a different number of pages than the one you're currently working on.",
          "contentLine2": "You can still import fields, but we'll only look at the first {{pageCount}} page(s) of the selected document. Any fields on subsequent pages won't be imported.",
          "title": "These documents look a little different"
        },
        "tooltipText": "Import fields from previously sent documents"
      },
      "initials": "Инициалы",
      "invalidEmail": "Недопустимый адрес электронной почты",
      "invalidExpirationDaysError": "Введите число от 1 до 365",
      "invalidExpirationDaysNotificationMessage": "Выберите дату окончания срока действия",
      "invalidRecipientErrorMessage": "Требуется имя и адрес электронной почты каждого получателя",
      "invalidSignatureRequest": "Запрос на подпись кажется недействительным, проверьте URL-адрес",
      "invite": "Invite",
      "inviteViewers": "Invite viewers",
      "kbaDescription": "Требуйте от получателей подтвердить свою личность с помощью теста, основанного на знаниях. Каждая подписывающая сторона должна будет пройти независимую проверку перед подписанием документа.",
      "kbaInfoMessage": "Подписывающие стороны будут заблокированы в документе после 3 неудачных попыток.",
      "kbaLabel": "Проверка подлинности на основе знаний",
      "legacyTokenInterceptor": {
        "buttonText": "Выполнение входа",
        "message": "Мы вернем вас сюда, чтобы вы могли продолжить с того места, на котором остановились.",
        "title": "Войдите в систему еще раз, чтобы получить доступ к запросам на подпись"
      },
      "messageLabel": "Сообщение получателям",
      "mixedAlignment": "Mixed alignment",
      "mixedDateFormat": "Mixed format",
      "mixedFontSizes": "Mixed font sizes",
      "moveAwaySelections": "Если покинуть текущую папку, ваш выбор не сохранится.",
      "multiLineLabel": "Разрешить несколько строк",
      "multipleRecipients": "Multiple recipients",
      "nameGroup": "Группа имен",
      "nextStep": "Следующий шаг",
      "optional": "Необязательное",
      "optionalComment": "Необязательный сопроводительный комментарий",
      "optionsLabel": "Параметры",
      "packagerConfirmationModal": {
        "contentLine1": "Нам необходимо объединить эти документы в один файл, прежде чем подготовить их к подписанию. Вы не сможете вернуться и удалить их после перехода к следующему шагу.",
        "contentLine2": "Действительно продолжить?",
        "mergeModalText": "Процесс может занять пару минут. Вы перейдете к следующему шагу, как только ваш документ будет готов.",
        "progressBarText": "Объединение...",
        "title": "Объединение документов"
      },
      "passcodeCharacterWarning": "Секретный код может содержать только цифры.",
      "passcodeDescription": "Получатели должны ввести секретный код {<fiveDigit>} для доступа к документам.",
      "passcodeFiveDigit": "5-значный",
      "passcodeInfoMessage": "Вам нужно будет поделиться этим секретным кодом с каждым получателем.",
      "passcodeLabel": "Секретный код",
      "passcodeLengthError": "Выберите секретный код или отключите этот параметр безопасности",
      "passcodeLengthErrorEditMode": "Пароль должен состоять из 5 цифр",
      "pending": "Ожидание",
      "personalFolders": "Личные папки",
      "placeFields": "Разместить поля",
      "prepareDocument": {
        "components": {
          "attachment": "Вложение",
          "checkmarkAnnotation": "Добавить галочку",
          "editor": {
            "chargeAfterExecutedTooltip": "Используйте этот параметр, если необходимо списать средства с карты после подписания всеми сторонами",
            "collectCreditCardTooltip": "Используйте этот параметр, если необходимо просто собрать информацию о карте",
            "defaultHelpText": {
              "attachmentComponent": "Отправьте свое вложение здесь",
              "paymentComponent": "Добавьте данные своей карты сюда"
            },
            "fieldSubtypeLabel": "Подтип поля",
            "fontSizeLabel": "Размер шрифта",
            "helpTextLabel": "Добавить текст справки",
            "paymentAmountLabel": "Сумма платежа (в долларах США)",
            "paymentAmountPlaceHolder": "Введите сумму платежа"
          },
          "textAnnotation": "Добавить текст"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "В каждом раскрывающемся списке должно быть как минимум 2 варианта",
          "paymentAmountInvalidError": "Введите сумму платежа, чтобы продолжить"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Signers don't have anything to sign.",
          "cancelButtonText": "Назад",
          "confirmButtonText": "Все равно продолжить",
          "description": "Вы в любом случае можете перейти к следующему шагу или вернуться назад и добавить поля для заполнения.",
          "subtitle": "Этим подписывающим сторонам нечего подписывать:",
          "title": "Вы забыли поля?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Document ID",
          "dueDate": "Due Date",
          "otherDetails": "Other details",
          "recipients": "Recipients",
          "sender": "Sender",
          "sentOn": "Sent on",
          "signerSequencing": "Signer sequencing applied",
          "signers": "Signers"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "View details"
        },
        "downloadModal": {
          "combinedDescription": "Includes signature cerificate and completed document",
          "combinedTitle": "Combined PDF",
          "completedDocumentDescription": "Signed document with completed fields",
          "completedDocumentTitle": "Completed document",
          "done": "Done",
          "originalDescription": "PDF document without any signer fields",
          "originalTitle": "Original PDF",
          "signatureCertificateDescription": "Certificate of trust from Sharefile",
          "signatureCertificateTitle": "Signature certificate",
          "title": "Download document"
        },
        "pageHeader": {
          "completed": "Completed",
          "download": "Download",
          "downloadOriginalDocument": "Download original document",
          "inProgress": "In Progress"
        }
      },
      "previousStep": "Предыдущий шаг",
      "processingState": "Обработка...",
      "radio": "Группа переключателей",
      "readyState": "Готово!",
      "reasonDeclineLabel": "Причина отказа",
      "reasonVoidLabel": "Причина аннулирования",
      "recipientDuplicate": "Этот человек уже является просматривающей стороной",
      "recipientEmail": "Электронная почта",
      "recipientListHeading": "Кто является получателями?",
      "recipientName": "Имя",
      "recipientNoName": "Это обязательное поле",
      "recipientRemindButtonLabel": "Напомнить",
      "recipientRole": "Роль",
      "recipientRoleSigner": "Подписывающая сторона",
      "recipientRoleViewer": "Просматривающая сторона",
      "recipientStatusSigned": "Подписано",
      "recipientUnlockLabel": "Разблокировать",
      "recipientsLabel": "Получатели",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "В ближайшее время в ShareFile появится возможность загружать CSV-файлы получателей, чтобы можно было одновременно запрашивать подпись у 300 человек. До тех пор все еще можно делать это в RightSignature.",
          "closeButton": "Отмена",
          "header": "Требуется пакетная отправка?",
          "redirectButton": "Перейти в RightSignature"
        },
        "packager": {
          "bodyLineOne": "В ближайшее время в ShareFile появится возможность отправлять несколько документов по одному запросу на подпись. До тех пор все еще можно делать это в RightSignature.",
          "closeButton": "Отмена",
          "header": "Требуется отправить несколько документов?",
          "redirectButton": "Перейти в RightSignature"
        },
        "template": {
          "bodyLineOne": "Вскоре вы сможете создавать и использовать шаблоны подписей в ShareFile.",
          "bodyLineTwo": "Приготовьтесь попробовать их в RightSignature.",
          "closeButton": "Не сейчас",
          "header": "Оптимизация процесса с помощью шаблонов",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "Попробовать шаблоны"
        }
      },
      "referenceIdLabel": "Идентификатор ссылки",
      "reminderSent": "Напоминание отправлено",
      "removeBulkSignersConfirmationModal": {
        "confirm": "Удалить подписывающие стороны",
        "content": "Все подписывающие стороны, импортированные из {<fileName>}, будут удалены.",
        "title": "Действительно удалить эти подписывающие стороны?"
      },
      "removeCheckboxGroup": "Удалить группу флажков",
      "removeFieldLabel": "Удалить поле",
      "removeFileDisabledTooltip": "Невозможно удалить документ",
      "removeFileFailed": "Не удалось удалить файл. Повторите попытку.",
      "requestSignatureButton": {
        "label": "Создать запрос на подпись"
      },
      "requireGroup": "Требуется группа",
      "required": "Обязательно",
      "requiredFieldLabel": "Обязательное поле",
      "requiredFieldsLabel": "Require fields",
      "reviewAndSend": "Просмотреть и отправить",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "Это поле не может быть пустым"
          }
        }
      },
      "save": "Сохранить",
      "saveCompletedDocument": "Сохранить готовый документ",
      "savedSignaturesLabel": "Select the signature to apply in the document",
      "search": "Поиск",
      "securityOptionsLabel": "Параметры безопасности",
      "selectOneOrMoreLabel": "Выберите один или несколько элементов",
      "selectOnlyOneLabel": "Выберите только один элемент",
      "selfSignCheckDisabledToolTip": "Самостоятельная подпись недоступна при выборе шаблона",
      "selfSignCheckLabel": "Я единственное подписывающее лицо",
      "selfSignCheckToolTip": "Подпишите документ самостоятельно, а затем поделитесь им. Он будет сохранен в выбранной папке.",
      "selfSignSucessMessage": "Документ сохранен",
      "selfSignature": "Ваша подпись",
      "sendForSignature": "Отправить для подписания",
      "sendInBulk": {
        "allSigners": "Все подписывающие стороны ({<signersCount>})",
        "bulkSigners": "Массовое подписание",
        "couldTakeAMoment": "Это может занять некоторое время.",
        "disableTooltipTextForPLG": "Upgrade your plan to get access",
        "downloadCSVTemplateButton": "Загрузить шаблон CSV",
        "email": "Электронная почта",
        "errors": {
          "correctTheFollowingError": "Исправьте следующие ошибки в файле и повторите попытку.",
          "exceedsRecipientsLimit": "{<fileName>} превышает ограничение в 300 подписывающих сторон.",
          "fileSizeExceeded": "Размер файла превышает ограничение в 5 МБ.",
          "onlyOneFile": "Подписывающие стороны можно импортировать только из одного файла.",
          "supportedFileType": "Подписывающие стороны можно импортировать только из файла .csv.",
          "unableToLoadSpreadsheet": "В файле отсутствует важная информация."
        },
        "gatheringSignersFromFile": "Сбор подписывающих сторон из файла...",
        "goBack": "Назад",
        "import": "Импорт",
        "importSigners": "Импорт подписывающих сторон",
        "importSignersModalText1": "Мы подготовили для вас шаблон CSV. Загрузите его и заполните поля имени и электронной почты (обязательно).",
        "importSignersModalText2": "CSV является единственным поддерживаемым форматом файлов. Количество подписывающих сторон не должно превышать 300.",
        "name": "Имя",
        "note": "Примечание. ",
        "previewingTheSigners": "Предварительный просмотр первых {<firstSignersCount>} из {<signersCount>} подписывающих сторон",
        "selectDifferentFile": "Выбрать другой файл",
        "title": "Массовая отправка",
        "tooltipText": "Массовую отправку можно использовать только с шаблонами с одной подписывающей стороной",
        "uploadFileText": "Перетащите свой файл CSV сюда или"
      },
      "sendRevisedRequest": "Отправить измененный запрос",
      "sendSignatureRequest": "Отправить запрос на подпись",
      "sendSigningLink": {
        "gotIt": "Понятно",
        "sentLinkInfoBanner": "Мы отправили вам ссылку",
        "signingLinkInfoMessage1": "Проверьте свою электронную почту {<email>}, где должно быть письмо со ссылкой для подписи этого документа.",
        "signingLinkInfoMessage2": "Скоро мы добавим возможность подписывать документы непосредственно в Sharefile."
      },
      "senderRoleChangeTooltip": "Отправитель не может быть просматривающей стороной",
      "sentByLabel": "Автор отправления",
      "setSigningOrder": "Установить порядок подписания",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "Отмена",
          "createFolderLabel": "Создать папку",
          "disabledNodeUploadReason": "Upload permission is needed to select this folder",
          "disabledSharedFoldersReason": "Не удается выбрать общие папки верхнего уровня",
          "modalTitle": "Сохранение подписанного соглашения в...",
          "selectFolderButtonLabel": "Выбрать"
        }
      },
      "sharedFolders": "Общие папки",
      "showAll": "Показать все",
      "signButtonLabel": "Подписать",
      "signYourself": "Подписать самостоятельно",
      "signature": "Подпись",
      "signatureAnnotationMessage": "Нажмите и удерживайте, чтобы нарисовать подпись внутри поля",
      "signatureAnnotationTextMessage": "Введите свою подпись в поле ниже",
      "signatureRequest": "Запрос на подпись",
      "signatureRequestErrorMessage": "Ошибка запроса подписи",
      "signatureRequestSuccessMessage": "Отправлен запрос на подпись",
      "signer": "Signer",
      "signerDescription": {
        "annotation": "Place fields for yourself and fill them out in advance",
        "bulkSigners": "You're placing fields for all signers",
        "signers": "You’ll place fields for each signer"
      },
      "signerMessageInputLabel": "Примечание для всех подписывающих сторон (необязательно)",
      "signerMessageInputLabelTooltip": "Это примечание будет включено в уведомление по электронной почте, которое будет отправлено подписывающим сторонам",
      "signerMessageInputPlaceholder": "Добавить персональное примечание для всех подписывающих сторон",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Document name and ID",
            "dueDateTitle": "Due date",
            "senderInfoTitle": "Sender",
            "senderMessageTitle": "Message from sender"
          },
          "fields": {
            "addInitials": "Add Initials",
            "attachment": {
              "buttonText": "Attach file",
              "fileUploadingError": "Unable to upload file. Please try again",
              "modalButtonContinue": "Done",
              "modalDeleteFileCancelButton": "Cancel",
              "modalDeleteFileOkButton": "Delete",
              "modalDeleteFileText": "Are you sure you want to delete {<fileName>}?",
              "modalDeleteFileTitle": "Delete file",
              "modalTitle": "Upload file",
              "modalUploadAnotherFileText": "and upload new file?",
              "uploadDifferentFileButton": "Upload different file",
              "uploadHint": "Single file",
              "uploadText": "Click or drag file to this area to upload",
              "uploadingStatus": "Uploading"
            },
            "autofilled": "Autofilled",
            "dropdown": {
              "clearSelection": "Clear selection",
              "selectOption": "Select option"
            },
            "enterText": "Enter Text",
            "format": "Format",
            "optional": "Optional",
            "paymentField": {
              "amount": "Amount",
              "authorizePayment": "Authorize payment",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Change",
              "clear": "Clear",
              "creditCardModalDetails": {
                "authorizeMessage": "I agree to be charged {<paymentAmount>} after submitting this document",
                "cvcTooltipMessage": "A three or four-digit security code printed on the back (or front for AMEX) of your credit card",
                "labels": {
                  "cardNumber": "Card Number",
                  "cvc": "CVC",
                  "expiryDate": "Expiry Date"
                },
                "modalTitle": "Edit card details",
                "placeholders": {
                  "cardNumber": "Enter card number",
                  "cvc": "Enter CVC"
                }
              },
              "creditDetails": "Enter card details"
            },
            "required": "Required",
            "selectDate": "Select date",
            "signature": {
              "clearSignatureButtonText": "Clear signature",
              "editSignatureButtonText": "Edit signature",
              "openSignatureModalText": "Sign here",
              "prefilledSignatureText": "For your convenience, we’ve pre-filled your name. You can change it if you need to."
            }
          },
          "navigation": {
            "next": "Next",
            "nextField": "Next field",
            "startSigning": "Start signing"
          },
          "progressBar": {
            "shortSuccessText": "You’ve completed all required fields",
            "status": "{{fieldsFilled}}/{{totalFields}} required fields",
            "submitAction": "Submit",
            "successText": "You’ve completed all required fields. Once you’re done reviewing, you can {<submitAction>} the document."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Track your progress"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "You have no fields to fill out",
              "optionalCount_0": "{{count}} optional fields",
              "optionalCount_1": "{{count}} optional fields",
              "optionalCount_2": "{{count}} optional fields",
              "optionalOnly": "You have {<optionalCountLabel>} to fill out",
              "pending": "Pending",
              "requiredAndOptional": "You have {<requiredCountLabel>} and {<optionalCountLabel>} to fill out",
              "requiredCount_0": "{{count}} required fields",
              "requiredCount_1": "{{count}} required fields",
              "requiredCount_2": "{{count}} required fields",
              "requiredOnly": "You have {<requiredCountLabel>} to fill out"
            },
            "label": {
              "details": "Details",
              "fields": "Fields"
            }
          }
        },
        "declineDocument": {
          "header": "Declined document",
          "message": "You have declined this document. If you still wish to sign it, please ask sender to send a new document",
          "sender": "Sender"
        },
        "header": {
          "documentActions": {
            "actions": "Actions",
            "actionsAndDetails": "Actions and details",
            "declineDocument": "Decline document",
            "declineFailed": "We couldn't decline the document. Try again",
            "downloadOriginal": "Download original PDF",
            "saveFailed": "We couldn't save your progress. Try again",
            "saveProgress": "Save progress",
            "saveSuccess": "Saved progress"
          },
          "moreActions": "More actions"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Authentication Failed!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancel",
            "decline": "Decline",
            "reason": "Reason",
            "reasonHelp": "This reason will be shared with the sender",
            "reasonPlaceholder": "Why are you declining?",
            "title": "Decline document",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} attempt remaining",
            "attemptsAmountPlural": "{{attemptsAmount}} attempts remaining",
            "contentMessage": "Enter the 5-digit passcode provided by the sender to access this document.",
            "inputLabel": "Enter passcode",
            "inputPlaceholder": "Enter 5 digit passcode",
            "inputValidationMessage": "Passcode must be 5 digits",
            "inputValueRequiedMessage": "This field is required",
            "invalidPasscodeMessage": "Invalid passcode.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}",
            "unlockButton": "Unlock",
            "welcomeMessage": "Welcome!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "We sent a copy of the completed document to {<email>}",
            "downloadButton": "Download document",
            "notAllSignedText": "We will send a copy of the signed document to {<email>} once all signers have signed this document",
            "reviewButton": "Review signed document",
            "title": "Submitted document"
          },
          "termsAndConditions": {
            "continueButton": "Continue",
            "esignConsent": "E-Sign consent",
            "footerEsignConsent": "You're acquainted with and agree to our {<link>}",
            "footerTerms": "You're acquainted with and agree to our {<linkTermsOfUse>} and {<linkPrivacyPolicy>}",
            "footerTitle": "By continuing, you confirm that:",
            "greetings": "Hi {{signerName}},",
            "privacyPolicy": "Privacy Policy",
            "senderMessageTitle": "Message from sender",
            "termsOfUse": "Terms of Use",
            "text": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Submit document",
          "submit": "Submit",
          "submitConfirm": "Are you sure you're ready to submit this document?",
          "submitWarning": "You won't be able to make changes after submitting",
          "submitWithUnfilledOptionalFields_0": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledOptionalFields_1": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledOptionalFields_2": "You skipped {{count}} optional fields. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Complete all required fields to submit the document"
        }
      },
      "signerSequencingDisabledTooltip": "Порядок подписания применим только в том случае, если имеется более 1 подписывающей стороны",
      "signerSequencingNotApplicableTooltip": "Порядок подписания не распространяется на просматривающие стороны",
      "signers": "Подписывающие стороны",
      "signersLabel": "Подписывающие стороны",
      "singerSequencingAppliedText": "Применена последовательность подписывающих лиц",
      "sizeLimitFile": "Файл {{filename}} ",
      "sizeLimitMessage": "превышает наш лимит в 20 МБ",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Remove template",
              "content": "The bulk signers list will be lost and you'll need to upload a new document to proceed.",
              "header": "Are you sure you want to remove this template"
            },
            "modal": {
              "confirmButton": "Удалить документ",
              "content": "При удалении документа удаляются все предопределенные получатели и назначенные им поля. Вы действительно хотите удалить документ?",
              "header": "Удалить документ"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "Выбрать",
              "createdBy": "Автор:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Eligible for bulk send",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "Не удалось загрузить шаблоны. Повторите попытку позже",
            "footer": {
              "buttonText": "Создать шаблон",
              "question": "Не удается найти то, что ищете?"
            },
            "noDocuments": "У вас еще нет шаблонов",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "Здесь ничего нет",
            "noSearchResultsDesc": "Похоже, ваш поиск не дал никаких результатов.",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Roles:",
            "title": "Выберите шаблон"
          },
          "sourceName": "Использовать шаблон"
        },
        "upload": {
          "basefileProcessingFailed": "Не удалось обработать файл {{ filename }}. Загрузите файл снова",
          "failed": "Не удалось загрузить файл {{ filename }}",
          "numberLimitMessage": "Невозможно загрузить более 5 файлов"
        }
      },
      "statusFilter": "Состояние",
      "switch": "Switch to old experience",
      "template": "Шаблон",
      "temporaryRedirectText": "Temporarily switch to RightSignature",
      "text": "Текст",
      "textAlignLabel": "Выровнять текст",
      "type": "Тип",
      "unableToFetchFileDetails": "Не удалось получить сведения о файле",
      "unableToProceedNoDocumentError": "Не удалось продолжить, добавьте документ",
      "unableToProceedNoSignerError": "Не удалось продолжить, добавьте хотя бы одну подписывающую сторону",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "Этот тип документа не поддерживается. Поддерживаемые типы файлов: .doc, .docx, .txt, .pdf",
      "updateDocumentErrorMessage": "Не удалось обновить документ.",
      "uploadDocumentErrorMessage": "Загрузите документ, чтобы перейти к следующему шагу",
      "uploadedFilesProcessingErrorMessage": "Один или несколько загруженных файлов все еще обрабатываются",
      "useSaved": "Use saved",
      "userFetchErrorMessage": "Не удалось получить сведения о пользователе",
      "view": "Просмотр",
      "viewer": "Viewer",
      "viewerUniqueEmailErrorMessage": "Электронная почта просматривающей стороны должна быть уникальной",
      "viewersLabel": "Просматривающие стороны",
      "void": "Аннулировать",
      "voidConfirmationTitle": "Подтверждение аннулирования документа",
      "zoomIn": "Увеличить",
      "zoomOut": "Уменьшить"
    }
  },
  {
    "key": "zh-CN",
    "content": {
      "AccountLimitReached": "该帐户已达到文档限制上限。",
      "AttachedByLabel": "附加者: {{signer}}",
      "AttachmentDetailsLabel": "附件",
      "AttachmentFieldLabel": "附件",
      "CheckFieldLabel": "复选框",
      "CheckGroupFieldLabel": "复选框组",
      "DateFieldLabel": "日期",
      "InitialsFieldLabel": "首字母缩写",
      "PaymentField": {
        "chargeAfterExecutedLabel": "签名后扣款",
        "chargedLabel": "已扣款金额: {<paymentAmount>}",
        "collectCardDetailsLabel": "收集信用卡详细信息",
        "defaultLabel": "付款",
        "paymentFailedLabel": "付款失败"
      },
      "PaymentFieldLabel": "付款字段",
      "SelectFieldLabel": "下拉菜单",
      "SignatureFieldLabel": "签名",
      "TextFieldLabel": "文本",
      "UpgradeBannerInfo": {
        "upgradeBannerText": "您当前的计划限制了可以发送以供签名的文档数量，并且您已达到上限。您仍然可以自己对文档进行签名。",
        "upgradeTrialBanner": "您的试用版中还剩 {<documentsLeft>}。"
      },
      "accountLimitExhaustedMessage": "无法发送文档以供签名，帐户已用尽限制。请升级帐户。",
      "accountStatsFetchErrorMessage": "无法检索帐户信息",
      "add": "添加",
      "addCheckbox": "添加复选框",
      "addFilesFromSharefile": "从 ShareFile 添加项目",
      "addMyself": "添加我自己",
      "addOptionLabel": "添加选项",
      "addRecipient": "添加其他收件人",
      "addSignature": "添加签名",
      "annotator": "您(现在)",
      "annotatorOptionTooltip": "提前填写字段",
      "apply": "应用",
      "archiveLocationBlank": "选择用于存储已签名的文档的文件夹",
      "assignedToLabel": "已分配给",
      "attachmentFieldLabel": "附件",
      "browseFiles": "浏览文件",
      "bulkSend": "批量发送",
      "cancel": "取消",
      "changesSaved": "更改已保存",
      "checkbox": "复选框",
      "checkboxGroup": "复选框组",
      "checkboxes": "复选框",
      "clear": "清除",
      "clearBackground": "Clear background",
      "close": "关闭",
      "completed": "已完成",
      "componentEditorHeaderLabel": "{<selectedNumberOfFields>} selected fields",
      "confirm": "确认",
      "continue": "继续",
      "createDocument": {
        "almostDone": "Almost done. Please don't click Refresh or Back button"
      },
      "createDocumentErrorMessage": "创建文档失败",
      "creationDateLabel": "发送时间",
      "dashboard": {
        "adminView": "管理员视图",
        "adminViewTooltipText": "查看从此帐户发送的所有文档",
        "autoDeleteText": "Auto-deletes in {{remaining}} day(s)",
        "clearFilters": "清除过滤器",
        "clearSearch": "清除搜索",
        "continueAction": "继续",
        "deleteAction": "删除",
        "downloadPdf": "下载",
        "esignLeftNav": "签名",
        "loadMore": "加载更多",
        "overdue": "逾期 1 天",
        "signatureDue": "截止日期: {{dueDate}}",
        "signatureRequests": "签名请求",
        "signatureSettings": "Signature settings",
        "signaturesSubmitted": "签名已提交",
        "templates": "模板",
        "title": "签名请求"
      },
      "dashboardResults": {
        "noFilterResults": {
          "description": "看起来没有任何对象与您的过滤器相匹配。",
          "title": "此处没有任何内容"
        },
        "noRequestsCreated": {
          "description": "需要获取签名吗? 请创建请求。",
          "title": "您还没有任何签名请求"
        },
        "noSearchResults": {
          "description": "看起来没有任何内容符合您的搜索条件。",
          "title": "此处没有任何内容"
        }
      },
      "date": "日期",
      "dateExample": "示例:",
      "dateExtendError": "请选择将来的日期",
      "dateFormatLabel": "格式",
      "dateSignedLabel": "自动填写签署日期",
      "dateSignedTooltip": "收件人将无法编辑此字段。它将在签署文件的那天自动填写。",
      "declinedByLabel": "{{name}} 已拒绝",
      "defaultBadgeLabel": "Default",
      "defaultSettings": {
        "attachments": {
          "attachCompletedPDFCheckboxLabel": "向所有人发送一封包含已完成的 PDF 的电子邮件",
          "attachCompletedPDFCheckboxTooltip": "在最后一次签名后，我们将向所有人发送一封电子邮件，其中包含已完成的 PDF 附件(除非大于 10 MB)。",
          "sectionHeader": "附件"
        },
        "header": "编辑默认设置",
        "headerInfo": "您在此处所做的任何更改都将应用于所有正在处理的签名请求。",
        "hipaaConfirmationBodyFirst": "应用此设置后，所有收件人都将收到已签名的文档的副本，该副本以电子邮件附件的形式发送。作为 HIPAA 客户，您有责任确认收件人允许以这种方式处理他们的信息。",
        "hipaaConfirmationBodySecond": "是否确实要应用此设置?",
        "hipaaConfirmationButton": "应用设置",
        "hipaaConfirmationHeader": "HIPAA 注意事项",
        "reminders": {
          "options": {
            "daily": "每天",
            "noAuto": "从不",
            "weekly": "每周"
          },
          "reminderFrequencyLabel": "发送自动提醒",
          "reminderFrequencyTooltip": "每周(3、7、14、21 和 28 天后)或每天(7 天后)向收件人发送电子邮件提醒，直到收件人进行签名为止。",
          "sectionHeader": "提醒"
        },
        "signature": {
          "allowDrawnSignatureLabel": "允许使用绘制的签名",
          "allowTypedSignatureLabel": "允许使用打印的签名",
          "applyBlueInkSignatureLabel": "对签名应用蓝色墨水",
          "applyBlueInkSignatureTooltipAdmin": "如果需要更改此设置，请联系您的管理员。",
          "applyBlueInkSignatureTooltipNonAdmin": "对所有签名使用蓝色墨水，以帮助区分已签名的原件和复印件。",
          "sectionHeader": "签名类型"
        },
        "signer": {
          "allowDownload": "允许签名者在签名之前下载原始文档",
          "sectionHeader": "签名者权限"
        }
      },
      "defaultSettingsDescription": "更改提醒、电子邮件附件和签名类型的设置。",
      "defaultSettingsEditLabel": "编辑默认设置",
      "defaultSettingsLabel": "默认设置",
      "defaultSettingsUpdateFailed": "无法更新默认设置",
      "defaultSettingsUpdateSuccess": "默认设置已更新",
      "defaultValueLabel": "添加默认文本",
      "delete": "删除",
      "deleteDocument": {
        "deleteConfirmationMessageFirst": "这将永久删除签名请求。完成此操作后将无法撤消。",
        "deleteConfirmationMessageSecond": "签名者和查看者将收到一封电子邮件，其中包含已完成的文档和签名证书以供其记录在案。",
        "deleteRequest": "删除请求",
        "deleteRequestSuccess": "请求已删除"
      },
      "deleteDraftDocumentLabel": "删除签名请求",
      "deleteDraftDocumentModalContent": "是否确实要删除该签名请求? 此操作无法撤消。",
      "deleteDraftDocumentSuccess": "签名请求已删除",
      "deleteViewerWarningHeader": "Are you sure you want to remove this recipient?",
      "deleteViewerWarningMessage": "They’ll no longer have access to this signature request.",
      "detailsTabSecurityOptions": {
        "editPasscode": "编辑通行码",
        "kba": "基于知识的身份验证",
        "passcode": "密码",
        "title": "已应用安全选项",
        "updatePasscodeSuccess": "本文档的通行码已更新。"
      },
      "documentAction": {
        "editButtonDisabledReason": {
          "bulkSend": "You can't revise requests that were sent with bulk send",
          "signerSigned": "You can't revise requests once someone has signed",
          "userNotSender": "You can't revise requests that you didn't send"
        }
      },
      "documentAlreadySentErrorDetails": "无法打开已发送的文档进行编辑，请启动新流程。",
      "documentAlreadySentErrorTitle": "文档已发送",
      "documentForSignature": "要发送的文件",
      "documentForceCompleted": "请求强制执行已完成",
      "documentLockedMessage": "由于多次尝试身份验证失败，此收件人被暂时锁定",
      "documentNameBlank": "输入文档名称",
      "documentNameInputDisabledTooltip": "无法编辑文档名称",
      "documentNameInputLabel": "文档名称",
      "documentStates": {
        "declined": "拒绝",
        "draft": "草稿",
        "editing": "暂停以进行修订",
        "executed": "已完成",
        "expired": "已过期",
        "pending": "正在进行",
        "voided": "已失效"
      },
      "documentThumbnailAltText": "第一页缩略图",
      "documentTransactionCreateErrorMessage": "无法创建文档事务",
      "documentTransactionGetErrorMessage": "无法获取文档事务",
      "documentTransactionNotEntitledErrorMessage": "You do not have permission to send a document for signature. Please contact the admin to get access.",
      "documentUnlockSuccessful": "文档解锁成功",
      "documentView": {
        "documentPageIndex": "第 {<currentPage>} 页，共 {{totalPages}} 页",
        "pageChangeInputLabel": "将页面更改为",
        "pageDown": "下一页",
        "pageUp": "上一页"
      },
      "documentVoided": "签名请求已失效",
      "dontShowAgain": "不再显示此消息",
      "downloadOptions": {
        "combinedPDF": "组合 PDF",
        "completedPDF": "已完成的 PDF",
        "download": "下载",
        "downloadErrorMessage": "不支持的下载类型: {{downloadType}}",
        "downloadOriginal": "原始 PDF",
        "signatureCertificate": "签名证书"
      },
      "draftSaveErrorMessage": "无法保存签名请求",
      "draftSaveSuccessMessage": "签名请求已保存",
      "draw": "绘图",
      "dueDate": "到期日期",
      "dueDateUpdated": "到期日期已更新",
      "edit": "编辑",
      "editDocumentModal": {
        "cancel": "返回",
        "confirm": "修订",
        "contentLine1": "如果您需要添加或删除收件人或字段，或者更改安全选项，则可以修订此请求。",
        "contentLine2": "当您进行修订时，我们将暂停此请求。这意味着尚未签名的每个人都将暂时失去访问权限。当您完成修订后，我们会向其发送一个新链接。",
        "contentLine3": "开始修订后，如果不发送修订后的文档，您将无法取消暂停此请求。",
        "title": "是否确实要修订此请求?"
      },
      "editDueDate": "编辑到期日期",
      "editLeftMidwayModal": {
        "cancel": "返回",
        "confirm": "稍后完成",
        "contentLine1": "暂停此请求以进行修订时，签名者将无法访问此请求。如果您完成了修订，请返回并选择 ",
        "contentLine2": "如果您还没有准备好，可以稍后再返回并完成修订。",
        "title": "您是否仍在进行修订?"
      },
      "editRecipients": "编辑收件人",
      "editSignatureRequest": "修订",
      "editSigner": "Edit signer",
      "editViewer": "Edit viewer",
      "emptyFolder": "此文件夹为空",
      "enforceStorageUsage": "在释放一些空间之前，您将无法创建新的签名请求",
      "expirationDateMessage": "过期日期: {{expirationDate}}",
      "expirationDaysLabel": "天",
      "expirationInputDescription": "在指定的天数后，文档将无法再签名:",
      "expirationInputLabel": "过期时间",
      "extendDueDate": "延长到期日期",
      "extendExpirationDate": "延长",
      "favorites": "收藏夹",
      "field": "字段",
      "fieldNameLabel": "“名称”字段",
      "fieldRequired": "此字段为必填字段",
      "fields": "字段",
      "fieldsAssignedWarningHeader": "分配给签名者的字段",
      "fieldsAssignedWarningMessage": "分配给签名者的所有字段都将被删除。",
      "fieldsDescription": "选择要放置在文档中的字段类型",
      "fileArchivalConfigurator": {
        "editButtonLabel": "编辑",
        "sectionLabel": "您想将签名的文档存储在哪里?"
      },
      "fileNotSupportedTooltip": "不支持的文件格式",
      "filesAndRecipients": "文件和收件人",
      "filterSelected": "1 个过滤器",
      "forceComplete": "强制完成",
      "forceCompleteTitle": "强制完成请求",
      "forceCompleteWarningFirst": "此操作将删除分配给尚未签名的签名者的所有字段，并将文档设置为已执行(最终状态)。此操作完成后将无法撤消。",
      "forceCompleteWarningSecond": "在控制板上反映新状态可能需要一小段时间。",
      "generateNewPasscodeButtonLabel": "生成新密码",
      "genericError": "出现错误",
      "groupRule": "组规则",
      "hasAtleastOneSignerErrorMessage": "至少添加一个签名者才能继续",
      "importFields": {
        "linkText": "Import fields",
        "modal": {
          "assignImportedFields": "Assign Imported Fields",
          "body": {
            "buttonText": "Select",
            "searchPlaceholder": "Search by document name, file name, sender or role",
            "sentBy": "Sent by"
          },
          "componentsImported": "{{count}} fields imported",
          "fields": "field(s)",
          "importFieldsButtonText": "Import fields",
          "importFrom": "Import from:",
          "mapAllRecipients": "Assign fields to new recipients to continue",
          "newRecipient": "New recipient",
          "noDocuments": "You don’t have any documents yet",
          "originalRecipient": "Original recipient",
          "selectDifferentDocument": "Select different document",
          "selectRecipient": "Select recipient",
          "sentBy": "Sent by",
          "title": "Select a document to import fields",
          "titleImportFields": "Import fields",
          "titleImportFieldsWithCount": "Import {{count}} fields"
        },
        "noFieldsToImportModal": {
          "confirmButtonText": "Go back",
          "contentLine1": "{<documentName>} doesn't have any fields that we can import. You can choose a different document or create new fields.",
          "title": "This document doesn't have any fields"
        },
        "pageMismatchModal": {
          "cancelButtonText": "Go back",
          "confirmButtonText": "Import fields anyway",
          "contentLine1": "The document you selected has a different number of pages than the one you're currently working on.",
          "contentLine2": "You can still import fields, but we'll only look at the first {{pageCount}} page(s) of the selected document. Any fields on subsequent pages won't be imported.",
          "title": "These documents look a little different"
        },
        "tooltipText": "Import fields from previously sent documents"
      },
      "initials": "首字母缩写",
      "invalidEmail": "电子邮件无效",
      "invalidExpirationDaysError": "输入介于 1 到 365 之间的数字",
      "invalidExpirationDaysNotificationMessage": "选择过期日期",
      "invalidRecipientErrorMessage": "我们需要每个收件人的姓名和电子邮件",
      "invalidSignatureRequest": "签名请求似乎无效，请验证 URL",
      "invite": "Invite",
      "inviteViewers": "Invite viewers",
      "kbaDescription": "要求收件人通过基于知识的测验来验证其身份。每个签名者在签署文档之前都需要独立验证。",
      "kbaInfoMessage": "3 次尝试失败后，签名者将被锁定在文档之外。",
      "kbaLabel": "基于知识的身份验证",
      "legacyTokenInterceptor": {
        "buttonText": "登录",
        "message": "我们将带您返回此处，以便您能够从上次离开的位置继续操作。",
        "title": "重新登录以访问您的签名请求"
      },
      "messageLabel": "给收件人的消息",
      "mixedAlignment": "Mixed alignment",
      "mixedDateFormat": "Mixed format",
      "mixedFontSizes": "Mixed font sizes",
      "moveAwaySelections": "如果离开当前文件夹，所做的选择将丢失。",
      "multiLineLabel": "允许多行",
      "multipleRecipients": "Multiple recipients",
      "nameGroup": "名称组",
      "nextStep": "下一步",
      "optional": "可选",
      "optionalComment": "可选的附带评论",
      "optionsLabel": "选项",
      "packagerConfirmationModal": {
        "contentLine1": "我们需要先将这些文档合并为一个文件，才能将其准备好进行签名。继续执行下一步后，您将无法返回并删除任何文档。",
        "contentLine2": "您准备好继续操作了吗?",
        "mergeModalText": "这可能需要一分钟时间。您的文档准备就绪后，我们将立即带您进入下一步。",
        "progressBarText": "正在合并...",
        "title": "合并文档"
      },
      "passcodeCharacterWarning": "密码只能包含数字。",
      "passcodeDescription": "收件人必须输入 {<fiveDigit>}密码才能访问文档。",
      "passcodeFiveDigit": "5 位数",
      "passcodeInfoMessage": "您需要与每个收件人共享此密码。",
      "passcodeLabel": "密码",
      "passcodeLengthError": "选择密码或者禁用该安全选项",
      "passcodeLengthErrorEditMode": "通行码必须为 5 位数字",
      "pending": "挂起",
      "personalFolders": "个人文件夹",
      "placeFields": "放置字段",
      "prepareDocument": {
        "components": {
          "attachment": "附件",
          "checkmarkAnnotation": "添加复选标记",
          "editor": {
            "chargeAfterExecutedTooltip": "如果您想在所有签名者都签名后从其信用卡中扣款，请使用此选项",
            "collectCreditCardTooltip": "如果您只想收集信用卡信息，请使用此选项",
            "defaultHelpText": {
              "attachmentComponent": "在此处上载您的附件",
              "paymentComponent": "请在此处添加您的信用卡详细信息"
            },
            "fieldSubtypeLabel": "字段子类型",
            "fontSizeLabel": "字体大小",
            "helpTextLabel": "添加帮助文本",
            "paymentAmountLabel": "付款金额(美元)",
            "paymentAmountPlaceHolder": "输入付款金额"
          },
          "textAnnotation": "添加文本"
        },
        "errors": {
          "dropdownInsufficientOptionsError": "每个下拉菜单至少需要包含 2 个选项",
          "paymentAmountInvalidError": "请输入付款金额以继续"
        },
        "noSignerComponentInfoModal": {
          "bulkSendSubtitle": "Signers don't have anything to sign.",
          "cancelButtonText": "返回",
          "confirmButtonText": "仍然继续",
          "description": "无论如何，您都可以继续进行下一步操作，也可以返回并添加字段以供其填写。",
          "subtitle": "这些签名者没有任何要签名的文档:",
          "title": "您是否忘记了任何字段?"
        }
      },
      "previewPage": {
        "documentDetails": {
          "documentId": "Document ID",
          "dueDate": "Due Date",
          "otherDetails": "Other details",
          "recipients": "Recipients",
          "sender": "Sender",
          "sentOn": "Sent on",
          "signerSequencing": "Signer sequencing applied",
          "signers": "Signers"
        },
        "documentHeader": {
          "status": "Status:",
          "viewDetails": "View details"
        },
        "downloadModal": {
          "combinedDescription": "Includes signature cerificate and completed document",
          "combinedTitle": "Combined PDF",
          "completedDocumentDescription": "Signed document with completed fields",
          "completedDocumentTitle": "Completed document",
          "done": "Done",
          "originalDescription": "PDF document without any signer fields",
          "originalTitle": "Original PDF",
          "signatureCertificateDescription": "Certificate of trust from Sharefile",
          "signatureCertificateTitle": "Signature certificate",
          "title": "Download document"
        },
        "pageHeader": {
          "completed": "Completed",
          "download": "Download",
          "downloadOriginalDocument": "Download original document",
          "inProgress": "In Progress"
        }
      },
      "previousStep": "上一步",
      "processingState": "正在处理...",
      "radio": "单选组",
      "readyState": "已就绪!",
      "reasonDeclineLabel": "拒绝原因",
      "reasonVoidLabel": "无效原因",
      "recipientDuplicate": "此人已经是查看者",
      "recipientEmail": "电子邮件",
      "recipientListHeading": "谁是收件人?",
      "recipientName": "姓名",
      "recipientNoName": "此字段为必填字段",
      "recipientRemindButtonLabel": "提醒",
      "recipientRole": "角色",
      "recipientRoleSigner": "签名者",
      "recipientRoleViewer": "查看者",
      "recipientStatusSigned": "已签名",
      "recipientUnlockLabel": "解锁",
      "recipientsLabel": "收件人",
      "redirection": {
        "bulkSend": {
          "bodyLineOne": "很快，ShareFile 将允许您上传收件人的 CSV，这样您就可以一次请求最多 300 人的签名。在此之前，您仍然可以在 RightSignature 中执行此操作。",
          "closeButton": "取消",
          "header": "想批量发送?",
          "redirectButton": "转至 RightSignature"
        },
        "packager": {
          "bodyLineOne": "很快，ShareFile 将允许您在一个签名请求中发送多个文档。在此之前，您仍然可以在 RightSignature 中执行此操作。",
          "closeButton": "取消",
          "header": "需要发送多个文档?",
          "redirectButton": "转至 RightSignature"
        },
        "template": {
          "bodyLineOne": "您很快就可以在 ShareFile 中创建和使用签名模板了。",
          "bodyLineTwo": "在 RightSignature 中尝试一下，做好准备。",
          "closeButton": "现在不",
          "header": "使用模板简化您的流程",
          "mergeField": {
            "button": "Go to RightSignature",
            "description": "If you have any templates with merge fields in RightSignature, they are not visible here. You can use them in RightSignature"
          },
          "redirectButton": "试用模板"
        }
      },
      "referenceIdLabel": "参考 ID",
      "reminderSent": "已发送提醒",
      "removeBulkSignersConfirmationModal": {
        "confirm": "删除签名者",
        "content": "从 {<fileName>} 中导入的所有签名者都将被删除。",
        "title": "是否确实要删除这些签名者?"
      },
      "removeCheckboxGroup": "删除复选框组",
      "removeFieldLabel": "删除字段",
      "removeFileDisabledTooltip": "无法删除文档",
      "removeFileFailed": "无法删除文件。请重试。",
      "requestSignatureButton": {
        "label": "创建签名请求"
      },
      "requireGroup": "需要组",
      "required": "必填",
      "requiredFieldLabel": "必填字段",
      "requiredFieldsLabel": "Require fields",
      "reviewAndSend": "查看并发送",
      "reviewDocument": {
        "documentName": {
          "input": {
            "emptyFieldErrorMessage": "此字段不能为空"
          }
        }
      },
      "save": "保存",
      "saveCompletedDocument": "保存已完成的文档",
      "savedSignaturesLabel": "Select the signature to apply in the document",
      "search": "搜索",
      "securityOptionsLabel": "安全选项",
      "selectOneOrMoreLabel": "选择一个或多个",
      "selectOnlyOneLabel": "仅选择一个",
      "selfSignCheckDisabledToolTip": "选择模板时无法使用自签名",
      "selfSignCheckLabel": "我是唯一的签名者",
      "selfSignCheckToolTip": "自己在文档上签名，然后共享。它将保存在所选文件夹中。",
      "selfSignSucessMessage": "文档已保存",
      "selfSignature": "您的签名",
      "sendForSignature": "发送以获取签名",
      "sendInBulk": {
        "allSigners": "全部 {<signersCount>} 个签名者",
        "bulkSigners": "批量签名者",
        "couldTakeAMoment": "这可能需要一些时间。",
        "disableTooltipTextForPLG": "Upgrade your plan to get access",
        "downloadCSVTemplateButton": "下载 CSV 模板",
        "email": "电子邮件",
        "errors": {
          "correctTheFollowingError": "请更正文件中的以下错误并重试。",
          "exceedsRecipientsLimit": "{<fileName>} 超过了我们的 300 位签名者限制。",
          "fileSizeExceeded": "此文件大于我们的 5 MB 限制。",
          "onlyOneFile": "只能从单个文件导入签名者。",
          "supportedFileType": "只能从 .csv 文件导入签名者。",
          "unableToLoadSpreadsheet": "您的文件缺少一些重要信息。"
        },
        "gatheringSignersFromFile": "正在从您的文件中收集签名者...",
        "goBack": "返回",
        "import": "导入",
        "importSigners": "导入签名者",
        "importSignersModalText1": "我们为您准备了一个 CSV 模板。请下载该模板并填写姓名和电子邮件字段(必填)。",
        "importSignersModalText2": "CSV 是唯一受支持的文件格式。签名者人数不能超过 300 人。",
        "name": "姓名",
        "note": "注意: ",
        "previewingTheSigners": "正在预览前 {<firstSignersCount>} 个签名者，共 {<signersCount>} 个",
        "selectDifferentFile": "选择其他文件",
        "title": "批量发送",
        "tooltipText": "批量发送功能只能与单个签名者模板一起使用",
        "uploadFileText": "请将您的 CSV 拖至此处或者"
      },
      "sendRevisedRequest": "发送修订后的请求",
      "sendSignatureRequest": "发送签名请求",
      "sendSigningLink": {
        "gotIt": "明白了",
        "sentLinkInfoBanner": "我们向您发送了一个链接",
        "signingLinkInfoMessage1": "请检查您的 {<email>} 电子邮件以获取用于对此文档签名的链接。",
        "signingLinkInfoMessage2": "您很快就可以直接登录 ShareFile 了。"
      },
      "senderRoleChangeTooltip": "发件人不能是查看者",
      "sentByLabel": "发送者",
      "setSigningOrder": "设置签名顺序",
      "shareFileFolder": {
        "selectShareFileFolder": {
          "cancelButtonLabel": "取消",
          "createFolderLabel": "创建文件夹",
          "disabledNodeUploadReason": "Upload permission is needed to select this folder",
          "disabledSharedFoldersReason": "无法选择顶层共享文件夹",
          "modalTitle": "将签名的协议保存到...",
          "selectFolderButtonLabel": "选择"
        }
      },
      "sharedFolders": "共享文件夹",
      "showAll": "全部显示",
      "signButtonLabel": "签名",
      "signYourself": "自签名",
      "signature": "签名",
      "signatureAnnotationMessage": "单击并拖动即可在框内绘制您的签名",
      "signatureAnnotationTextMessage": "请在下面的字段中键入您的签名",
      "signatureRequest": "签名请求",
      "signatureRequestErrorMessage": "签名请求失败",
      "signatureRequestSuccessMessage": "已发送签名请求",
      "signer": "Signer",
      "signerDescription": {
        "annotation": "Place fields for yourself and fill them out in advance",
        "bulkSigners": "You're placing fields for all signers",
        "signers": "You’ll place fields for each signer"
      },
      "signerMessageInputLabel": "面向所有签名者的注释(可选)",
      "signerMessageInputLabelTooltip": "我们会将此备注包含在发送给签名者的电子邮件通知中",
      "signerMessageInputPlaceholder": "为所有签名者添加个性化注释",
      "signerPage": {
        "content": {
          "documentDetails": {
            "documentInfoTitle": "Document name and ID",
            "dueDateTitle": "Due date",
            "senderInfoTitle": "Sender",
            "senderMessageTitle": "Message from sender"
          },
          "fields": {
            "addInitials": "Add Initials",
            "attachment": {
              "buttonText": "Attach file",
              "fileUploadingError": "Unable to upload file. Please try again",
              "modalButtonContinue": "Done",
              "modalDeleteFileCancelButton": "Cancel",
              "modalDeleteFileOkButton": "Delete",
              "modalDeleteFileText": "Are you sure you want to delete {<fileName>}?",
              "modalDeleteFileTitle": "Delete file",
              "modalTitle": "Upload file",
              "modalUploadAnotherFileText": "and upload new file?",
              "uploadDifferentFileButton": "Upload different file",
              "uploadHint": "Single file",
              "uploadText": "Click or drag file to this area to upload",
              "uploadingStatus": "Uploading"
            },
            "autofilled": "Autofilled",
            "dropdown": {
              "clearSelection": "Clear selection",
              "selectOption": "Select option"
            },
            "enterText": "Enter Text",
            "format": "Format",
            "optional": "Optional",
            "paymentField": {
              "amount": "Amount",
              "authorizePayment": "Authorize payment",
              "authorizeTooltip": "Your card will be charged ${{paymentAmount}} once you submit this document",
              "change": "Change",
              "clear": "Clear",
              "creditCardModalDetails": {
                "authorizeMessage": "I agree to be charged {<paymentAmount>} after submitting this document",
                "cvcTooltipMessage": "A three or four-digit security code printed on the back (or front for AMEX) of your credit card",
                "labels": {
                  "cardNumber": "Card Number",
                  "cvc": "CVC",
                  "expiryDate": "Expiry Date"
                },
                "modalTitle": "Edit card details",
                "placeholders": {
                  "cardNumber": "Enter card number",
                  "cvc": "Enter CVC"
                }
              },
              "creditDetails": "Enter card details"
            },
            "required": "Required",
            "selectDate": "Select date",
            "signature": {
              "clearSignatureButtonText": "Clear signature",
              "editSignatureButtonText": "Edit signature",
              "openSignatureModalText": "Sign here",
              "prefilledSignatureText": "For your convenience, we’ve pre-filled your name. You can change it if you need to."
            }
          },
          "navigation": {
            "next": "Next",
            "nextField": "Next field",
            "startSigning": "Start signing"
          },
          "progressBar": {
            "shortSuccessText": "You’ve completed all required fields",
            "status": "{{fieldsFilled}}/{{totalFields}} required fields",
            "submitAction": "Submit",
            "successText": "You’ve completed all required fields. Once you’re done reviewing, you can {<submitAction>} the document."
          },
          "progressComponent": {
            "buttonLabel": "Fields tracker",
            "drawerTitle": "Track your progress"
          },
          "sidePanelTabs": {
            "fieldsTab": {
              "noFields": "You have no fields to fill out",
              "optionalCount": "{{count}} optional field",
              "optionalOnly": "You have {<optionalCountLabel>} to fill out",
              "pending": "Pending",
              "requiredAndOptional": "You have {<requiredCountLabel>} and {<optionalCountLabel>} to fill out",
              "requiredCount": "{{count}} required field",
              "requiredOnly": "You have {<requiredCountLabel>} to fill out"
            },
            "label": {
              "details": "Details",
              "fields": "Fields"
            }
          }
        },
        "declineDocument": {
          "header": "Declined document",
          "message": "You have declined this document. If you still wish to sign it, please ask sender to send a new document",
          "sender": "Sender"
        },
        "header": {
          "documentActions": {
            "actions": "Actions",
            "actionsAndDetails": "Actions and details",
            "declineDocument": "Decline document",
            "declineFailed": "We couldn't decline the document. Try again",
            "downloadOriginal": "Download original PDF",
            "saveFailed": "We couldn't save your progress. Try again",
            "saveProgress": "Save progress",
            "saveSuccess": "Saved progress"
          },
          "moreActions": "More actions"
        },
        "helpAndFaq": {
          "haveQuestionsContent": "Contact the sender: {{senderName}} at {{senderEmail}} with any questions.\n\nShareFile e-signature is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
          "haveQuestionsTitle": "I have question about contents of this document",
          "howToCompleteContentTitle": "Follow steps below:",
          "howToCompleteFirst": "Click on ‘Start signing’. It will point you to the first field",
          "howToCompleteFourth": "Once you have completed all the required fields, you can submit the document",
          "howToCompleteSecond": "Use ‘Next’ button on the left side to move to next field",
          "howToCompleteThird": "You can use field tracker on right side panel to track your progress",
          "howToCompleteTitle": "How do I complete this document?",
          "isLegallyContent": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.\n\nShareFile’s e-signature technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
          "isLegallyTitle": "Is this legally binding?",
          "title": "Frequently asked questions",
          "whatIsShareFileContent": "ShareFile e-signature allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. ShareFile e-signature is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
          "whatIsShareFileTitle": "What is ShareFile?"
        },
        "modals": {
          "authenticationFailed": {
            "contentHeader": "Authentication Failed!",
            "contentMessage": "You have exceeded maximum number of authentication attempts. {<br>}Please ask sender to unlock this document for you.",
            "sender": "Sender"
          },
          "declineDocument": {
            "cancel": "Cancel",
            "decline": "Decline",
            "reason": "Reason",
            "reasonHelp": "This reason will be shared with the sender",
            "reasonPlaceholder": "Why are you declining?",
            "title": "Decline document",
            "warningMessage": "Are you sure you want to decline this document? We’ll send a message to sender and all the signers who has signed this document before you. You won’t be able to undo this."
          },
          "digiCert": {
            "2FAMessage": {
              "message": "and follow instructions there to authorize signature",
              "mobileApp": "{{mobileAppName}} mobile app",
              "open": "Open"
            },
            "completeAuthorization": "Complete authorization",
            "credentials": {
              "certificate": {
                "certificate": "{{certificateType}} certificate",
                "emptySearch": "Your search doesn't match any certificates",
                "noCredentials": "You don't have any valid certificates in this account",
                "select": "So please select",
                "sender": "Sender has requested",
                "signature": "{{certificateType}} certified signature."
              },
              "infoMessage": "Once you click on Authorize signature, you will receive further instructions on {{mobileAppName}} Mobile app",
              "placeholder": "Select certificate",
              "title": "Authorize signature"
            },
            "failedRequest": {
              "badRequest": "Failed to authorize signature",
              "message": "Please try again",
              "timeout": "Your authorization attempt has timed out",
              "unauthorized": "DigiCert session expired",
              "unauthorizedMessage": "Please sign in to DigiCert again"
            },
            "message": "Once you authorize your signature using DigiCert certificate document will be submitted to sender",
            "signIn": "Sign in to DigiCert",
            "steps": {
              "firstStep": {
                "description": "If you don’t have DigiCert account then you will be prompted to create one",
                "title": "Sign in to DigiCert"
              },
              "secondStep": {
                "description": "You will be asked select QES or AES certificate and complete 2-factor authentication",
                "title": "Complete authorization via 2FA"
              },
              "title": "Steps to authorize signature"
            },
            "title": "Complete eIDAS signature authorization"
          },
          "kba": {
            "OK": "OK",
            "birthday": "Date of birth",
            "btnCompleteVerification": "Complete verification",
            "btnNextStep": "Next step",
            "cityLabel": "City",
            "countryPlacehplder": "Select country",
            "countyLabel": "Country",
            "identityVerificationAdvisory": "Identity Verification Advisory",
            "identityVerificationAdvisoryInformation": "You are receiving this document using ShareFile's e-signature service, such as RightSignature. The sender of this document has enabled Knowledge-Based Authentication to verify your identity. ShareFile uses IDology, a third party service to help perform this verification service. The use of your personal information for these purposes will have no effect on your credit report, credit score, or reporting to lenders.",
            "personalInfo": "Personal info",
            "requiredField": "This field is required",
            "requiredQuestion": "This is a required question",
            "securityQuestions": "Security questions",
            "selectDate": "Select date (DD/MM/YYYY)",
            "senderInformation": "{{senderEmail}} has invited you to sign this document. Complete identity verification to access the document.",
            "signerName": "Name",
            "ssnLabel": "Last 4 digits of SSN",
            "stateLabel": "State",
            "statePlaceholder": "Select state",
            "streetAddress": "Street address",
            "streetAddressHint": "Only your street address is required for this verification",
            "verificationFailed": "Identity verification failed. Please enter correct information and try again.",
            "welcomeMessage": "Welcome!",
            "zipcodeLabel": "Zipcode"
          },
          "passcode": {
            "attemptsAmount": "{{attemptsAmount}} attempt remaining",
            "attemptsAmountPlural": "{{attemptsAmount}} attempts remaining",
            "contentMessage": "Enter the 5-digit passcode provided by the sender to access this document.",
            "inputLabel": "Enter passcode",
            "inputPlaceholder": "Enter 5 digit passcode",
            "inputValidationMessage": "Passcode must be 5 digits",
            "inputValueRequiedMessage": "This field is required",
            "invalidPasscodeMessage": "Invalid passcode.",
            "senderInformation": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}",
            "unlockButton": "Unlock",
            "welcomeMessage": "Welcome!"
          },
          "shortcuts": {
            "closeModal": "Close modal",
            "goToNextField": "Go to next field",
            "goToPreviousField": "Go to previous field",
            "interactWithField": "Interact with the field",
            "openDropdownMenu": "Open dropdown menu/modal",
            "or": "or",
            "redo": "Redo",
            "selectOption": "Select option/action",
            "showHideShortcuts": "Show/Hide shortcuts",
            "title": "Keyboard shortcuts",
            "undo": "Undo",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out"
          },
          "success": {
            "allSignedText": "We sent a copy of the completed document to {<email>}",
            "downloadButton": "Download document",
            "notAllSignedText": "We will send a copy of the signed document to {<email>} once all signers have signed this document",
            "reviewButton": "Review signed document",
            "title": "Submitted document"
          },
          "termsAndConditions": {
            "continueButton": "Continue",
            "esignConsent": "E-Sign consent",
            "footerEsignConsent": "You're acquainted with and agree to our {<link>}",
            "footerTerms": "You're acquainted with and agree to our {<linkTermsOfUse>} and {<linkPrivacyPolicy>}",
            "footerTitle": "By continuing, you confirm that:",
            "greetings": "Hi {{signerName}},",
            "privacyPolicy": "Privacy Policy",
            "senderMessageTitle": "Message from sender",
            "termsOfUse": "Terms of Use",
            "text": "{{senderName}} ({{senderEmail}}) has invited you to sign {<documentName>}"
          }
        },
        "submitDocument": {
          "header": "Submit document",
          "submit": "Submit",
          "submitConfirm": "Are you sure you're ready to submit this document?",
          "submitWarning": "You won't be able to make changes after submitting",
          "submitWithUnfilledOptionalFields": "You skipped an optional field. Are you sure you're ready to submit this document?",
          "submitWithUnfilledRequiredFields": "Complete all required fields to submit the document"
        }
      },
      "signerSequencingDisabledTooltip": "签名顺序仅适用于超过 1 位签名者的情况",
      "signerSequencingNotApplicableTooltip": "签名顺序不适用于查看者",
      "signers": "签名者",
      "signersLabel": "签名者",
      "singerSequencingAppliedText": "应用了签名者排序",
      "sizeLimitFile": "文件 {{filename}} ",
      "sizeLimitMessage": "超过 20 MB 限制",
      "startDocument": {
        "document": {
          "delete": {
            "bulkSignDeleteModal": {
              "confirmButton": "Remove template",
              "content": "The bulk signers list will be lost and you'll need to upload a new document to proceed.",
              "header": "Are you sure you want to remove this template"
            },
            "modal": {
              "confirmButton": "删除文档",
              "content": "删除文档会删除所有预定义的收件人及其分配的字段。是否确实要删除该文档?",
              "header": "删除文档"
            }
          }
        },
        "template": {
          "modal": {
            "body": {
              "buttonText": "选择",
              "createdBy": "创建者:",
              "searchPlaceholder": "Search"
            },
            "eligible": "Eligible for bulk send",
            "emptyTemplateListDescription": "Here you will be able to find templates from your RightSignature account",
            "failedToLoadTemplates": "无法加载模板。请稍后再试",
            "footer": {
              "buttonText": "创建模板",
              "question": "找不到您要搜索的内容?"
            },
            "noDocuments": "您还没有任何模板",
            "noSearchResult": {
              "subtitle": "We couldn't find {<searchTerm>} in any template titles or descriptions.",
              "title": "Your search doesn't match any templates"
            },
            "noSearchResults": "此处没有任何内容",
            "noSearchResultsDesc": "看起来没有任何内容符合您的搜索条件。",
            "rightSignatureTemplates": "RightSignature templates",
            "rolesLabel": "Roles:",
            "title": "选择模板"
          },
          "sourceName": "使用模板"
        },
        "upload": {
          "basefileProcessingFailed": "无法处理文件 {{ filename }}。请重新上载该文件",
          "failed": "上载文件 {{ filename }} 失败",
          "numberLimitMessage": "无法上载 5 个以上的文件"
        }
      },
      "statusFilter": "状态",
      "switch": "Switch to old experience",
      "template": "模板",
      "temporaryRedirectText": "Temporarily switch to RightSignature",
      "text": "文本",
      "textAlignLabel": "对齐文本",
      "type": "类型",
      "unableToFetchFileDetails": "无法获取文件详细信息",
      "unableToProceedNoDocumentError": "无法继续，请添加文档",
      "unableToProceedNoSignerError": "无法继续，请至少添加一个签名者",
      "unauthorizedModal": {
        "alreadySigned": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "You have already signed this document"
        },
        "completed": {
          "description": "You can review the document by requesting a new link from sender",
          "title": "Document completed"
        },
        "declined": {
          "description": "Any one of the signers has declined this document. Please reach out to sender for more information",
          "title": "Declined document"
        },
        "expired": {
          "description": "This document expired before it was completed. Please reach out to sender for more information",
          "title": "Document expired"
        },
        "invalid": {
          "description": "Please use valid link from your email",
          "title": "Invalid document link"
        },
        "noAccess": {
          "description": "Sender has revised this signature request. Please reach out to sender for more information",
          "title": "You don’t have access to this document"
        },
        "notification": {
          "description": "Please check your email inbox",
          "title": "Sent link!"
        },
        "onRevision": {
          "description": "Sender is currently revising this document. Try again later or reach out to sender for more information",
          "title": "Paused for revisions"
        },
        "pendingIdentityVerification": {
          "boldDescription": "check your email and click the 'Verify email' link",
          "description": "You have successfully signed this document. To submit this document you will need to verify your identity. To do this, simply {<boldDescription>} that was sent to you by ShareFile.",
          "title": "Pending identity verification"
        },
        "resendVerificationLink": "Resend verification link",
        "sendNewLink": "Send new link",
        "sender": "Sender",
        "tokenExpired": {
          "description": "Please request a new link to view this document",
          "title": "Document link expired"
        },
        "voided": {
          "description": "This document has been voided by the sender. Reach out to sender for more information",
          "title": "Voided document"
        },
        "wrongTurn": {
          "description": "Sender has enabled signer sequencing for this document. You will receive an email when it’s your turn to sign",
          "title": "It’s not your turn to sign yet"
        }
      },
      "unsupportedFileTypeMessage": "不支持此文档类型。支持的文件类型为 .doc、.docx、.txt、.pdf",
      "updateDocumentErrorMessage": "无法更新文档。",
      "uploadDocumentErrorMessage": "上载文档以进入下一步",
      "uploadedFilesProcessingErrorMessage": "上载的一个或多个文件仍在处理中",
      "useSaved": "Use saved",
      "userFetchErrorMessage": "检索用户信息失败",
      "view": "查看",
      "viewer": "Viewer",
      "viewerUniqueEmailErrorMessage": "查看者电子邮件必须唯一",
      "viewersLabel": "查看者",
      "void": "失效",
      "voidConfirmationTitle": "无效文件确认",
      "zoomIn": "放大",
      "zoomOut": "缩小"
    }
  }
];